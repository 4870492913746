<div class="profile-middle-sec appsettings-custom common-padd">
  <div class="container">
    <div style="display: none" class="card">
      <h3
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        Email Change Section
      </h3>
      <div class="card-mid-description">
        <form [formGroup]="appSettingsEmailFrom">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Current Email</mat-label>
                  <input
                    type="text"
                    matInput
                    placeholder="Current Email"
                    required="required"
                    formControlName="oldEmail"
                    readonly
                  />
                </mat-form-field>
              </div>
              <!-- <div *ngIf="f.oldEmail.touched && f.oldEmail.invalid">
                <span class="text-left text-danger pb-1" *ngIf="f.oldEmail.errors.required">Alert Name is required
                </span>
            </div> -->
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>New Email</mat-label>
                  <input
                    type="text"
                    matInput
                    placeholder="New Email"
                    required="required"
                    formControlName="newEmail"
                    [pattern]="emailPattern"
                  />
                </mat-form-field>
              </div>
              <div *ngIf="f.newEmail.touched && f.newEmail.invalid">
                <span
                  class="text-left text-danger pb-1"
                  *ngIf="f.newEmail.errors.required"
                  >New Email is required
                </span>
                <span
                  class="text-left text-danger pb-1"
                  *ngIf="f.newEmail.errors?.pattern"
                >
                  New Email is not valid.
                </span>
              </div>
            </div>
          </div>
        </form>
      </div>
      <p style="text-align: right">
        <!-- <button mat-flat-button style="color: #3eab70; border: 1px solid #3eab70;"
            >CANCEL</button>&nbsp; -->
        <button mat-raised-button color="primary" (click)="changeEmail()">
          UPDATE
        </button>
      </p>
    </div>

    <div class="card auth-mainbox" *ngIf="this.userDeatils.isEmail">
      <h1
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        Password Change Section
      </h1>
      <div class="card-mid-description">
        <form [formGroup]="appSettingsPasswordFrom">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Current Password</mat-label>
                  <input
                    type="password"
                    matInput
                    placeholder="Current Password"
                    required="required"
                    formControlName="oldPassword"
                    [type]="showPassword ? 'text' : 'password'"
                  />
                  <mat-icon
                    matSuffix
                    (click)="togglePasswordVisibility()"
                    style="cursor: pointer"
                    >{{
                      showPassword ? "visibility_off" : "visibility"
                    }}</mat-icon
                  >
                </mat-form-field>
              </div>
              <div *ngIf="g.oldPassword.touched && g.oldPassword.invalid">
                <span
                  class="text-left text-danger pb-1"
                  *ngIf="g.oldPassword.errors.required"
                  >Current Password is required
                </span>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>New Password</mat-label>
                  <input
                    type="password"
                    matInput
                    placeholder="New Password"
                    required="required"
                    formControlName="NewPassword"
                    [pattern]="passwordPattern"
                    [type]="showPassword2 ? 'text' : 'password'"
                  />
                  <mat-icon
                    matSuffix
                    (click)="togglePasswordVisibility2()"
                    style="cursor: pointer"
                    >{{
                      showPassword2 ? "visibility_off" : "visibility"
                    }}</mat-icon
                  >
                </mat-form-field>
              </div>
              <!-- <div *ngIf="g.NewPassword.touched && g.NewPassword.invalid">
                <span class="text-left text-danger pb-1" *ngIf="g.NewPassword.errors.required">New Password is required
                </span>
            </div> -->
              <div *ngIf="g.NewPassword.touched && g.NewPassword.invalid">
                <span
                  class="text-left text-danger pb-1"
                  *ngIf="g.NewPassword.errors.required"
                  >New Password is required
                </span>
                <span
                  class="text-left text-danger pb-1"
                  *ngIf="g.NewPassword.errors?.pattern"
                  >You must use upper or lower case text,numbers & The special
                  characters ~ ! % * _ - [ ] ? , .
                </span>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Confirm Password</mat-label>
                  <input
                    type="password"
                    matInput
                    placeholder="Confirm Password"
                    required="required"
                    formControlName="ConfirmPassword"
                    [type]="showPassword3 ? 'text' : 'password'"
                  />
                  <mat-icon
                    matSuffix
                    (click)="togglePasswordVisibility3()"
                    style="cursor: pointer"
                    >{{
                      showPassword3 ? "visibility_off" : "visibility"
                    }}</mat-icon
                  >
                </mat-form-field>
              </div>
              <!-- <div *ngIf="f.ConfirmPassword.touched && f.ConfirmPassword.invalid">
                <span class="text-left text-danger pb-1" *ngIf="f.ConfirmPassword.errors.required">New Password is required
                </span>
            </div> -->
              <div
                *ngIf="g.ConfirmPassword.touched && g.ConfirmPassword.invalid"
                class="text-left text-danger pb-1"
              >
                <div *ngIf="g.ConfirmPassword.errors.required">
                  Confirm Password is required.
                </div>
                <div *ngIf="g.ConfirmPassword.errors.confirmedValidator">
                  Password and Confirm Password must be match.
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <p style="text-align: right">
        <!-- <button mat-flat-button style="color: #3eab70; border: 1px solid #3eab70;"
        >CANCEL</button>&nbsp; -->
        <button mat-raised-button color="primary" (click)="changePassWord()">
          UPDATE
        </button>
      </p>
    </div>
  </div>
</div>
