import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { HttpIOService } from 'src/app/services/http-io.service';
import { JsEditProfileComponent } from '../js-edit-profile/js-edit-profile.component';
import { JsEducationComponent } from '../js-education/js-education.component';
import { JsExperianceComponent } from '../js-experiance/js-experiance.component';
import { JsLicenseOrCertificateComponent } from '../js-license-or-certificate/js-license-or-certificate.component';
import { JsSkillComponent } from '../js-skill/js-skill.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { environment } from '../../../../environments/environment';
import { FirstProfileOptionComponent } from '../first-profile-option/first-profile-option.component';
import { SharejobComponent } from '../../job-search/sharejob/sharejob.component';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import html2pdf from 'html2pdf.js';
import { JobseekerShareProfileComponent } from '../jobseeker-share-profile/jobseeker-share-profile.component';
import { Observable, Observer } from 'rxjs';
import imageToBase64 from 'image-to-base64/browser';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
@Component({
  selector: 'app-job-seeker-profile',
  templateUrl: './job-seeker-profile.component.html',
  styleUrls: ['./job-seeker-profile.component.scss'],
})
export class JobSeekerProfileComponent implements OnInit {
  isDisplay: boolean = false;
  userDeatils: any;
  companyData: any;
  educationAllRecords: any;
  experianceAllRecords: any;
  licenseAllRecords: any;
  profileRecords: any = { isResumeParsed: false };
  skillAllRecords: any = [];
  fileName: any;
  fileToUpload: any;
  uploadFileResult: any;
  base64File: string | ArrayBuffer;
  show: boolean;
  cv: boolean = false;
  base64Image: string;
  base64Data: any;
  CVpurserData: any;
  v: any;
  imageForPdf: any;
  isFirstOpened: boolean = false;
  // skillShow = [];
  // allFruits: any[] = ['Apple', 'Lemon', 'Lime', 'Orange', 'Strawberry'];
  constructor(
    // @Inject(MAT_DIALOG_DATA) public data: any,
    private titleService: Title,
    private router: Router,
    private io: HttpIOService,
    private helper: Helper,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    // private dialogRef: MatDialogRef<JobSeekerProfileComponent>,
    private fb: UntypedFormBuilder,
    private auth: AuthService
  ) {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    if (localUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
    }
    if (sessionUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }
  }

  ngOnInit(): void {
    this.getAllDetails();
  }

  async getAllDetails() {
    let jsonData = {
      id: this.userDeatils.id,
      path: 'get-job-seeker',
    };
    await this.io._JSallDetails(jsonData).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        if (!res['result'].userData.isResumeParsed && !this.isFirstOpened) {
          // console.log("+++++++++++++++")
          const dialogRef = this.dialog.open(FirstProfileOptionComponent, {
            height: 'auto',
            width: '550px',
            autoFocus: false,
            data: {},
          });
          dialogRef.afterClosed().subscribe((res) => {
            if (res) {
            } else {
              this.show = true;
            }
          });
        } else {
          // this.router.navigate(['/view-profile-data']);
          this.show = true;
        }

        this.isFirstOpened = true;
        this.profileRecords = res['result'].userData;
        this.educationAllRecords =
          res['result'].userData.educations == undefined
            ? []
            : res['result'].userData.educations;
        this.experianceAllRecords =
          res['result'].userData.experience == undefined
            ? []
            : res['result'].userData.experience;
        this.licenseAllRecords =
          res['result'].userData.license == undefined
            ? []
            : res['result'].userData.license;
        this.skillAllRecords =
          res['result'].userData.skills == undefined
            ? []
            : res['result'].userData.skills;
        this.getBase64ImageFromUrl(this.profileRecords.profileImage);
      }
    });
  }

  async getBase64ImageFromUrl(image) {
    let jsonData = {
      path: 'image-convert',
      key: decodeURIComponent(
        image
          .toString()
          .replace('https://worknigeria-file-storage.s3.amazonaws.com/', '')
      ),
    };
    this.io._profilePictureForPdf(jsonData).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        this.imageForPdf = res['result'].imageData;
      }
    });
  }

  yearClculation(data) {
    if (data.startDate) {
      if (data.endDate === '' || data.endDate === null) {
        let thisYear = new Date().getFullYear();
        let dtArr = data.startDate.split('-');
        let final = thisYear - Number(dtArr[0]);
        return final;
      }
    }
    if (data.startDate && data.endDate) {
      let dtstart = data.startDate.split('-');
      let dtend = data.endDate.split('-');
      if (dtstart[0] !== dtend[0]) {
        let final = parseInt(dtend[1], 10) + 12 - parseInt(dtstart[1], 10);
        return final;
      } else {
        let final = parseInt(dtend[1], 10) - parseInt(dtstart[1], 10);
        return final;
      }
    }
    return '';
  }

  calculateDuration(start: string, end: string): string {
    // Convert start and end dates to Date objects
    const startDate = new Date(start);
    const endDate = end ? new Date(end) : new Date();

    // Get the start and end years and months
    const startYear = startDate.getFullYear();
    const endYear = endDate.getFullYear();
    const startMonth = startDate.getMonth();
    const endMonth = endDate.getMonth();
    const diffInDays = Math.ceil(
      (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24)
    );

    // If start and end years and months are the same, return "less than a month"
    if ((startYear === endYear && startMonth === endMonth) || diffInDays < 30) {
      return 'less than a month';
    }
    // If start and end years are the same, return the number of months between them
    else if (startYear === endYear) {
      return `${endMonth - startMonth} ${endMonth - startMonth === 1 ? 'month' : 'months'
        }`;
    }
    // If start and end years are different, calculate the number of months and years between them
    else {
      const months = (endYear - startYear) * 12 + (endMonth - startMonth);
      const years = Math.floor(months / 12);
      const remainingMonths = months % 12;

      // If the duration is less than a year, return the number of remaining months
      if (years === 0) {
        return `${remainingMonths} ${remainingMonths === 1 ? 'month' : 'months'
          }`;
      }
      // If the duration is a whole number of years, return the number of years
      else if (remainingMonths === 0) {
        return `${years} ${years === 1 ? 'year' : 'years'}`;
      }
      // If the duration is a combination of years and months, return both
      else {
        return `${years} ${years === 1 ? 'year' : 'years'
          } and ${remainingMonths} ${remainingMonths === 1 ? 'month' : 'months'}`;
      }
    }
  }

  experiance(data) {
    const dialogRef = this.dialog.open(JsExperianceComponent, {
      height: 'auto',
      width: '550px',
      autoFocus: false,
      data: { data, wholeData: Object.assign([], this.experianceAllRecords) },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res == 'save') {
        this.getAllDetails();
      }
    });
  }

  education(data) {
    const dialogRef = this.dialog.open(JsEducationComponent, {
      height: 'auto',
      width: '550px',
      autoFocus: false,
      data: { data, wholeData: Object.assign([], this.educationAllRecords) },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res == 'save') {
        // console.log("++++++++++++")
        this.getAllDetails();
      }
    });
  }

  profile() {
    const dialogRef = this.dialog.open(JsEditProfileComponent, {
      height: 'auto',
      width: '550px',
      autoFocus: false,
      data: { data: this.profileRecords },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res == 'save') {
        // console.log("++++++++++++")
        this.getAllDetails();
      }
    });
  }

  certifiate(data) {
    const dialogRef = this.dialog.open(JsLicenseOrCertificateComponent, {
      height: 'auto',
      width: '550px',
      autoFocus: false,
      data: { data, wholeData: Object.assign([], this.licenseAllRecords) },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res == 'save') {
        // console.log("++++++++++++")
        this.getAllDetails();
      }
    });
  }

  skill() {
    const dialogRef = this.dialog.open(JsSkillComponent, {
      height: 'auto',
      width: '550px',
      autoFocus: true,
      data: { data: Object.assign([], this.skillAllRecords) },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res == 'save') {
        // console.log("++++++++++++")
        this.getAllDetails();
      }
    });
  }

  onSelectFile(event) {
    this.fileName = event.target.files[0].name;
    if (event.target.files && event.target.files[0]) {
      let filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        this.fileToUpload = event.target.files[i];
        // const reader = new FileReader();
        // reader.onloadend = () => {
        //   let fileNameExtention = this.fileName.split(".");
        //   // console.log(fileNameExtention)
        //   if (fileNameExtention[1] == 'docx') {
        //     this.base64Data = reader.result.toString().replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,", "");
        //   } else {
        //     this.base64Data = reader.result.toString().replace("data:application/pdf;base64,", "");
        //   }
        // let jsonData = {
        //   "filedata": this.base64Data,
        //   "fileName": this.fileName,
        //   "userId": this.userDeatils.id,
        //   "path": "add-parsed-resume",
        // }
        // console.log(jsonData)

        const formData = new FormData();
        formData.append('path', 'parse-resume');
        formData.append('userId', this.userDeatils.id);
        formData.append('file', this.fileToUpload);
        formData.append('purpose', 'parseResumeUpload');

        this.io._CVpurse(formData).subscribe(
          (res: any) => {
            if (res.serverResponse.code == 200) {
              // console.log(res)
              this.CVpurserData = res;
              this.io.setforCVpurserData(this.CVpurserData);

              this.router.navigate(['/jobseeker-profile-confirm']);
            }
            if (res.serverResponse.code == 404) {
              // this.snackBar.open(res.serverResponse.message, "OK", {
              // })
              this.snackBar
                .openFromComponent(DynamicSnacbarErrorComponent, {
                  data: res.serverResponse.message,
                  verticalPosition: 'top', // 'top' | 'bottom'
                  horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
                })
                .afterOpened()
                .subscribe(this.helper.setOnTop);
            }
          },
          (err) => console.log(err)
        );
        // };
        // reader.readAsDataURL(this.fileToUpload);
      }
    }
  }

  // Share job //
  shareJob() {
    const dialogRef = this.dialog.open(JobseekerShareProfileComponent, {
      height: 'auto',
      width: '550px',
      data: { userData: this.profileRecords },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res === true) {
        return;
      }
    });
  }

  async exportAsPDF() {
    this.isDisplay = true;
    setTimeout(() => {
      this.genPdf();
    }, 1000);
  }

  // genPdf() {
  //   var element = document.getElementById('htmlData');
  //   var opt = {
  //     margin: 0.7,
  //     filename: 'CV.pdf',
  //     image: { type: 'jpeg', quality: 0.98 },
  //     html2canvas: { scale: 2 },
  //     jsPDF: { unit: 'in', orientation: 'portrait', pdfA: true },
  //   };
  //   html2pdf().set(opt).from(element).save();
  //   this.isDisplay = false;
  // }

  // To generate the CV as a pdf document
  genPdf() {
    const element = document.getElementById('htmlData');
    const opt = {
      margin: [0.5, 0.5, 0.5, 0.5], // Increase margin slightly for better readability
      filename: this.profileRecords?.firstName + '_CV.pdf',
      image: { type: 'jpeg', quality: 1.0 }, // Use max quality for sharper images
      html2canvas: { scale: 3, useCORS: true }, // Increase scale for better resolution, enable CORS for images
      jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
    };

    html2pdf().set(opt).from(element).save();
    this.isDisplay = false;
  }
}
