<div class="recruitment">
    <div class="recruit-header row">
        <div class="col-lg-5">
            <h1>Get Top Talent Fast; Expert Recruitment Services for Nigerian Businesses</h1>
            <button (click)="goToConsultForm('consulationForm')">Book A Consultation</button>
        </div>
        <div class="col-lg-1"></div>
        <div class="col-lg-6">
            <img src="../../../assets/images/recruit-handshake.webp" />
        </div>
    </div>
    <div class="row recruit-text">
        <h2>Tired of Struggling to Find the Right Talent?</h2>
        <p>Hiring the right candidates can feel like an endless challenge—sorting through countless resumes, facing
            long hiring timelines, and still ending up with candidates who aren’t the right fit. At Worknigeria, we
            simplify the process. We provide complete recruitment and staffing solutions tailored to meet your needs,
            connecting you with top talent quickly and efficiently. </p>
    </div>
    <div class="what-we-for row">
        <div class="col-lg-6 first-col">
            <h2>Our recruitment services are geared for:</h2>
            <ul>
                <li *ngFor="let item of services">
                    {{ item }}
                </li>
            </ul>
            <img src="../../../assets/images/iso-certification.webp" />
        </div>
        <div class="col-lg-6 second-col">
            <h2>What we offer</h2>
            <p>We specialize in providing top-notch recruitment and expatriate management solutions tailored to your
                organization’s needs such as:</p>
            <ul>
                <li *ngFor="let offr1 of offer_one">
                    <h3>{{ offr1.heading }}</h3>
                    <p>{{ offr1.text }}</p>
                </li>
            </ul>
        </div>
    </div>
    <div class="get-consult row" id="consulationForm">
        <div class="col-lg-6">
            <ul>
                <li *ngFor="let offr1 of offer_two">
                    <h3>{{ offr1.heading }}</h3>
                    <p>{{ offr1.text }}</p>
                </li>
            </ul>
        </div>
        <div class="col-lg-6 form-section">
            <form [formGroup]="consultForm" autocomplete="off" class="consult-form">
                <img src="../../../assets/images/woman-consult-icon.webp" />
                <h3>GET A FREE CONSULTATION</h3>
                <input placeholder="Name*" type="text" class="custom-input" formControlName="fullName" />
                <div *ngIf="f.fullName.touched && f.fullName.invalid">
                    <span class="text-left text-danger pb-1" *ngIf="f.fullName.errors.required">Name is required
                    </span>
                    <span class="text-left text-danger pb-1" *ngIf="f.fullName.errors?.pattern">
                        Name is not valid.
                    </span>
                </div>
                <input placeholder="Email*" type="email" class="custom-input" formControlName="email" />
                <div *ngIf="f.email.touched && f.email.invalid">
                    <span class="text-left text-danger pb-1" *ngIf="f.email.errors.required">Email is required
                    </span>
                    <span class="text-left text-danger pb-1" *ngIf="f.email.errors?.pattern">
                        Email is not valid.
                    </span>
                </div>
                <input placeholder="Phone Number*" type="text" class="custom-input" formControlName="phonenumber" />
                <div *ngIf="f.phonenumber.touched && f.phonenumber.invalid">
                    <span class="text-left text-danger pb-1" *ngIf="f.phonenumber.errors.required">Phone Number is required
                    </span>
                    <span class="text-left text-danger pb-1" *ngIf="f.phonenumber.errors?.pattern">
                        Phone Number is not valid.
                    </span>
                </div>
                <button (click)="sendEmail()">SEND</button>
            </form>
        </div>
    </div>
    <!-- How WN works -->
    <div class="how-it-works">
        <h2>How It Works</h2>
        <div class="card-container">
            <div class="card working-cards" *ngFor="let card of WN_works">
                <img [src]="card.img" alt="{{ card.heading }}" />
                <h3>{{ card.heading }}</h3>
                <p>{{ card.text }}</p>
            </div>
        </div>
    </div>
    <!-- Choose WN -->
    <div class="choose-wn">
        <h2>Why Choose Worknigeria?</h2>
        <div class="row">
            <div class="col-lg-6">
                <ul>
                    <li *ngFor="let pointer of choose_wn">
                        <p><span>{{ pointer.title }}</span>{{ pointer.text }}</p>
                    </li>
                </ul>
            </div>
            <div class="col-lg-6">
                <img src="../../../assets/images/choose-wn.webp" />
            </div>
        </div>
    </div>
    <!-- Trusted by Section -->
    <div class="trusted-by-section">
        <h2>Trusted by leading Employers in Nigeria:</h2>
        <p>We are proud to partner with various clients across industries who trust us to deliver great solutions
            tailored to their particular needs. Our client base shows our commitment to quality, innovation, and
            excellence.</p>
        <div class="ticker-outer-container">
            <div class="ticker-inner-container">
                <div class="ticker-container">
                    <div *ngFor="let logo of logos" class="ticker-item">
                        <img [src]="logo" class="ticker-img" alt="Logo" />
                    </div>
                    <div *ngFor="let logo of logos" class="ticker-item">
                        <img [src]="logo" class="ticker-img" alt="Logo" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Testimonials Section -->
    <div class="testimonials-section">
        <h4>Testimonials</h4>
        <!-- Carousel Cards -->
        <div id="cardCarousel" class="carousel slide" data-bs-interval="false">
            <!-- Carousel inner -->
            <div class="carousel-inner">
                <div *ngFor="let cardSet of groupedCards(); let i = index" class="carousel-item"
                    [ngClass]="{'active': i === 0}">
                    <div class="row justify-content-center">
                        <div class="col" *ngFor="let card of cardSet; let j = index">
                            <div class="card testimonials-card">
                                <p class="name-initials" [ngClass]="getColorClass(j)">{{ getInitials(card.companyName)
                                    }}</p>
                                <h5 class="card-title">{{card.quote}}</h5>
                                <div class="star-icons">
                                    <ng-container *ngFor="let star of [1, 2, 3, 4, 5]">
                                        <i class="fas fa-solid fa-star"
                                            [ngClass]="{ 'golden-star': star <= card.stars, 'white-star': star > card.stars }"></i>
                                    </ng-container>
                                </div>
                                <p class="card-text">– {{card.companyName}}</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Indicators -->
            <div class="carousel-indicators">
                <button *ngFor="let page of [].constructor(totalPages); let i = index" type="button"
                    data-bs-target="#cardCarousel" [attr.data-bs-slide-to]="i" [ngClass]="{ 'active': i === 0 }"
                    [attr.aria-current]="i === 0 ? 'true' : null" [attr.aria-label]="'Slide ' + (i + 1)"></button>
            </div>
        </div>
        <!-- Carousel Cards -->
    </div>
    <!-- Frequently Asked Questions Section -->
    <div class="freq-ques">
        <h2>Frequently Asked Questions</h2>
        <div class="row d-flex align-items-center">
            <div class="col-lg-7">
                <ul class="freq-ques-list">
                    <li *ngFor="let item of questions; let i = index">
                        <div class="d-flex justify-content-between align-items-center" (click)="toggleQuestion(i)">
                            <h5>{{ item.Ques }}</h5>
                            <img [src]="item.open ? '../../../assets/images/minus-icon.webp' : '../../../assets/images/plus-icon.webp'"
                                [ngStyle]="{ 'height': item.open ? '3px' : '18px' }" />
                        </div>
                        <p *ngIf="item.open">{{ item.Ans }}</p>
                    </li>
                </ul>
            </div>
            <div class="col-lg-1"></div>
            <div class="col-lg-4 more-ques">
                <img src="../../../assets/images/message-icon.webp" />
                <h6>Do you have more questions?</h6>
                <p>Either on recruitment or any of our services including <b>Job Board, Background checks, Manpower
                        Outsourcing, Training, and more.</b></p>
                <button (click)="navigateToContactUs('Recruitment')">Contact us directly</button>
            </div>
        </div>
    </div>
</div>