import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { UnsebscribeComponent } from './container/elements/unsebscribe/unsebscribe.component';
import { WithNavComponent } from './container/layouts/with-nav/with-nav.component';
import { AuthGuard } from './gaurd/auth.guard';
import { HomePageGuardGuard } from './gaurd/home-page-guard.guard';
import { LoginGuard } from './gaurd/login.guard';
import { AuthService } from './services/auth.service';
import { HomeNewComponent } from './views/auth/home-new/home-new.component';
import { HomeComponent } from './views/auth/home/home.component';
import { JobSearchComponent } from './views/job-search/job-search.component';
import { TrainingDevelopmentComponent } from './views/auth/training-development/training-development.component';
import { SandboxComponent } from './views/sandbox/sandbox.component';
import { title } from 'process';
import { JobseekerCampaignComponent } from './views/jobseeker-campaign/jobseeker-campaign.component';
import { BackgroundCheckComponent } from './views/background-check/background-check.component';
import { EmployerLandingComponent } from './views/employer-landing/employer-landing.component';
import { ManpowerOutsourcingComponent } from './views/manpower-outsourcing/manpower-outsourcing.component';
import { RecruitmentLandingComponent } from './views/recruitment-landing/recruitment-landing.component';
import { SurveyPageComponent } from './views/survey-page/survey-page.component';
import { SearchUiComponent } from './views/job-search/search-ui/search-ui.component';

const routes: Routes = [
  {
    path: '',
    component: WithNavComponent,
    children: [
      {
        path: '',
        canActivate: [HomePageGuardGuard],
        component: SearchUiComponent,
        pathMatch: 'full',
      },
      {
        path: 'home',
        component: HomeNewComponent,
        data: {
          title: 'HOME',
        },
        canActivate: [HomePageGuardGuard],
      },
      {
        path: 'unsubscribe/:subscriptionId',
        component: UnsebscribeComponent,
      },
      {
        path: 'training',
        component: TrainingDevelopmentComponent,
        data: { title: 'Training and Development' },
      },
      {
        path: 'upload-cvs',
        component: SandboxComponent,
        data: { title: 'Sandbox - Upload CVs' },
      },
      {
        path: 'job-search',
        component: JobSearchComponent,
        data: {
          title: 'Job Search',
        },
      },
      {
        path: 'recruitment',
        component: RecruitmentLandingComponent,
        data: {
          title: 'Recruitment Landing Page',
        },
      },
      {
        path: 'smartscreening',
        component: SurveyPageComponent,
        data: {
          title: 'Survey Test Page',
        },
      },
      {
        path: '',
        loadChildren: () =>
          import('./views/auth/auth.module').then((m) => m.AuthModule),
      },
      {
        path: '',
        loadChildren: () =>
          import('./views/job-search/job-search.module').then(
            (m) => m.JobSearchModule
          ),
      },
      {
        path: '',
        loadChildren: () =>
          import('./views/jobseeker/jobseeker.module').then(
            (m) => m.JobseekerModule
          ),
      },
      {
        path: '',
        loadChildren: () =>
          import('./views/employer/employer.module').then(
            (m) => m.EmployerModule
          ),
      },
      {
        path: '',
        loadChildren: () =>
          import('./views/general/general.module').then((m) => m.GeneralModule),
      },
      {
        path: 'cv-search',
        loadChildren: () =>
          import('./views/cv-search/cv-search.module').then(
            (m) => m.CvSearchModule
          ),
      },
      {
        path: 'cv-review',
        loadChildren: () =>
          import('./views/cv-review/cv-review.module').then(
            (m) => m.CvReviewModule
          ),
      },
      {
        path: 'message',
        loadChildren: () =>
          import('./views/message/message.module').then((m) => m.MessageModule),
      },

      // {
      //   path: 'message',
      //   component: MessageComponent,
      //   data: {
      //     title: 'message',
      //   },canActivate: [AuthGuard]
      // }
    ],
  },
  {
    path: 'jobseeker-campaign',
    component: JobseekerCampaignComponent,
    data: {
      title: 'Jobseeker Campaign',
    },
  },
  {
    path: 'background-check',
    component: BackgroundCheckComponent,
    data: {
      title: 'Background Check',
    },
  },
  {
    path: 'employer-landing',
    component: EmployerLandingComponent,
    data: {
      title: 'Employer Landing Page',
    },
  },
  {
    path: 'manpower-outsourcing',
    component: ManpowerOutsourcingComponent,
    data: {
      title: 'Manpower Sourcing Page',
    },
  },

  // Wild Card Routes
  {
    path: '**',
    redirectTo: 'job-search',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {
  // userDeatils: any;
  // companyData: any;
  // constructor(
  //   private _authService: AuthService,
  //   private _router: Router
  // ) {
  //   let localUser = (localStorage.getItem('workNigeriaUser'));
  //   let sessionUser = (sessionStorage.getItem('workNigeriaUser'))
  //   if (localUser) {
  //     this.userDeatils = JSON.parse(this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser'))));
  //     this.companyData = this._authService.getCurrentCompanyData();
  //   }
  //   if (sessionUser) {
  //     this.userDeatils = JSON.parse(this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser'))))
  //     this.companyData = this._authService.getCurrentCompanyDataInSessoin();
  //   }
  //   if (this.userDeatils) {
  //     console.log(this.userDeatils.userType)
  //   }
  // }
}
