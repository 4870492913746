<body class="scrl">
  <div class="close-icon" (click)="onNoClickClose()">
    <span class="close"><a>&#10006;</a></span>
  </div>
  <h3 class="heading-1" *ngIf="selectedOption !== 'applyWithoutLogin'">How would you like to Apply?</h3>
  <h3 class="heading-1" *ngIf="selectedOption === 'applyWithoutLogin'">Apply Without Login</h3>
  <hr class="anon-hr" />

  <div class="apply-btns" *ngIf="selectedOption !== 'applyWithoutLogin'">
    <div class="login-to-apply" (click)="getContainerClass('loginToApply'); onNoClick();">
      <p>Login to Apply</p>
    </div>

    <div class="without-login" (click)="getContainerClass('applyWithoutLogin')">
      <p>Apply without Login</p>
    </div>
  </div>

  <form class="example-form" [formGroup]="anonymousForm" style="padding-right: 10px"
    *ngIf="selectedOption === 'applyWithoutLogin'">
    <div class="form-group">
      <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>First Name</mat-label>
        <input type="text" matInput placeholder="First Name" required="required" formControlName="firstName" />
      </mat-form-field>
      <div *ngIf="f.firstName.touched && f.firstName.invalid">
        <span class="text-left text-danger pb-1" *ngIf="f.firstName.errors.required">First Name is required
        </span>
        <span class="text-left text-danger pb-1" *ngIf="f.firstName.errors?.pattern">
          First Name is not valid.
        </span>
      </div>
    </div>

    <div class="form-group">
      <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Last Name</mat-label>
        <input type="text" matInput placeholder="Last Name" required="required" formControlName="lastName" />
      </mat-form-field>
      <div *ngIf="f.lastName.touched && f.lastName.invalid">
        <span class="text-left text-danger pb-1" *ngIf="f.lastName.errors.required">Last Name is required
        </span>
        <span class="text-left text-danger pb-1" *ngIf="f.lastName.errors?.pattern">
          Last Name is not valid.
        </span>
      </div>
    </div>


    <div class="form-group">
      <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Email</mat-label>
        <input type="email" matInput placeholder="Email" required="required" formControlName="email"
          [pattern]="emailPattern" />
      </mat-form-field>
      <div *ngIf="f.email.touched && f.email.invalid">
        <span class="text-left text-danger pb-1" *ngIf="f.email.errors.required">Email is required
        </span>
        <span class="text-left text-danger pb-1" *ngIf="f.email.errors?.pattern">
          Email is not valid.
        </span>
      </div>
    </div>
    <p>Upload a copy of your Cv on Worknigeria for other employers looking for similar role <span>[Optional]</span></p>
    <!-- CV Upload Container -->
    <div class="cv-upload-container">
      <div class="file-upload-container" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)"
        (drop)="onFileDropped($event)">
        <div class="dropzone" *ngIf="!file">
          <img src="../../../assets/images/upload-icon.png" />
          <p>Drag your file to start uploading</p>
        </div>
        <div class="or-continue" *ngIf="!file">
          <div class="or">OR</div>
        </div>
        <div class="custom-file-upload" *ngIf="!file">
          <input type="file" (change)="onFilesSelected($event)" accept=".pdf, .docx" />
          <button>Browse files</button>
        </div>
        <div *ngIf="file" class="progress-div">
          <h4>{{ uploadText }}</h4>
          <div class="d-flex align-items-center justify-content-center progress-bar-container">
            <mat-progress-bar mode="determinate" [value]="file.progress"></mat-progress-bar>
            <p class="uploaded-file-name">{{ file.name }}</p>
            <button (click)="removeFile()" class="btn-remove">&#10006;</button>
          </div>
        </div>
      </div>
    </div>
    <button class="btn-continue" (click)="saveCVs()">Continue</button>
  </form>
</body>

<!-- 
 <div>
    <mat-radio-group class="my-radio-group" [(ngModel)]="selectedOption">
      <div>
        <div [class]="getContainerClass()">
          <mat-radio-button class="applyLogin mat-accent" value="applyWithoutLogin">Apply without login</mat-radio-button>
        </div>
        <div [class]="getContainerClass()">
          <mat-radio-button value="loginToApply">Login to apply</mat-radio-button>
        </div>
      </div>
    </mat-radio-group>

    <form class="example-form" [formGroup]="anonymousForm" style="padding-right: 10px"
      *ngIf="selectedOption === 'applyWithoutLogin'">
      <div class="form-group">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>First Name</mat-label>
          <input type="text" matInput placeholder="First Name" required="required" formControlName="firstName" />
        </mat-form-field>
      </div>
      <div *ngIf="f.firstName.touched && f.firstName.invalid">
        <span class="text-left text-danger pb-1" *ngIf="f.firstName.errors.required">First Name is required
        </span>
      </div>

      <div class="form-group">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Last Name</mat-label>
          <input type="text" matInput placeholder="Last Name" required="required" formControlName="lastName" />
        </mat-form-field>
      </div>
      <div *ngIf="f.lastName.touched && f.lastName.invalid">
        <span class="text-left text-danger pb-1" *ngIf="f.lastName.errors.required">Last Name is required
        </span>
      </div>

      <div class="form-group">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Email</mat-label>
          <input type="email" matInput placeholder="Email" required="required" formControlName="email"
            [pattern]="emailPattern" />
        </mat-form-field>
        <div *ngIf="f.email.touched && f.email.invalid">
          <span class="text-left text-danger pb-1" *ngIf="f.email.errors.required">Email is required
          </span>
          <span class="text-left text-danger pb-1" *ngIf="f.email.errors?.pattern">
            Email is not valid.
          </span>
        </div>
      </div>
       
      <br />
      <p class="declaration">Declaration</p>

      <p class="declaration-details">
        The information in this application form is true and complete. I agree
        that any deliberate omission, falsification or misrepresentation in the
        application form will be grounds for rejecting this application or
        subsequent dismissal if employed by the organisation. Where applicable, I
        consent that the organisation can seek clarification regarding
        professional registration details.
      </p>
      <br />
      <section class="example-section">
        <mat-checkbox class="example-margin" [checked]="checked" [disabled]="disabled" class="check-box-statement"
          (change)="checkDeclaration($event.checked)">
          I agree to the above declaration
        </mat-checkbox>
      </section>
      <br />
      <p class="text-right pb-3">
        <button mat-flat-button (click)="onNoClickClose()">CANCEL</button>&nbsp;
        <button mat-raised-button color="primary" (click)="guestUserCreat()" [disabled]="declrationCheckBox == false">
          NEXT
        </button>
      </p>
    </form>

    <div *ngIf="selectedOption === 'loginToApply'">
      <br />
      <p class="declaration">Declaration</p>

      <p class="declaration-details">
        The information in this application form is true and complete. I agree
        that any deliberate omission, falsification or misrepresentation in the
        application form will be grounds for rejecting this application or
        subsequent dismissal if employed by the organisation. Where applicable, I
        consent that the organisation can seek clarification regarding
        professional registration details.
      </p>
      <br />
      <p class="text-right pb-2">      
        <button mat-raised-button color="primary" (click)="onNoClick()">Login</button>
      </p>
    </div>

  </div>
 -->