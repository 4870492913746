<div class="employer-landing-page">
    <!-- Navbar -->
    <header class="campaign-header">
        <div class="logo">
            <a href="javascript:void(0);" routerLink="/home">
                <img src="../assets/images/logo.webp" />
            </a>
        </div>
        <div class="list-btns">
            <ul>
                <li>
                    <button class="hdr-login" (click)="goToEmployerSignup()">Login</button>
                </li>
                <li>
                    <button class="hdr-signup" (click)="goToEmployerSignup()">SIGNUP</button>
                </li>
            </ul>
        </div>
    </header>
    <!-- Connect Section -->
    <div class="connect-with row">
        <div class="col-lg-6 col-12 connect-with-text">
            <h1>Connect with Quality Candidates, Access Top CVs, and Fill Roles Faster</h1>
            <p>GET STARTED FOR FREE</p>
            <button (click)="goToEmployerSignup()">Signup Here</button>
        </div>
        <div class="col-lg-6 col-12">
            <img src="../../../assets/images/connect_with_employer.webp" class="connect-with-img" />
        </div>
    </div>
    <!-- Trusted by Section -->
    <div class="trusted-by-section">
        <h2>Trusted by leading Employers in Nigeria:</h2>
        <div class="ticker-outer-container">
            <div class="ticker-inner-container">
                <div class="ticker-container">
                    <div *ngFor="let logo of logos" class="ticker-item">
                        <img [src]="logo" class="ticker-img" alt="Logo" />
                    </div>
                    <div *ngFor="let logo of logos" class="ticker-item">
                        <img [src]="logo" class="ticker-img" alt="Logo" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Why you should grow section -->
    <div class="why-grow">
        <div class="row">
            <div class="col-lg-6 col-12 order-lg-2 pl-0">
                <p class="why-grow-p">Tired of struggling to find the right candidates? Worknigeria’s Employer Job Board
                    gives you instant access to a diverse pool of skilled professionals across Nigeria. Whether you’re
                    hiring for entry-level roles or seeking seasoned experts, we’ve got you covered.</p>
                <h2>Why you should choose us to grow today.</h2>
                <ul class="custom-list">
                    <li *ngFor="let item of items">
                        <h4> {{ item.title }} </h4>
                        <p> {{ item.text }} </p>
                    </li>
                </ul>
            </div>
            <div class="col-lg-6 col-12 order-lg-1">
                <img class="what-you-get-img" src="../../../assets/images/why-grow-employer.webp" />
            </div>
        </div>
        <div class="get-started-strip">
            <p class="p-1">Get started for free today</p>
            <button class="p-2" (click)="goToEmployerSignup()">Sign up</button>
        </div>
    </div>
    <!-- How it works -->
    <div class="how-it-works">
        <h2>How to make the best of WorkNigeria</h2>
        <div class="wn-container">
            <div class="wn-cards">
                <img class="curve-arrow" src="../../../assets/images/orange-curve-arrow.webp" />
                <img class="icons" src="../../../assets/images/signup-icon.webp" />
                <h4>Signup</h4>
                <p>Create a free account in minutes and start posting your job openings right away.</p>
            </div>
    
            <div class="wn-cards bg-orange">
                <img class="curve-arrow-down" src="../../../assets/images/orange-curve-arrow.webp" />
                <img class="icons" src="../../../assets/images/post-job-icon.webp" />
                <h4>Post A Job</h4>
                <p>Fill out a simple job posting form to get your openings live and start receiving applications.</p>
            </div>
    
            <div class="wn-cards">
                <img class="curve-arrow" src="../../../assets/images/orange-curve-arrow.webp" />
                <img class="icons" src="../../../assets/images/browse-cv-icon.webp" />
                <h4>Browse CV</h4>
                <p>Easily narrow down candidates by skills, experience, location, and more.</p> 
            </div>
    
            <div class="wn-cards">
                <img class="icons" src="../../../assets/images/connect-icon.webp" />
                <h4>Connect and Hire</h4>
                <p>Contact candidates directly through the platform and streamline your hiring process.</p> 
            </div>
        </div>
    </div>
    <!-- get started and hired strip -->
    <div class="get-hired-strip">
        <p>Ready to Find Your Next Great Hire? Get Started for Free</p>
        <button (click)="goToEmployerSignup()">Sign Up for Free</button>
    </div>
    <!-- copyrights -->
     <div class="copyright-section">
        <p>© 2024 Worknigeria.</p>
     </div>
</div>