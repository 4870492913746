import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { Helper } from 'src/app/helper/defult';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { HttpIOService } from 'src/app/services/http-io.service';
import { UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-survey-page',
  templateUrl: './survey-page.component.html',
  styleUrls: ['./survey-page.component.scss']
})
export class SurveyPageComponent implements OnInit {
  emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9_.+-]{2,}$/;
  namePattern = /^[A-Za-z]+(?: [A-Za-z]+)*$/;
  companyPattern = /^(?!\s*$)[A-Za-z0-9 !@#$%^&*()_+[\]{};':"\\|,.<>/?-]+$/;
  phonePattern = /^\d{7,15}$/;
  surveyForm: UntypedFormGroup;
  formSubmitted = false;
  showForm = false;
  // Why you like array 
  pointers = [
    {
      img: '../../../../assets/images/save-time-icon.webp',
      title: 'Save Time',
      text: 'Reduce application overload and filter out unqualified candidates automatically.',
    },
    {
      img: '../../../../assets/images/match-icon.webp',
      title: 'Better Matches',
      text: 'Receive applications from only pre-qualified candidates who fit your specific needs.',
    },
    {
      img: '../../../../assets/images/hiring-icon.webp',
      title: 'Streamline Hiring',
      text: 'Improve efficiency and hire faster with targeted applications.',
    },
  ]
  constructor(
    private router: Router,
    private helper: Helper,
    private snackBar: MatSnackBar,
    private io: HttpIOService,
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.validationForm();
  }

  goToForm() {
    this.showForm = true;
    this.formSubmitted = false;
    window.scrollTo({ top: 0, behavior: 'smooth' });

    // Click Count
    let payload = {
      path: "/add-training-user-info",
      category: "surveybutton",
    }
    this.io._saveUserData(payload).subscribe((res: any) => {
      if (res.serverResponse.code === 200) {
      } else {
        this.snackBar
          .openFromComponent(DynamicSnacbarErrorComponent, {
            data: res.serverResponse.message,
            verticalPosition: 'top',
            horizontalPosition: 'right',
            duration: 6000,
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
      }
    })
  }

  //-------------- Survey Form Functions -------------------------------------
  validationForm() {
    this.surveyForm = this.fb.group({
      fullName: ['', Validators.compose([ Validators.required, Validators.pattern(this.namePattern),]),],
      email: ['', Validators.compose([Validators.required, Validators.pattern(this.emailPattern),]),],
      phoneNumber: ['', Validators.compose([Validators.required, Validators.pattern(this.phonePattern)]),],
      company: ['',Validators.compose([ Validators.pattern(this.companyPattern),]),],
      path: ['/add-training-user-info', Validators.required],
      category: ['survey', Validators.required],
    })
  }

  // Validation Controls //
  get f() {
    return this.surveyForm?.controls;
  }

  sendEmail() {
    if (this.surveyForm.status === 'INVALID') {
      this.helper.markFormGroupTouched(this.surveyForm);
      return false;
    }
    this.io._saveUserData(this.surveyForm.value).subscribe((res: any) => {
      if (res.serverResponse.code === 200) {
        this.formSubmitted = true;
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        this.snackBar
          .openFromComponent(DynamicSnacbarErrorComponent, {
            data: res.serverResponse.message,
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            duration: 6000,
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
      }
    })
  }
}
