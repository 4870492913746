import { Component, OnInit } from '@angular/core';
import { BackgroundCheckFormComponent } from 'src/app/container/elements/background-check-form/background-check-form.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-background-check',
  templateUrl: './background-check.component.html',
  styleUrls: ['./background-check.component.scss']
})
export class BackgroundCheckComponent implements OnInit {
  // Employers logos list
  logos = [
    '../../../../assets/images/tbo-logo.webp',
    '../../../../assets/images/zedcrest-logo.webp',
    '../../../../assets/images/aim-logo.webp',
    '../../../../assets/images/pecan-logo.webp',
    '../../../../assets/images/routelink-logo.webp',
    '../../../../assets/images/qlc-logo.webp',
    '../../../../assets/images/magna-logo.webp',
    '../../../../assets/images/manna-logo.webp',
    '../../../../assets/images/fohat-logo.webp',
    '../../../../assets/images/arise-logo.webp',
    '../../../../assets/images/richway-logo.webp',
    '../../../../assets/images/platview-logo.webp',
    '../../../../assets/images/cochlear-logo.webp',
    '../../../../assets/images/gosolve-logo.webp',
  ]

    // List of why you choose WN 
    items = [
      {
        title: 'Access Top Talent Pools:',
        text: ' We source and provide you with the best candidates across industries.',
      },
      {
        title: 'Tailored Hiring Solutions:',
        text: 'Customized strategies to meet your unique hiring needs and company values.',
      },
      {
        title: 'Fast & Efficient Recruitment:',
        text: 'We deliver even before your deadline.',
      },
      {
        title: 'Dedicated Support:',
        text: 'Get personalized service from a dedicated account manager who understands your business.',
      },
    ];

  // Cards Data for hiring risks
  hiring = [
    {
      image: '../../../../assets/images/criminal-history.webp',
      btext: 'Criminal History Checks:',
      para: 'Protect your workplace by verifying criminal records.',
    },
    {
      image: '../../../../assets/images/employment-education.webp',
      btext: 'Employment & Education Verification:',
      para: 'Confirm candidates’ work experience and academic achievements.',
    },
    {
      image: '../../../../assets/images/reference-check.webp',
      btext: 'Reference Checks and more:',
      para: 'Gain deeper insights into candidates’ past performance.',
    },
  ];

  constructor (
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
      
  }

  openForm() {
    const dialogRef = this.dialog.open(BackgroundCheckFormComponent, {
      height: 'auto',
      width: '761px',
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe();
  }
}
