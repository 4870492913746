import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { DynamicSnacbarSkipComponent } from 'src/app/container/elements/dynamic-snacbar-skip/dynamic-snacbar-skip.component';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { HttpIOService } from 'src/app/services/http-io.service';

@Component({
  selector: 'app-create-alert',
  templateUrl: './create-alert.component.html',
  styleUrls: ['./create-alert.component.scss'],
})
export class CreateAlertComponent implements OnInit {
  email?: string;
  createAlartForm: UntypedFormGroup;
  emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9_.+-]{2,}$/;
  checked: boolean = false;
  disabled = false;
  declrationCheckBox: any = false;
  jobCatagoryTypeList: any;
  cityRecords: any;
  employemntTypeList: any;
  public emailFrequency: any[] = ['daily', 'weekly', 'never'];
  userDeatils: any;
  companyData: any;
  selectedemploymentTypeName: any = '';
  selectedCategotyName: any = '';
  buttonVisible: boolean = false;
  public Salary: any[] = [
    100000, 200000, 300000, 400000, 500000, 600000, 700000, 800000, 900000,
    1000000,
  ];
  akhariLocation: any = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private titleService: Title,
    private router: Router,
    private io: HttpIOService,
    private helper: Helper,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<CreateAlertComponent>,
    private fb: UntypedFormBuilder,
    private auth: AuthService,
  ) {
    if (this.email == null) {
      let localUser = localStorage.getItem('workNigeriaUser');
      let sessionUser = sessionStorage.getItem('workNigeriaUser');
      if (localUser) {
        this.userDeatils = JSON.parse(
          this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
        );
        this.companyData = this.auth.getCurrentCompanyData();
      }
      if (sessionUser) {
        console.log(atob(sessionStorage.getItem('workNigeriaUser')));
        this.userDeatils = JSON.parse(
          this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
        );
        this.companyData = this.auth.getCurrentCompanyDataInSessoin();
      }
    }
  }

  ngOnInit(): void {
    this.validationForm();
    // this.createAlartForm.patchValue({salaryForm:this.data['PerticularAlert'].minimumSalary,})

    this.jobCatagory();
    this.location();
    this.employementType();
    if (this.data != null) {
      if (this.data['PerticularAlert'] != null) {
        this.patchValue();
        this.buttonVisible = true;
      }
      if (this.data['email'] != null) {
        this.email = this.data['email'];
      }
    }
  }
  // Validation Controls //
  get f() {
    return this.createAlartForm.controls;
  }

  validationForm() {
    this.createAlartForm = this.fb.group({
      alertName: ['', Validators.required],
      Keyword: ['', Validators.required],
      matchAllWord: [''],
      location: [''],
      salaryForm: [''],
      salaryTo: [''],
      category_id: [''],
      employment_type_id: [''],
      email_frequency: ['', Validators.required],
    });
  }

  patchValue() {
    let patchValue = {
      alertName: this.data['PerticularAlert'].alertName,
      Keyword: this.data['PerticularAlert'].keywords,
      location: this.data['PerticularAlert'].preferredLocation,
      salaryForm: this.data['PerticularAlert'].minimumSalary,
      salaryTo: this.data['PerticularAlert'].maximumSalary,
      category_id: this.data['PerticularAlert'].categoryId,
      employment_type_id: this.data['PerticularAlert'].employmentTypeId,
      email_frequency: this.data['PerticularAlert'].emailFrequency,
    };
    this.createAlartForm.patchValue(patchValue);
  }
  checkDeclaration(event) {
    // console.log(event)
    this.declrationCheckBox = event;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  // Job Catogery //
  jobCatagory() {
    this.io
      .apicall({ path: 'category-list' }, 'job/category-list')
      .subscribe((res: any) => {
        // console.log(res)
        if (res.serverResponse.code == 200) {
          this.jobCatagoryTypeList = res['result'].categoryList;
        } else {
          this.snackBar
            .openFromComponent(DynamicSnacbarErrorComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
              duration: 6000,
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
        }
      });
  }
  joblocationName(e) {
    let jobLocationNames = this.cityRecords.filter(
      (jobname) => jobname.id == e
    );
    console.log(jobLocationNames);
    this.akhariLocation = `${jobLocationNames[0]?.city},${jobLocationNames[0]?.state}`;

    // console.log(this.akhariLocation);
  }
  // CIty List //
  location() {
    let data = { path: 'city-list' };
    this.io._cityList(data).subscribe((res: any) => {
      // console.log(res)
      if (res.serverResponse.code == 200) {
        this.cityRecords = res['result'].cityList;
      }
    });
  }

  // Employement Type //
  employementType() {
    this.io
      .apicall({ path: 'employment-type-list' }, 'job/employment-type-list')
      .subscribe((res: any) => {
        // console.log(res)
        if (res.serverResponse.code == 200) {
          this.employemntTypeList = res['result'].employmentList;
        } else {
          this.snackBar
            .openFromComponent(DynamicSnacbarErrorComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
              duration: 6000,
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
        }
      });
  }
  changeCategoty(event) {
    this.selectedCategotyName = event.source.triggerValue;
  }
  changeEmploment(event) {
    this.selectedemploymentTypeName = event.source.triggerValue;
  }
  createAlert() {
    if (this.createAlartForm.status === 'INVALID') {
      this.helper.markFormGroupTouched(this.createAlartForm);
      return false;
    }
    if (
      Number(this.createAlartForm.get('salaryForm').value) >
      Number(this.createAlartForm.get('salaryTo').value)
    ) {
      // this.snackBar.open("' Salary To' needs to be greater ", "OK");
      this.snackBar
        .openFromComponent(DynamicSnacbarSkipComponent, {
          data: "' Salary To' should be greater ",
          verticalPosition: 'top', // 'top' | 'bottom'
          horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
        })
        .afterOpened()
        .subscribe(this.helper.setOnTop);
      return false;
    }
    let jsonData = {
      path: this.email != null ? 'set-alert-visitor' : 'set-alert',
      alertName: this.createAlartForm.get('alertName').value,
      userId: this.email != null ? '' : this.userDeatils.id,
      keywords: this.createAlartForm.get('Keyword').value,
      preferredLocation: this.createAlartForm.get('location').value,
      categoryId: this.createAlartForm.get('category_id').value,
      employmentTypeId: this.createAlartForm.get('employment_type_id').value,
      employmentType: String(this.selectedemploymentTypeName),
      jobCategory: String(this.selectedCategotyName),
      minimumSalary: this.createAlartForm.get('salaryForm').value,
      maximumSalary: this.createAlartForm.get('salaryTo').value,
      emailFrequency: this.createAlartForm.get('email_frequency').value,
      email: this.email,
    };
    const asArray = Object.keys(jsonData);

    asArray.map((item) => {
      if (jsonData[item] === 'any') {
        // console.log(jsonData[item]);
        // delete jsonData[item]
        jsonData[item] = '';
      }
    });
    // console.log('ooo',jsonData);

    if (this.email != null) {
      this.io._setAlertVisitor(jsonData).subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          // console.log(res);
          this.snackBar
            .openFromComponent(DynamicSnacbarComponent, {
              data: res.serverResponse.message,

              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
          this.dialogRef.close('save');
        }
      });
    } else {
      this.io._setAlert(jsonData).subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          // console.log(res);
          this.snackBar
            .openFromComponent(DynamicSnacbarComponent, {
              data: res.serverResponse.message,

              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
          this.dialogRef.close('save');
        }
      });
    }

    // let position = {

    // }
    //     this.snackBar.openFromComponent(DynamicSnacbarComponent, {
    //       data:'res.serverResponse.message',

    //     });
  }

  updateAlert() {
    if (this.createAlartForm.status === 'INVALID') {
      this.helper.markFormGroupTouched(this.createAlartForm);
      return false;
    }
    if (
      Number(this.createAlartForm.get('salaryForm').value) >
      Number(this.createAlartForm.get('salaryTo').value)
    ) {
      // this.snackBar.open("Salary to should be greater ", "OK");
      this.snackBar
        .openFromComponent(DynamicSnacbarSkipComponent, {
          data: "' Salary To' should be greater ",
          verticalPosition: 'top', // 'top' | 'bottom'
          horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
        })
        .afterOpened()
        .subscribe(this.helper.setOnTop);
      return false;
    }
    let jsonData = {
      path: 'modify-alert',
      alertName: this.createAlartForm.get('alertName').value,
      id: this.data['PerticularAlert'].id,
      keywords: this.createAlartForm.get('Keyword').value,
      preferredLocation: this.createAlartForm.get('location').value,
      categoryId: this.createAlartForm.get('category_id').value,
      employmentTypeId: this.createAlartForm.get('employment_type_id').value,
      employmentType:
        this.selectedemploymentTypeName == ''
          ? this.data['PerticularAlert'].employmentType
          : String(this.selectedemploymentTypeName),
      jobCategory:
        this.selectedCategotyName == ''
          ? this.data['PerticularAlert'].jobCategory
          : String(this.selectedCategotyName),
      minimumSalary: this.createAlartForm.get('salaryForm').value,
      maximumSalary: this.createAlartForm.get('salaryTo').value,
      emailFrequency: this.createAlartForm.get('email_frequency').value,
    };
    const asArray = Object.keys(jsonData);

    asArray.map((item) => {
      if (jsonData[item].toLowerCase() === 'any') {
        // delete jsonData[item]
        jsonData[item] = '';
      }
    });
    // console.log(jsonData);
    this.io._updateAlert(jsonData).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        // console.log(res);
        this.snackBar
          .openFromComponent(DynamicSnacbarComponent, {
            data: res.serverResponse.message,
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
        this.dialogRef.close('save');
      }
    });
  }
}
