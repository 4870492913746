import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from '../app/modules/material/material.module';
import { SharedModule } from '../app/modules/shared/shared.module';
import { GeneralModuleModule } from '../app/views/general-module/general-module.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  AngularEditorConfig,
  AngularEditorModule,
} from '@kolkov/angular-editor';
import { MAT_LEGACY_SNACK_BAR_DEFAULT_OPTIONS as MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/legacy-snack-bar';
import { SuccecErrorModalComponent } from './alert-message/succec-error-modal/succec-error-modal.component';
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from '@abacritt/angularx-social-login';
import { environment } from 'src/environments/environment';
import {} from '@angular/fire';
// import { AngularFirestoreModule } from '@angular/fire/firestore';
// import { AngularFireStorageModule } from '@angular/fire/storage';
// import { AngularFireMessagingModule } from '@angular/fire/messaging';
// import { AngularFireDatabaseModule } from '@angular/fire/database';
// import { AngularFireModule } from 'angularfire2';
import { AF } from './providers/twitterLogin';
// import * as firebase from 'firebase';
// import { AgmCoreModule } from '@agm/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { SNSClient, AddPermissionCommand, ListSubscriptionsByTopicCommand } from "@aws-sdk/client-sns";
//
// import { ShareButtonsConfig,ShareModule  } from '@ngx-share/core';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoaderInterceptor } from "./inperceptor'/loader.interceptor";
import { NgxSpinnerModule } from 'ngx-spinner';
import { JwtInterceptor } from "./inperceptor'/jwt.interceptor";
import { ErrorInterceptor } from "./inperceptor'/error.interceptor";
// import { MessageComponent } from './views/message/message.component';
import { DynamicSnacbarComponent } from './container/elements/dynamic-snacbar/dynamic-snacbar.component';
import { DynamicSnacbarErrorComponent } from './container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { DynamicSnacbarSkipComponent } from './container/elements/dynamic-snacbar-skip/dynamic-snacbar-skip.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  NgbPaginationModule,
  NgbAlertModule,
} from '@ng-bootstrap/ng-bootstrap';
import { UnsebscribeComponent } from './container/elements/unsebscribe/unsebscribe.component';
import { FileUploadComponent } from './container/elements/file-upload/file-upload.component';
// import { ScrollspyDirective } from './scrollspy.directive';
// import { Footer2Component } from './container/elements/footer2/footer2.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { Angular4PaystackModule } from 'angular4-paystack';
import { PopupConfirmationComponent } from './container/elements/popup-confirmation/popup-confirmation.component';
import { DownloadBrochurePopupComponent } from './container/elements/download-brochure-popup/download-brochure-popup.component';
import { SandboxComponent } from './views/sandbox/sandbox.component';
import { JobseekerCampaignComponent } from './views/jobseeker-campaign/jobseeker-campaign.component';
import { BackgroundCheckComponent } from './views/background-check/background-check.component';
import { BackgroundCheckFormComponent } from './container/elements/background-check-form/background-check-form.component';
import { EmployerLandingComponent } from './views/employer-landing/employer-landing.component';
import { ManpowerOutsourcingComponent } from './views/manpower-outsourcing/manpower-outsourcing.component';
import { RecruitmentLandingComponent } from './views/recruitment-landing/recruitment-landing.component';
import { SurveyPageComponent } from './views/survey-page/survey-page.component';

// firebase.initializeApp(environment.firebaseConfig);
@NgModule({
  declarations: [
    AppComponent,
    SuccecErrorModalComponent,
    // MessageComponent,
    DynamicSnacbarComponent,
    DynamicSnacbarErrorComponent,
    DynamicSnacbarSkipComponent,
    UnsebscribeComponent,
    FileUploadComponent,
    PopupConfirmationComponent,
    DownloadBrochurePopupComponent,
    SandboxComponent,
    JobseekerCampaignComponent,
    BackgroundCheckComponent,
    BackgroundCheckFormComponent,
    EmployerLandingComponent,
    ManpowerOutsourcingComponent,
    RecruitmentLandingComponent,
    SurveyPageComponent,
    // ScrollspyDirective,
    // Footer2Component,
  ],
  imports: [
    NgbPaginationModule,
    NgbAlertModule,
    NgxSpinnerModule,
    AngularEditorModule,
    BrowserModule,
    MatDialogModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MaterialModule,
    SharedModule,
    GeneralModuleModule,
    HttpClientModule,
    AngularEditorModule,
    FormsModule,
    ReactiveFormsModule,
    Angular4PaystackModule.forRoot(environment.paystack_key),
    FontAwesomeModule,
    ShareIconsModule,
    ShareButtonsModule.withConfig({
      debug: true,
    }),
    NgbModule,
    SocialLoginModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        lang: 'en',
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.Gmail_Client_ID),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.Fb_ID),
          },
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 5000,
        // verticalPosition: 'top', // 'top' | 'bottom'
        // horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
        // horizontalPosition: 'right',
        // verticalPosition: '120px',
      },
    },
    // SNSClient, AddPermissionCommand,ListSubscriptionsByTopicCommand,
    AF,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
