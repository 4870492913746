<div [ngClass]="loaded == true ? 'saved-job-background' : 'transparent-bg'">
  <div *ngIf="collectionRecords?.length != '0'" class="close-icon">
    <span class="close"><a [mat-dialog-close]="false">&#10006;</a></span>
  </div>
  <div *ngIf="collectionRecords?.length != '0'" class="new-screen">
    <div class="new-bookmark">
      <h5>Save to list</h5>
      <hr />
      <div class="bookmarklisting" *ngFor="let row of collectionRecords; let i = index">
        <div class="profilelogo ng-star-inserted" (click)="saveExsitCollection(row)">
          <img style="cursor: pointer" src="../../../../assets/images/sm-Logo.png" />
          <h4 style="cursor: pointer">
            {{ row?.name[0].toUpperCase() + row?.name.slice(1) }}
            <span *ngIf="row?.count == 0">Nothing saved yet</span>
            <span *ngIf="row?.count != 0">{{ row?.count }}</span>
          </h4>
        </div>
      </div>
      <hr />

      <a class="createnewlist" href="javascript:void(0);" (click)="createNewList()" *ngIf="!show"><span>+</span> Create
        new list</a>

      <div *ngIf="show" class="createbookmark">
        <form [formGroup]="saveJob">
          <input type="text" formControlName="collectionName" class="form-control" placeholder="New list name"
            required />
          <div *ngIf="f.collectionName.touched && f.collectionName.invalid">
            <span class="text-left text-danger pb-1" *ngIf="f.collectionName.errors.required">
              Collection Name id required
            </span>
          </div>
          <input type="submit" value="CREATE LIST" (click)="saveNewCollection()" class="sendcreate" />
        </form>
      </div>
    </div>
  </div>
</div>
<!-- ../../../assets/images/sm-Logo.png -->