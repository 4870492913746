<!-- Navbar -->
<header class="campaign-header">
    <div class="logo">
        <a href="javascript:void(0);" routerLink="/home">
            <img src="../assets/images/logo.webp"/>
        </a>
    </div>
    <div class="list-btns">
        <ul>
            <li>
                <button class="hdr-login" (click)="openForm()">Login</button>
            </li>
            <li>
                <button class="hdr-signup" (click)="openForm()">SIGNUP</button>
            </li>
        </ul>
    </div>
</header>
<!-- Background Check Header -->
<div class="background-check">
    <h1>Transform Your Hiring Process with Worknigeria</h1>
    <p>Finding the right people is challenging, but WorkNigeria makes it easy. Our all-in-one recruitment 
        service does the heavy lifting, from identifying top talent to ensuring each candidate is the perfect 
        fit for your business.</p>
    <button (click)="openForm()">Get Started Here</button>
</div>
<!-- Trusted by Section -->
<div class="trusted-by-section">
    <h2>Trusted by leading Employers in Nigeria:</h2>
    <div class="ticker-outer-container">
        <div class="ticker-inner-container">
            <div class="ticker-container">
                <div *ngFor="let logo of logos" class="ticker-item">
                    <img [src]="logo" class="ticker-img" alt="Logo" />
                </div>
                <div *ngFor="let logo of logos" class="ticker-item">
                    <img [src]="logo" class="ticker-img" alt="Logo" />
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Why you choose WN Section -->
<div class="why-choose-section row">
    <h2>Why Choose WorkNigeria?</h2>
    <div class="row">
        <div class="col-lg-6 col-12 order-lg-2">
            <ul class="custom-list">
                <li *ngFor="let item of items">
                    <h4> {{ item.title }} </h4>
                    <p> {{ item.text }} </p>
                </li>
            </ul>
        </div>
        <div class="col-lg-6 col-12 order-lg-1">
            <img class="what-you-get-img" src="../../../assets/images/why-choose-img.webp" />
        </div>
    </div>
    <div class="hired-strip">
        <p class="p-1">Discover the Perfect Fit for Your Team</p>
        <p class="p-2" (click)="openForm()">Start Here</p>
    </div>
</div>
<!-- Eliminate Hiring Risks Section -->
<div class="hiring-risks">
    <h2>We also Help You Eliminate Hiring Risks with Thorough Background Checks</h2>
    <p class="p-hr-risk">When it comes to hiring, trust but verify. Our comprehensive background checks help you make 
        informed decisions, ensuring your new hires are as qualified and reliable as they appear.</p>
    <div class="card-container">
        <div class="card" *ngFor="let hire of hiring">
            <img [src]="hire.image" />
            <p><span>{{ hire.btext }}</span> {{ hire.para }} </p>
            <p class="read-more" (click)="openForm()">Learn More >></p>
        </div>
    </div>
    <div class="hiring-strip">
        <p class="p-3">Let’s solve your Hiring Problems Fast! Schedule a Free Background Check Consultation!</p>
        <p class="p-4" (click)="openForm()">Start Here</p>
    </div>
</div>
<!-- How the process works section -->
<div class="how-it-works-section">
    <h2>How it works</h2>
    <div class="wn-container">
        <div class="wn-cards">
            <img class="curve-arrow" src="../../../assets/images/orange-curve-arrow.webp" />
            <img class="icons" src="../../../assets/images/consultation-icon.webp" />
            <h4>Consultation</h4>
            <p>Share your hiring needs with our experts for a customized recruitment and background check plan.</p>
        </div>

        <div class="wn-cards bg-white bd-rad">
            <img class="curve-arrow-down" src="../../../assets/images/orange-curve-arrow.webp" />
            <img class="icons" src="../../../assets/images/recruitment-icon.webp" />
            <h4>Recruitment</h4>
            <p>We handle the sourcing, screening, and presentation of top candidates tailored to your business.</p>
        </div>

        <div class="wn-cards">
            <img class="icons" src="../../../assets/images/verification-icon.webp" />
            <h4>Verification</h4>
            <p>Our thorough background checks give you confidence in every hiring decision.</p> 
        </div>
    </div>
    <div class="get-consultation">
        <button (click)="openForm()">Get a Free Consultation</button>
    </div>
</div>
<!-- copyrights section -->
<div class="copyright-section">
    <p><i class="far fa-copyright"></i> 2023 Worknigeria </p>
</div>