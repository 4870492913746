<!-- Navbar -->
<header class="campaign-header">
    <div class="logo">
        <a href="javascript:void(0);" routerLink="/home">
            <img src="../assets/images/logo.webp"/>
        </a>
    </div>
    <div class="list-btns">
        <ul>
            <li>
                <button class="hdr-login" (click)="goToSignUp()">Login</button>
            </li>
            <li>
                <button class="hdr-signup" (click)="goToSignUp()">SIGNUP</button>
            </li>
        </ul>
    </div>
</header>
<!-- Header Section -->
<div class="jobseeker-campaign-header row">
    <div class="col-12 col-lg-6 text">
        <h1>Find Your Next High-Paying Job Faster with Worknigeria!</h1>
        <p>Join thousands of Nigerians who are landing their dream jobs every day. Sign up now to get started!</p>
        <button (click)="goToSignUp()">Signup Here</button>
    </div>
    <div class="col-12 col-lg-6 image">
        <img src="../../../assets/images/jobseeker-campaign-header.webp" />
    </div>
</div>
<!-- What you get on WN Section -->
<div class="what-you-get-section row">
    <h2>What you get on Worknigeria</h2>
    <div class="row">
        <div class="col-lg-6 col-12 order-lg-2">
            <ul class="custom-list">
                <li *ngFor="let item of items">
                    <h4> {{ item.title }} </h4>
                    <p> {{ item.text }} </p>
                </li>
            </ul>
        </div>
        <div class="col-lg-6 col-12 order-lg-1">
            <img class="what-you-get-img" src="../../../assets/images/jobseeker-campaign-get-img.webp" />
        </div>
    </div>
    <div class="hired-strip">
        <p class="p-1">Ready to get hired?</p>
        <p class="p-2" (click)="goToSignUp()">Start Here</p>
    </div>
</div>
<!-- Best of WN -->
<div class="best-of-wn-section">
    <h2>How to make the best of WorkNigeria</h2>
    <div class="wn-container">
        <div class="wn-cards">
            <img class="curve-arrow" src="../../../assets/images/curve-arrow.webp" />
            <img class="icons" src="../../../assets/images/create-account-icon.webp" />
            <h4>Create account</h4>
            <p>It’s seamless, just insert your email address and you are ready.</p>
        </div>

        <div class="wn-cards bg-white bd-rad">
            <img class="curve-arrow-down" src="../../../assets/images/curve-arrow.webp" />
            <img class="icons" src="../../../assets/images/create-profile-icon.webp" />
            <h4>Create your profile</h4>
            <p>Fill in your details and upload your resume to get started.</p>
        </div>

        <div class="wn-cards">
            <img class="curve-arrow" src="../../../assets/images/curve-arrow.webp" />
            <img class="icons" src="../../../assets/images/explore-icon.webp" />
            <h4>Explore Opportunities</h4>
            <p>Search and apply for jobs that match your skills and interests.</p> 
        </div>

        <div class="wn-cards">
            <img class="icons" src="../../../assets/images/hire-icon.webp" />
            <h4>Get Hired</h4>
            <p>Connect with top employers and land your next role.</p> 
        </div>
    </div>
</div>
<!-- Testimonials Section -->
<div class="testimonials-section">
    <h2>Testimonials</h2>
    <p>These are just few testimonials from what our users have said about us</p>
    <!-- Testimonials Cards -->
    <div class="cards-container">
        <div class="card testimonials-card" *ngFor="let card of testimonials let i = index">
            <img [src]="card.logo" alt="{{ card.heading }}" />
            <h4> {{ card.heading }} </h4>
            <p class="m-0"> {{ card.para }} </p>
            <p> {{ card.saidBy }} </p>
            <p class="learn-more" (click)="goToSignUp()">Learn More —></p>
        </div>
    </div>
</div>
<!-- Trusted by Section -->
<div class="trusted-by-section">
    <h2>Trusted by leading Employers in Nigeria:</h2>
    <div class="ticker-outer-container">
        <div class="ticker-inner-container">
            <div class="ticker-container">
                <div *ngFor="let logo of logos" class="ticker-item">
                    <img [src]="logo" class="ticker-img" alt="Logo" />
                </div>
                <div *ngFor="let logo of logos" class="ticker-item">
                    <img [src]="logo" class="ticker-img" alt="Logo" />
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Next Great Opportunity Section -->
<div class="great-opportunity-section row">
    <div class="col-lg-2 col-0"></div>
    <div class="col-lg-4 col-12">
        <img src="../../../assets/images/woman-on-laptop-campaign.webp" />
    </div>
    <div class="col-lg-4 col-12">
        <h2>Don't Miss Out on Your Next Great Opportunity!</h2>
        <p>Sign up today and take the first step towards your new job.</p>
        <button (click)="goToSignUp()">Signup Here</button>
    </div>
    <div class="col-lg-2 col-0"></div>
</div>
<!-- copyrights section -->
<div class="copyright-section">
    <p><i class="far fa-copyright"></i> 2023 Worknigeria </p>
</div>