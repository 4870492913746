import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { NgTinyUrlService } from 'ng-tiny-url';
import { Title } from '@angular/platform-browser';
import { AuthService } from 'src/app/services/auth.service';
import { Helper } from 'src/app/helper/defult';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';

@Component({
  selector: 'app-sharejob',
  templateUrl: './sharejob.component.html',
  styleUrls: ['./sharejob.component.scss'],
})
export class SharejobComponent implements OnInit {
  url: any;
  record: any;
  title: any;
  showTittle: boolean = true;
  pic: any;
  email_title: any;
  userDeatils: any;
  companyData: any;
  linkUrl: string;
  jobLocation: any;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private tinyUrl: NgTinyUrlService,
    private titleService: Title,
    public dialogRef: MatDialogRef<SharejobComponent>,
    private auth: AuthService,
    private helper: Helper,
    private snackBar: MatSnackBar
  ) {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    if (localUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
    }
    if (sessionUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }
  }
  // description: string = "gcchkj";

  ngOnInit(): void {
    if (this.data['data'].collectionname != undefined) {
      this.record = this.data['data'].collectionname;
      this.jobLocation = this.data['data'].job_location;
      this.title = 'Share this List with friends and family';
      this.detectMob();
      // this.linkUrl = `https://d2c7gcaexouhqs.cloudfront.net/job-search?userId=${this.data['data'].userId}&collectionId=${this.data['data'].collectionId}`;
      this.tinyUrl
        .shorten(
          `https://worknigeria.com/job-search?userId=${this.data['data'].userId}&collectionId=${this.data['data'].collectionId}`
        )
        .subscribe((res: any) => {
          this.url = res;
        });
    } else {
      console.log(this.data)
      this.pic = this.data['data'].is_3mtt ? '../../../../assets/images/3mtt.svg' : this.data['data'].company_behalf_logo ?? this.data['data'].logo;
      this.record = this.data['data'].title;
      this.jobLocation = this.data['data'].job_location;
      this.title = 'Share this job with friends and family';
      this.detectMob();
      // this.linkUrl =`https://d2c7gcaexouhqs.cloudfront.net/job-search?id=${this.data['data'].id}`
      this.tinyUrl
        .shorten(
          `https://worknigeria.com/job-search?id=${this.data['data'].id}&title=${this.data['data'].title}`
        )
        .subscribe((res) => {
          this.url = encodeURI(res);
        });
    }
    if (this.userDeatils) {
      this.email_title = `${this.userDeatils.firstName} thinks you may want to hear about this job!`;
    }
  }

  detectMob() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }
  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  openSocialMedia(check){
    if (check === 'linkedin') {
      window.open(
        `https://www.linkedin.com/sharing/share-offsite/?url=${this.url}`
      );
    }else if (check === 'facebook') {
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${this.url}`
      );
    }else if (check === 'whatsapp') {
      window.open(
        `https://api.whatsapp.com/send?text=${this.url}`
      );
    }
  }


  copyUrl() {
    navigator.clipboard.writeText(this.url).then(() => {
      this.snackBar
            .openFromComponent(DynamicSnacbarComponent, {
              data: "URL Copied to clipboard",
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
    }, (error) => {
      this.snackBar
        .openFromComponent(DynamicSnacbarErrorComponent, {
          data: 'Something went wrong ',
          verticalPosition: 'top', // 'top' | 'bottom'
          horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          duration: 6000,
        })
        .afterOpened()        
        .subscribe(this.helper.setOnTop);
    });
  }

  // private linkedinShare():void{
  //   let winOpts : WindowParamsInterface = this.getShareWindowParams();
  //   window.open(this.linkedinShareLink + 'url=' + encodeURIComponent(this.permalinkUrl),
  //   null, //name
  //   'top=' + winOpts.top + ',left=' + winOpts.left + ',toolbar=0,status=0,width=' + winOpts.width + ',height=' + winOpts.height
  // );
  // }
}
