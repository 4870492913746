<div class="training-development-section">
    <!-- Training banner -->
    <div class="banners">
        <img class="img-banner" src="../assets/images/training-banner.webp">
    </div>
    <!-- About the Training and Development Section -->
    <div class="about-training">
        <h4>About Worknigeria Training</h4>
        <p class="about-text">
            We understand that investing in employee development can be time-consuming and
            expensive, but at Worknigeria, we have designed our programs to be cost-effective
            and time-efficient. Our team of experienced Training and Development specialists work with you to identify
            your training needs, design a customized program, and measure its effectiveness. Our
            training emphasizes the development of knowledge, skills, and attitudes in diverse areas;
            all with the intent of improving your individual staff competence, team performance,
            overall productivity and business profitability.
        </p>
        <div class="grid-container">
            <div class="grid-item left-side">
                <p>
                    Global business dynamics drive the constantly changing and evolving environment in which we all
                    do business. Our training and development programs offer opportunities for individuals or companies
                    to proactively upskill and gain better understanding across various topics to stay ahead of the
                    competition. We provide a number of open courses in addition to completely customizable training
                    for your organization.
                </p>
                <button class="btn-download" (click)="downloadBrochure()">
                    <img src="../../../../assets/images/download-brochure-icon.svg" />
                    Download our 2025 training brochure
                </button>
            </div>
            <div class="grid-item right-side">
                <p>
                    Do you have a special training requirement for yourself or your team?
                </p>
                <button class="btn-request" (click)="sendARequest()">
                    Send us a request
                </button>
            </div>
        </div>
    </div>
    <!-- Training solutions Section -->
    <div class="training-section">
        <h3>Worknigeria Training Solution</h3>
        <div class="row">
            <div class="training-card-container">
                <div class="card training-card" [ngClass]="onReadMore ? 'training-card-height' : 'training-card'"
                    *ngFor="let card of training_solution_cards">
                    <img [src]="card.imgSrc" alt="{{ card.heading }}" />
                    <h2>{{ card.heading }}</h2>
                    <p class="read-more" (click)="readMore()">Read more <span *ngIf="onReadMore">^^</span> <span
                            *ngIf="!onReadMore">>></span> </p>
                    <p class="p-text" *ngIf="onReadMore">{{ card.text }}</p>
                </div>
            </div>
        </div>
    </div>
    <!-- Upcoming Training Section -->
    <div class="upcoming-training" *ngIf="upcoming_trainings.length > 0">
        <h3>Our Upcoming Trainings</h3>
        <div class="row">
            <div class="card-container">
                <div *ngFor="let card of upcoming_trainings" class="card-wrapper">
                    <div class="card upcoming-cards">
                        <img [src]="card.image_link" alt="{{ card.title }}" />
                        <h2>{{ card.title }}</h2>
                        <p>{{ checkPhysicalVirtual(card.is_physical, card.is_virtual) }}</p>
                    </div>
                    <div class="register-link">
                        <p><span>Register Here: </span> <a [href]="card.training_link">{{ card.training_link }}</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>    
    <!-- Faculty Section & Key Area Development -->
    <div class="faculty-section">
        <h3>Worknigeria Faculty</h3>
        <img src="../../../../assets/images/key-development.png" class="mobile-view-img">
        <p>Our faculty stands unparalleled in expertise and dedication, embodying the pinnacle of professional
            excellence. Each member brings a wealth of knowledge and real-world experience, ensuring that our curriculum
            is current and deeply enriching. With a passion for teaching and a commitment to fostering a dynamic
            learning environment, our faculty goes above and beyond to support and inspire our training participants.
        </p>
        <div class="key-development">
            <h3>Key Development Area</h3>
            <div class="row">
                <div class="col-lg-6">
                    <ul class="custom-list">
                        <li *ngFor="let item of items">{{ item }}</li>
                    </ul>
                </div>
                <div class="col-lg-6">
                    <img src="../../../../assets/images/key-development.png">
                </div>
            </div>
        </div>
    </div>
    <!-- Testimonials Section -->
    <div class="testimonials-section">
        <h4>Testimonials</h4>
        <!-- Carousel Cards -->
        <div id="cardCarousel" class="carousel slide" data-bs-interval="false">
            <!-- Carousel inner -->
            <div class="carousel-inner">
                <div *ngFor="let cardSet of groupedCards(); let i = index" class="carousel-item"
                    [ngClass]="{'active': i === 0}">
                    <div class="row justify-content-center">
                        <div class="col" *ngFor="let card of cardSet; let j = index">
                            <div class="card testimonials-card">
                                <p class="name-initials"  [ngClass]="getColorClass(j)">{{ getInitials(card.companyName) }}</p>
                                <h5 class="card-title">{{card.quote}}</h5>
                                <div class="star-icons">
                                    <ng-container *ngFor="let star of [1, 2, 3, 4, 5]">
                                        <i class="fas fa-solid fa-star"
                                            [ngClass]="{ 'golden-star': star <= card.stars, 'white-star': star > card.stars }"></i>
                                    </ng-container>
                                </div>
                                <p class="card-text">– {{card.companyName}}</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Indicators -->
            <div class="carousel-indicators">
                <button *ngFor="let page of [].constructor(totalPages); let i = index" type="button"
                    data-bs-target="#cardCarousel" [attr.data-bs-slide-to]="i" [ngClass]="{ 'active': i === 0 }"
                    [attr.aria-current]="i === 0 ? 'true' : null" [attr.aria-label]="'Slide ' + (i + 1)"></button>
            </div>
        </div>
        <!-- Carousel Cards -->
    </div>
</div>