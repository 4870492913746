import { Component, Inject, OnInit, HostListener, OnDestroy } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { SuccecErrorModalComponent } from 'src/app/alert-message/succec-error-modal/succec-error-modal.component';
import { Helper } from 'src/app/helper/defult';
import { HttpIOService } from 'src/app/services/http-io.service';

import { SocialAuthService } from '@abacritt/angularx-social-login';
import { SocialUser } from '@abacritt/angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
} from '@abacritt/angularx-social-login';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';
import { AF } from 'src/app/providers/twitterLogin';
import { EmailNotificationComponent } from '../email-notification/email-notification.component';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { EmployerService } from 'src/app/services/employer.service';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { SocialLogin } from 'src/app/models/user/socialLogin';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { PopupConfirmationComponent } from 'src/app/container/elements/popup-confirmation/popup-confirmation.component';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [
    {
      provide: CarouselConfig,
      useValue: { interval: 1500, noPause: false, showIndicators: true },
    },
  ],
})
export class LoginComponent implements OnInit, OnDestroy {
  authSubscription!: Subscription;
  emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9_.+-]{2,}$/;
  login: UntypedFormGroup;
  timeZone: any;
  user: SocialUser;
  userSocialLogin: SocialLogin;
  linkedInToken: any;
  remember: boolean;
  twitteroauthtoken: any;
  twitteroauthverifier: any;
  emailValue: any;
  userDataForTwitter: any;
  fbValue: any;
  googleValue: any;
  GuestUserjobId: any;
  redirectUrl: any;
  redirectForGuestSelectJob: any;
  public showPassword: boolean = false;
  signupURL: string = '/signup'
  checkUserType = '';
  userDetails: any;
  companyDataLog: any;

  slides = [
    { image: '../../../../assets/images/signup.png', text: 'First' },
    { image: '../../../../assets/images/sm-Logo.png', text: 'Third' },
    { image: '../../../../assets/images/signup.png', text: 'Third' },
  ];
  noWrapSlides = false;
  showIndicator = true;
  getUrlRedirection: any;
  constructor(
    // @Inject(MatDialog) public dialog: MatDialog,
    public dialog: MatDialog,
    @Inject(UntypedFormBuilder) private fb: UntypedFormBuilder,
    private io: HttpIOService,
    private helper: Helper,
    @Inject(MatSnackBar) private snackBar: MatSnackBar,
    @Inject(Router) private router: Router,
    private socialauthService: SocialAuthService,
    @Inject(ActivatedRoute) private route: ActivatedRoute,
    @Inject(ActivatedRoute) private activeRoute: ActivatedRoute,
    @Inject(HttpClient) private http: HttpClient,
    private _authService: AuthService,
    private twitter: AF,
    private empService: EmployerService,
  ) {


    this.getUrlRedirection = sessionStorage.getItem('setUrlRedirection');
    // console.log(this.getUrlRedirection)
    // this.GuestUserjobId = this.route.snapshot.queryParams["jobId"];
    let guestUserByselected = sessionStorage.getItem('guestUserSelectedJob');
    if (guestUserByselected) {
      this.GuestUserjobId = JSON.parse(
        atob(sessionStorage.getItem('guestUserSelectedJob'))
      );
    }

    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    this.twitteroauthtoken = this.route.snapshot.queryParams['oauth_token'];
    this.twitteroauthverifier =
      this.route.snapshot.queryParams['oauth_verifier'];

    if (this.twitteroauthtoken && this.twitteroauthverifier) {
      this.fetchDataFromtwitter();
    }
    this.activeRoute.queryParams.subscribe((event) => {
      this.checkUserType = event.userType;
      if (event.userType && event.userType === 'employer') {
        this.signupURL = '/signupemployer'
      }
      this.redirectUrl = event['role'];
      this.redirectForGuestSelectJob = event['GuestUser'];
    });
  }
  ngOnDestroy(): void {
    this.authSubscription.unsubscribe();
  }

  isSmallScreen = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isSmallScreen = window.innerWidth < 639;
  }

  ngOnInit(): void {
    // console.log(this.GuestUserjobId)
    this.formInsli();
    this.onResize(null);
    // this.socialauthService.authState.subscribe((user) => {
    //   this.user = user;
    //   console.log(this.user)
    // });
    this.authSubscription = this.socialauthService.authState.subscribe((user) => {
      if(user){
        this.user = user;
        switch(user.provider){
          case 'GOOGLE':
            this.signInWithgoogle()
            break;
        }
      }else{
        this.user = null
      }
    });
  }

  googleSignin(googleWrapper: any) {
    googleWrapper.click();
  }

  // Create Modal Driven Form //
  formInsli() {
    this.login = this.fb.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.emailPattern),
        ]),
      ],
      password: [
        '',
        Validators.compose([
          Validators.required,
        ]),
      ],
      path: ['login', Validators.required],
    });
  }

  // Validation Controls //
  get f() {
    return this.login.controls;
  }

  // Sign Up Modal //
  signUP() {
    if (this.checkUserType === 'is3MTTFellow') {
      if (environment.production) {
        window.location.href = "https://3mtt.worknigeria.com/fellow-signup";
      } else {
        window.location.href = "https://3mtt-development.worknigeria.com/fellow-signup";
      }
    } else if (this.checkUserType === 'is3MTTEmployer') {
      if (environment.production) {
        window.location.href = "https://3mtt.worknigeria.com/employer-signup";
      } else {
        window.location.href = "https://3mtt-development.worknigeria.com/employer-signup";
      }
    } else {
      this.router.navigate([this.signupURL]);
    }
  }

  // Forget Modal //
  forgetPassword() {
    this.router.navigate(['/forgot-password']);
  }

  // Show Pasword //
  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  // Remember ME //
  rememberMe(event: MatSlideToggleChange) {
    const isChecked = event.checked;
    if (isChecked) {
      this.remember = true;
    } else {
      this.remember = false;
    }
  }


  // Log in //
  signin() {
    if (this.login.status === 'INVALID') {
      this.helper.markFormGroupTouched(this.login);
      return false;
    }

    this.io.apicall(this.login.value, 'auth/login').subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        this.empService.setforWSconnect(res);
        // this.snackBar.open(res.serverResponse.message, "OK", {
        // })
        // this.dialogRef.close();
        let userdata = res['result'].userData;
        let companyData = res['result'].companyData;
        this.companyDataLog = res['result'].companyData;

        if(!this.checkUserType){
          this._authService.sendTokenInsession(res['result'].token);
          this._authService.setCurrentUserDetailsInSessoin(userdata);
          this._authService.setCurrentCompanyDataInSessoin(res['result'].companyData);
          this.router.navigate(['/home']);
        }

        if (userdata.is_3mtt === true && (this.checkUserType === 'is3MTTFellow' || this.checkUserType === 'is3MTTEmployer')) {
          this.snackBar
            .openFromComponent(DynamicSnacbarComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);

          let token = res['result'].token;
          let companyData = res['result'].companyData;

          if (this.remember) {
            this._authService.sendToken(token);
            this._authService.setCurrentUserDetails(userdata);
            this._authService.setCurrentCompanyData(companyData);
          } else {
            this._authService.sendTokenInsession(token);
            this._authService.setCurrentUserDetailsInSessoin(userdata);
            this._authService.setCurrentCompanyDataInSessoin(companyData);

            // localStorage.setItem('companyDetails',btoa(JSON.stringify(res['result'].companyData)))
          }

        } else if (!userdata.is_3mtt && (this.checkUserType === 'jobseeker' || this.checkUserType === 'employer')) {
          this.snackBar
            .openFromComponent(DynamicSnacbarComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);

          let token = res['result'].token;

          if (this.remember) {
            this._authService.sendToken(token);
            this._authService.setCurrentUserDetails(userdata);
            this._authService.setCurrentCompanyData(companyData);
          } else {
            this._authService.sendTokenInsession(token);
            this._authService.setCurrentUserDetailsInSessoin(userdata);
            this._authService.setCurrentCompanyDataInSessoin(companyData);

            // localStorage.setItem('companyDetails',btoa(JSON.stringify(res['result'].companyData)))
          }


        } else {
          
          if (this.checkUserType === 'is3MTTFellow' || this.checkUserType === 'is3MTTEmployer') {
            let no3MTT = 'not 3mtt user';
            const dialogRef = this.dialog.open(PopupConfirmationComponent, {
              height: 'auto',
              width: '450px',
              data: no3MTT,
            });
            dialogRef.afterClosed().subscribe(() => {
            });
          } else if (this.checkUserType === 'jobseeker' || this.checkUserType === 'employer') {
            let no3MTT = 'not WorkNigeria user';
            const dialogRef = this.dialog.open(PopupConfirmationComponent, {
              height: 'auto',
              width: '450px',
              data: no3MTT,
            });
            dialogRef.afterClosed().subscribe(() => {
            });
          }
            else if (userdata.userType == 'jobseeker') {
              if (userdata.is_3mtt == true) {
                this.router.navigate(['/home']);
                return true;
              }
              if (this.redirectUrl) {
                this.router.navigateByUrl(`/${this.redirectUrl}`);
                return false;
              } else if (this.getUrlRedirection) {
                this.router.navigateByUrl(`/${this.getUrlRedirection}`);
                sessionStorage.removeItem('setUrlRedirection');
                return false;
              } else if (this.GuestUserjobId) {
                if (this.GuestUserjobId == 'job-alert') {
                  this.router.navigate(['/job-alert']);
                } else {
                  this.router.navigate(['/job-search']);
                }
            } else if (this.redirectForGuestSelectJob) {
              this.router.navigate(['/job-search'], {
                queryParams: { role: 'Guest-User' },
              });
            } else {
              this.router.navigate(['/home']);
            }
          } else {
            if (userdata.userType == 'super_admin') {
              let getUrl = sessionStorage.getItem('companyVerificaton');
              if (getUrl) {
                let url = JSON.parse(
                  atob(sessionStorage.getItem('companyVerificaton'))
                );
                if (url) {
                  let shortURL = url.split('/');
                  this.router.navigateByUrl(`/${shortURL[shortURL.length - 1]}`);
                  sessionStorage.removeItem('companyVerificaton');
                  return false;
                }
              }
            }
            if (this.getUrlRedirection) {
              this.router.navigateByUrl(`/${this.getUrlRedirection}`);
              sessionStorage.removeItem('setUrlRedirection');
              return false;
            }
            if (this.redirectUrl) {
              this.router.navigate(['/jobProvider-profile']);
              return false;
            }
            if (this.GuestUserjobId) {
              this.router.navigate(['/job-search']);
            } else if (this.redirectForGuestSelectJob) {
              this.router.navigate(['/job-search'], {
                queryParams: { role: 'Guest-User' },
              });
            }
            if (userdata.userType == 'employer' && userdata.firstLogin) {
              // console.log('here')
              this.router.navigate(['/verification']);
            } else {
              if (userdata.is_3mtt == true) {
                this.router.navigate(['/Dashboard']);
              } else {
                this.router.navigate(['/employer-home']);
              }
            }
          }
          return;
        }
        if (userdata.userType == 'jobseeker') {
          if (userdata.is_3mtt == true) {
            this.router.navigate(['/home']);
            return true;
          }
          if (this.redirectUrl) {
            this.router.navigateByUrl(`/${this.redirectUrl}`);
            return false;
          } else if (this.getUrlRedirection) {
            this.router.navigateByUrl(`/${this.getUrlRedirection}`);
            sessionStorage.removeItem('setUrlRedirection');
            return false;
          } else if (this.GuestUserjobId) {
            if (this.GuestUserjobId == 'job-alert') {
              this.router.navigate(['/job-alert']);
            } else {
              this.router.navigate(['/job-search']);
            }
          } else if (this.redirectForGuestSelectJob) {
            this.router.navigate(['/job-search'], {
              queryParams: { role: 'Guest-User' },
            });
          } else {
            this.router.navigate(['/home']);
          }
        } else {
          if (userdata.userType == 'super_admin') {
            let getUrl = sessionStorage.getItem('companyVerificaton');
            if (getUrl) {
              let url = JSON.parse(
                atob(sessionStorage.getItem('companyVerificaton'))
              );
              if (url) {
                let shortURL = url.split('/');
                this.router.navigateByUrl(`/${shortURL[shortURL.length - 1]}`);
                sessionStorage.removeItem('companyVerificaton');
                return false;
              }
            }
          }
          if (this.getUrlRedirection) {
            this.router.navigateByUrl(`/${this.getUrlRedirection}`);
            sessionStorage.removeItem('setUrlRedirection');
            return false;
          }
          if (this.redirectUrl) {
            this.router.navigate(['/jobProvider-profile']);
            return false;
          }
          if (this.GuestUserjobId) {
            this.router.navigate(['/job-search']);
          } else if (this.redirectForGuestSelectJob) {
            this.router.navigate(['/job-search'], {
              queryParams: { role: 'Guest-User' },
            });
          }
          if (userdata.userType == 'employer' && userdata.firstLogin) {
            // console.log('here')
            this.router.navigate(['/choose-employer-subscription']);
          } else {
            if (userdata.is_3mtt == true) {
              this.router.navigate(['/Dashboard']);
            } else {
              if(this.companyDataLog.cacVerified) {
                this.router.navigate(['/employer-home']);
              }
              else {
                this.router.navigate(['verification']);
              }
            }
          }
        }
        
      }
      if (res.serverResponse.code == 400) {
        // this.snackBar.open(res.serverResponse.message, "OK", {
        //       })
        this.snackBar
          .openFromComponent(DynamicSnacbarErrorComponent, {
            data: res.serverResponse.message,
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right',
            duration: 6000,
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
      }
    });
  }

  // SIGNIN WITH FACEBOOK //
  signInWithFB(): void {
    this.socialauthService
      .signIn(FacebookLoginProvider.PROVIDER_ID)
      .then((fbResponse: any) => {
        // if (!fbResponse.email) {
        //   const dialogRef = this.dialog.open(EmailNotificationComponent, {
        //     height: 'auto',
        //     width: '450px',
        //     data: {}
        //   });
        //   dialogRef.afterClosed().subscribe(res => {
        //     if (res) {
        //       console.log(res);
        //       this.fbValue = res
        //       // this.loginWithSocialTwitter();

        //     }

        //   });
        //   return false;
        // }
        this.userSocialLogin = {
          socialId: fbResponse.id,
          email: fbResponse.email ? fbResponse.email : '',
          socialType: 'facebook',
          path: 'social-login',
        };

        this.io
          .apicall(this.userSocialLogin, 'auth/social-login')
          .subscribe((res: any) => {
            if (res.serverResponse.code == 200) {
              // this.snackBar.open(res.serverResponse.message, "OK", {
              // })
              this.empService.setforWSconnect(res);
              this.snackBar
                .openFromComponent(DynamicSnacbarComponent, {
                  data: res.serverResponse.message,
                  verticalPosition: 'top', // 'top' | 'bottom'
                  horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
                })
                .afterOpened()
                .subscribe(this.helper.setOnTop);
              let userdata = res['result'].userData;
              let token = res['result'].token;
              let companyData = res['result'].companyData;

              this._authService.sendToken(token);
              this._authService.setCurrentUserDetails(userdata);
              this._authService.setCurrentCompanyData(companyData);

              if (this.GuestUserjobId) {
                if (this.GuestUserjobId == 'job-alert') {
                  this.router.navigate(['/job-alert']);
                } else {
                  this.router.navigate(['/job-search']);
                }
              } else if (this.getUrlRedirection) {
                this.router.navigateByUrl(`/${this.getUrlRedirection}`);
                sessionStorage.removeItem('setUrlRedirection');
              } else if (this.redirectForGuestSelectJob) {
                this.router.navigate(['/job-search'], {
                  queryParams: { role: 'Guest-User' },
                });
              } else {
                this.router.navigate(['/home']);
              }
            } else {
              // this.dialogRef.close();
              // const dialogRef = this.dialog.open(SuccecErrorModalComponent, {
              //   height: 'auto',
              //   width: '450px',
              //   data: { message: res.serverResponse.message }
              // });
              // dialogRef.afterClosed().subscribe(res => {
              //   if (res === true) {
              //   }
              // });
              // this.snackBar.open(res.serverResponse.message, "OK", {
              // })
              this.snackBar
                .openFromComponent(DynamicSnacbarErrorComponent, {
                  data: res.serverResponse.message,
                  verticalPosition: 'top', // 'top' | 'bottom'
                  horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
                  duration: 6000,
                })
                .afterOpened()
                .subscribe(this.helper.setOnTop);
            }
          });
        // .catch(err => {
        //   if (!err.error.serverResponse) {
        //     // const dialogRef = this.dialog.open(SuccecErrorModalComponent, {
        //     //   height: 'auto',
        //     //   width: '450px',
        //     //   data: { message: 'Please check connectivity' }
        //     // });
        //     // dialogRef.afterClosed().subscribe(res => {
        //     //   if (res === true) {
        //     //   }
        //     // });
        //     this.snackBar.open('Please check connectivity', "OK", {
        //     })
        //   }
        //   else {
        //     // const dialogRef = this.dialog.open(SuccecErrorModalComponent, {
        //     //   height: 'auto',
        //     //   width: '450px',
        //     //   data: { message: err.error.serverResponse.message }
        //     // });
        //     // dialogRef.afterClosed().subscribe(res => {
        //     //   if (res === true) {
        //     //   }
        //     // });
        //     this.snackBar.open(err.error.serverResponse.message, "OK", {
        //     })
        //   }
        // })
      });
  }

  // SIGNIN WITH GOOGLE //
  signInWithgoogle(): void {
    this.userSocialLogin = {
      socialId: this.user.id,
      email: this.user.email ? this.user.email : '',
      socialType: 'google',
      path: 'social-login',
    };
    this.io
      .apicall(this.userSocialLogin, 'auth/social-login')
      .subscribe((res: any) => {
        // console.log(res)
        if (res.serverResponse.code == 200) {
          this.empService.setforWSconnect(res);
          if (this.GuestUserjobId) {
            if (this.GuestUserjobId == 'job-alert') {
              this.router.navigate(['/job-alert']);
            } else {
              this.router.navigate(['/job-search']);
            }
          } else if (this.getUrlRedirection) {
            this.router.navigateByUrl(`/${this.getUrlRedirection}`);
            sessionStorage.removeItem('setUrlRedirection');
          } else if (this.redirectForGuestSelectJob) {
            this.router.navigate(['/job-search'], {
              queryParams: { role: 'Guest-User' },
            });
          } else {
            this.router.navigate(['/home']);
          }
          this.snackBar
            .openFromComponent(DynamicSnacbarComponent, {
              data: res.serverResponse.message,

              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
          // this.dialogRef.close();
          let userdata = res['result'].userData;
          let token = res['result'].token;
          let companyData = res['result'].companyData;

          this._authService.sendToken(token);
          this._authService.setCurrentUserDetails(userdata);
          this._authService.setCurrentCompanyData(companyData);
        } else {
          // this.dialogRef.close();
          // const dialogRef = this.dialog.open(SuccecErrorModalComponent, {
          //   height: 'auto',
          //   width: '450px',
          //   data: { message: res.serverResponse.message }
          // });
          // dialogRef.afterClosed().subscribe(res => {
          //   if (res === true) {
          //   }
          // });
          this.snackBar
            .openFromComponent(DynamicSnacbarErrorComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
              duration: 6000,
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
        }
      });
  }

  // Login With Linkedin //
  loginWithLinkedin() {
    window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${environment.linkedInCredentials.clientId}&redirect_uri=${environment.linkedInCredentials.redirectUrl}&state=login&scope=profile,email,openid`;
  }

  // Login With Twitter //
  loginWithTwittwer() {
    this.io
      .apicall(
        {
          path: 'get-twitter-oauth-token',
          callbackUrl: `${environment.twitterDetails.callBackUrlForLogin}`,
        },
        'auth/get-twitter-oauth-token'
      )
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          window.open(
            `https://api.twitter.com/oauth/authenticate?${res['result'].tokenBody.token}`,
            '_blank'
          );
          // window.location.href = `https://api.twitter.com/oauth/authenticate?${res['result'].tokenBody.token}`;
        } else {
          this.snackBar
            .openFromComponent(DynamicSnacbarComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
        }
      });
    // .catch(err => {
    //   if (!err.error.serverResponse) {
    //     this.snackBar.open('Please check connectivity', "OK", {
    //     })
    //   }
    //   else {
    //     this.snackBar.open(err.error.serverResponse.message, "OK", {
    //     })
    //   }
    // })
  }

  fetchDataFromtwitter() {
    this.io
      .apicall(
        {
          path: 'user-data-from-twitter',
          oauthToken: this.twitteroauthtoken,
          oauthVerifier: this.twitteroauthverifier,
          processFollowed: 'login',
        },
        'auth/user-data-from-twitter'
      )
      .subscribe((auth: any) => {
        if (auth.serverResponse.code == 200) {
          // this.loginWithSocialTwitter(res['result'].userData);
          let userdata = auth['result'].userData;
          let token = auth['result'].token;
          let companyData = auth['result'].companyData;

          this._authService.sendToken(token);
          this._authService.setCurrentUserDetails(userdata);
          this._authService.setCurrentCompanyData(companyData);
          this.loginWithSocialTwitter();

          // if (this.userDataForTwitter.email) {
          // }
          // if (!this.userDataForTwitter.email) {
          //   const dialogRef = this.dialog.open(EmailNotificationComponent, {
          //     height: 'auto',
          //     width: '450px',
          //     data: {}
          //   });
          //   dialogRef.afterClosed().subscribe(res => {
          //     if (res) {
          //       console.log(res);
          //       this.emailValue = res
          //       this.loginWithSocialTwitter();

          //     }
          //     else {
          //       this.snackBar.open('Please Enter Email Address', 'OK', {});
          //       this.router.navigate(['/login']);
          //     }

          //   });
          // }

          // console.log(res)
        } else {
          // this.snackBar.open(auth.serverResponse.message, "OK", {
          // })
          this.snackBar
            .openFromComponent(DynamicSnacbarErrorComponent, {
              data: auth.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
              duration: 6000,
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
        }
      });
    // .catch(err => {
    //   if (!err.error.serverResponse) {
    //     this.snackBar.open('Please check connectivity', "OK", {
    //     })
    //   }
    //   else {
    //     this.snackBar.open(err.error.serverResponse.message, "OK", {
    //     })
    //   }
    // })
  }

  loginWithSocialTwitter() {
    // if(!this.emailValue) {
    //   const dialogRef = this.dialog.open(EmailNotificationComponent, {
    //     height: 'auto',
    //     width: '450px',
    //     data: {}
    //   });
    //   dialogRef.afterClosed().subscribe(res => {
    //     if (res) {
    //       console.log(res);
    //       this.emailValue = res
    //       this.loginWithSocialTwitter();

    //     }

    //   });
    //   return false;
    // }

    let payload = {
      socialId: this.userDataForTwitter.id,
      email: this.userDataForTwitter.email ? this.userDataForTwitter.email : '',
      socialType: 'twitter',
      path: 'social-login',
    };

    this.io.apicall(payload, 'auth/social-login').subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        // this.snackBar.open(res.serverResponse.message, "OK", {
        // })
        this.snackBar
          .openFromComponent(DynamicSnacbarComponent, {
            data: res.serverResponse.message,
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
        let userdata = res['result'].userData;
        let token = res['result'].token;
        let companyData = res['result'].companyData;

        this._authService.sendToken(token);
        this._authService.setCurrentUserDetails(userdata);
        this._authService.setCurrentCompanyData(companyData);

        if (this.GuestUserjobId) {
          this.router.navigate(['/job-search']);
        } else if (this.redirectForGuestSelectJob) {
          this.router.navigate(['/job-search'], {
            queryParams: { role: 'Guest-User' },
          });
        } else {
          this.router.navigate(['/home']);
        }
      } else {
        this.snackBar
          .openFromComponent(DynamicSnacbarErrorComponent, {
            data: res.serverResponse.message,
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            duration: 6000,
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
      }
    });
    // .catch(err => {
    //   if (!err.error.serverResponse) {

    //     this.snackBar.open('Please check connectivity', "OK", {
    //     })
    //   }
    //   else {

    //     this.snackBar.open(err.error.serverResponse.message, "OK", {
    //     })
    //   }
    // })
  }
}
