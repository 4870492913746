import { Component, Inject, OnInit } from '@angular/core';
import { waitForAsync } from '@angular/core/testing';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { HttpIOService } from 'src/app/services/http-io.service';
@Component({
  selector: 'app-rename-collection',
  templateUrl: './rename-collection.component.html',
  styleUrls: ['./rename-collection.component.scss'],
})
export class RenameCollectionComponent implements OnInit {
  show: boolean = false;
  userDeatils: any;
  companyData: any;
  renameCollection: UntypedFormGroup;
  collectionRecords: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private auth: AuthService,
    private fb: UntypedFormBuilder,
    private io: HttpIOService,
    private snackBar: MatSnackBar,
    private helper: Helper,
    public dialogRef: MatDialogRef<RenameCollectionComponent>
  ) {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    if (localUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
    }
    if (sessionUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }
    // this.getCollection();
  }

  ngOnInit(): void {
    // console.log(this.data);

    this.renameCollection = this.fb.group({
      Name: ['', Validators.required],
    });
  }
  // Validation Controls //
  get f() {
    return this.renameCollection.controls;
  }
  saveRenameCollection() {
    if (this.renameCollection.status === 'INVALID') {
      this.helper.markFormGroupTouched(this.renameCollection);
      return false;
    }
    // console.log(this.renameCollection.value)
    let jsonData = {
      path: 'rename-collection',
      id: this.userDeatils.id,
      collectionId: this.data.collectionId,
      collectionName: this.renameCollection.get('Name').value,
    };

    // console.log(jsonData);
    this.io._renameCollection(jsonData).subscribe((res: any) => {
      // console.log(res);
      if (res.serverResponse.code == 200) {
        this.snackBar
          .openFromComponent(DynamicSnacbarComponent, {
            data: res.serverResponse.message,
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
        this.dialogRef.close('success');
      } else {
        this.snackBar
          .openFromComponent(DynamicSnacbarErrorComponent, {
            data: res.serverResponse.message,
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            duration: 6000,
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
