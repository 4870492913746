import { Component, OnInit, ɵɵqueryRefresh } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { HttpIOService } from 'src/app/services/http-io.service';
import { SavedjobComponent } from '../../job-search/savedjob/savedjob.component';
import { SharejobComponent } from '../../job-search/sharejob/sharejob.component';
import { Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { Observable, Subscription, interval } from 'rxjs';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import { RenameCollectionComponent } from '../rename-collection/rename-collection.component';
import { ViaEmailComponent } from '../../job-search/via-email/via-email.component';
import { ViaExternalUrlComponent } from '../../job-search/via-external-url/via-external-url.component';
import { ViaWorkNigeriaComponent } from '../../job-search/via-work-nigeria/via-work-nigeria.component';
import { environment } from 'src/environments/environment';
import { DeleteConfirmationComponent } from '../delete-confirmation/delete-confirmation.component';
import { ReportJobComponent } from '../../job-search/report-job/report-job.component';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { DynamicSnacbarSkipComponent } from 'src/app/container/elements/dynamic-snacbar-skip/dynamic-snacbar-skip.component';
import { CollectionCreateComponent } from '../collection-create/collection-create.component';

@Component({
  selector: 'app-jobseeker-save-job',
  templateUrl: './jobseeker-save-job.component.html',
  styleUrls: ['./jobseeker-save-job.component.scss'],
})
export class JobseekerSaveJobComponent implements OnInit {
  status: boolean = false;
  nojob: boolean = true;
  jobLists: any;
  detailsOfJob: any;
  shareJobId: any = '';
  shareJobCollection: any;
  searchDipendency: any;
  xxxxx: boolean = false;
  userDeatils: any;
  companyData: any;
  filterData: any;
  refresh: Subject<any> = new Subject();
  collectionRecords: any;
  selectedCollectionID: any;
  selectedCollectionName: any;
  ByDefaultSelectedCollection: any;
  payload: any = {};
  buttonDisable: boolean = false;
  showRightSectionInMobile: boolean = false;
  isExpriedButton: boolean;
  dateDiff: number;
  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private io: HttpIOService,
    private auth: AuthService,
    private helper: Helper,
    private dialog: MatDialog,
    private titleService: Title
  ) {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    if (localUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
    }
    if (sessionUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }
  }

  ngOnInit(): void {
    this.getCollection();
  }
  getCollection() {
    let jsonData = {
      id: this.userDeatils.id,
      path: 'collection-list',
    };
    this.io._getCollection(jsonData).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        // console.log(res);
        this.collectionRecords = res['result'].collectionList;
        this.ByDefaultSelectedCollection = res['result'].collectionList[0].id;
        this.selectedCollectionID = res['result'].collectionList[0].id;
        this.byDefaultCollectionJob();
        if (this.selectedCollectionID == this.collectionRecords[0].id) {
          this.buttonDisable = true;
        }
      }
    });
  }

  byDefaultCollectionJob() {
    this.detailsOfJob = '';
    this.payload = {
      path: 'saved-job-list',
      id: this.userDeatils.id,
      collectionId: this.selectedCollectionID,
    };
    // this.apiJobSearch(jsonData)
    // console.log(this.payload)
    this.io._saveAllJobsByCollection(this.payload).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        this.jobLists = res['result'].jobList;
        // console.log(this.jobLists.length);
        if (this.jobLists.length != 0) {
          this.nojob = false;
          // console.log(this.jobLists)
          this.detailsOfJob = this.jobLists[0];
          this.status = this.jobLists[0].id;
          this.jobExpried(this.detailsOfJob);
        }
      }
    });
  }

  changeCollection(e) {
    this.nojob = true;
    this.detailsOfJob = '';
    this.buttonDisable = false;
    this.selectedCollectionID = e.value;
    this.selectedCollectionName = e.source.triggerValue;
    if (this.selectedCollectionID == this.collectionRecords[0].id) {
      this.buttonDisable = true;
    }
    this.payload = {
      path: 'saved-job-list',
      id: this.userDeatils.id,
      collectionId: this.selectedCollectionID,
    };
    // this.apiJobSearch(jsonData)
    this.io._saveAllJobsByCollection(this.payload).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        // console.log(res);

        this.jobLists = res['result'].jobList;
        if (this.jobLists.length != 0) {
          this.nojob = false;
          // console.log(this.jobLists)
          this.detailsOfJob = this.jobLists[0];
          this.status = this.jobLists[0].id;
          this.jobExpried(this.detailsOfJob);
        }
      }
    });
  }
  // CLICK ON JOB LIST //
  jobDetails(i, job) {
    // console.log(job)
    this.showRightSectionInMobile = true;
    this.status = job.id;
    this.detailsOfJob = this.jobLists[i];
    this.jobExpried(this.detailsOfJob);
  }
  jobExpried(job) {
    if (job.expired_at) {
      this.dateDiff = this.helper.calculateDiff(
        new Date(job.expired_at),
        new Date()
      );
      // console.log(dateDiff)
      if (this.dateDiff >= 0) {
        this.isExpriedButton = false;
      } else {
        this.isExpriedButton = true;
      }
    }

    // var hours = Math.abs(new Date(job.expired_at).getTime() - new Date().getTime()) / 36e5;
    // console.log(hours)
  }
  // EVENT EXPAIRE TIME //
  eventExpireIn(eventDt: string | number | Date) {
    const dateDiff = this.helper.calculateDiff(new Date(), new Date(eventDt));
    if (dateDiff === 0) {
      return 'today';
    } else if (dateDiff === 1) {
      return 'yesterday';
    } else {
      return `${dateDiff} days ago`;
    }
  }
  mobileViewClassClose() {
    this.showRightSectionInMobile = false;
  }
  // Saved job //
  savedJob(job) {
    // console.log(this.payload);
    let jsonData = {
      path: 'unsaved-job',
      jobId: job.id,
      id: this.userDeatils.id,
      // "collectionId": this.selectedCollectionID
    };
    //  console.log(jsonData);
    this.io._unSaveJob(jsonData).subscribe((res: any) => {
      //  console.log(res);
      if (res.serverResponse.code == 200) {
        this.snackBar
          .openFromComponent(DynamicSnacbarComponent, {
            data: res.serverResponse.message,
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
        this.io._saveAllJobsByCollection(this.payload).subscribe((res: any) => {
          if (res.serverResponse.code == 200) {
            this.detailsOfJob = '';
            // console.log(res);
            this.jobLists = res['result'].jobList;
            if (this.jobLists.length != 0) {
              // console.log(this.jobLists)
              this.detailsOfJob = this.jobLists[0];
              this.status = this.jobLists[0].id;
              this.jobExpried(this.detailsOfJob);
            }
          }
        });
      } else {
        this.snackBar
          .openFromComponent(DynamicSnacbarErrorComponent, {
            data: res.serverResponse.message,
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            duration: 6000,
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
      }
    });
  }
  // Share job //
  shareCollectionAllJob() {
    let data = {
      path: 'search-job',
      userId: this.userDeatils.id,
      collectionId: this.selectedCollectionID,
      collectionname:
        this.selectedCollectionName == undefined
          ? 'default'
          : this.selectedCollectionName,
    };
    // console.log(data)
    const dialogRef = this.dialog.open(SharejobComponent, {
      height: 'auto',
      width: '450px',
      data: { data: data },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res === true) {
        return;
      }
    });
  }

  removeCollection() {
    const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
      height: 'auto',
      width: '400px',
      data: {},
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res == 'save') {
        let data = {
          path: 'remove-collection',
          id: this.userDeatils.id,
          collectionId: this.selectedCollectionID,
        };
        this.io._removeCollection(data).subscribe((res: any) => {
          if (res.serverResponse.code == 200) {
            // console.log(res);
            this.getCollection();
            this.snackBar
              .openFromComponent(DynamicSnacbarComponent, {
                data: res.serverResponse.message,
                verticalPosition: 'top', // 'top' | 'bottom'
                horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
              })
              .afterOpened()
              .subscribe(this.helper.setOnTop);
            // console.log( this.collectionRecords)
          }
        });
      }
    });
  }
  // Share job //
  shareJob(job) {
    const dialogRef = this.dialog.open(SharejobComponent, {
      height: 'auto',
      width: '450px',
      data: { data: job },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res === true) {
        return;
      }
    });
  }

  createCollection() {
    const dialogRef = this.dialog.open(CollectionCreateComponent, {
      height: 'auto',
      width: '450px',
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        console.log(res);
        this.collectionRecords = res;
      }
    });
  }

  renameCollectionDialog() {
    const dialogRef = this.dialog.open(RenameCollectionComponent, {
      height: 'auto',
      width: '450px',
      data: { collectionId: this.selectedCollectionID },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res == 'success') {
        let jsonData = {
          id: this.userDeatils.id,
          path: 'collection-list',
        };
        this.io._getCollection(jsonData).subscribe((res: any) => {
          if (res.serverResponse.code == 200) {
            this.collectionRecords = res['result'].collectionList;
            // console.log(this.collectionRecords);
            // this.ByDefaultSelectedCollection = res['result'].collectionList[0].id;
            // this.selectedCollectionID = res['result'].collectionList[0].id;
            // this.byDefaultCollectionJob();
            // if (this.selectedCollectionID == this.collectionRecords[0].id) {
            //   this.buttonDisable = true;
            // }
          }
        });
      }
    });
  }

  alreadyApply() {
    // this.snackBar.open("You have Already Applied", "OK")
    this.snackBar
      .openFromComponent(DynamicSnacbarSkipComponent, {
        data: 'You have Already Applied',
        verticalPosition: 'top', // 'top' | 'bottom'
        horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
      })
      .afterOpened()
      .subscribe(this.helper.setOnTop);
  }

  draftJob(Perticularjob: any) {
    // console.log(Perticularjob);
    let jsonData = {
      path: 'draft-job',
      userId: this.userDeatils.id,
      jobId: Perticularjob.id,
      activityType: '',
    };
    if (Perticularjob.apply_via == '1') {
      jsonData.activityType = environment.activityType.via_workNigeria;
    } else if (Perticularjob.apply_via == '2') {
      jsonData.activityType = environment.activityType.via_url;
    } else if (Perticularjob.apply_via == '3') {
      jsonData.activityType = environment.activityType.via_email;
    }
    // console.log(jsonData)
    this.io._draftJob(jsonData).subscribe((res: any) => {
      // console.log(res);
      if (res.serverResponse.code == 200) {
        // this.snackBar.open(res.serverResponse.message, "OK", {
        // })
      }
    });
  }

  async applyJob(Perticularjob: any) {
    if (this.userDeatils?.id != undefined || '') {
      await this.draftJob(Perticularjob);
    }

    if (Perticularjob.apply_via == '3') {
      const dialogRef = this.dialog.open(ViaEmailComponent, {
        height: 'auto',
        width: '450px',
        data: { Perticularjob },
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res == 'save') {
          // console.log("++++++++++++")
          this.io
            ._saveAllJobsByCollection(this.payload)
            .subscribe((res: any) => {
              if (res.serverResponse.code == 200) {
                this.detailsOfJob = '';
                // console.log(res);
                this.jobLists = res['result'].jobList;
                if (this.jobLists.length != 0) {
                  // console.log(this.jobLists)
                  this.detailsOfJob = this.jobLists[0];
                  this.status = this.jobLists[0].id;
                  this.jobExpried(this.detailsOfJob);
                }
              }
            });
        }
      });
    } else if (Perticularjob.apply_via == '2') {
      const dialogRef = this.dialog.open(ViaExternalUrlComponent, {
        height: 'auto',
        width: '450px',
        data: { Perticularjob },
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res == 'save') {
          // console.log("++++++++++++")
          this.io
            ._saveAllJobsByCollection(this.payload)
            .subscribe((res: any) => {
              if (res.serverResponse.code == 200) {
                this.detailsOfJob = '';
                // console.log(res);
                this.jobLists = res['result'].jobList;
                if (this.jobLists.length != 0) {
                  // console.log(this.jobLists)
                  this.detailsOfJob = this.jobLists[0];
                  this.status = this.jobLists[0].id;
                  this.jobExpried(this.detailsOfJob);
                }
              }
            });
        }
      });
    } else if (Perticularjob.apply_via == '1') {
      const dialogRef = this.dialog.open(ViaWorkNigeriaComponent, {
        height: 'auto',
        width: '450px',
        data: { Perticularjob },
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res == 'save') {
          // console.log("++++++++++++")
          this.io
            ._saveAllJobsByCollection(this.payload)
            .subscribe((res: any) => {
              if (res.serverResponse.code == 200) {
                this.detailsOfJob = '';
                // console.log(res);
                this.jobLists = res['result'].jobList;
                if (this.jobLists.length != 0) {
                  // console.log(this.jobLists)
                  this.detailsOfJob = this.jobLists[0];
                  this.status = this.jobLists[0].id;
                  this.jobExpried(this.detailsOfJob);
                }
              }
            });
        }
      });
    }
  }

  reportModel(Perticularjob: any) {
    const dialogRef = this.dialog.open(ReportJobComponent, {
      height: 'auto',
      width: '450px',
      data: { Perticularjob },
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res == 'save') {
        // console.log("++++++++++++")
        // this.ngOnInit();
      }
    });
  }
}
