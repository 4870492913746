import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
  MatLegacyDialog as MatDialog,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { Helper } from 'src/app/helper/defult';
import { HttpIOService } from 'src/app/services/http-io.service';
import { PasswordValidation } from 'src/app/validators/password.validator';
import { ChangePasswordComponent } from '../change-password/change-password.component';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss'],
})
export class NewPasswordComponent implements OnInit {
  reset: UntypedFormGroup;
  passwordPattern =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  id: any;
  public showPassword: boolean = false;
  public showPassword2: boolean = false;
  constructor(
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private io: HttpIOService,
    private helper: Helper,
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((params) => {
      this.id = params['_id'];
    });
  }

  ngOnInit(): void {
    this.formInisilize();
  }
  // Show Pasword //
  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }
  // Show Pasword //
  public togglePasswordVisibility2(): void {
    this.showPassword2 = !this.showPassword2;
  }
  // form insilize //
  formInisilize() {
    this.reset = this.fb.group(
      {
        id: [this.id, Validators.required],
        path: ['reset-password', Validators.required],
        password: [
          '',
          Validators.compose([
            Validators.required,
            Validators.pattern(this.passwordPattern),
          ]),
        ],
        newPassword: [
          '',
          Validators.compose([
            Validators.required,
            Validators.pattern(this.passwordPattern),
          ]),
        ],
      },
      { validator: PasswordValidation('password', 'newPassword') }
    );
  }

  // Validation Controls //
  get f() {
    return this.reset.controls;
  }

  // Change Password //
  changedPassword() {
    if (this.reset.status === 'INVALID') {
      this.helper.markFormGroupTouched(this.reset);
      return false;
    }
    let payLoad = {
      id: this.id,
      newPassword: this.reset.value.newPassword,
      path: 'reset-password',
    };
    this.io.apicall(payLoad, 'auth/reset-password').subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        // this.dialogRef.close();
        // const dialogRef = this.dialog.open(ChangePasswordComponent, {
        //   height: 'auto',
        //   width: '450px',
        //   data: {}
        // });
        // dialogRef.afterClosed().subscribe(res => {
        //   if (res === true) {
        //   }
        // });
        this.router.navigate(['/password-changed']);
      } else {
        // this.snackBar.open(res.serverResponse.message, 'OK', {})
        this.snackBar
          .openFromComponent(DynamicSnacbarErrorComponent, {
            data: res.serverResponse.message,
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            duration: 6000,
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
      }
    });
    // .catch(err => {
    //   if (!err.error.serverResponse) {
    //     this.snackBar.open('Please check connectivity', 'OK', {})
    //   }
    //   else {
    //     this.snackBar.open(err.error.serverResponse.message, 'OK', {})
    //   }
    // })
  }
}
