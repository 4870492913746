import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
  MatLegacyDialog as MatDialog,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { DynamicSnacbarSkipComponent } from 'src/app/container/elements/dynamic-snacbar-skip/dynamic-snacbar-skip.component';
import { Helper } from 'src/app/helper/defult';
import { HttpIOService } from 'src/app/services/http-io.service';
import { NewPasswordComponent } from '../new-password/new-password.component';

@Component({
  selector: 'app-emailed-code',
  templateUrl: './emailed-code.component.html',
  styleUrls: ['./emailed-code.component.scss'],
})
export class EmailedCodeComponent implements OnInit {
  codeEnter: UntypedFormGroup;
  otpPattern = '[0-9]+';
  id: any;
  emailId: any;
  id_email: any;
  constructor(
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private io: HttpIOService,
    private helper: Helper,
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((params) => {
      this.id = params['_id'];
      this.emailId = params['eId'];
    });
    // this.id_email = this.emailId.toString().slice(0,1);
    // console.log(this.id_email);
  }

  ngOnInit(): void {
    if (!this.id || !this.emailId) {
      this.router.navigate(['/forgot-password']);
      // this.snackBar.open('You have come unauthorized','OK',{});
      this.snackBar
        .openFromComponent(DynamicSnacbarSkipComponent, {
          data: 'You have come unauthorized',

          verticalPosition: 'top', // 'top' | 'bottom'
          horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
        })
        .afterOpened()
        .subscribe(this.helper.setOnTop);
    }

    this.formInsli();
  }

  // Create Modal Driven Form //
  formInsli() {
    this.codeEnter = this.fb.group({
      otp: [null, Validators.compose([Validators.required])],
      id: [this.id, Validators.required],
      path: ['verify-otp', Validators.required],
    });
  }

  // Validation Controls //
  get f() {
    return this.codeEnter.controls;
  }

  // Code Enter //
  newPassword() {
    if (this.codeEnter.status === 'INVALID') {
      this.helper.markFormGroupTouched(this.codeEnter);
      return false;
    }

    this.io
      .apicall(this.codeEnter.value, 'auth/verify-otp')
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          // this.dialogRef.close();
          // const dialogRef = this.dialog.open(NewPasswordComponent, {
          //   height: 'auto',
          //   width: '450px',
          //   data: {id: this.data.id}
          // });
          // dialogRef.afterClosed().subscribe(res => {
          //   if (res === true) {
          //   }
          // });
          this.router.navigate(['/reset-password'], {
            queryParams: { _id: this.id },
          });
        } else {
          // this.snackBar.open(res.serverResponse.message, 'OK', {})
          this.snackBar
            .openFromComponent(DynamicSnacbarErrorComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
              duration: 6000,
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
        }
      });
    // .catch(err => {
    //   if (!err.error.serverResponse) {
    //     this.snackBar.open('Please check connectivity', 'OK', {})
    //   }
    //   else {
    //     this.snackBar.open(err.error.serverResponse.message, 'OK', {})
    //   }
    // })
  }

  // Resend Link Password //
  resendPassword() {
    // if (this.forgotPassword.status === 'INVALID') {
    //   this.helper.markFormGroupTouched(this.forgotPassword);
    //   return false;
    // }
    let payload = {
      email: this.emailId,
      path: 'forgot-password',
    };
    this.io.apicall(payload, 'auth/forgot-password').subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        // this.dialogRef.close(true);
        // const dialogRef = this.dialog.open(EmailedCodeComponent, {
        //   height: 'auto',
        //   width: '450px',
        //   data: { id: res.result.id }
        // });
        // dialogRef.afterClosed().subscribe(res => {
        //   if (res === true) {
        //   }
        // });
        // this.router.navigate(['/code-verify'],{queryParams:{_id:res.result.id,eId:this.forgotPassword.value.email}});
      } else {
        // this.snackBar.open(res.serverResponse.message, 'OK', {})
        this.snackBar
          .openFromComponent(DynamicSnacbarErrorComponent, {
            data: res.serverResponse.message,
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            duration: 6000,
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
      }
    });
    // .catch(err => {
    //   if (!err.error.serverResponse) {
    //     this.snackBar.open('Please check connectivity', 'OK', {})
    //   }
    //   else {
    //     this.snackBar.open(err.error.serverResponse.message, 'OK', {})
    //   }
    // })
  }
}
