<div class="sandbox-container">
    <div class="sandbox-ui">
        <div class="row">
            <div class="mobile-view-img">
                <h1 class="sandbox-text-mobile">Get Noticed by Employers! Submit your details here.</h1>
                <img class="sandbox-img-mobile" src="../../../assets/images/sandbox-mobile-img.webp" />
            </div>
            <div class="col-lg-6 sandbox-text">
                <h1>Get Noticed by Top Employers! <br> Submit Your Details</h1>
                <p class="para-1">In our recent survey, recruiters told us that CV search is the top tool they use 
                    to find the best candidates. Post your CV on Worknigeria today to ensure recruiters and hiring 
                    managers can easily find you.</p>
                <form [formGroup]="sandBoxForm" autocomplete="off" class="main-ctr">
                    <!-- First and Last Name -->
                    <div class="row">
                        <div class="col-lg-6 col-12 form-group">
                            <mat-form-field class="example-full-width" appearance="fill">
                                <mat-label>First Name</mat-label>
                                <input type="text" matInput placeholder="First Name" required="required"
                                    formControlName="firstName" />
                            </mat-form-field>
                            <div class="error-msg" *ngIf="f.firstName.touched && f.firstName.invalid">
                                <span class="text-danger pb-1" *ngIf="f.firstName.errors.required">First Name is
                                    required</span>
                                <span class="text-left text-danger pb-1" *ngIf="f.firstName.errors?.pattern">
                                    Please enter a valid name
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-6 col-12 form-group">
                            <mat-form-field class="example-full-width" appearance="fill">
                                <mat-label>Last Name</mat-label>
                                <input type="text" matInput placeholder="Last Name" required="required"
                                    formControlName="lastName" />
                            </mat-form-field>
                            <div class="error-msg" *ngIf="f.lastName.touched && f.lastName.invalid">
                                <span class="text-danger pb-1" *ngIf="f.lastName.errors?.required">
                                    Last Name is required
                                </span>
                                <span class="text-left text-danger pb-1" *ngIf="f.lastName.errors?.pattern">
                                    Please enter a valid name
                                </span>
                            </div>
                        </div>
                    </div>
                    <!-- Email -->
                    <div class="form-group">
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Enter Email</mat-label>
                            <input type="email" matInput placeholder="Enter Email" required="required"
                                formControlName="email" [pattern]="emailPattern" />
                        </mat-form-field>
                        <div class="error-msg" *ngIf="f.email.touched && f.email.invalid">
                            <span class="text-danger pb-1" *ngIf="f.email.errors?.required">Email is required</span>
                            <span class="text-danger pb-1" *ngIf="f.email.errors?.pattern">Email is not valid</span>
                        </div>
                    </div>
                    <!-- Job Role -->
                    <div class="form-group">
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Job Role</mat-label>
                            <input type="text" matInput placeholder="Job Role" required="required"
                                formControlName="jobRole" [pattern]="jobRolePattern" />
                        </mat-form-field>
                        <div class="error-msg" *ngIf="f.jobRole.touched && f.jobRole.invalid">
                            <span class="text-danger pb-1" *ngIf="f.jobRole.errors?.required">Job Role is
                                required</span>
                            <span class="text-danger pb-1" *ngIf="f.jobRole.errors?.pattern">Job Role is not
                                valid</span>
                        </div>
                    </div>
                    <!-- CV Upload Container -->
                    <div class="cv-upload-container">
                        <h3>
                            Upload CV
                        </h3>
                        <div class="file-upload-container" (dragover)="onDragOver($event)"
                            (dragleave)="onDragLeave($event)" (drop)="onFileDropped($event)">
                            <div class="dropzone" *ngIf="!file">
                                <img src="../../../assets/images/upload-icon.png" />
                                <p>Drag your file to start uploading</p>
                            </div>
                            <div class="or-continue" *ngIf="!file">
                                <div class="or">OR</div>
                            </div>
                            <div class="custom-file-upload" *ngIf="!file">
                                <input type="file" (change)="onFilesSelected($event)" accept=".pdf, .docx" />
                                <button>Browse files</button>
                            </div>
                            <div *ngIf="file" class="progress-div">
                                <h4>{{ uploadText }}</h4>
                                <div class="d-flex align-items-center justify-content-center progress-bar-container">
                                    <mat-progress-bar mode="determinate" [value]="file.progress"></mat-progress-bar>
                                    <p class="uploaded-file-name">{{ file.name }}</p>
                                    <button (click)="removeFile()" class="btn-remove">&#10006;</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Save CV button -->
                    <div class="btn-div">
                        <button class="btn-save-cv" [disabled]="!this.file" (click)="saveCVs()">Save CV</button>
                    </div>
                </form>
            </div>
            <div class="col-lg-6">
                <img src="../../../assets/images/sandbox-img.webp" class="sandbox-img" alt="Sandbox Image" />
            </div>
        </div>
    </div>
</div>