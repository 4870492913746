import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EmployerService } from 'src/app/services/employer.service';
import { environment } from 'src/environments/environment';
import { HttpIOService } from 'src/app/services/http-io.service';
import { DynamicSnacbarErrorComponent } from '../dynamic-snacbar-error/dynamic-snacbar-error.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder, Validators, } from '@angular/forms';

@Component({
  selector: 'app-popup-confirmation',
  templateUrl: './popup-confirmation.component.html',
  styleUrls: ['./popup-confirmation.component.scss']
})
export class PopupConfirmationComponent {
  saveCandidate = false;
  saveInFolder = false;
  folders: any;
  selectedFolderName: string;
  selectedFolderId: string;
  params: any;
  jobLists: any;
  userDetails: any;
  userToken: string;
  companyData: any;
  updatedJobList = [];
  inviteToApply = false;
  stars = Array(5).fill(0);
  rating = 0;
  userFeedbackForm: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PopupConfirmationComponent>,
    private router: Router,
    private es: EmployerService,
    private io: HttpIOService,
    private snackBar: MatSnackBar,
    private helper: Helper,
    private auth: AuthService,
    private dataService: DataService,
    private fb: UntypedFormBuilder,
  ) {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    this.userToken = sessionStorage.getItem('workNigeriaToken');

    if (localUser) {
      this.userDetails = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
    }
    if (sessionUser) {
      this.userDetails = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }
    if (this.userDetails === undefined) {
      return;
    } else {
      this.userDetails.isEmail;
    }
  }

  ngOnInit(): void {
    if (this.data['data'] === 'save CV') {
      this.getFolderforCV();
    } else if (this.data['data'] === 'invite to apply') {
      this.fetchJobList();
    } else if (this.data['data'] === 'brochure success') {
      // Triggers the download of the Brochure PDF
      const link = document.createElement('a');
      link.href = '../../../../assets/WN-2025 Training Calendar.pdf';
      link.download = 'WN-2025 Training Calendar.pdf';
      link.click();
    }
    this.validationForm();
  }

  validationForm() {
    this.userFeedbackForm = this.fb.group({
      rating: [0, Validators.required],
      comment: [''],
    });
  }

  fetchJobList() {
    if (environment.production === true) {
      let payLoad = {
        path: 'get-job-by-provider-or-company',
        provider_id: this.userDetails.id,
      };

      this.io
        .apicall(payLoad, 'job/get-job-by-provider-or-company')
        .subscribe((res: any) => {
          if (res.serverResponse.code == 200) {
            this.jobLists = res['result'].job;
          } else {
            this.snackBar
              .openFromComponent(DynamicSnacbarErrorComponent, {
                data: res.serverResponse.message,
                verticalPosition: 'top', // 'top' | 'bottom'
                horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
                duration: 6000,
              })
              .afterOpened()
              .subscribe(this.helper.setOnTop);
          }
        });
    } else {
      let payLoad = {
        path: 'employer-wise-job-list',
        job_provider_id: this.userDetails.id,
      };
      this.io
        .apicall(payLoad, 'job/employer-wise-job-list')
        .subscribe((res: any) => {
          if (res.serverResponse.code == 200) {
            this.jobLists = res['result'].jobList;
          } else {
            this.snackBar
              .openFromComponent(DynamicSnacbarErrorComponent, {
                data: res.serverResponse.message,
                verticalPosition: 'top', // 'top' | 'bottom'
                horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
                duration: 6000,
              })
              .afterOpened()
              .subscribe(this.helper.setOnTop);
          }
        });
    }
  }

  onNoClick() {
    this.dialogRef.close();
  }

  postJob() {
    this.router.navigate(['post-job']);
    this.dialogRef.close();
  }

  goToDashboard() {
    this.router.navigate(['Dashboard']);
    this.dialogRef.close();
    this.loadUpdatedAssets();
  }
  goToSearchCV() {
    this.dataService.setUserData(undefined)
    this.router.navigate(['Dashboard'], {
      queryParams: ['search-cv']
    });
    this.dialogRef.close();
    // this.dataService.loadUpdatedAssets().subscribe(() => {
    // });
  }

  goToCampaignDashboard() {
    this.router.navigate(['Dashboard'], {
      queryParams: ['promote-job']
    });
    this.dialogRef.close();
  }

  goToJobs() {
    this.router.navigate(['employer-home']);
    this.dialogRef.close();
  }

  newFolder() {
    this.saveInFolder = true;
    this.saveCandidate = false;
  }

  getFolderforCV() {
    this.es._getFolderforCVs().subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        this.folders = res['result'];
      }
    });
  }

  onFolderSelect(event: any) {
    this.selectedFolderId = event.value;
    const selectedFolder = this.folders.find(folder => folder.id === this.selectedFolderId);

    if (selectedFolder) {
      this.selectedFolderName = selectedFolder.name;
    }
  }

  saveCV(candidateId, folderId, folderName, check) {
    if (check === 'old') {
      this.params = {
        folderId: folderId,
        candidateIds: [candidateId]
      };
    } else {
      this.params = {
        folderName: folderName,
        candidateIds: [candidateId]
      };
    }
    this.es._saveCV(this.params).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        this.saveCandidate = true;
        this.saveInFolder = false;
      }
    })
  }

  createEmptyFolder(folderName) {
    this.dialogRef.close(folderName);
  }

  locationType(loc: any) {
    switch (loc) {
      case 'REMOTE':
        return 'Remote';
      case 'OUT_OF_COUNTRY':
        return 'Out of Country';
      default:
        return 'Onsite';
    }
  }

  updateSelectedJobsList(isChecked: any, jobId: String): void {
    if (isChecked.checked) {
      this.updatedJobList.push(jobId);
    } else {
      this.updatedJobList.splice(this.jobLists.indexOf(jobId), 1);
    }
  }

  sendInvite() {
    let payLoad = {
      path: 'invite-to-apply',
      firstName: this.data['jobDetails']?.firstName,
      lastName: this.data['jobDetails']?.lastName,
      email: this.data['jobDetails']?.contactDetails?.email,
      jobId: this.updatedJobList[0]
    };
    this.es._inviteToApply(payLoad)
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          this.inviteToApply = true;
        } else {
          this.snackBar
            .openFromComponent(DynamicSnacbarErrorComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
              duration: 6000,
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
        }
      });
  }

  upgradePlan() {
    this.router.navigate(['/choose-employer-subscription'], { state: { ...this.data.details } });
    this.dialogRef.close();
  }

  purchaseBundle(type: string) {
    this.router.navigate([`/bundles/${type}`], { state: { ...this.data.details } });
    this.dialogRef.close();
  }

  payFromWallet() {
    if (this.data.data === 'Insufficient Credits CV') {
      if (this.data?.walletBalance >= this.data?.details?.extraAmountRequired) {
        this.dialogRef.close('payFromWallet');
      } else {
        this.router.navigate(['/fund-wallet'], { state: { ...this.data.details } })
        this.dialogRef.close();
      }
    } else {
      this.router.navigate(['/fund-wallet'], { state: { ...this.data.details } });
      this.dialogRef.close();
    }
  }

  postCampaign(): void {
    this.dialogRef.close('job-overview');
    // this.es._postCampaign({...this.data.campaignDetails}).subscribe((res) => {
    //   console.log(res['serverResponse'])
    //   if (res['serverResponse']['code'] == 200) {
    //     this.dialogRef.close('success')
    //   }
    // });
  }
  unlockCV(): void {
    this.io._unlockCV({ candidateId: this.data.campaignDetails.candidateId, payFromWallet: true }).subscribe((res) => {
      if (res['serverResponse']['code'] == 200) {

        this.goToSearchCV()

      }
    });
  }
  loadUpdatedAssets() {
    this.dataService.setUserData(undefined)
  }

  goToHomePage() {
    this.router.navigate(['/home']);
    this.dialogRef.close();
  }

  goTo3MTT() {
    if (environment.production) {
      window.location.href = "https://3mtt.worknigeria.com/";
    } else {
      window.location.href = "https://3mtt-development.worknigeria.com";
    }
    this.dialogRef.close();
  }

  goToSignUp() {
    this.router.navigate(["/signup"]);
    this.dialogRef.close();
  }

  goToServices() {
    this.router.navigate(["/services"]);
    this.dialogRef.close();
  }

  setRating(value: number): void {
    this.rating = value;
    this.userFeedbackForm.patchValue({ rating: this.rating });
  }
    

// Saves the feedback data to the backend
submitRating(): void {
  console.log("user form", this.userFeedbackForm);
  let payload = {
    path: "/add-user-feedback-info",
    category: "job-post",
    rating: this.userFeedbackForm.value.rating,
    comments: this.userFeedbackForm.value.comment,
  }
    this.io._submitFeedback(payload).subscribe((res: any) => {
    if (res.serverResponse.code === 200) {
      this.router.navigate(['Dashboard']);
      this.dialogRef.close();
    } else {
      this.snackBar
        .openFromComponent(DynamicSnacbarErrorComponent, {
          data: res.serverResponse.message,
          verticalPosition: 'top', // 'top' | 'bottom'
          horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          duration: 6000,
        })
        .afterOpened()
        .subscribe(this.helper.setOnTop);
    }
  });
}
}