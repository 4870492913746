import { Component, EventEmitter, OnInit, Output, AfterViewInit, ViewChild, Injectable } from '@angular/core';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, Sort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { EmployerService } from 'src/app/services/employer.service';
import { EmpExtendPopupComponent } from '../../emp-campaign-management/emp-extend-popup/emp-extend-popup.component';
import { EmpDashJobOverviewComponent } from '../dashboard-elements/emp-dash-job-overview/emp-dash-job-overview.component';
import { v4 as uuidv4 } from 'uuid';
import { MatDialog } from '@angular/material/dialog';
import { EmpDashPreviewComponent } from '../emp-dash-preview/emp-dash-preview.component';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpIOService } from 'src/app/services/http-io.service';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { PopupConfirmationComponent } from 'src/app/container/elements/popup-confirmation/popup-confirmation.component';
import { DataService } from 'src/app/services/data.service';
import { IntroJSService } from 'src/app/services/introjs.service';
import { DynamicSnacbarSkipComponent } from 'src/app/container/elements/dynamic-snacbar-skip/dynamic-snacbar-skip.component';

@Component({
  selector: 'app-emp-dash-promote-jobs',
  templateUrl: './emp-dash-promote-jobs.component.html',
  styleUrls: ['./emp-dash-promote-jobs.component.scss']
})
export class EmpDashPromoteJobsComponent {
  campaignList = [];
  jobsList = [];
  selectedJobsList = [];

  isCampaignBeingAdded: boolean = false;
  userDetails: any;
  companyData: any;
  today: any;
  search = '';

  currentPage = 0;
  currentJobsPage = 0;
  currentCampaignsPage = 0;
  currentBalance = 0;
  filteredJobs: any[] = [];

  campaignsPagination = {
    size: 4,
    start: 0,
    total: 0,
  };

  jobsPagination = {
    size: 10,
    start: 0,
    total: 0,
  };

  newCampaignForm: FormGroup;

  title = '';
  transactionRef = uuidv4();

  displayedColumns: string[];
  userPlan = '';
  // dataSource: any;

  @Output() dataEvent = new EventEmitter<boolean>();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private _formBuilder: FormBuilder,
    private employerService: EmployerService,
    private helper: Helper,
    private auth: AuthService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private _liveAnnouncer: LiveAnnouncer,
    private router: Router,
    private io: HttpIOService,
    private dataService: DataService,
    private introService: IntroJSService
  ) {
    this.auth.userUpdated.subscribe((res) => {
      let localUser = localStorage.getItem('workNigeriaUser');
      let sessionUser = sessionStorage.getItem('workNigeriaUser');
      if (localUser) {
        this.userDetails = JSON.parse(
          this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
        );
        this.companyData = this.auth.getCurrentCompanyData();
      }
      if (sessionUser) {
        this.userDetails = JSON.parse(
          this.helper.fromBinary(
            atob(sessionStorage.getItem('workNigeriaUser'))
          )
        );
        this.companyData = this.auth.getCurrentCompanyDataInSessoin();
      }

      this.currentBalance = this.userDetails.balance ?? 0;
    });

    this.newCampaignForm = this._formBuilder.group({
      title: ['', [Validators.required, this.noWhitespaceValidator]],
      // budget: [10000.0, Validators.required],
      startDate: ['', [Validators.required, this.noWhitespaceValidator]],
      // endDate: ['', Validators.required],
    });
  }

  noWhitespaceValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const isWhitespace = (control.value || '').trim().length === 0;
    return isWhitespace ? { 'whitespace': true } : null;
  }



  updateLocalUserDetails() {
    let jsonData = {
      path: 'get-job-seeker',
      id: this.userDetails.id,
    };
    this.employerService._getCurrentUser(jsonData).subscribe((res) => {
      if (res['serverResponse'].code === 200) {
        this.auth.setCurrentUserDetails(res['result']['userData']);
        this.auth.setCurrentUserDetailsInSessoin(res['result']['userData']);
      }
    });
  }

  ngOnInit(): void {
    this.today = this.todaysDate();
    //this.updateLocalUserDetails();
    this.getAllCampaigns();
  }

  // ngAfterViewInit() {
  //   this.dataSource.sort = this.sort;
  //   this.dataSource.paginator = this.paginator;
  // }

  async getEmployerJobs(sortOrder: string = 'DESC'): Promise<void> {
    let params = {
      size: this.jobsPagination.size,
      start: this.jobsPagination.start,
      sortParam: 'created_at',
      sortOrder,
    };
    if (environment.production === true) {
      let jsonData = {
        path: 'get-job-by-provider-or-company',
        company_id: this.userDetails.companyId,
        start: this.jobsPagination.start,
        size: this.jobsPagination.size,
      };
      await this.employerService
        ._getJobByproviderAndCompany(jsonData)
        .subscribe((res: any) => {
          if (res.serverResponse.code == 200) {
            this.jobsList = res['result'].job;
            this.jobsPagination.total = res['pagination']['total'];
            this.jobsPagination.start += this.jobsPagination.size;
          }
        });
    } else {
      let payLoad = {
        path: 'employer-wise-job-list',
        job_provider_id: this.userDetails.id,
        start: this.jobsPagination.start,
        size: this.jobsPagination.size,
      };

      this.io
        .apicall(payLoad, 'job/employer-wise-job-list', { ...params })
        .subscribe((res: any) => {
          if (res.serverResponse.code == 200) {
            this.jobsList = res['result'].jobList;
            this.jobsPagination = res['result']['pagination'];
          } else {
            this.snackBar
              .openFromComponent(DynamicSnacbarErrorComponent, {
                data: res.serverResponse.message,
                verticalPosition: 'top', // 'top' | 'bottom'
                horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
                duration: 6000,
              })
              .afterOpened()
              .subscribe(this.helper.setOnTop);
          }
        });
    }
  }

  createdAtDate(datetime: string) {
    const dateObj = new Date(datetime);

    const month = dateObj.toLocaleString('en-US', { month: 'short' });
    const year = dateObj.getFullYear();

    const formattedDate = `${dateObj.getDate()} ${month} ${year}`;

    return formattedDate;
  }

  daysLeft(endDate: string) {
    const today = new Date();
    const end = new Date(endDate);

    // Calculate the difference in milliseconds between the two dates
    const timeDiff = end.getTime() - today.getTime();

    // Calculate the number of days left
    const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

    if (daysLeft > 0) {
      return daysLeft;
    } else {
      return 0;
    }
  }

  getAllCampaigns(sortOrder: string = 'DESC'): void {
    let params = {
      size: this.campaignsPagination.size,
      start: this.campaignsPagination.start,
      sortParam: 'createdAt',
      sortOrder,
      search: this.search,
    };

    this.employerService._getCampaigns(params).subscribe((res) => {
      if (res['serverResponse']['code'] == 200) {
        this.campaignList = res['result'];
        this.displayedColumns = ['status', 'type', 'applicants', 'createdAt', 'endDate', 'paymentStatus'];
        // this.dataSource = new MatTableDataSource(this.campaignList);
        // this.dataSource.sort = this.sort;
        // this.dataSource.paginator = this.paginator;
        this.campaignsPagination.total = res['pagination']['total'];
        this.campaignsPagination.start = res['pagination']['start'];
      }
      if((localStorage.getItem('promoteJobTour') ?? '0') === '0'){
        setTimeout(() => {
          this.introService.promoteJob()
        }, 1000)
      }
    });
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    this.campaignsPagination.start = 0;
    this.currentCampaignsPage = 0
    if (sortState.direction) {
      this.getAllCampaigns(sortState.direction.toUpperCase());
    } else {
      this.getAllCampaigns();
    }
  }

  startNewCampaign(): void {
    this.getEmployerJobs();
    this.isCampaignBeingAdded = true;
    this.currentPage = 1
  }

  postCampaign(): void {
    const selectedDate = new Date(this.newCampaignForm.value.startDate);
    const todayDate = new Date(this.today);

    if (selectedDate < todayDate) {
      this.newCampaignForm.get('startDate').setErrors({ dateError: true });
      return;
    }
    let jsonData = {
      path: 'create-campaign',
      type: this.newCampaignForm.value.title,
      budget: this.newCampaignForm.value.budget,
      // budgetCurrency: 'NGN',
      startDate: this.newCampaignForm.value.startDate + 'T00:00:00Z',
      // endDate: this.newCampaignForm.value.endDate,
      jobIds: this.selectedJobsList,
    };

    this.employerService._postCampaign(jsonData).subscribe((res) => {
      if (res['serverResponse']['code'] == 200) {
        const dialogRef = this.dialog.open(PopupConfirmationComponent, {
          height: 'auto',
          width: 'auto',
          autoFocus: false,
          data: {
            data: 'Campaign Successful',
            campaignDetails: jsonData
          },
        })
        dialogRef.afterClosed().subscribe(() => {
          this.loadUpdatedAssets()
          this.isCampaignBeingAdded = false;
          this.dataEvent.emit(this.isCampaignBeingAdded);
          this.currentPage = 1;
          // this.updateLocalUserDetails();
          this.getAllCampaigns();
        });
      } else {
        console.log(res['serverResponse'])
        const dialogRef = this.dialog.open(PopupConfirmationComponent, {
          height: 'auto',
          width: 'auto',
          autoFocus: false,
          data: {
            data: 'Insufficient Credits',
            allowedPromotions: res['serverResponse']?.details?.creditsUtilized,
            subscription: res['serverResponse']?.details?.subscription || 'Bronze',
            details: {
              ...jsonData,
              extraAmountRequired: res['serverResponse']?.details?.extraAmountRequired,
              jobs: this.jobsList.filter((job) => this.selectedJobsList.includes(job.id)),
              creditBreakup: res['serverResponse']?.details?.creditBreakup,
            },
          },
        })
      }
    });
  }

  goToNextPage(): void {
    if (this.currentPage === 1 && this.selectedJobsList.length === 0) {
      return;
    }
    if (this.currentPage === 2 && !this.newCampaignForm.valid) {
      return;
    }
    this.currentPage++;
  }

  goBack(): void {
    if (this.currentPage === 1) {
      this.isCampaignBeingAdded = false;
      this.dataEvent.emit(this.isCampaignBeingAdded);
      return;
    }

    this.currentPage--;
  }

  onJobsPageChanged(event: any): void {
    this.jobsPagination.start = event.pageIndex * this.jobsPagination.size;
    this.currentJobsPage = event.pageIndex;
    this.getEmployerJobs();
  }

  onCampaignsPageChanged(event: any): void {
    this.campaignsPagination.start = event.pageIndex * this.campaignsPagination.size;
    this.currentCampaignsPage = event.pageIndex;
    this.getAllCampaigns();
  }

  updateSelectedJobsList(isChecked: any, jobId: String): void {
    if (isChecked.checked) {
      this.selectedJobsList.push(jobId);
    } else {
      this.selectedJobsList.splice(this.selectedJobsList.indexOf(jobId), 1);
    }
  }

  formatDateToDDMMYYYY(timestamp: number): string {
    const date = new Date(timestamp);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${day}-${month}-${year}`;
  }

  getCampaignStatus(status: string, endDate): string {
    let daysLeftZero = this.daysLeft(endDate);
    if (status == 'Stop' && daysLeftZero <= 0) {
      status = 'Expired';
    }
    switch (status) {
      case 'Start':
        return 'ACTIVE';
      case 'Stop':
        return 'INACTIVE';
      case 'Expired':
        return 'EXPIRED';
      default:
        return 'ACTIVE';
    }
  }

  getCampaignStatusClass(status: string, endDate): string {
    let daysLeftZero = this.daysLeft(endDate);
    if (status == 'Stop' && daysLeftZero <= 0) {
      status = 'Expired';
    }
    switch (status) {
      case 'Start':
        return 'curved-pill-active';
      case 'Stop':
        return 'curved-pill-inactive';
      case 'Expired':
        return 'curved-pill-expired';
      default:
        return 'curved-pill-active';
    }
  }

  toggleCampaignStatus(event: any, campaign: any): void {
    let jsonData = {
      path: 'update-campaign',
      status: event.target.checked ? 'Start' : 'Stop',
    };
    this.employerService
      ._updateCampaign(jsonData, campaign.id)
      .subscribe((res) => {
        console.log(res)
        if (res['serverResponse']['code'] == 200) {
          console.log(res['result'].id)
          this.campaignList = this.campaignList.map((item) => (item.id === res['result'].id ? { ...res['result'], applicants: item.applicants } : item));
          console.log(this.campaignList)
          // this.getAllCampaigns();
        } else {
          console.log('res 2: ', res);
        }
      });
  }

  openExtendPopUp() {
    const dialogRef = this.dialog.open(EmpExtendPopupComponent, {
      width: '876px',
      height: '461px',
      panelClass: 'no-border-radius-dialog',
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res == 'save') {
      }
    });
  }

  fundWallet() {
    let jsonData = {
      path: 'fund-wallet',
      cardName: 'Neil abc',
      cardType: 'Visa',
      transactionId: '1234',
      paymentAmount: 1000000,
      currency: 'NGN',
      status: 'Paid',
    };

    this.employerService._fundWallet(jsonData).subscribe((res) => {
      if (res['serverResponse']['code'] == 200) {
        console.log(res);
        this.currentBalance += 1000000;
        this.userDetails.balance = this.currentBalance;
        this.auth.setCurrentUserDetails(this.userDetails);
        this.auth.setCurrentUserDetailsInSessoin(this.userDetails);
        // this.postCampaign();
      } else {
        console.log(res);
      }
    });
  }


  async postAjob() {
    let checkEmailValid = await this.io._checkEmailValid();
    if (checkEmailValid == true) {
      await this.io
        ._getUserPlan()
        .subscribe(
          (res: any) => {
            if (res.serverResponse.code === 200) {
              this.userPlan =  res.serverResponse.message;
              if (this.userPlan === 'Success') {
                const dataToSend = {
                  data: 'from dashboard add job'
                }
                this.router.navigate(['post-job'], {
                  queryParams: dataToSend
                });
              } else {
                this.router.navigate(['/choose-employer-subscription']);
              }
  
            } else {
              console.log(res);
            }
          },
          (error: any) => {
            console.error('An error occurred:', error);
          }
        );
      } else {
        this.snackBar
        .openFromComponent(DynamicSnacbarSkipComponent, {
          data: 'Please verify your email to add job',
          verticalPosition: 'top', // 'top' | 'bottom'
          horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
        })
        .afterOpened()
        .subscribe(this.helper.setOnTop);
      }
  }
  previewJobList() {
    this.filteredJobs = this.jobsList.filter((job) =>
      this.selectedJobsList.includes(job.id)
    );

    const dialogRef = this.dialog.open(EmpDashPreviewComponent, {
      width: '881px',
      height: '675px',
      autoFocus: false,
      data: { filteredJobs: [...this.filteredJobs] }, // Pass a copy of the filtered job list
    });

    dialogRef.afterClosed().subscribe((updatedJobsList: any[]) => {
      if (updatedJobsList) {
        this.selectedJobsList = updatedJobsList;
      }
    });
  }

  locationType(loc: any) {
    switch (loc) {
      case 'REMOTE':
        return 'Remote';
      case 'OUT_OF_COUNTRY':
        return 'Out of Country';
      case 'NIGERA':
        return 'Onsite';
    }
  }

  formatTitle(jobTitle: string) {
    return jobTitle
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  paymentCancel() {
    console.log('Payment Cancelled');
  }

  paymentDone(event: any) {
    console.log(event);
    let jsonData: any = {
      path: 'fund-wallet',
      cardName: 'N/A',
      cardType: 'N/A',
      transactionId: event.reference,
      paymentAmount: this.newCampaignForm.value.budget - this.currentBalance,
      currency: 'NGN',
      status: 'Paid',
    };
    this.employerService._fundWallet(jsonData).subscribe((res: any) => {
      if (res['serverResponse'].code === 200) {
        console.log('Payment Successful');
        this.userDetails.balance = 0;
        // this.updateLocalUserDetails();
        this.postCampaign();
      } else {
        console.log('Payment Failed');
      }
    });
  }

  updateTransactionId(event: any) {
    this.transactionRef = uuidv4();
  }

  loadUpdatedAssets() {
    this.dataService.loadUpdatedAssets().subscribe(() => { });
  }

  todaysDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
}
