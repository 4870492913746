import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { DownloadBrochurePopupComponent } from 'src/app/container/elements/download-brochure-popup/download-brochure-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { HttpIOService } from 'src/app/services/http-io.service';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { Helper } from 'src/app/helper/defult';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { PopupConfirmationComponent } from 'src/app/container/elements/popup-confirmation/popup-confirmation.component';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
})
export class AboutUsComponent implements OnInit {
  emailForm: UntypedFormGroup;
  emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9_.+-]{2,}$/;

  // Employers logos list
  logos = [
    '../../../../assets/images/tbo-logo.webp',
    '../../../../assets/images/zedcrest-logo.webp',
    '../../../../assets/images/aim-logo.webp',
    '../../../../assets/images/pecan-logo.webp',
    '../../../../assets/images/routelink-logo.webp',
    '../../../../assets/images/qlc-logo.webp',
    '../../../../assets/images/magna-logo.webp',
    '../../../../assets/images/manna-logo.webp',
    '../../../../assets/images/fohat-logo.webp',
    '../../../../assets/images/arise-logo.webp',
    '../../../../assets/images/richway-logo.webp',
    '../../../../assets/images/platview-logo.webp',
    '../../../../assets/images/cochlear-logo.webp',
    '../../../../assets/images/gosolve-logo.webp',
  ]

  // Comments by the employers
  testimonials = [
    {
      logo: '../../../../assets/images/chidinma-icon.webp',
      heading: 'Nico Farms',
      para: 'WorkNigeria provided excellent background check services for our company. Their process was thorough, efficient, and reliable, giving us complete confidence in the candidates we hired. The detailed reports were clear and timely, ensuring a smooth recruitment process.',
      saidBy: '',
    },
    {
      logo: '../../../../assets/images/emeka-icon.webp',
      heading: 'Bridgehead Engineering',
      para: 'WorkNigeria exceeded our expectations in recruiting top-notch middle-level talent for our company. The candidates are a perfect fit, contributing immediately to our success. Highly recommend their recruitment services!',
      saidBy: '— HR Jonathan',
    },
    {
      logo: '../../../../assets/images/adenike-icon.webp',
      heading: 'Sundry Agro',
      para: 'WorkNigeria\'\s document control course was concise, informative, and highly practical. It significantly improved our document management skills. Highly recommended!',
      saidBy: '— Nancy',
    },
  ]

  // Why choose work nigeria pointers
  items = [
    {
      heading: 'Leadership You Can Trust: ',
      para: ' Our team draws on years of expertise in recruitment and workforce development to confront Nigeria’s unemployment challenge head-on.'
    },
    {
      heading: 'Innovative Solutions: ',
      para: 'From free job postings to extensive training programs, we are shattering boundaries and creating possibilities for everybody.'
    },
    {
      heading: 'Commitment to Growth: ',
      para: 'We do not just match jobs; we build careers, supporting every stage of our job seekers’ journeys.'
    },
  ]

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private io: HttpIOService,
    private snackBar: MatSnackBar,
    private helper: Helper,
  ) { }

  ngOnInit(): void {
    this.validationForm();
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  validationForm() {
    this.emailForm = this.fb.group({
      service: ['', Validators.required],
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.emailPattern),
        ]),
      ],
      message: ['', Validators.required],
    })
  }

  // Validation Controls //
  get f() {
    return this.emailForm.controls;
  }

  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }

  goToSignUp() {
    this.router.navigate(['/signup']);
  }

  downloadBrochure() {
    const dialogRef = this.dialog.open(DownloadBrochurePopupComponent, {
      height: 'auto',
      width: '761px',
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe();
  }

  sendEmail() {
    let payload =
    {
      path: '/brochure-email',
      message: this.emailForm.value.message,
      email: this.emailForm.value.email,
      subject: this.emailForm.value.service,
    }
    this.io._brochureEmail(payload).subscribe((res: any) => {
      if (res.serverResponse.code === 200) {
        let successData = "about us email"
        const dialogRef = this.dialog.open(PopupConfirmationComponent, {
          height: 'auto',
          width: 'auto',
          autoFocus: false,
          data: {
            data: successData,
          },
        });
        dialogRef.afterClosed().subscribe();
        this.router.navigate(['/home']);
      } else {
        this.snackBar
          .openFromComponent(DynamicSnacbarErrorComponent, {
            data: res.serverResponse.message,
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            duration: 6000,
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
      }
    })
  }
}
