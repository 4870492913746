  <section class="employer-home">
    <div class="img">
      <img class="img-banner" src="../assets/images/services-banner.png">
    </div>
  </section>
  
<div class="text-center align-items-center mt-5">
    <h1 class="services-heading">
        End to End Human Resources Services
    </h1>
    <p class="services-heading-para">
        We are a 360 Human Resources Company. We offer a variety of services that make your human resources requirements
        and needs seamless
    </p>
</div>

<hr class="services-horizontal-line-separator mt-5">
<section class="services-list services-row-1">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-6 order-2">
                <div class="img img-row-1"><img src="../assets/images/recruitment-img.webp"></div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="services-list-info">
                    <h2 class="list-heading">Recruitment</h2>
                    <p class="services-para-text-size set-width-row-1-para">We provide complete staffing and recruiting
                        solutions for Human
                        Resources Departments throughout Nigeria and Sub- Sahara Africa. We are the owners and managers
                        of the prestigious <a (click)="navigateToSearchJob()" class="job-board"><b><u>job board</u></b></a> and have
                        in-house recruitment experts saddled with the responsibility of spotting talented people with
                        the right skills and attitude.</p>
                    <p class="services-para-text-size set-width-row-1-para">Focusing only on human resources staffing,
                        we bring extensive and
                        unique experience to the task. Our highest priorities are dedication to performance, integrity
                        and excellence in customer service.</p>
                    <button href="javascript:void(0);" class="btn btn-learn-more"
                        (click)="goToRecruitment()">Learn More</button>
                </div>
            </div>
            <div class="col-lg-1 col-md-1"></div>
        </div>
    </div>
</section>
<section class="services-list services-row-2 mt-3">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6 row-2-img-container">
                <div class="img training-img"><img src="../assets/images/training-img.webp"></div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="services-list-info">
                    <h2 class="list-heading">Training and Development</h2>
                    <p class="services-para-text-size set-width-row-2-para">Global business dynamics drive the
                        constantly changing and evolving environment in which we all
                        do business. Our training and development programs offer opportunities for individuals or
                        companies to proactively upskill and gain better understanding across various topics to stay
                        ahead of the competition. We provide a number of public trainings in addition to completely
                        customizable trainings for your organization.</p>
                    <button class="btn btn-learn-more"
                        (click)="navigateToTraining()">Learn More</button>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="services-list services-row-3 mt-3">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6 order-2">
                <div class="img"><img src="../assets/images/sme-support-img.webp"></div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="services-list-info">
                    <h2 class="list-heading">SME Support</h2>
                    <p class="services-para-text-size set-width-row-3-para">With Worknigeria, you can outsource your HR
                        Department while we
                        manage every HR functions
                        remotely and, or on-site visit. By outsourcing your HR functions to us you save huge cost of
                        hiring and maintaining an in-house HR Expert whose cost may include wage bills, pension, tax,
                        admin expenses, rewards, work tools, time and of cause desk space among others. We help you get
                        rid of these complexities by acting as your HR department. This strategy helps you to
                        concentrate on your core operations and strategic competencies. We address the key human related
                        issues that support SMEs growth and integrate the business strategy to Performance Management
                        system.</p>
                    <button href="javascript:void(0);" class="btn btn-learn-more"
                        (click)="navigateToContactUs('SME Support')">Learn More</button>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="services-list services-row-4 mt-3">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="img"><img src="../assets/images/background-check-img.webp"></div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="services-list-info">
                    <h2 class="list-heading">Background Check</h2>
                    <p class="services-para-text-size set-width-row-4-para">
                        We carry out thorogh verification of employee's certifications, trainings, or educational
                        claims. Our verification services leverage on our contact with universities, colleges,
                        vocational schools and other bodies, etc.
                    </p>
                    <button href="javascript:void(0);" class="btn btn-learn-more"
                        (click)="navigateToContactUs('Background Check')">Learn
                        More</button>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="services-list services-row-5 mt-3">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6  col-md-6 order-2">
                <div class="img"><img src="../assets/images/hr-advisory-img.webp"></div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="services-list-info">
                    <h2 class="list-heading">HR Advisory</h2>
                    <p class="services-para-text-size set-width-row-4-para">Experience the benefits of Worknigeria’s expert HR advisory services: increased employee engagement, reduced turnover, and streamlined processes tailored to your unique business needs. Unlock your team’s full potentials while enjoying cost-effective, innovative solutions.</p>
                    <button href="javascript:void(0);" class="btn btn-learn-more"
                        (click)="navigateToContactUs('HR Advisory')">Learn More</button>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="services-list services-row-6 mt-3">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="img"><img src="../assets/images/expetriate-management-img.webp"></div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="services-list-info">
                    <h2 class="list-heading">Expatriate Management</h2>
                    <p class="services-para-text-size set-width-row-6-para">
                        We help you navigate the processes and controls in place to obtain the necessary permits that
                        ensure your expatriates are working within the statutory legislation
                    </p>
                    <button href="javascript:void(0);" class="btn btn-learn-more"
                        (click)="navigateToContactUs('Expatriate Management')">Learn
                        More</button>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="services-list services-row-7 mt-3">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6  col-md-6 order-2">
                <div class="img"><img src="../assets/images/manpower-support-img.webp"></div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="services-list-info">
                    <h2 class="list-heading">Manpower Support</h2>
                    <p class="services-para-text-size set-width-row-7-para">
                        We are girded with vast experience in the deployment of professional, skilled, semi-skilled and
                        unskilled workers to the oil and gas sector as well as other conventional business environments
                    </p>
                    <button href="javascript:void(0);" class="btn btn-learn-more"
                        (click)="navigateToContactUs('Manpower Support')">Learn More</button>
                    <!-- <div class="line8"><img src="../assets/images/new-8.png"></div> -->
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="employer common-padd pb-0 black4">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="img"><img src="../assets/images/Frame706501.png"></div>
            </div>
            <div class="col-lg-6">
                <div class="desc white">
                    <h2>Are you looking for<br> a job?</h2>
                    <h5>Discover trustworthy job posting from our <br>verified employers</h5>
                    <a href="javascript:void(0);" class="nbtn-white m20">Search Jobs</a>
                </div>
            </div>
        </div>
    </div>
</section> -->