import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { Helper } from '../helper/defult';

@Injectable({
  providedIn: 'root',
})
export class HttpIOService {
  private baseUrl = environment.baseUrl;
  data_: any;
  private jobCategory = new BehaviorSubject<any>(null);
  private displayPicture = new BehaviorSubject<any>(null);
  private cvPurserData = new BehaviorSubject<any>(null);

  userDeatils: any;
  companyData: any;
  //get and set mobile number

  constructor(
    // private auth: AuthService,
    public http: HttpClient,
    private injector: Injector
  ) { }

  setforjobCategory(data) {
    this.jobCategory.next(data);
  }
  getforjobCategory() {
    return this.jobCategory.asObservable();
  }

  setfordisplayPicture(data) {
    this.displayPicture.next(data);
  }
  getfordisplayPicture() {
    return this.displayPicture.asObservable();
  }

  setforCVpurserData(data) {
    this.cvPurserData.next(data);
  }
  getforCVpurserData() {
    return this.cvPurserData.asObservable();
  }

  getuser() {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    if (localUser) {
      this.userDeatils = JSON.parse(
        this.injector.get(Helper).fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      // this.companyData = this.auth.getCurrentCompanyData();
    }
    if (sessionUser) {
      this.userDeatils = JSON.parse(
        this.injector.get(Helper).fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      // this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }
    return this.userDeatils;
  }

  apicall2(body_params: any, request_path: any, action_type: String = 'post') {
    return new Promise((resolve, reject) => {
      let url = this.baseUrl + request_path;

      if (action_type == 'get') {
        console.log('get');
        this.http.get(url).subscribe(
          (data) => {
            this.data_ = data;
            resolve(this.data_);
          },
          (err) => {
            this.data_ = err.error;
            reject(err);
          }
        );
      }

      if (action_type == 'post') {
        console.log('post');
        this.http.post(url, body_params).subscribe(
          (data) => {
            this.data_ = data;
            resolve(this.data_);
          },
          (err) => {
            this.data_ = err.error;
            reject(err);
          }
        );
      }

      if (action_type == 'file') {
        console.log('get');
        let headers = new HttpHeaders();
        this.http.post(url, body_params, { headers: headers }).subscribe(
          (data) => {
            this.data_ = data;
            resolve(this.data_);
          },
          (err) => {
            this.data_ = err.error;
            resolve(this.data_);
          }
        );
      }
      if (action_type == 'Url') {
        console.log('get');
        let httpOptions = {
          headers: new HttpHeaders().set('Content-Type', 'application/json'),
        };
        this.http.post(url, body_params, httpOptions).subscribe(
          (data) => {
            this.data_ = data;
            resolve(this.data_);
          },
          (err) => {
            this.data_ = err.error;
            resolve(this.data_);
          }
        );
      }
    });
  }
  apicall(data: any, path: any, queryParams?: any) {
    return this.http.post(`${environment.baseUrl}${path}`, data, { params: queryParams });
  }

  _unsubscribeJobAlert(data: any) {
    return this.http.post(`${environment.baseUrl}subscription/unsubscribe-job-alert`, data);
  }
  _activeForEmpManage(data: any) {
    return this.http.post(`${environment.baseUrl}job/active-job`, data);
  }
  _inactiveForEmpManage(data: any) {
    return this.http.post(`${environment.baseUrl}job/inactive-job`, data);
  }

  _cityList(path: any) {
    return this.http.post(`${environment.baseUrl}job/city-list`, path);
  }

  _companyList(path: any) {
    return this.http.post(`${environment.baseUrl}job/company-list`, path);
  }

  _searchJob(JsonBody: any) {
    return this.http.post(
      `${environment.baseUrl}jobsearch/search-job`,
      JsonBody
    );
  }

  _fiveHotJob(JsonBody: any) {
    return this.http.post(
      `${environment.baseUrl}jobsearch/search-job`,
      JsonBody
    );
  }
  _featureJob(JsonBody: any) {
    return this.http.post(`${environment.baseUrl}job/featured-jobs`, JsonBody);
  }
  _checkEmailValid() {
    // console.log(this.userDeatils);
    let userData = this.getuser();
    return userData.isEmailVerified;
  }
  _relatedJob(JsonBody: any) {
    return this.http.post(
      `${environment.baseUrl}jobsearch/related-job`,
      JsonBody
    );
  }
  _saveJob(JsonBody: any) {
    return this.http.post(`${environment.baseUrl}job/saved-job`, JsonBody);
  }
  _unSaveJob(JsonBody: any) {
    return this.http.post(`${environment.baseUrl}job/unsaved-job`, JsonBody);
  }

  _getCollection(JsonBody: any) {
    return this.http.post(
      `${environment.baseUrl}job/collection-list`,
      JsonBody
    );
  }
  _resendEmail(JsonBody: any) {
    return this.http.post(`${environment.baseUrl}auth/resend-email`, JsonBody);
  }
  // -------------------------- Save Job Start -----------------------//

  _removeCollection(JsonBody: any) {
    return this.http.post(
      `${environment.baseUrl}job/remove-collection`,
      JsonBody
    );
  }
  _saveAllJobsByCollection(JsonBody: any) {
    return this.http.post(`${environment.baseUrl}job/saved-job-list`, JsonBody);
  }
  _renameCollection(JsonBody: any) {
    return this.http.post(
      `${environment.baseUrl}job/rename-collection`,
      JsonBody
    );
  }
  _createCollection(JsonBody: any) {
    return this.http.post(
      `${environment.baseUrl}job/rename-collection`,
      JsonBody
    );
  }
  // -------------------------- Save Job end -----------------------//

  _viewJob(JsonBody: any) {
    return this.http.post(`${environment.baseUrl}job/view-job`, JsonBody);
  }

  _guestUser(JsonBody: any) {
    return this.http.post(
      `${environment.baseUrl}user/create-guest-user`,
      JsonBody
    );
  }
  // -------------------------- Applied Job Start -----------------------//
  _applyViaWorkNigeria(JsonBody: any) {
    return this.http.post(`${environment.baseUrl}job/apply-job`, JsonBody);
  }
  _applyViaEmail(JsonBody: any) {
    return this.http.post(
      `${environment.baseUrl}job/apply-job-via-email`,
      JsonBody
    );
  }
  _applyViaExtralnamUrl(JsonBody: any) {
    return this.http.post(
      `${environment.baseUrl}job/apply-job-via-external-site`,
      JsonBody
    );
  }

  _appliedJobs(JsonBody: any) {
    return this.http.post(`${environment.baseUrl}job/applied-jobs`, JsonBody);
  }
  // -------------------------- Applied Job End -----------------------//

  _uploadFile(JsonBody: any) {
    // return this.http.post(`${environment.baseUrl}job/upload-file`, JsonBody);
    // const headers = new Headers({'enctype': 'multipart/form-data'});
    return this.http.post(`${environment.baseUrl}job/upload-file`, JsonBody);
  }
  _userDocuments(JsonBody: any) {
    return this.http.post(`${environment.baseUrl}job/user-documents`, JsonBody);
  }

  // -------------------- To save user data on saving the Brochure -----------------
  _saveUserData(data: object) {
    return this.http.post(`${environment.baseUrl}add-training-user-info`, data);
  }

  // ------------------- Get the data for Upcoming Trainings --------------------
  _getUpcomingTrainingData() {
    return this.http.get(`${environment.baseUrl}training-data`);
  }

  // -------------------- Start  Alert --------------------
  _setAlert(JsonBody: any) {
    return this.http.post(`${environment.baseUrl}job/set-alert`, JsonBody);
  }

  _setAlertVisitor(JsonBody: any) {
    return this.http.post(`${environment.baseUrl}job/set-alert-visitor`, JsonBody);
  }

  _updateAlert(JsonBody: any) {
    return this.http.post(`${environment.baseUrl}job/modify-alert`, JsonBody);
  }

  _removeAlert(JsonBody: any) {
    return this.http.post(`${environment.baseUrl}job/delete-alert`, JsonBody);
  }
  // -------------------- End   Alert --------------------

  _getUserSpecificAlert(JsonBody: any) {
    return this.http.post(
      `${environment.baseUrl}job/user-job-alerts`,
      JsonBody
    );
  }

  // ------------------- Generate OTP for Verification ------------------
  _generateOTP(data: any) {
    return this.http.post(`${environment.baseUrl}company/generate-company-otp`, data);
}

// -------------- OTP Verification -----------
_otpVerification( data: any) {
  return this.http.post(
      `${environment.baseUrl}company/verify-company-email`,
      data
  );
}

  _draftJob(JsonBody: any) {
    return this.http.post(`${environment.baseUrl}job/draft-job`, JsonBody);
  }

  _JSallDetails(JsonBody: any) {
    return this.http.post(
      `${environment.baseUrl}user/get-job-seeker`,
      JsonBody
    );
  }
  _JSallDetailsByPublickId(JsonBody: any) {
    return this.http.post(
      `${environment.baseUrl}user/get-job-seeker-public`,
      JsonBody
    );
  }

  // -----------------     add and edit education   -------------------
  _JSaddEducation(JsonBody: any) {
    return this.http.post(`${environment.baseUrl}user/add-education`, JsonBody);
  }

  _JSeditEducation(JsonBody: any) {
    return this.http.post(
      `${environment.baseUrl}user/edit-education`,
      JsonBody
    );
  }

  // -----------------     add and edit experiance   -------------------
  _JSaddExperiance(JsonBody: any) {
    return this.http.post(
      `${environment.baseUrl}user/add-experience`,
      JsonBody
    );
  }

  _JSeditExperiance(JsonBody: any) {
    return this.http.post(
      `${environment.baseUrl}user/edit-experience`,
      JsonBody
    );
  }

  // -----------------     add and edit License   -------------------
  _JSaddLicense(JsonBody: any) {
    return this.http.post(`${environment.baseUrl}user/add-license`, JsonBody);
  }

  _JSeditLicense(JsonBody: any) {
    return this.http.post(`${environment.baseUrl}user/edit-license`, JsonBody);
  }

  // -----------------     add and remove skill   -------------------
  _JSaddSkill(JsonBody: any) {
    return this.http.post(`${environment.baseUrl}user/add-skills`, JsonBody);
  }

  _JSupdateSkill(JsonBody: any) {
    return this.http.post(
      `${environment.baseUrl}user/removed-skills`,
      JsonBody
    );
  }

  _JSgetSkill(JsonBody: any) {
    return this.http.post(`${environment.baseUrl}user/get-skills`, JsonBody);
  }

  // -----------------     edit profile   -------------------
  _JSupdateProfile(JsonBody: any) {
    return this.http.post(
      `${environment.baseUrl}user/update-profile`,
      JsonBody
    );
  }

  // -----------------    Report Job   -------------------
  _reportJob(JsonBody: any) {
    return this.http.post(`${environment.baseUrl}job/report-job`, JsonBody);
  }
  // -----------------    Base 64   -------------------
  _CVpurse(JsonBody: any) {
    // return this.http.post(`${environment.baseUrl}user/add-parsed-resume`, JsonBody);
    return this.http.post(`${environment.baseUrl}user/parse-resume`, JsonBody);
  }
  // -----------------    Subscribtion   -------------------
  _getAllSubscription(JsonBody: any) {
    return this.http.post(
      `${environment.baseUrl}subscription/all-subscriptions`,
      JsonBody
    );
  }

  _updateUserSubscription(JsonBody: any) {
    return this.http.post(
      `${environment.baseUrl}subscription/add-user-subscription`,
      JsonBody
    );
  }

  _submitAllDataByCvPurser(JsonBody: any) {
    return this.http.post(
      `${environment.baseUrl}user/add-parsed-resume-data-to-use`,
      JsonBody
    );
  }

  _contactUs(JsonBody: any) {
    return this.http.post(
      `${environment.baseUrl}user/send-contact-form`,
      JsonBody
    );
  }
  _changeEmail(JsonBody: any) {
    return this.http.post(`${environment.baseUrl}user/change-email`, JsonBody);
  }
  _changePassword(JsonBody: any) {
    return this.http.post(
      `${environment.baseUrl}user/change-password`,
      JsonBody
    );
  }
  _profilePictureForPdf(JsonBody: any) {
    return this.http.post(
      `${environment.baseUrl}user/image-to-base64`,
      JsonBody
    );
  }
  _getCompanyDetails(JsonBody: any) {
    return this.http.post(`${environment.baseUrl}job/get-company`, JsonBody);
  }

  _createEmployerSubscription(JsonBody: any) {
    return this.http.post(
      `${environment.baseUrl}user/subscription`,
      JsonBody
    );
  }
  _getAllPlans(JsonBody: any) {
    return this.http.get(
      `${environment.baseUrl}user/subscription/plans`,
      JsonBody
    );
  }

  _getUserPlan() {
    return this.http.get(`${environment.baseUrl}user/subscription`);
  }

  _cancelUserSubscription(){
    return this.http.patch(`${environment.baseUrl}user/subscription/cancel`, {});
  }

  _purchaseBundle(JsonBody: any){
    return this.http.post(`${environment.baseUrl}/user/bundles`, JsonBody);
  }
  _getBundles(JsonBody: any){
    return this.http.post(`${environment.baseUrl}/user/bundles`, JsonBody);
  }
  _getUserAssets(){
    return this.http.get(`${environment.baseUrl}/user/asset`);
  }
  _unlockCV(JsonBody: any){
    return this.http.post(`${environment.baseUrl}/cv-search/unlock-cv`, JsonBody);
  }
  _getCVs(){
    return this.http.get(`${environment.baseUrl}/cv-search/get-cvs`);
  }
  _getCandidates(params: any){
    return this.http.get(`${environment.baseUrl}/user/candidates`, { params });
  }
  _getUnlockedCVs(params: any){
    return this.http.get(`${environment.baseUrl}/cv-search/get-unlocked-cvs`, { params });
  }

  //---------------------------------------- Retrieve Campaigns ------------------------------------
  // _retrieveCampaigns(JsonBody: any) {
  //   return this.http.post(`${environment.baseUrl}job/retrieve-campaigns`, JsonBody);
  // }



  //  Test Data
  _testAnalytics(JsonBody: any) {
    const analytics_data = [
      {
        campaign: "Senior Frontend Develepor",
        impressions: "189k",
        clicks: "9k",
        applicants: "1,151",
        budget: "N100,000",
        spend: "N56,123.12",
      },
      {
        campaign: "Senior Frontend Develepor",
        impressions: "189k",
        clicks: "9k",
        applicants: "1,151",
        budget: "N100,000",
        spend: "N56,123.12",
      },
      {
        campaign: "Senior Frontend Develepor Officer Officer",
        impressions: "189k",
        clicks: "9k",
        applicants: "1,151",
        budget: "N100,000",
        spend: "N56,123.12",
      }
    ]
    return analytics_data;
  }

}