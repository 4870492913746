import { Component, OnInit, Injectable, ViewChild } from '@angular/core';
import { UntypedFormBuilder, FormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';
import { Router } from '@angular/router';
import { SuccecErrorModalComponent } from 'src/app/alert-message/succec-error-modal/succec-error-modal.component';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { HttpIOService } from 'src/app/services/http-io.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
// import * as firebase from 'firebase';
import { EmployerService } from 'src/app/services/employer.service';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { DynamicSnacbarSkipComponent } from 'src/app/container/elements/dynamic-snacbar-skip/dynamic-snacbar-skip.component';
@Injectable()
export class JobServices {
  constructor(private http: HttpClient) {}

  async commonService(rawData: any, token: string, path: string) {
    try {
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: token,
      });
      let options = { headers: headers };
      const body = rawData;
      const data = await this.http
        //.post(`${environment.baseUrl}${path}`, body, options)
        .post(
          'https://ne8dhnimwd.execute-api.us-east-1.amazonaws.com/dev/' + path,
          body,
          options
        )

        .toPromise();
      return data;
    } catch (error) {
      console.error(`Error occurred: ${error}`);
    }
    return 'Somthing wrong ';
  }
}

@Component({
  providers: [JobServices],
  selector: 'app-emp-manage-jobs',
  templateUrl: './emp-manage-jobs.component.html',
  styleUrls: ['./emp-manage-jobs.component.scss'],
})
export class EmpManageJobsComponent implements OnInit {
  valueNumber: number = 250;
  selectedTabIndex: number = 0;
  @ViewChild('tabs') tabGroup: MatTabGroup;
  nullJob: boolean = true;
  name = 'shaw';
  userDeatils: any;
  jobLists: any;
  detailsOfJob: any = '';
  myFlagForSlideToggle: boolean;
  currentIndex: number;
  companyData: any;
  status: boolean = false;
  emailVaild: any;
  userToken: string;
  appliedUsers: any;
  // db = firebase.firestore();
  countData: any = '';
  showRightSectionInMobile: boolean = false;
  startJobIndex: any = 0;
  loadMoreButtonVisible: boolean = true;
  constructor(
    private jobService: JobServices,
    private router: Router,
    private snackBar: MatSnackBar,
    private io: HttpIOService,
    private helper: Helper,
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private auth: AuthService,
    private employerService: EmployerService
  ) {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    this.userToken = sessionStorage.getItem('workNigeriaToken');

    if (localUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
    }
    if (sessionUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }

    this.userDeatils.isEmail;
  }

  ngOnInit(): void {
    let arr = [10, 10, 30, 20, 40, 5, 20];
    arr.push(50);
    console.log(arr);
    this.fetchJobList();
    this.activityLog();
  }

  // FETCH JOB LIST //
  fetchJobList() {
    if (environment.production === true){
    let payLoad = {
      path: 'get-job-by-provider-or-company',
      provider_id: this.userDeatils.id,
    };

    this.io
      .apicall(payLoad, 'job/get-job-by-provider-or-company')
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          this.jobLists = res['result'].job;
          console.log(this.jobLists);
          if (this.jobLists.length > 0) {
            this.detailsOfJob = this.jobLists[0];
            console.log("details of job: ",this.detailsOfJob)
            this.status = this.jobLists[0].id;
            this.currentIndex = 0;
          }
        } else {
          this.snackBar
            .openFromComponent(DynamicSnacbarErrorComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
              duration: 6000,
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
        }
      });
    } else {
      let payLoad = {
        path: 'employer-wise-job-list',
        job_provider_id: this.userDeatils.id,
      };
  
      this.io
        .apicall(payLoad, 'job/employer-wise-job-list', { sort: 'DESC', size: 10, start: 0 })
        .subscribe((res: any) => {
          if (res.serverResponse.code == 200) {
            this.jobLists = res['result'].jobList;
            console.log("jobList",this.jobLists);
            if (this.jobLists.length > 0) {
              this.detailsOfJob = this.jobLists[0];
              console.log("details of job: ",this.detailsOfJob)
              this.status = this.jobLists[0].id;
              this.currentIndex = 0;
            }
          } else {
            this.snackBar
              .openFromComponent(DynamicSnacbarErrorComponent, {
                data: res.serverResponse.message,
                verticalPosition: 'top', // 'top' | 'bottom'
                horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
                duration: 6000,
              })
              .afterOpened()
              .subscribe(this.helper.setOnTop);
          }
        });
    }
  }

  //infinite scroll
  onScrollDown() {
    this.startJobIndex = this.startJobIndex + 10;

    let payLoad = {
      path: 'get-job-by-provider-or-company',
      provider_id: this.userDeatils.id,
      start: this.startJobIndex,
    };
    this.io
      .apicall(payLoad, 'job/get-job-by-provider-or-company')
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          if (res['result'].job.length > 0) {
            let data = res['result'].job;
            this.jobLists.push(...data);
          }
          if (res['result'].job.length == 0) {
            this.loadMoreButtonVisible = false;
            this.snackBar
              .openFromComponent(DynamicSnacbarSkipComponent, {
                data: 'No job found',
                verticalPosition: 'top', // 'top' | 'bottom'
                horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
              })
              .afterOpened()
              .subscribe(this.helper.setOnTop);
          }
        }
      });
  }

  // EVENT EXPAIRE TIME //
  eventExpireIn(eventDt: string | number | Date) {
    const dateDiff = this.helper.calculateDiff(new Date(), new Date(eventDt));
    if (dateDiff === 0) {
      return 'today';
    } else if (dateDiff === 1) {
      return 'yesterday';
    } else {
      return `${dateDiff} days ago`;
    }
  }

  // GET JOB DETAILS //
  jobDetails(i, job) {
    // console.log(i);
    this.showRightSectionInMobile = true;
    this.status = job.id;
    this.currentIndex = i;
    this.detailsOfJob = this.jobLists[i];
    // this.jobApplied(this.detailsOfJob);
  }
  mobileViewClassClose() {
    this.showRightSectionInMobile = false;
  }
  // jobApplied(job: any) {
  //   var jobData = {
  //     path: 'applied-job-apps',
  //     jobId: job.id,
  //   };
  //   this.jobService
  //     .commonService(jobData, this.userToken, 'job/applied-jobs')
  //     .then((resStr) => {
  //       this.appliedUsers = [];
  //       this.appliedUsers = resStr['result']['appliedJobApps'];
  //       // console.log(this.appliedUsers);
  //       // this.openDialog(appliedUsers);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // }

  // async startChatting(application) {
  //   this.db
  //     .collection('Messages')
  //     .doc(
  //       `${application.jobId}::${application.job_provider_id}::${application.userId}`
  //     )
  //     .set({
  //       channelId: `${application.jobId}::${application.job_provider_id}::${application.userId}`,
  //       title: `${application.title}`,
  //       jobId: `${application.jobId}`,
  //       userId: application.userId,
  //       provider_id: application.job_provider_id,
  //       jobSeeker_name: application.name
  //     });
  //   this.router.navigate(['/message'], { state: application });
  // }

  // REDIRECT TO EDIT JOB //
  routeToEditJob(jobId) {
    let checkEmailValid = this.io._checkEmailValid();
    if (checkEmailValid == true) {
      this.router.navigate(['/edit-job'], { queryParams: { id: jobId, popup: 'from header edit job' } });
    } else {
      // this.snackBar.open("Please verify your email to edit jobs", "OK");
      this.snackBar
        .openFromComponent(DynamicSnacbarSkipComponent, {
          data: 'Please verify your email to edit jobs',
          verticalPosition: 'top', // 'top' | 'bottom'
          horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
        })
        .afterOpened()
        .subscribe(this.helper.setOnTop);
    }
  }

  async routeToAddJob() {
    let checkEmailValid = await this.io._checkEmailValid();
    // console.log(checkEmailValid)
    if (checkEmailValid == true) {
      const dataToSend = {
        data: 'from header add job'
      }
      this.router.navigate(['post-job'], {
        queryParams: dataToSend
      });
    } else {
      this.snackBar
        .openFromComponent(DynamicSnacbarSkipComponent, {
          data: 'Please verify your email to edit jobs',
          verticalPosition: 'top', // 'top' | 'bottom'
          horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
        })
        .afterOpened()
        .subscribe(this.helper.setOnTop);
    }
  }

  // updateAplicationStatus(appliedJob, status) {
  //   let applicationStatus = {
  //     path: 'update-app-status',
  //     id: appliedJob.id,
  //     appliedStatus: status,
  //   };
  //   this.jobService
  //     .commonService(applicationStatus, this.userToken, 'job/applied-jobs')
  //     .then((res) => {
  //       this.jobApplied(this.detailsOfJob);
  //       console.log(res);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });

  //   if (status == 'accept') {
  //     // this.chatWithEmp(appliedJob);
  //   }
  // }

  //ACTIVE OR INACTIVE SLIDE //
  activeInactive(job, index) {
    console.log(job, index);
    let checkEmailValid = this.io._checkEmailValid();
    let jobStatus = job.job_status;
    if (checkEmailValid == true) {
      if (jobStatus === 'active') {
        let jsonData = {
          path: 'inactive-job',
          id: job.id,
        };
        this.io._inactiveForEmpManage(jsonData).subscribe((res: any) => {
          console.log(this.jobLists[index]);
          this.jobLists[index].job_status = res['result'].job.job_status;
        });
      } else {
        let jsonData = {
          path: 'active-job',
          id: job.id,
        };
        this.io._activeForEmpManage(jsonData).subscribe((res: any) => {
          console.log(res);
          this.jobLists[index].job_status = res['result'].job.job_status;
        });
      }
    } else {
      // this.snackBar.open("Please verify your email to active or inactive jobs", "OK");
      this.snackBar
        .openFromComponent(DynamicSnacbarSkipComponent, {
          data: 'Please verify your email to active or inactive jobs',
          verticalPosition: 'top', // 'top' | 'bottom'
          horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
        })
        .afterOpened()
        .subscribe(this.helper.setOnTop);
    }

    // if( this.myFlagForSlideToggle === true){
    //   let jsonData ={
    //     path: "active-job",
    //     id:jobId
    //   }
    //   this.io._activeForEmpManage(jsonData).subscribe((res:any)=>{
    //     console.log(res);

    //   })
    // }
  }

  async activityLog() {
    // userRole: "company_admin"
    console.log(this.userDeatils.userRole);
    if (
      this.userDeatils.userRole == 'company_admin' ||
      this.userDeatils.userType == 'super_admin'
    ) {
      let jsonData = {
        path: 'count-total-active-applied-views-job',
        company_id: this.companyData.id,
      };
      await this.employerService
        ._getAllActivity(jsonData)
        .subscribe((res: any) => {
          if (res.serverResponse.code == 200) {
            // console.log(res);
            this.countData = res.countData;
          }
        });
    } else {
      let jsonData = {
        path: 'count-total-active-applied-views-job',
        provider_id: this.userDeatils.id,
      };
      await this.employerService
        ._getAllActivity(jsonData)
        .subscribe((res: any) => {
          if (res.serverResponse.code == 200) {
            this.countData = res.countData;
          }
        });
    }
  }
}
