<div class="manpower-page">
    <!-- Navbar -->
    <header class="manpower-header">
        <div class="logo">
            <a href="javascript:void(0);" routerLink="/home">
                <img src="../assets/images/logo.webp" />
            </a>
        </div>
        <div class="list-btns">
            <ul>
                <li>
                    <button class="hdr-login" (click)="openForm()">Login</button>
                </li>
                <li>
                    <button class="hdr-signup" (click)="openForm()">SIGNUP</button>
                </li>
            </ul>
        </div>
    </header>
    <!-- Manpower Efficiency Section -->
    <div class="efficiency-section row">
        <div class="col-lg-6 col-12 efficiency-text">
            <h1>Boost Efficiency with Worknigeria’s <span>Manpower Outsourcing</span> Services</h1>
            <p>Deploy Skilled Professionals Across Sectors Without the Hassle – Leave the Recruitment to Us!</p>
            <button (click)="openForm()">Learn More</button>
        </div>
        <div class="col-lg-6 col-12">
            <img src="../../../assets/images/manpower-efficiency.webp" />
        </div>
    </div>
    <!-- Trusted by Section -->
    <div class="trusted-by-section">
        <h2>Trusted by leading Employers in Nigeria:</h2>
        <div class="ticker-outer-container">
            <div class="ticker-inner-container">
                <div class="ticker-container">
                    <div *ngFor="let logo of logos" class="ticker-item">
                        <img [src]="logo" class="ticker-img" alt="Logo" />
                    </div>
                    <div *ngFor="let logo of logos" class="ticker-item">
                        <img [src]="logo" class="ticker-img" alt="Logo" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Outsource with Confidence -->
    <div class="outsource-confidence">
        <div class="row">
            <div class="col-lg-4 col-12">
                <h2>Outsource with Confidence, Hire with Ease</h2>
                <p class="confidence-p">Finding the right talent for your business can be a time-consuming and complex process. With WorkNigeria’s Manpower Outsourcing Services, we simplify it for you.</p>
                <button (click)="openForm()">Learn More</button>
            </div>
            <div class="col-lg-4 col-12 d-flex justify-content-center">
                <img src="../../../assets/images/outsource-confidence.webp"/>
            </div>
            <div class="col-lg-4 col-12">
                <ul class="custom-confidence-list">
                    <li *ngFor="let item of confidence">
                        <h4> {{ item.title }} </h4>
                        <p> {{ item.text }} </p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="get-started-strip">
            <p class="p-1">Get started for free today</p>
            <button class="p-2" (click)="openForm()">Sign up</button>
        </div>
    </div>
    <!-- Benefits of Manpower Outsource -->
    <div class="benefits">
        <div class="row">
            <div class="col-lg-6 col-12 order-lg-2 pl-0">
                <h2>Benefits of WorkNigeria’s Manpower Outsourcing</h2>
                <p class="benefits-p">Outsourcing your manpower needs to Worknigeria means more than just filling
                    positions. It’s about optimizing your operations and focusing on what you do best.</p>
                <ul class="custom-list">
                    <li *ngFor="let item of items">
                        <h4> {{ item.title }} </h4>
                        <p> {{ item.text }} </p>
                    </li>
                </ul>
                <button (click)="openForm()">Click Here to get started</button>
            </div>
            <div class="col-lg-6 col-12 order-lg-1 d-flex justify-content-center">
                <img class="what-you-get-img" src="../../../assets/images/woman-on-laptop-campaign.webp" />
            </div>
        </div>
    </div>
    <!-- Free Quote strip -->
    <div class="free-quote-strip">
        <p>Discover the Benefits of Manpower Outsourcing – Contact Us Today!</p>
        <button (click)="openForm()">Get a Free Quote</button>
    </div>
    <!-- copyrights -->
    <div class="copyright-section">
        <p>© 2024 Worknigeria.</p>
    </div>
</div>