import {
  Component,
  ElementRef,
  Inject,
  NgZone,
  OnInit,
  Pipe,
  PipeTransform,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
// import { MapsAPILoader } from '@agm/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { HttpIOService } from 'src/app/services/http-io.service';
import { Helper } from 'src/app/helper/defult';
import { SuccecErrorModalComponent } from 'src/app/alert-message/succec-error-modal/succec-error-modal.component';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import { EmployerService } from 'src/app/services/employer.service';
declare var google: any;
import { Observable, Subscription, fromEvent } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { DynamicSnacbarSkipComponent } from 'src/app/container/elements/dynamic-snacbar-skip/dynamic-snacbar-skip.component';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';
import { environment } from 'src/environments/environment';
import * as currency from '../../../../assets/jsons/currency _limited.json';
import { PopupConfirmationComponent } from 'src/app/container/elements/popup-confirmation/popup-confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { IntroJSService } from 'src/app/services/introjs.service';

@Component({
  selector: 'app-employer-addjob',
  templateUrl: './employer-addjob.component.html',
  styleUrls: ['./employer-addjob.component.scss'],
})
export class EmployerAddjobComponent implements OnInit {
  nullJob: boolean = true;
  name = 'shaw';
  cityRecords: any;
  currencies: any = (currency as any).default;
  jobCatagoryName: any;
  filteredOptionsLocation: Observable<any[]>;
  // @ViewChild('search1') search1: ElementRef;
  // @ViewChild('search2') search2: ElementRef;
  locationName: any;
  lat: any;
  lng: any;
  zoom: number;
  userData: any;
  reqMsg = '';
  htmlContent = '';
  htmlContent2 = '';
  postJob: UntypedFormGroup;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        // 'link',
        // 'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
      ],
      [
        // 'undo',
        // 'redo',
        // 'bold',
        // 'italic',
        // 'underline',
        // 'strikeThrough',
        // 'subscript',
        // 'superscript',
        // 'justifyLeft',
        // 'justifyCenter',
        // 'justifyRight',
        // 'justifyFull',
        // 'indent',
        // 'outdent',
        // 'insertUnorderedList',
        // 'insertOrderedList',
        // 'heading',
        // 'fontName'
      ],
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };
  employemntTypeList: any;
  jobCatagoryTypeList: any;
  applyValue: any;
  public minExp: any[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
  public maxExp: any = [];
  isDescription: boolean = false;
  isChoseApply: boolean = false;
  isAbout: boolean = false;
  userDeatils: any;
  companyData: any;
  jobLocationNames: any;
  akhariLocation: string;
  employementTypeID: any;
  LocationDropShowValue: any;
  apply_at_external_site: boolean = false;
  apply_via_email: boolean = false;
  defaultemployemntTypeName: any;
  defaultemployemntTypeId: any;
  countData: any = '';
  filteredOptions: Observable<string[]>;
  userManagement: UntypedFormGroup;
  CompaniesResult: any = [];
  selectCompany: any = '';
  filteredLocation: Observable<any[]>;
  filteredCategory: Observable<any[]>;
  logoUploadBehalfOfCompanyError: Boolean = false;
  company_behalf_logo: String;
  miniSal: any;
  // @ViewChild('UploadFileInput') uploadFileInput: ElementRef;
  myfilename = '';
  companyPic: any = '../../../assets/images/sm-Logo.png';

  adImages: string[] = [
    'assets/images/post-job-ad.png',
    'assets/images/post-job-ad-small.png',
  ];
  imageIndex = 0;

  screenWidth: number;
  private resizeSubscription: Subscription;

  postJobText = '';
  rangeSelected = false;

  constructor(
    @Inject(Router) private router: Router,
    // private mapsAPILoader: MapsAPILoader,
    @Inject(NgZone) private ngZone: NgZone,
    @Inject(MatSnackBar) private snackBar: MatSnackBar,
    private io: HttpIOService,
    private helper: Helper,
    @Inject(MatDialog) public dialog: MatDialog,
    @Inject(UntypedFormBuilder) private fb: UntypedFormBuilder,
    private auth: AuthService,
    private employerService: EmployerService,
    private route: ActivatedRoute,
    private introService: IntroJSService
  ) {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    if (localUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
    }
    if (sessionUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }
  }

  ngOnInit(): void {
    this.resizeSubscription = fromEvent(window, 'resize').subscribe((event) => {
      this.screenWidth = (event.target as Window).innerWidth;
      this.onScreenResize();
    });

    // Get initial screen width
    this.screenWidth = window.innerWidth;
    this.onScreenResize();

    this.activityLog();
    this.validationForm();
    this.jobCatagory();
    this.cityLIst();
    this.validationFormCompanies();
    this.employementType();
    if (this.userDeatils.userType == 'super_admin') {
      // this.validationFormCompanies();
      this.getAllCompaniesList();
    }

    // Just to check if the post job is clicked from da
    this.route.queryParams.subscribe(params => {
      this.postJobText = params['data'];
   });
  }

  ngAfterViewInit(): void {
    if((localStorage.getItem('jobPostTour') ?? '0') === '1' && (localStorage.getItem('jobPostDetailedTour') ?? '0') === '0'){
      setTimeout(() => {
        this.introService.jobPostDetailed()
      }, 1000)
    }
  }

  onScreenResize() {
    if (this.screenWidth < 992) {
      this.imageIndex = 1;
    } else {
      this.imageIndex = 0;
    }
  }

  onSalarySelect(value) {
    if (value === 'range') {
      this.rangeSelected = true;
    } else {
      this.rangeSelected = false;
    }
  }

  ngOnDestroy() {
    // Unsubscribe from the window resize event to prevent memory leaks
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
    }
  }

  redirectToConference() {
    window.open('https://inspireafricaconference.com', '_blank');
  }

  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.myfilename = '';
      Array.from(fileInput.target.files).forEach((file: File) => {
        console.log(file);
        this.myfilename += file.name;
      });

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs) => {
          // Return Base64 Data URL
          const imgBase64Path = e.target.result;
        };
      };
      this.uploadLogoBehalfOfCompany(fileInput.target.files[0]);
      reader.readAsDataURL(fileInput.target.files[0]);

      // Reset File Input to Selct Same file again
      // this.uploadFileInput.nativeElement.value = '';
    } else {
      this.myfilename = 'No file selected';
    }
  }

  async uploadLogoBehalfOfCompany(file) {
    const formData = new FormData();

    formData.append('path', 'upload-file');
    formData.append('file', file);
    formData.append('purpose', 'companyBehalfJobLogoUpload');
    await this.io._uploadFile(formData).subscribe(
      (res: any) => {
        if (res.serverResponse.code == 200) {
          this.company_behalf_logo = res.result.documentUrl;
          this.logoUploadBehalfOfCompanyError = false;
        }
      },
      (err) => console.log(err)
    );
  }

  // Insilize Form //
  validationFormCompanies() {
    this.userManagement = this.fb.group({
      companies: [''],
    });
  }
  // Insilize Form //
  validationForm() {
    this.postJob = this.fb.group({
      jobTitle: ['', Validators.required],
      location: ['', Validators.required],
      location_type: ['', Validators.required],
      employementTypes: ['', Validators.required],
      jobCategoryTypes: ['', Validators.required],
      currency: ['', Validators.required],
      salarySelected: ['', Validators.required],
      minimumSalary: [''],
      maximumSalary: [''],
      minexprience: ['', Validators.required],
      maxexprience: [''],
      campanyName: [this.companyData.companyName, Validators.required],
      company_behalf_name: [''],
      description: ['', Validators.required],
      about: ['', Validators.required],
      via_email: [''],
      external_url: [''],
      featureJob: [false],
      city: [''],
      is_3mtt: [this.userDeatils.is_3mtt ?? false, Validators.required],
    });
  }
  rangeValidation() {
    this.maxExp = [];
    let minexprience = this.postJob.get('minexprience').value;
    for (let i = parseInt(minexprience); i <= 39; i++) {
      this.maxExp.push(i + 1);
    }
  }

  async getAllCompaniesList() {
    let jsonData = {
      path: 'get-company-list',
    };
    await this.employerService
      ._getAllCompaniesList(jsonData)
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          // console.log(this.companyData);
          this.CompaniesResult = res['result'].companyList;
          let superAdminCompany = this.CompaniesResult.find(
            (element) => element.id == this.companyData.id
          );
          // console.log(superAdminCompany);
          this.userManagement.controls.companies.setValue(
            superAdminCompany.companyName
          );
          this.changeCompany(superAdminCompany.companyName);
        }
      });
    this.filteredOptions =
      this.userManagement.controls.companies.valueChanges.pipe(
        startWith(''),
        map((value) => this._filter(value))
      );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.CompaniesResult.filter((option) =>
      option.companyName.toLowerCase().includes(filterValue)
    );
  }

  changeCompany(event) {
    this.selectCompany = '';
    this.selectCompany = this.CompaniesResult.find(
      (x) => x.companyName == event
    );
    this.companyPic = this.selectCompany.companyLogo;
    this.postJob.patchValue({ campanyName: this.selectCompany.companyName });
    console.log(this.selectCompany);
  }

  //location Dropdown show //
  locationDropEvent(event: MatRadioChange) {
    this.LocationDropShowValue = event.value;
    if (event.value == 'NIGERIA') {
      this.postJob.controls['location'].enable();
    } else {
      this.postJob.controls['location'].disable();
      this.akhariLocation = undefined;
    }
  }
  // Validation Controls //
  get f() {
    return this.postJob.controls;
  }

  // CIty List //
  cityLIst() {
    let data = { path: 'city-list' };
    this.io._cityList(data).subscribe(
      (res: any) => {
        // console.log(res)
        if (res.serverResponse.code == 200) {
          this.cityRecords = res['result'].cityList;
          // console.log(this.cityRecords)
        }
      },
      (err) => console.log(err),
      () => {
        this.filteredLocation =
          this.postJob.controls.location.valueChanges.pipe(
            startWith(''),
            // map(row => this._filterLocation(row)),

            map((value) => {
              const state = typeof value === 'string' ? value : value;
              return state
                ? this._filterLocation(state as any)
                : this.cityRecords.slice();
            })
          );
      }
    );
  }

  displayFn(state: any): string {
    console.log(state);
    return state ? state : '';
  }

  private _filterLocation(value: string): string[] {
    if (value != '') {
      const filterValue = value.toLowerCase();
      return this.cityRecords.filter((state: any) =>
        state.state.toLowerCase().includes(filterValue)
      );
    }
  }

  // Employement Type //
  employementType() {
    this.io
      .apicall({ path: 'employment-type-list' }, 'job/employment-type-list')
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          this.employemntTypeList = res['result'].employmentList;
          this.defaultemployemntTypeName =
            this.employemntTypeList[0].employmentType;
          this.defaultemployemntTypeId = this.employemntTypeList[0].id;
          // console.log(this.employemntTypeList)
        } else {
          // this.snackBar.open(res.serverResponse.message, "OK", {
          // })
          this.snackBar
            .openFromComponent(DynamicSnacbarErrorComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
        }
      });
  }

  employmentTypeChange(e) {
    this.employementTypeID = this.employemntTypeList.filter(
      (name) => name.employmentType == e
    );
  }

  jobCatagory() {
    this.io
      .apicall({ path: 'category-list' }, 'job/category-list')
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          this.jobCatagoryTypeList = res['result'].categoryList;
          setTimeout(() => {
            this.filteredCategory = this.jobCatagoryTypeList;
            this.filteredCategory =
              this.postJob.controls.jobCategoryTypes.valueChanges.pipe(
                startWith(''),
                map((value) => {
                  const category = typeof value === 'string' ? value : value;
                  return category
                    ? this._filterCategory(category as any)
                    : this.jobCatagoryTypeList.slice();
                })
              );
          }, 1000);
        } else {
          this.snackBar
            .openFromComponent(DynamicSnacbarErrorComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
        }
      });
  }
  private _filterCategory(value: string): string[] {
    if (value != '') {
      const filterValue = value.toLowerCase();
      return this.jobCatagoryTypeList.filter((state: any) =>
        state.categoryName.toLowerCase().includes(filterValue)
      );
    }
  }

  // Change Drop Down Of Job category //
  jobCategoryChange(e) {
    // console.log(e);
    this.jobCatagoryName = this.jobCatagoryTypeList.filter(
      (jobname) => jobname.categoryName == e
    );
    console.log(this.jobCatagoryName);
  }

  joblocationName(e) {
    this.jobLocationNames = this.cityRecords.filter(
      (jobname) => jobname.state == e
    );
    // console.log(this.jobLocationNames);
    this.akhariLocation = `${this.jobLocationNames[0].state}`;
    // console.log(this.akhariLocation);
  }
  // Chosse Apply method //
  applyJobs(event: MatRadioChange, flag) {
    this.applyValue = flag;

    this.apply_at_external_site = this.applyValue == 2;
    this.apply_via_email = this.applyValue == 3;

    this.postJob.controls['external_url'].setValidators(
      this.apply_at_external_site ? [Validators.required] : null
    );
    this.postJob.controls['via_email'].setValidators(
      this.apply_via_email ? [Validators.required] : null
    );

    this.postJob.controls['external_url'].updateValueAndValidity();
    this.postJob.controls['via_email'].updateValueAndValidity();
    this.postJob.patchValue({
      external_url: this.apply_at_external_site
        ? ''
        : this.postJob.value.external_url,
      via_email: this.apply_via_email ? '' : this.postJob.value.via_email,
    });
  }

  addJob() {
    if (this.userDeatils.userType == 'super_admin') {
      if (this.selectCompany == '') {
        // this.snackBar.open("Please select a company", "OK", {
        // })

        this.snackBar
          .openFromComponent(DynamicSnacbarSkipComponent, {
            data: 'Please select a company',
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
        return false;
      }
    }
    if (this.postJob.status === 'INVALID') {
      this.helper.markFormGroupTouched(this.postJob);
      return false;
    }
    if (this.postJob.get('maximumSalary').value != '') {
      if (
        Number(this.postJob.get('minimumSalary').value) >
        Number(this.postJob.get('maximumSalary').value)
      ) {
        console.log(
          typeof this.postJob.get('minimumSalary').value,
          this.postJob.get('maximumSalary').value
        );
        this.snackBar
          .openFromComponent(DynamicSnacbarSkipComponent, {
            data: "' Maximum Salary' should be greater than minimum salary",
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
        return false;
      }
    }
    if (!this.htmlContent) {
      this.isDescription = true;
      return false;
    }
    if (!this.htmlContent2) {
      this.isAbout = true;
      return false;
    }
    if (!this.applyValue) {
      this.isChoseApply = true;
      return false;
    }

    if (this.postJob.value.location_type == 'NIGERIA') {
      let locationValue = this.cityRecords.filter(
        (jobname) => jobname.state == this.postJob.value.location
      );
      if (locationValue.length == 0) {
        this.snackBar
          .openFromComponent(DynamicSnacbarSkipComponent, {
            data: 'Please select a state from list',
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
        return false;
      }
    }

    let CategoryValue = this.jobCatagoryTypeList.filter(
      (jobname) => jobname.categoryName == this.postJob.value.jobCategoryTypes
    );
    if (CategoryValue.length == 0) {
      this.snackBar
        .openFromComponent(DynamicSnacbarSkipComponent, {
          data: 'Please select a category from list',
          verticalPosition: 'top', // 'top' | 'bottom'
          horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
        })
        .afterOpened()
        .subscribe(this.helper.setOnTop);
      return false;
    }

    let payload = {
      path: 'post-job',
      title: this.postJob.value.jobTitle,
      description: this.htmlContent,
      company_name: this.postJob.value.campanyName,
      company_behalf_name: this.postJob.value.company_behalf_name,
      company_behalf_logo: this.company_behalf_logo,
      logo: this.companyData.companyLogo
        ? this.companyData.companyLogo
        : environment.WNSquareCompanyLogo,
      city_id:
        this.postJob.value.location == undefined || ''
          ? this.LocationDropShowValue
          : this.jobLocationNames[0].id,
      employment_type: this.postJob.value.employementTypes,
      employment_type_id:
        this.employementTypeID == undefined
          ? this.defaultemployemntTypeId
          : this.employementTypeID[0].id,
      location_type: this.postJob.value.location_type,
      is_3mtt: this.postJob.value.is_3mtt,
      job_category: this.postJob.value.jobCategoryTypes,
      category_id: this.jobCatagoryName[0].id,
      currency: this.postJob.value.currency,
      minimum_experience: this.postJob.value.minexprience.toString(),
      maximum_experience: this.postJob.value.maxexprience.toString(),
      salary_type: this.postJob.value.salarySelected.toString(),
      minimum_salary: this.postJob.value.minimumSalary.toString(),
      maximum_salary: this.postJob.value.maximumSalary.toString(),
      about: this.htmlContent2,
      apply_via: this.applyValue,
      job_provider_id:
        this.selectCompany == ''
          ? this.userDeatils.id
          : this.selectCompany.primaryContactPersonNameId,
      job_location: this.akhariLocation != undefined ? this.akhariLocation : '',
      company_id:
        this.selectCompany == '' ? this.companyData.id : this.selectCompany.id,
      external_url: this.postJob.value.external_url,
      via_email: this.postJob.value.via_email,
      // "is_featured": this.postJob.value.featureJob,
      city: this.postJob.value.city,
    };
    this.io.apicall(payload, 'job/post-job').subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        const dialogRef = this.dialog.open(PopupConfirmationComponent, {
          height: 'auto',
          width: 'auto',
          autoFocus: false,
          data: {
            data: this.postJobText,
          },
        });
        dialogRef.afterClosed().subscribe(() => {
          const navigationExtras: NavigationExtras = {
            replaceUrl: true, 
          };
        });
      } else {
        this.snackBar
          .openFromComponent(DynamicSnacbarErrorComponent, {
            data: res.serverResponse.message,
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
      }
    });
  }

  cancelJob() {
    if (this.postJobText === 'from dashboard add job') {
      this.router.navigate(['Dashboard']);
    }else {
      this.router.navigate(['/employer-home']);
    }
  }

  async activityLog() {
    // userRole: "company_admin"
    // console.log(this.userDeatils.userRole);
    if (
      this.userDeatils.userRole == 'company_admin' ||
      this.userDeatils.userType == 'super_admin'
    ) {
      let jsonData = {
        path: 'count-total-active-applied-views-job',
        company_id: this.companyData.id,
      };
      await this.employerService
        ._getAllActivity(jsonData)
        .subscribe((res: any) => {
          if (res.serverResponse.code == 200) {
            // console.log(res);
            this.countData = res.countData;
          }
        });
    } else {
      let jsonData = {
        path: 'count-total-active-applied-views-job',
        provider_id: this.userDeatils.id,
      };
      await this.employerService
        ._getAllActivity(jsonData)
        .subscribe((res: any) => {
          if (res.serverResponse.code == 200) {
            this.countData = res.countData;
          }
        });
    }
  }
}
