import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { SuccecErrorModalComponent } from 'src/app/alert-message/succec-error-modal/succec-error-modal.component';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { EmployerService } from 'src/app/services/employer.service';
import { HttpIOService } from 'src/app/services/http-io.service';

@Component({
  selector: 'app-gen-password',
  templateUrl: './gen-password.component.html',
  styleUrls: ['./gen-password.component.scss'],
})
export class GenPasswordComponent implements OnInit {
  linkId: any;
  appSettingsPasswordFrom: UntypedFormGroup;
  emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9_.+-]{2,}$/;
  passwordPattern =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  getUserDetails: any;
  constructor(
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private io: HttpIOService,
    private helper: Helper,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private _authService: AuthService,
    private employerService: EmployerService
  ) {
    this.route.queryParams.subscribe((param) => {
      this.linkId = param['id'];
    });
    // console.log(this.text);
  }

  ngOnInit(): void {
    this.passwordValidationForm();
    console.log(this.linkId);
    if (this.linkId) {
      this.getInvitationUserDetails();
    }
  }
  get g() {
    return this.appSettingsPasswordFrom.controls;
  }

  passwordValidationForm() {
    this.appSettingsPasswordFrom = this.fb.group(
      {
        email: [''],
        NewPassword: [
          '',
          Validators.compose([
            Validators.required,
            Validators.pattern(this.passwordPattern),
          ]),
        ],
        ConfirmPassword: ['', [Validators.required]],
      },
      {
        validator: this.ConfirmedValidator('NewPassword', 'ConfirmPassword'),
      }
    );
  }

  ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (
        matchingControl.errors &&
        !matchingControl.errors.confirmedValidator
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  async getInvitationUserDetails() {
    let jsonData = {
      path: 'get-invitation-user',
      invitationCode: this.linkId,
    };
    await this.employerService
      ._genInvitationUserDetails(jsonData)
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          console.log(res);
          this.getUserDetails = res['result'].userData;
          this.appSettingsPasswordFrom.patchValue({
            email: this.getUserDetails.email,
          });
        }
        if (res.serverResponse.code == 400) {
          this.snackBar
            .openFromComponent(DynamicSnacbarErrorComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
              duration: 6000,
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
          this.router.navigate(['/login']);
        }
      });
  }

  async setPassWord() {
    let jsonData = {
      path: 'invitation-authorization-user',
      invitationCode: this.linkId,
      password: this.appSettingsPasswordFrom.get('NewPassword').value,
    };
    await this.employerService
      ._setPasswordForInvitationUser(jsonData)
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          console.log(res);
          this.snackBar
            .openFromComponent(DynamicSnacbarComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
          this.router.navigate(['/login']);
        }
      });
  }
}
