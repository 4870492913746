import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-jobseeker-campaign',
  templateUrl: './jobseeker-campaign.component.html',
  styleUrls: ['./jobseeker-campaign.component.scss']
})
export class JobseekerCampaignComponent implements OnInit{
  // List of what you get on WN 
  items = [
    {
      title: 'Exclusive Jobs:',
      text: 'Discover job opportunities with competitive benefits.',
    },
    {
      title: 'Tailored Recommendations:',
      text: 'Get personalized job alerts based on your skills and preferences.',
    },
    {
      title: 'Simple Process:',
      text: 'Apply to multiple jobs with just one click.',
    },
    {
      title: 'Stay Updated:',
      text: 'Receive the latest job openings directly in your inbox.',
    },
  ];

  testimonials = [
    {
      logo: '../../../../assets/images/chidinma-icon.webp',
      heading: 'Chidinma',
      para: '“WorkNigeria helped me find my dream job in less than a month! The process was so easy and the job recommendations were spot on.”',
      saidBy: '— Chidinma A., Lagos',
    },
    {
      logo: '../../../../assets/images/emeka-icon.webp',
      heading: 'Emeka',
      para: '“I was struggling to find the right opportunities until I joined WorkNigeria. Now, I receive job alerts that are exactly what I’m looking for.”',
      saidBy: '— Emeka O., Abuja',
    },
    {
      logo: '../../../../assets/images/adenike-icon.webp',
      heading: 'Adenike',
      para: '“WorkNigeria lives up to their name in the HR industry”',
      saidBy: '',
    },
  ]

  // Employers logos list
  logos = [
    '../../../../assets/images/tbo-logo.webp',
    '../../../../assets/images/zedcrest-logo.webp',
    '../../../../assets/images/aim-logo.webp',
    '../../../../assets/images/pecan-logo.webp',
    '../../../../assets/images/routelink-logo.webp',
    '../../../../assets/images/qlc-logo.webp',
    '../../../../assets/images/magna-logo.webp',
    '../../../../assets/images/manna-logo.webp',
    '../../../../assets/images/fohat-logo.webp',
    '../../../../assets/images/arise-logo.webp',
    '../../../../assets/images/richway-logo.webp',
    '../../../../assets/images/platview-logo.webp',
    '../../../../assets/images/cochlear-logo.webp',
    '../../../../assets/images/gosolve-logo.webp',
  ]

  constructor (
    private router: Router,
  ) {}

  ngOnInit(): void {
      
  }

  goToSignUp() {
    this.router.navigate(['/signup']);
  }
}
