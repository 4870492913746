import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-with-nav',
  templateUrl: './with-nav.component.html',
  styleUrls: ['./with-nav.component.scss'],
})
export class WithNavComponent implements OnInit {
  check: boolean;

  constructor(public router: Router) {}

  ngOnInit(): void {}

  isFooterVisible() {
    let forbiddenRoutes = [
      '/cv-review',
      '/choose-employer-subscription',
      '/Dashboard',
      '/verification',
      '/login?userType=jobseeker',
      '/login?userType=is3MTTFellow',
      '/login?userType=is3MTTEmployer',
      '/message',
      '/upload-cvs',
    ];
    if (forbiddenRoutes.includes(this.router.url)) {
      return false;
    } else {
      return true;
    }
  }
}
