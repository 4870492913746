import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { HttpIOService } from 'src/app/services/http-io.service';
import { ViaEmailComponent } from '../via-email/via-email.component';
import { ViaExternalUrlComponent } from '../via-external-url/via-external-url.component';
import { ViaWorkNigeriaComponent } from '../via-work-nigeria/via-work-nigeria.component';
import { EmployerService } from 'src/app/services/employer.service';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { PopupConfirmationComponent } from 'src/app/container/elements/popup-confirmation/popup-confirmation.component';
@Component({
  selector: 'app-anonymous-user',
  templateUrl: './anonymous-user.component.html',
  styleUrls: ['./anonymous-user.component.scss'],
})
export class AnonymousUserComponent implements OnInit {
  anonymousForm: UntypedFormGroup;
  emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9_.+-]{2,}$/;
  checked: boolean = false;
  disabled = false;
  declrationCheckBox: any = false;

  // Upload CV
  file: { name: string, progress: number, file: File, size: number } | null = null;
  fileToUpload = [];
  namePattern = /^[A-Za-z]+(?: [A-Za-z]+)*$/;
  jobRolePattern = /^(?! )[A-Za-z0-9\s]*$|^.*[^ ]$/;
  uploadText = "Uploading...";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private titleService: Title,
    private router: Router,
    private io: HttpIOService,
    private helper: Helper,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AnonymousUserComponent>,
    private fb: UntypedFormBuilder,
    private auth: AuthService,
    private empService: EmployerService,
    private snackBar: MatSnackBar,
  ) { }

  applyWithoutLogin = false;
  loginToApply = false;
  selectedOption = 'loginToApply';

  getContainerClass(check): string {
    this.selectedOption = check;
    if (this.selectedOption === 'applyWithoutLogin') {
      return 'radio-row';
    } else {
      return 'login-to-apply';
    }
  }

  ngOnInit(): void {
    this.validationForm();
    console.log("this.data", this.data['Perticularjob'].job_category);
  }
  // Validation Controls //
  get f() {
    return this.anonymousForm.controls;
  }

  validationForm() {
    this.anonymousForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.emailPattern),
        ]),
      ],
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
    this.router.navigate(['/login'], {
      queryParams: { GuestUser: 'Guest-User' },
    });
    sessionStorage.setItem(
      'guestUserSelectedJob',
      btoa(JSON.stringify(this.data['Perticularjob'].id))
    );
  }
  onNoClickClose() {
    this.dialogRef.close();
  }
  checkDeclaration(event) {
    // console.log(event)
    this.declrationCheckBox = event;
  }

  guestUserCreat() {
    if (this.anonymousForm.status === 'INVALID') {
      this.helper.markFormGroupTouched(this.anonymousForm);
      return false;
    }
    let jsonData = {
      path: 'create-guest-user',
      firstName: this.anonymousForm.get('firstName').value,
      lastName: this.anonymousForm.get('lastName').value,
      email: this.anonymousForm.get('email').value,
    };
    if (this.declrationCheckBox == true) {
      this.io._guestUser(jsonData).subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          this.dialogRef.close();
          // console.log(res);
          sessionStorage.setItem(
            'guestUser',
            btoa(JSON.stringify(res['result'].userData))
          );
          this.modelOpen();
        }
      });
    }
  }

  modelOpen() {
    if (this.data['Perticularjob'].apply_via == '3') {
      const dialogRef = this.dialog.open(ViaEmailComponent, {
        //height: '450px',
        width: '450px',
        data: {
          Perticularjob: this.data['Perticularjob'],
          user: 'WithoutSignIn',
        },
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res == 'save') {
          // console.log("++++++++++++")
        }
      });
    } else if (this.data['Perticularjob'].apply_via == '2') {
      const dialogRef = this.dialog.open(ViaExternalUrlComponent, {
        //height: '450px',
        width: '450px',
        data: {
          Perticularjob: this.data['Perticularjob'],
          user: 'WithoutSignIn',
        },
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res == 'save') {
          // console.log("++++++++++++")
        }
      });
    } else if (this.data['Perticularjob'].apply_via == '1') {
      const dialogRef = this.dialog.open(ViaWorkNigeriaComponent, {
        //height: '450px',
        width: '450px',
        data: {
          Perticularjob: this.data['Perticularjob'],
          user: 'WithoutSignIn',
        },
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res == 'save') {
          // console.log("++++++++++++")
        }
      });
    }
  }

  // CV UPLOAD AND SAVE FUNCTIONS
  async saveCVs() {
    if (this.anonymousForm.status === 'INVALID') {
      this.helper.markFormGroupTouched(this.anonymousForm);
      return false;
    }

    const formData = new FormData();
    formData.append('path', 'upload-file');
    formData.append('purpose', 'parseResume');
    formData.append('file', this.file.file);

    await this.io._uploadFile(formData).subscribe(
      (res: any) => {
        this.fileToUpload.push(res['result'].id + '_' + res['result'].fileName);
        // Takes the CV and parse it
        let payLoad = {
          path: "/public/resume-parser",
          firstName: this.anonymousForm.value.firstName,
          lastName: this.anonymousForm.value.lastName,
          email: this.anonymousForm.value.email,
          jobRoles: this.data['Perticularjob'].job_category,
          files: this.fileToUpload.map(file => ({
            Key: file,
          })),
        }
        this.empService._parseResumeSandbox(payLoad).subscribe((res: any) => {
          if (res.statusCode == 200) {

            let successData = "sandbox CV";
            const dialogRef = this.dialog.open(PopupConfirmationComponent, {
              height: 'auto',
              width: 'auto',
              autoFocus: false,
              data: {
                data: successData,
                firstName: this.anonymousForm.value.firstName,
              },
            });
            this.file = null;
            this.fileToUpload = [];
            this.anonymousForm.reset();
            dialogRef.afterClosed().subscribe();
          } else {
            this.snackBar
              .openFromComponent(DynamicSnacbarErrorComponent, {
                data: res.body,
                verticalPosition: 'top', // 'top' | 'bottom'
                horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
                duration: 6000,
              })
              .afterOpened()
              .subscribe(this.helper.setOnTop);
          }
        });

      },
      (err) => console.log(err)
    );

  }


  onFilesSelected(event: any): void {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      this.file = { name: selectedFile.name, progress: 0, file: selectedFile, size: selectedFile.size };
      this.uploadFile();
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    // Add some visual feedback for drag over
    (event.target as HTMLElement).classList.add('drag-over');
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    // Remove visual feedback when drag leaves
    (event.target as HTMLElement).classList.remove('drag-over');
  }

  onFileDropped(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    const droppedFile = event.dataTransfer?.files[0];
    if (droppedFile) {
      this.file = { name: droppedFile.name, progress: 0, file: droppedFile, size: droppedFile.size };
      this.uploadFile();
    }
    (event.target as HTMLElement).classList.remove('drag-over');
  }

  uploadFile(): void {
    this.uploadText = "Uploading...";
    const interval = setInterval(() => {
      if (this.file) {
        this.file.progress += 10;
        if (this.file.progress >= 100) {
          this.uploadText = "Upload Complete";
          clearInterval(interval);
        }
      }
    }, 1000);
  }


  removeFile(): void {
    this.file = null;
  }
}
