import { Component, HostListener, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  AbstractControl,
  ValidationErrors
} from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar'; import { SocialAuthService } from '@abacritt/angularx-social-login';
import { SocialUser } from '@abacritt/angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
} from '@abacritt/angularx-social-login';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpIOService } from 'src/app/services/http-io.service';
import { EmailNotificationComponent } from '../email-notification/email-notification.component';
import { SocialLogin } from 'src/app/models/user/socialLogin';
import { EmployerService } from 'src/app/services/employer.service';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';
import { AuthService } from 'src/app/services/auth.service';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { Helper } from 'src/app/helper/defult';

@Component({
  selector: 'app-employer-sign-up',
  templateUrl: './employer-sign-up.component.html',
  styleUrls: ['./employer-sign-up.component.scss']
})
export class EmployerSignUpComponent {
  googleValue: any;
  GuestUserjobId: any;
  userSocialLogin: SocialLogin;
  getUrlRedirection: any;
  redirectForGuestSelectJob: any;
  employerSignup: UntypedFormGroup;
  emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9_.+-]{2,}$/;
  passwordPattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  namePattern = /^[A-Za-z]+(?: [A-Za-z]+)*$/;
  companyPattern = /^(?!\s*$)[A-Za-z0-9 !@#$%^&*()_+[\]{};':"\\|,.<>/?-]+$/;
  email: any;
  role: any;
  showPassword = false;
  showConfirmPassword = false;

  constructor(
    @Inject(MatDialog) public dialog: MatDialog,
    @Inject(UntypedFormBuilder) private fb: UntypedFormBuilder,
    private io: HttpIOService,
    private socialauthService: SocialAuthService,
    private empService: EmployerService,
    @Inject(MatSnackBar) private snackBar: MatSnackBar,
    @Inject(Router) private router: Router,
    private _authService: AuthService,
    private helper: Helper,

  ) {

  }

  ngOnInit(): void {
    this.formInit();
    this.onResize(null);
  }

  isSmallScreen = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isSmallScreen = window.innerWidth < 639;
  }

  // Create Modal Driven Form //
  formInit() {
    this.employerSignup = this.fb.group({
      firstName: ['', 
        Validators.compose([
          Validators.required,
          Validators.pattern(this.namePattern),
        ]),
      ],
      lastName: ['', 
        Validators.compose([
          Validators.required,
          Validators.pattern(this.namePattern),
        ]),
      ],
      companyName: ['', 
        Validators.compose([
          Validators.required,
          Validators.pattern(this.companyPattern),
        ]),
      ],
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.emailPattern),
        ]),
      ],
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.passwordPattern),
        ]),
      ],
      confirmPassword: [
        '',
        Validators.compose([Validators.required]),
      ],
      path: ['register-job-provider', Validators.required],
    }, {
      validators: this.passwordMatchValidator, 
    });
  
    // if (this.email && this.role === 'employer') {
    //   this.employerSignup.patchValue({
    //     email: this.email,
    //   });
    // }
  }

  // Validation Controls //
  get f() {
    return this.employerSignup.controls;
  }

  signIn() {
    this.router.navigate(['/login'], { queryParams: { userType: 'employer' } });
  }

  termsOfUse() {
    this.router.navigate(['/terms-and-conditions']);
  }

  // Sign in using google 
  signInWithgoogle(): void {
    this.socialauthService
      .signIn(GoogleLoginProvider.PROVIDER_ID)
      .then((googleResponse: any) => {
        if (!googleResponse.email) {
          const dialogRef = this.dialog.open(EmailNotificationComponent, {
            height: 'auto',
            width: '450px',
            data: {},
          });
          dialogRef.afterClosed().subscribe((res) => {
            if (res) {
              // console.log(res);
              this.googleValue = res;
              // this.loginWithSocialTwitter();
            }
          });
          return false;
        }
        this.userSocialLogin = {
          socialId: googleResponse.id,
          email: googleResponse.email ? googleResponse.email : '',
          socialType: 'google',
          path: 'social-login',
        };

        this.io
          .apicall(this.userSocialLogin, 'auth/social-login')
          .subscribe((res: any) => {
            // console.log(res)
            if (res.serverResponse.code == 200) {
              this.empService.setforWSconnect(res);
              if (this.GuestUserjobId) {
                if (this.GuestUserjobId == 'job-alert') {
                  this.router.navigate(['/job-alert']);
                } else {
                  this.router.navigate(['/job-search']);
                }
              } else if (this.getUrlRedirection) {
                this.router.navigateByUrl(`/${this.getUrlRedirection}`);
                sessionStorage.removeItem('setUrlRedirection');
              } else if (this.redirectForGuestSelectJob) {
                this.router.navigate(['/job-search'], {
                  queryParams: { role: 'Guest-User' },
                });
              } else {
                this.router.navigate(['/home']);
              }
              this.snackBar
                .openFromComponent(DynamicSnacbarComponent, {
                  data: res.serverResponse.message,

                  verticalPosition: 'top', // 'top' | 'bottom'
                  horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
                })
                .afterOpened()
                .subscribe(this.helper.setOnTop);
              // this.dialogRef.close();
              let userdata = res['result'].userData;
              let token = res['result'].token;
              let companyData = res['result'].companyData;

              this._authService.sendToken(token);
              this._authService.setCurrentUserDetails(userdata);
              this._authService.setCurrentCompanyData(companyData);
            } else {
              this.snackBar
                .openFromComponent(DynamicSnacbarErrorComponent, {
                  data: res.serverResponse.message,
                  verticalPosition: 'top', // 'top' | 'bottom'
                  horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
                  duration: 6000,
                })
                .afterOpened()
                .subscribe(this.helper.setOnTop);
            }
          });
      });
  }
  signInWithFB(): void {
    this.socialauthService
      .signIn(FacebookLoginProvider.PROVIDER_ID)
      .then((fbResponse: any) => {
        this.userSocialLogin = {
          socialId: fbResponse.id,
          email: fbResponse.email ? fbResponse.email : '',
          socialType: 'facebook',
          path: 'social-login',
        };

        this.io
          .apicall(this.userSocialLogin, 'auth/social-login')
          .subscribe((res: any) => {
            if (res.serverResponse.code == 200) {
              this.empService.setforWSconnect(res);
              this.snackBar
                .openFromComponent(DynamicSnacbarComponent, {
                  data: res.serverResponse.message,
                  verticalPosition: 'top', // 'top' | 'bottom'
                  horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
                })
                .afterOpened()
                .subscribe(this.helper.setOnTop);
              let userdata = res['result'].userData;
              let token = res['result'].token;
              let companyData = res['result'].companyData;

              this._authService.sendToken(token);
              this._authService.setCurrentUserDetails(userdata);
              this._authService.setCurrentCompanyData(companyData);

              if (this.GuestUserjobId) {
                if (this.GuestUserjobId == 'job-alert') {
                  this.router.navigate(['/job-alert']);
                } else {
                  this.router.navigate(['/job-search']);
                }
              } else if (this.getUrlRedirection) {
                this.router.navigateByUrl(`/${this.getUrlRedirection}`);
                sessionStorage.removeItem('setUrlRedirection');
              } else if (this.redirectForGuestSelectJob) {
                this.router.navigate(['/job-search'], {
                  queryParams: { role: 'Guest-User' },
                });
              } else {
                this.router.navigate(['/home']);
              }
            } else {
              this.snackBar
                .openFromComponent(DynamicSnacbarErrorComponent, {
                  data: res.serverResponse.message,
                  verticalPosition: 'top', // 'top' | 'bottom'
                  horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
                  duration: 6000,
                })
                .afterOpened()
                .subscribe(this.helper.setOnTop);
            }
          });
      });
  }
  login() {
    this.router.navigate(['/login']);
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  toggleConfirmPasswordVisibility() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  createAccount() {
    if (this.employerSignup.status === 'INVALID') {
      this.helper.markFormGroupTouched(this.employerSignup);
      return false;
    }
    this.io
      .apicall(this.employerSignup.value, 'auth/register-job-provider')
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          this.snackBar
            .openFromComponent(DynamicSnacbarComponent, {
              data: res.serverResponse.message,

              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
          this.snackBar
            .openFromComponent(DynamicSnacbarComponent, {
              data: 'Your account has been successfully created. Please check your email to verify your account',
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
          this.router.navigate(['/login']);
        } else {
          this.snackBar
            .openFromComponent(DynamicSnacbarErrorComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
              duration: 6000,
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
        }
      });
  }

  // Create a custom validator function
  passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
    const password = control.get('password');
    const confirmPassword = control.get('confirmPassword');
  
    if (password && confirmPassword && password.value !== confirmPassword.value) {
      return { passwordMismatch: true };
    }
  
    return null;
  }
  
}