<div class="verification-wrap common-padd">
  <p class="wel-text"> Welcome {{ companyData.firstName }}</p>
  <h1>LET’S VERIFY YOUR COMPANY</h1>
  <!-- CAC FORM -->
  <div class="row arrow-up-cac">
    <img src="../../../../assets/images/cac-icon.webp" class="cac-icon" />
    <div class="cac-div">
      <h2>Use Your Company CAC <img src="../../../../assets/images/arrow-side.webp" class="arrow-side"
          [ngClass]="showCACForm ? 'arrow-down' : 'arrow-side'" (click)="showForms('cac')" /></h2>
      <p>Verify your company with CAC to unlock free job postings</p>
    </div>
  </div>
  <div class="row auth-main-verify" *ngIf="showCACForm">
    <div class="row arrow-down-cac">
      <div class="col-lg-5 col-12 img-div order-lg-1">
        <img src="../../../../assets/images/company-verification.webp" alt="company verification" />
      </div>
      <div class="col-lg-7 col-12 order-lg-2 d-flex justify-content-center align-items-center">
        <div class="form-wrap d-flex justify-content-center align-items-center">
          <form class="" [formGroup]="VerificationForm">
            <!-- Company Name -->
            <div class="form-group">
              <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Company Name</mat-label>
                <input type="text" matInput placeholder="Company Name" formControlName="CompanyName" />
              </mat-form-field>
              <div *ngIf="f.CompanyName.touched && f.CompanyName.invalid">
                <span class="text-left text-danger pb-1" *ngIf="f.CompanyName.errors.required">Company Name is required
                </span>
                <span class="text-left text-danger pb-1" *ngIf="f.CompanyName.errors.hasSpecialCharacters">
                  Special characters are not allowed in Company Name
                </span>
              </div>
            </div>
            <!-- Company Address -->
            <div class="form-group">
              <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Company Address</mat-label>
                <input type="text" matInput placeholder="" formControlName="CompanyAddress" />
              </mat-form-field>
              <div *ngIf="f.CompanyAddress.touched && f.CompanyAddress.invalid">
                <span class="text-left text-danger" *ngIf="f.CompanyAddress.errors.required">Company Address is required
                </span>
              </div>
            </div>
            <!-- Company Website -->
            <div class="form-group">
              <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Company Website (Optional)</mat-label>
                <input type="text" matInput placeholder="" formControlName="CompanyWebsite" />
              </mat-form-field>
            </div>
            <!-- Registration Number -->
            <div class="form-group">
              <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Enter CAC Registration Number</mat-label>
                <input type="text" matInput placeholder="" formControlName="registrationNumber" />
              </mat-form-field>
              <div *ngIf="f.registrationNumber.touched && f.registrationNumber.invalid">
                <span class="text-left text-danger pb-1" *ngIf="f.registrationNumber.errors.required">
                  CAC Number is required
                </span>
                <span class="text-left text-danger pb-1" *ngIf="f.registrationNumber.errors?.pattern">
                  Invalid CAC Number
                </span>
              </div>
            </div>
            <!-- Upload CAC Form -->
            <div class="upload-cac">
              <p class="upload-text">Upload CAC Certificate </p>
              <div class="row d-flex justify-content-center align-items-center">
                <div class="col-lg-6">
                  <label class="custom-file-upload">
                    <input type="file" (change)="onFileSelected($event)" accept=".pdf" />
                    <img src="../../../../assets/images/browse-arrow.png" /> Browse computer
                  </label>
                </div>
                <div class="col-lg-6 d-flex align-items-center justify-content-center p-0">
                  <mat-progress-bar mode="determinate" [value]="uploadProgress"
                    *ngIf="uploadProgress == 0"></mat-progress-bar>
                  <mat-progress-bar mode="determinate" [value]="uploadProgress"
                    *ngIf="selectedFile && uploadProgress > 0"></mat-progress-bar>
                  <p class="uploaded-file-name" *ngIf="fileName">{{ fileName }}</p>

                </div>
              </div>
            </div>
            <button class="verify-btn" (click)="submit()">
              Verify My Company
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- EMAIL FORM -->
  <div class="row arrow-up-email">
    <img src="../../../../assets/images/email-icon.webp" class="email-icon" />
    <div class="email-div">
      <h2>Use Your Company Email <img src="../../../../assets/images/arrow-side.webp" class="arrow-side"
          [ngClass]="showEmailForm ? 'arrow-down' : 'arrow-side'" (click)="showForms('email')" /></h2>
      <p>Verify your Account with your company email and phone number</p>
    </div>
  </div>
  <div class="row auth-main-verify bg-email-form" *ngIf="showEmailForm">
    <div class="row arrow-down-cac">
      <div class="col-lg-5 col-12 img-div order-lg-1">
        <img src="../../../../assets/images/email-verification.webp" alt="email verification" />
      </div>
      <div class="col-lg-7 col-12 order-lg-2 d-flex justify-content-center align-items-center">
        <div class="form-wrap d-flex justify-content-center align-items-center" *ngIf="!otpScreen">
          <form class="" [formGroup]="EmailForm">
            <!-- Company Name -->
            <div class="form-group">
              <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Company Name</mat-label>
                <input type="text" matInput placeholder="Company Name" formControlName="CompanyName" />
              </mat-form-field>
              <div *ngIf="f2.CompanyName.touched && f2.CompanyName.invalid">
                <span class="text-left text-danger pb-1" *ngIf="f2.CompanyName.errors.required">Company Name is required
                </span>
                <span class="text-left text-danger pb-1" *ngIf="f2.CompanyName.errors.hasSpecialCharacters">
                  Special characters are not allowed in Company Name
                </span>
              </div>
            </div>
            <!-- Company Email -->
            <div class="form-group">
              <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Company Email</mat-label>
                <input type="email" matInput placeholder="" formControlName="CompanyEmail" />
              </mat-form-field>
              <div *ngIf="f2.CompanyEmail.touched && f2.CompanyEmail.invalid">
                <span class="text-left text-danger pb-1" *ngIf="f2.CompanyEmail.errors.required">Email is required
                </span>
                <span class="text-left text-danger pb-1" *ngIf="f2.CompanyEmail.errors?.pattern">
                  Email is not valid
                </span>
              </div>
            </div>
            <!-- Phone Number -->
            <div class="form-group">
              <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Phone Number</mat-label>
                <input type="email" matInput placeholder="" formControlName="PhoneNumber" />
              </mat-form-field>
              <div *ngIf="f2.PhoneNumber.touched && f2.PhoneNumber.invalid">
                <span class="text-left text-danger pb-1" *ngIf="f2.PhoneNumber.errors.required">Phone Number is required
                </span>
                <span class="text-left text-danger pb-1" *ngIf="f2.PhoneNumber.errors?.pattern">
                  Phone Number is not valid
                </span>
              </div>
            </div>
            <button class="verify-email-btn" (click)="generateOTP()">
              Verify My Company
            </button>
          </form>
        </div>
        <!-- Generate OTP Screen -->
        <form [formGroup]="digitsForm" cForm>
          <div class="form-wrap otp-screen" *ngIf="otpScreen">
            <p class="ver-code-text">We have sent a verification code to <b>{{ EmailForm.value.CompanyEmail }}</b> enter the code here to continue
            </p>
            <div style="display: flex; flex-direction: column; height: 100%;">
              <div class="d-flex justify-content-between gap-3">
                <input [class.invalid-otp]="invalidOTP" cFormControl type="number" formControlName="digit1"
                  class="form-control otp-box" (input)="moveToNextInput('digit1', 'digit2', 'start')">
                <input [class.invalid-otp]="invalidOTP" cFormControl type="number" formControlName="digit2"
                  class="form-control otp-box" (input)="moveToNextInput('digit2', 'digit3', 'digit1')">
                <input [class.invalid-otp]="invalidOTP" cFormControl type="number" formControlName="digit3"
                  class="form-control otp-box" (input)="moveToNextInput('digit3', 'digit4', 'digit2')">
                <input [class.invalid-otp]="invalidOTP" cFormControl type="number" formControlName="digit4"
                  class="form-control otp-box" (input)="moveToNextInput('digit4', 'submit', 'digit3')">
              </div>
              <p *ngIf="invalidOTP" style="color: red; margin-bottom: -5px; margin-top: 20px; text-align: center;">
                Invalid verification code, try again</p>
              <button id="verify-otp-btn" *ngIf="!btnLoader" class="btn-1" (click)="verifyOTP()" cButton>continue</button>
              <button *ngIf="btnLoader" cButton class="btn-1" disabled>
                <!-- <c-spinner aria-hidden="true" size="sm"></c-spinner> -->
                Loading...
              </button>
              <button class="resend-btn" (click)="generateOTP()">resend code</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>