import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { Observable } from 'zen-observable-ts/lib/zenObservable';
import { DynamicSnacbarErrorComponent } from '../container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { Helper } from '../helper/defult';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userBalance: number;
  userPlan: any;
  user: any;
  userUpdated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(
    private myRoute: Router,
    private helper: Helper,
    private dialogRef: MatDialog,
    //  private httpClient: HttpClient,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}
  // local storage //
  unsubscribed() {
    return new Observable((observer) => {
      observer.next(123); // TODO: review
    });
  }
  setCurrentUserDetails(user) {
    if(localStorage.getItem('workNigeriaToken')){
      this.userBalance = user.balance
      localStorage.setItem(
        'workNigeriaUser',
        btoa(this.helper.toBinary(JSON.stringify(user)))
      );
      this.userUpdated.next(true);
    }
  }

  getCurrentUserDetails() {
    return JSON.parse(atob(localStorage.getItem('workNigeriaToken')));
  }

  setCurrentCompanyData(companyData) {
    localStorage.setItem(
      'workNigeriacompanyData',
      btoa(JSON.stringify(companyData))
    );
  }

  getCurrentCompanyData() {
    let companyValue = localStorage.getItem('workNigeriacompanyData');
    // console.log(companyValue)
    if (companyValue != null) {
      let regxValue = new RegExp(
        '^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$'
      );
      // companyValue.match(regxValue)
      // console.log(companyValue.match(regxValue))
      if (companyValue.match(regxValue) != null) {
        return JSON.parse(atob(localStorage.getItem('workNigeriacompanyData')));
      } else {
        localStorage.clear();
        // this.snackBar.open("User Details Not Found", "OK", {})
        this.snackBar
          .openFromComponent(DynamicSnacbarErrorComponent, {
            data: 'User Details Not Found',
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            duration: 6000,
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);

        this.router.navigate(['/home']);
        window.location.reload();
      }
    }
  }
  // sessionStorage //

  setCurrentUserDetailsInSessoin(user) {
    if(sessionStorage.getItem('workNigeriaToken')){
      this.userBalance = user.balance
      sessionStorage.setItem(
        'workNigeriaUser',
        btoa(this.helper.toBinary(JSON.stringify(user)))
      );
      this.userUpdated.next(true);
    }
  }

  getCurrentUserDetailsInSessoin() {
    return JSON.parse(atob(sessionStorage.getItem('workNigeriaToken')));
  }

  setCurrentCompanyDataInSessoin(companyData) {
    sessionStorage.setItem(
      'workNigeriacompanyData',
      btoa(JSON.stringify(companyData))
    );
  }
  getCurrentCompanyDataInSessoin() {
    let companyValue = sessionStorage.getItem('workNigeriacompanyData');
    // console.log(companyValue)
    if (companyValue != null) {
      let regxValue = new RegExp(
        '^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$'
      );
      // console.log(regxValue)
      //  console.log(companyValue.match(regxValue))
      //  console.log(companyValue.test(regxValue))

      if (companyValue.match(regxValue) != null) {
        return JSON.parse(
          atob(sessionStorage.getItem('workNigeriacompanyData'))
        );
      } else {
        sessionStorage.clear();
        // this.snackBar.open("User Details Not Found,Please Login Again", "OK", {})
        this.snackBar
          .openFromComponent(DynamicSnacbarErrorComponent, {
            data: 'User Details Not Found,Please Login Again',
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            duration: 6000,
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
        this.router.navigate(['/home']);
        // window.location.reload();
      }
    }
    // return JSON.parse(atob(sessionStorage.getItem("workNigeriacompanyData")))
  }
  //
  isLoggin(tokens) {
    let token = JSON.parse(
      this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
    );
    this.user = JSON.parse(
      this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
    );
    if (
      JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      )
    ) {
      return localStorage.setItem('workNigeriaToken', btoa(tokens));
    }
  }

  sendToken(token) {
    // console.log(token)
    localStorage.setItem('workNigeriaToken', token);
  }

  sendTokenInsession(token) {
    // console.log(token)
    sessionStorage.setItem('workNigeriaToken', token);
  }

  getToken() {
    // console.log(localStorage.getItem("workNigeriaToken"))
    return localStorage.getItem('workNigeriaToken');
  }

  getTokenFormseesion() {
    // console.log(sessionStorage.getItem('workNigeriaToken'))
    return sessionStorage.getItem('workNigeriaToken');
  }

  isLoggedIn() {
    return this.getToken() !== null;
  }

  isLoggedInFormsession() {
    return this.getTokenFormseesion() !== null;
  }

  logout() {
    let token = localStorage.getItem('workNigeriaToken');
    let sessiontoken = sessionStorage.getItem('workNigeriaToken');

    if (!token) {
      // this.checkingUserInFirebase(this.user.nibiId);
      localStorage.clear();
      this.myRoute.navigate(['/home']);
    }

    if (!sessiontoken) {
      sessionStorage.clear();
      this.myRoute.navigate(['/home']);
    }
  }

  allLogout() {
    localStorage.removeItem('workNigeriaToken');
    sessionStorage.removeItem('workNigeriaToken');
    localStorage.removeItem('workNigeriaUser');
    sessionStorage.removeItem('workNigeriaUser');
    localStorage.removeItem('workNigeriacompanyData');
    sessionStorage.removeItem('workNigeriacompanyData');
    // localStorage.clear();
    // sessionStorage.clear();
    this.dialogRef.closeAll();
    // window.localStorage.clear();
    // window.location.reload();
    this.myRoute.navigate(['/home']);
    // window.reload();
    // window.location.reload();
  }
  getUserBalance() {
    return this.userBalance || 0;
  }
  setUserBalance(amount: number) {
    this.userBalance = amount || 0;
  }
  setUserPlan(plan: any) {
    this.userPlan = plan || {subscriptionPlan: 'None'};
  }
  getUserPlan() {
    return this.userPlan || {subscriptionPlan: 'None'};
  }
}
