import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Helper } from 'src/app/helper/defult';
import { HttpIOService } from 'src/app/services/http-io.service';
import { DynamicSnacbarErrorComponent } from '../dynamic-snacbar-error/dynamic-snacbar-error.component';
import { MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import { PopupConfirmationComponent } from '../popup-confirmation/popup-confirmation.component';

@Component({
  selector: 'app-background-check-form',
  templateUrl: './background-check-form.component.html',
  styleUrls: ['./background-check-form.component.scss']
})
export class BackgroundCheckFormComponent implements OnInit {
  userDetailsForm: FormGroup;
  emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9_.+-]{2,}$/;
  namePattern = /^[a-zA-Z]+$/;
  // numberPattern = /^\d+$/;
  numberPattern = /^\+234\s\d{3}\s\d{4}\s\d{4}$/;
  companyPattern = /^(?!\s*$)[a-zA-Z0-9\s]+$/;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<BackgroundCheckFormComponent>,
    private fb: FormBuilder,
    private helper: Helper,
    private io: HttpIOService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.userDetailsForm = this.fb.group({
      fullName: ['', [Validators.required, Validators.pattern(this.namePattern)]],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      phoneNumber: ['', [Validators.required, Validators.pattern(this.numberPattern)]],
      path: ['/add-training-user-info', Validators.required],
      category: ['consultation', Validators.required],
      company: ['', [Validators.required, Validators.pattern(this.companyPattern)]],
    });
  }

  get f() {
    return this.userDetailsForm.controls;
  }

  onNoClick() {
    this.dialogRef.close();
  }

  onNext() {
    if (this.userDetailsForm.status === 'INVALID') {
      this.helper.markFormGroupTouched(this.userDetailsForm);
      return false;
    }
    this.dialogRef.close();
    this.io._saveUserData(this.userDetailsForm.value).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        let successData = 'background check confirms';
        const dialogOpen = this.dialog.open(PopupConfirmationComponent, {
          height: 'auto',
          width: '450px',
          data: {
            data: successData,
          },
        });
        dialogOpen.afterClosed().subscribe(() => {
        });
        } else {
        this.snackBar
          .openFromComponent(DynamicSnacbarErrorComponent, {
            data: res.serverResponse.message,

            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
      }
    })
  }
}
