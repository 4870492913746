<div class="container-main">
    <div class="return-to-job">
        <!-- <button class="return-click" (click)="returnToJobs()">
            <img class="left-arrow" src="../../../../../../assets/images/left-arrow.png" />
            Return To Jobs
        </button> -->
        <button class="btn-more-cv" (click)="navigateToSearchCV()">
            Find More CV
        </button>
    </div>
    <div class="row">
        <div class="col-lg-2">
            <div class="d-flex align-items-center justify-content-between mb-3">
                <p class="job-list-heading">Folders</p>
                <img src="../../../../../../assets/images/create-folder-icon.png" class="create-folder-icon" (click)="this.createNewFolder()"/>
            </div>
            <div class="folder-manage-left jobsearch-height">
                <ng-container *ngFor="let folder of folders; let i = index">
                    <div [ngClass]="{
                        'folder-manage-box': true,
                        'folder-bgcolor': true,
                        'success': selectedFolderId === folder?.id || status === folder?.id,
                      }" (mouseover)="onFolderHover(folder?.id)" (mouseout)="onFolderUnhover(folder?.id)"
                        (click)="onFolderClick(folder?.id, i)">
                        <img src="../../../../../../assets/images/folder-icon.png" class="folder-icon" />
                        <p>{{ folder.name }}</p>
                    </div>
                </ng-container>
                <div class="card-container">
                    <div class="dashboard-ad" *ngFor="let card of cards; let i = index; trackBy: trackByFn"
                      [style.opacity]="currentCardIndex === i ? '1' : '0'">
                      <img [src]="card.image" />
                      <h1 class={{card.headingClass}}>{{ card.title }}</h1>
                      <p *ngIf="card.subtitle && card.subtitle !== ''">{{ card.subtitle }}</p>
                      <button class={{card.buttonClass}} (click)="handleButtonClick(card.buttonAction)">{{ card.buttonText }}</button>
                    </div>
                  </div>
            </div>
        </div>
        <div class="col-lg-3">
            <span class="d-flex align-items-center justify-content-between mb-3">
                <span class="job-list-heading">Candidates List</span>
                <span class="job-count">{{candidatesList?.length}} match</span>
            </span>
            <div class="job-manage-left jobsearch-height">
                <ng-container *ngFor="let list of candidatesList; let i = index">
                    <div (click)="jobDetails(i, list)" [ngClass]="status == list?.id
                                    ? 'job-manage-box bgcolor success'
                                    : 'job-manage-box bgcolor'">
                        <ng-container>
                            <div class="job-manage-img">
                                <img src='../../../../../../assets/images/no-image-icon.webp'
                                onerror="this.onerror=null;this.src='../../../assets/images/no-image-icon.webp'" />
                            </div>
                            <div class="job-manage-desc">
                                <h5>
                                    <span>{{ list?.firstName.toUpperCase() }}</span>&nbsp;
                                    <span>{{ list?.lastName.toUpperCase() }}</span>
                                </h5>
                                <p class="cv-designation title-case">{{list?.designation}}</p>
                                <p class="cv-location title-case"><img class="location-icon"
                                        src="../../../../../../assets/images/location-icon.png" />{{list?.city || 'Not Found'}}</p>
                                <p *ngIf="list?.skills.length" class="cv-skills"> Skills:
                                    <span>{{list?.skills[0]}}</span>
                                    <span>{{list?.skills[1]}}</span>
                                </p>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
                <!-- <p class="job-manage-load-more" *ngIf="loadMoreButtonVisible" (click)="onScrollDown()">
                    load more ...
                </p> -->

                <!-- <div class="no-post-yet" *ngIf="detailsOfJob == ''">
                    <h4>No jobs posted yet</h4>
                </div> -->
            </div>
        </div>
        <div class="col-lg-7">
            <div class="job-list-heading row mt-1">Preview</div>
            <div class="mangage-job-manage-right job-manage-right-shadow p-0 job-manage-preview"
                [ngClass]="showRightSectionInMobile ? 'mobile-view-parent' : ''" *ngIf="detailsOfJob !== ''; let i = index">
                <button (click)="mobileViewClassClose()" class="mobilejob-back">
                    <i class="far fa-times-circle"></i>
                </button>
                <div [ngClass]="showRightSectionInMobile ? 'mobile-view-child' : ''">
                    <div class="preview-div">
                        <ng-container>
                            <div class="job-manage-img">
                                <img src='../../../../../../assets/images/no-image-icon.webp'
                                onerror="this.onerror=null;this.src='../../../assets/images/no-image-icon.webp'" />
                            </div>
                            <div>
                                <h4 class="preview-text">
                                    <span>{{detailsOfJob.firstName.toUpperCase()}}</span>&nbsp;
                                    <span>{{detailsOfJob.lastName.toUpperCase()}}</span>
                                </h4>
                            </div>
                            <div class="cv-save-msg">
                                <button class="cv-apply-btn" (click)="inviteToApply(detailsOfJob)">Invite To
                                    Apply</button>
                                <button class="cv-msg-btn" (click)="messageOpen(detailsOfJob)">Message</button>
                            </div>
                        </ng-container>
                    </div>
                    <div class="jobsearch-height">
                        <hr class="job-manage-hr" />
                        <div class="job-manage-moreinfo-wrap">
                            <h6>Contact</h6>
                        </div>
                        <div class="job-manage-desc-half flex">
                            <div [ngClass]="{'col-8': detailsOfJob?.locked, 'col-12': !detailsOfJob?.locked}">
                                <p>
                                    <span class="plft">Email</span>
                                    <span class="prit">{{ detailsOfJob.contactDetails.email || 'Not Found' }}</span>
                                </p>
                                <p>
                                    <span class="plft">Phone Number</span>
                                    <span class="prit">{{ detailsOfJob.contactDetails.number || 'Not Found' }}</span>
                                </p>
                                <p>
                                    <span class="plft">Address</span>
                                    <span class="prit">{{ detailsOfJob.contactDetails.address || 'Not Found' }}</span>
                                </p>
                                <p>
                                    <span class="plft">LinkedIn</span>
                                    <span class="prit">
                                        <a class="linkedin-link" *ngIf="detailsOfJob.contactDetails.linkedIn !== ''" [href]="detailsOfJob.contactDetails.linkedIn">
                                            {{ detailsOfJob.contactDetails.linkedIn || 'Not Found' }}
                                        </a>
                                        <span *ngIf="detailsOfJob.contactDetails.linkedIn === ''">
                                            {{ 'Not Found' }}
                                        </span>
                                    </span>
                                </p>
                            </div>
                            <div class="col-4 unlock-candidate">
                                <button *ngIf="detailsOfJob?.locked" class="cv-unlock-btn" (click)="unlockCV(detailsOfJob.id)"><i class="fas fa-unlock"></i>Unlock Contact</button>
                            </div>
                        </div>
                        <hr class="job-manage-hr" />
                        <div class="job-manage-moreinfo-wrap">
                            <h6>About</h6>
                        </div>
                        <div class="job-manage-desc-half">
                            <p>
                                <span class="plft">Job Title</span>
                                <span class="prit title-case">{{ detailsOfJob.designation || 'Not Found' }}</span>
                            </p>
                            <p *ngIf="detailsOfJob.city || detailsOfJob.state">
                                <span class="plft">Location</span>
                                <span class="prit title-case">{{ detailsOfJob.state + " " + detailsOfJob.city}}</span>
                            </p>
                            <p>
                                <span class="plft">Preference</span>
                                <span class="prit title-case">{{ detailsOfJob.preference || 'Not Found'}}</span>
                            </p>
                            <p>
                                <span class="plft">Total experience</span>
                                <span class="prit">{{ convertMonthsToYearsAndMonths(detailsOfJob.totalExperience) || 'Not Found' }}</span>
                            </p>
                        </div>
                        <hr class="job-manage-hr" />
                        <div class="job-manage-moreinfo-wrap">
                            <h6>Skills</h6>
                        </div>
                        <div class="job-manage-desc-half skills">
                            <div *ngFor="let skill of detailsOfJob.skills">
                                <div class="skill-container">
                                    <div class="skill-text">{{ skill }}</div>
                                </div>
                            </div>
                        </div>
                        <hr class="job-manage-hr" />
                        <div class="job-manage-moreinfo-wrap">
                            <h6>Experience</h6>
                        </div>
                        <div class="job-manage-desc-half" *ngFor="let experience of detailsOfJob.experience">
                            <p>
                                <span class="plft org-name dynamic">{{ experience.company }}</span>&nbsp;<span class="prit" *ngIf="experience.joblocation && (experience.joblocation?.City !== '' || experience.joblocation?.State !== '')">- {{ experience.joblocation?.City }} {{ experience.joblocation?.State }}</span>
                                <!-- <span class="prit title-case">{{ education.universityName || 'Not Found' }}</span> -->
                            </p>
                            <p>
                                <!-- <span class="plft">Course</span> -->
                                <span class="prit title-case dynamic">{{ experience.Title }}</span>&nbsp;<span class="prit title-case duration">- {{dateFormatter(experience.startDate)}} - {{ experience.stillWorking === 'true' ? 'PRESENT' : dateFormatter(experience.endDate)}}</span>
                            </p>
                            <p>
                                <!-- <span class="plft">End Year</span> -->
                                <span class="prit desc">{{ experience.description }}</span>
                            </p>
                        </div>
                        <hr class="job-manage-hr" />
                        <div class="job-manage-moreinfo-wrap">
                            <h6>Education</h6>
                        </div>
                        <div class="job-manage-desc-half" *ngFor="let education of detailsOfJob.educations">
                            <p>
                                <span class="plft org-name dynamic">{{education.universityName}}</span>&nbsp;<span class="prit title-case">- {{dateFormatterYear(education.endYear)}}</span>
                                <!-- <span class="prit title-case">{{ education.universityName || 'Not Found' }}</span> -->
                            </p>
                            <p>
                                <span class="prit title-case education">{{ education.courseName }}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>