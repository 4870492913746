import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Injectable, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';
import { Helper } from 'src/app/helper/defult';
import { EmployerService } from 'src/app/services/employer.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class CompanyServices {
  constructor(private http: HttpClient) {}

  async companyCURDService(rawData: any, token: string, path: string) {
    try {
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: token,
      });
      let options = { headers: headers };
      const body = rawData;
      const data = await this.http
        .post(`${environment.baseUrl}${path}`, body, options)
        .toPromise();
      return data;
    } catch (error) {
      console.error(`Error occurred: ${error}`);
    }
    return 'Somthing wrong ';
  }
}

@Component({
  selector: 'app-emp-company',
  providers: [CompanyServices],
  templateUrl: './emp-company.component.html',
  styleUrls: ['./emp-company.component.scss'],
})
export class EmpCompanyComponent implements OnInit {
  paths: any = {
    update: '/job/update-company/',
  };
  companyDetails: any = {
    companyName: '',
    email: '',
    companyLocation: '',
    industry: '',
    about: '',
    shortDescription: '',
    phone: '',
    primaryContactPersonName: '',
    firstName: '',
    lastName: '',
  };
  userDetails: any;
  userToken: string;
  htmlContent: any;
  fileName: string = '';
  url: any;
  msg = '';
  viewOnly: boolean = false;
  companyAdminList: any;
  selectedPrimaryContactperson: any = {};
  constructor(
    private companyService: CompanyServices,
    private snackBar: MatSnackBar,
    private employerService: EmployerService,
    private helper: Helper
  ) {
    this.companyDetails = JSON.parse(
      atob(sessionStorage.getItem('workNigeriacompanyData'))
    );
    this.htmlContent = this.companyDetails.about;

    this.userDetails = JSON.parse(
      this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
    );
    this.userToken = sessionStorage.getItem('workNigeriaToken');
    if (this.userDetails.userRole == 'company_basic') {
      this.viewOnly = true;
    }
  }

  ngOnInit(): void {
    this.url = this.companyDetails.companyLogo;
    this.getCompanyAdminList();
  }

  async getCompanyAdminList() {
    let jsonData = {
      path: 'get-users',
      companyId: this.companyDetails.id,
      userRole: 'company_admin',
    };
    await this.employerService
      ._getCompanyAdminList(jsonData)
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          // console.log(res);
          this.companyAdminList = res['userData'];
          this.selectedPrimaryContactperson = this.companyAdminList.find(
            (x) => x.id == this.companyDetails.primaryContactPersonNameId
          );
        }
      });
  }

  changePrimaryContactPerson(event) {
    this.selectedPrimaryContactperson = '';
    this.selectedPrimaryContactperson = event.value;
    this.companyDetails.email = event.value.email;
    this.companyDetails.firstName = event.value.firstName;
    this.companyDetails.lastName = event.value.lastName;
  }

  selectFile(event) {
    if (!event.target.files[0] || event.target.files[0].length == 0) {
      this.msg = 'You must select an image';
      return;
    }
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.msg = 'Only images are supported';
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.msg = '';
      this.url = reader.result;
    };

    // let jsonData = {
    //   "path": "update-company-logo",
    //   "company_id": this.userDetails.companyId,
    //   "file": event.target.files[0],
    //   "purpose": "companyLogoUpload",
    // }
    const formData = new FormData();
    formData.append('company_id', this.userDetails.companyId);
    formData.append('path', 'update-company-logo');
    formData.append('file', event.target.files[0]);
    formData.append('purpose', 'companyLogoUpload');
    this.employerService
      ._companyProfilePicUpload(formData)
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          console.log(res);
        }
      });
  }

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '150px',
    minHeight: '0',
    maxHeight: 'auto',
    width: '80%',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    uploadUrl: 'v1/image',
    sanitize: true,
    toolbarPosition: 'top',
  };

  onFormSubmit() {
    let newDetails = {
      path: 'update-company',
      company_id: this.companyDetails.id,
      companyName: this.companyDetails.companyName,
      email: this.companyDetails.email,
      companyLocation: this.companyDetails.companyLocation
        ? this.companyDetails.companyLocation
        : '',
      industry: this.companyDetails.industry
        ? this.companyDetails.industry
        : '',
      about: this.htmlContent ? this.htmlContent : '',
      shortDescription: this.companyDetails.shortDescription
        ? this.companyDetails.shortDescription
        : '',
      phone: this.companyDetails.phone ? this.companyDetails.phone : '',
      primaryContactPersonNameId: this.selectedPrimaryContactperson.id,
      firstName: this.companyDetails.firstName,
      lastName: this.companyDetails.lastName,
    };
    this.companyService
      .companyCURDService(newDetails, this.userToken, this.paths.update)
      .then((resStr) => {
        console.log(resStr);
        this.toastMessage(resStr);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  toastMessage(res) {
    if (res['serverResponse']['code'] == 200) {
      // this.snackBar.open(`${res['serverResponse']['message']}`, 'OK', {});
      this.snackBar
        .openFromComponent(DynamicSnacbarComponent, {
          data: res['serverResponse']['message'],
          verticalPosition: 'top', // 'top' | 'bottom'
          horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
        })
        .afterOpened()
        .subscribe(this.helper.setOnTop);
      sessionStorage.setItem(
        'workNigeriacompanyData',
        btoa(JSON.stringify(res['result']['item']))
      );
    } else {
      // this.snackBar.open(`${res['serverResponse']['message']}`, 'OK', {});
      this.snackBar
        .openFromComponent(DynamicSnacbarErrorComponent, {
          data: res.serverResponse.message,
          verticalPosition: 'top', // 'top' | 'bottom'
          horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          duration: 6000,
        })
        .afterOpened()
        .subscribe(this.helper.setOnTop);
    }
  }
}
