<div class="survey-ui" *ngIf="!showForm && !formSubmitted">
    <div class="survey-header row">
        <div class="col-lg-6 col-12 first-col">
            <h1>Still Getting Unqualified <span>Applications,<img src="../../../assets/images/decore-line.webp"
                class="decore-line" /></span> Even After Using ATS?</h1>
            <p>Whether you use an ATS or not, our tool ensures that only the right people for your role are able to apply in the first place. </p>
            <button (click)="goToForm()">Get It Now</button>
        </div>
        <div class="col-lg-6 col-12 second-col">
            <img src="../../../assets/images/bored-worker-icon.webp" />
        </div>
    </div>
    <div class="how-it-works">
        <h2>How It Works</h2>
        <p>Our smart screening tool evaluates applicants at the point of application, allowing only the best matches 
            to submit their CVs—ensuring you see candidates fit for your role. It also auto-filters out AI-generated 
            CVs, saving you valuable time.</p>
    </div>
    <div class="why-you-love row">
        <div class="col-lg-6 col-12 second-col">
            <img src="../../../assets/images/female-worker-icon.webp" />
        </div>
        <div class="col-lg-5 col-12 first-col">
            <h2>Why You’ll Love It:</h2>
            <ul>
                <li *ngFor="let points of pointers">
                    <img [src]="points.img" />
                    <div>
                        <h4>{{ points.title }}</h4>
                        <p>{{ points.text }}</p>
                    </div>
                </li>
            </ul>
            <button (click)="goToForm()">Get It Now</button>
        </div>
        <div class="col-lg-1 col-0"></div>
    </div>
    <div class="early-access row">
        <div class="col-lg-5 col-12 order-lg-1">
            <h3>Get Early Access at an Exclusive  Price!</h3>
            <p>Research has shown that a growing number of unqualified  applicants now use generative AI  to boost 
                their resume rankings on ATS, making it harder for employers to find top talent.</p>
            <p><b>Be among the first to experience <span>Smart Screening</span> at an exclusive early-access price.</b></p>
            <button (click)="goToForm()">Get It Now</button>
        </div>
        <div class="col-lg-5 col-12 order-lg-2">
            <img src="../../../assets/images/laptop-icon.webp" />
        </div>
    </div>
</div>
<!------------------------------ SURVEY FORM UI ------------------------------------>
<div class="form-ui">
    <div class="form-text row" *ngIf="!formSubmitted && showForm">
        <div class="col-lg-6 col-12 first-col">
            <h1>Sign-Up Now to Unlock <span class="text-orange">Smart Screening</span> and say farewell to large pools of unqualified applicants.</h1>
            <p>Early Bird Access: 50% off!</p>
            <div class="container">
                <div class="rate">
                    <h3 class="green-text">NGN50,000</h3>
                    <h3 class="stroked-text">NGN100,000</h3>
                </div>
                <div class="days">
                    <p>For 30days</p>
                </div>
            </div>
            <p class="offer">100% Money-Back Guaranteed if you don't get results! Price Goes Up Soon!</p>
            <p>Fill out the form to get started</p>
        </div>
        <div class="col-lg-6 col-12 second-col">
            <form [formGroup]="surveyForm" class="s-form">
                <!-- Full Name Input -->
                <label>Name*</label>
                <input placeholder="enter your Full name" type="text" class="custom-input form-control"
                    formControlName="fullName" />
                <div *ngIf="f.fullName.touched && f.fullName.invalid">
                    <span class="text-left text-danger pb-1" *ngIf="f.fullName.errors.required">Name is required</span>
                    <span class="text-left text-danger pb-1" *ngIf="f.fullName.errors?.pattern">Name is not valid.</span>
                </div>
                <!-- Email Input -->
                <label>Email*</label>
                <input placeholder="user@example.com" type="email" class="custom-input form-control"
                    formControlName="email" />
                <div *ngIf="f.email.touched && f.email.invalid">
                    <span class="text-left text-danger pb-1" *ngIf="f.email.errors.required">Email is required</span>
                    <span class="text-left text-danger pb-1" *ngIf="f.email.errors?.pattern">Email is not valid.</span>
                </div>
                <!-- Phone Number Input -->
                <label>Phone Number*</label>
                <input placeholder="080*********" type="text" class="custom-input form-control"
                    formControlName="phoneNumber" />
                <div *ngIf="f.phoneNumber.touched && f.phoneNumber.invalid">
                    <span class="text-left text-danger pb-1" *ngIf="f.phoneNumber.errors.required">Phone Number is required</span>
                    <span class="text-left text-danger pb-1" *ngIf="f.phoneNumber.errors?.pattern">Phone Number is not valid.</span>
                </div>
                <!-- Company Name Input -->
                <label>Company Name</label>
                <input placeholder="company name" type="text" class="custom-input form-control"
                    formControlName="company" />
                <div *ngIf="f.company.touched && f.company.invalid">
                    <span class="text-left text-danger pb-1" *ngIf="f.company.errors.required">Company Name is required</span>
                    <span class="text-left text-danger pb-1" *ngIf="f.company.errors?.pattern">Company Name is not valid.</span>
                </div>
                <button (click)="sendEmail()">Get Early Access</button>
            </form>
        </div>
    </div>
    <!-- Form Submission UI -->
    <div class="form-submitted" *ngIf="formSubmitted && showForm">
        <h1>Thank you for your interest in our <span>smart screening</span> feature </h1>
        <p>Thank you for indicating your interest in our Smart Screening feature beta! We will be sharing more details soon about how you can get started. In the meantime, feel free to reach out if you have any questions.</p>
    </div>
</div>