import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';
import { HttpIOService } from 'src/app/services/http-io.service';
import { Helper } from 'src/app/helper/defult';
import { PopupConfirmationComponent } from 'src/app/container/elements/popup-confirmation/popup-confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
@Component({
  selector: 'app-recruitment-landing',
  templateUrl: './recruitment-landing.component.html',
  styleUrls: ['./recruitment-landing.component.scss']
})
export class RecruitmentLandingComponent implements OnInit {
  consultForm: UntypedFormGroup;
  emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9_.+-]{2,}$/;
  namePattern = /^[A-Za-z]+(?: [A-Za-z]+)*$/;
  phonePattern = /^\d{7,15}$/;

  services = [
    'Small and Medium-Sized Enterprises (SMEs): Looking for cost-effective and efficient hiring solutions.',
    'Large Corporations: Requiring highly qualified workers across numerous departments.',
    'Startups: Seeking the right talent to support rapid expansion.',
    'HR Departments: Needing expert assistance in locating and assessing excellent candidate.'
  ]

  offer = [
    {
      heading: 'Permanent Recruitment:',
      text: 'Finding long-term, full-time employees to meet your organization\'\s core needs.',
    },
    {
      heading: 'Executive Recruitment:',
      text: 'Sourcing top-tier talent for senior-level and specialized roles.',
    },
    {
      heading: 'Contract Staffing:',
      text: 'Providing skilled professionals for short-term projects or specific periods.',
    },
    {
      heading: 'Manpower Outsourcing:',
      text: 'Managing the recruitment, placement, and administration of staff for your business operations.'
    },
    {
      heading: 'Mass Recruitment:',
      text: 'Hiring large numbers of employees efficiently for businesses scaling operations or launching projects.'
    },
    {
      heading: 'Remote Workforce Recruitment:',
      text: 'Connecting companies with skilled remote professionals for flexible, location-independent roles.'
    },
    {
      heading: 'Graduate Recruitment:',
      text: 'Identifying and hiring fresh graduates with potential for entry-level and trainee positions.'
    },
    {
      heading: 'Temporary Staffing:',
      text: 'Providing flexible staffing solutions for seasonal or ad-hoc needs.'
    },
  ]

  offer_one = this.offer.slice(0, 4);
  offer_two = this.offer.slice(4, 8);

  WN_works = [
    {
      img: '../../../../assets/images/folder-icon.webp',
      heading: 'Initial Briefing:',
      text: 'You provide a detailed job description, including the role requirements, number of hires, and deadlines. ',
    },
    {
      img: '../../../../assets/images/talent-icon.webp',
      heading: 'Talent Sourcing:',
      text: 'We tap into our extensive database and also advertise the position across 30+ trusted platforms to attract the best candidates for the role.',
    },
    {
      img: '../../../../assets/images/screening-icon.webp',
      heading: 'Screening & Shortlisting:',
      text: 'We review CVs, conduct thorough interviews, and shortlist at least five strong candidates for your review and assessment.',
    },
    {
      img: '../../../../assets/images/data-storage-icon.webp',
      heading: 'Final Selection:',
      text: 'You select your preferred candidates or request another batch based on your assessment. You may also choose to include us in the interview process or handle it directly.',
    },
    {
      img: '../../../../assets/images/email-one-icon.webp',
      heading: 'Guarantee & Follow-up:',
      text: 'If the chosen candidate resigns or is terminated within three months due to non-performance or misconduct, we’ll replace them at no additional cost to you.',
    },
  ]

  // choose wn pointers
  choose_wn = [
    {
      title: 'Quick Turn Around: ',
      text: 'We provide you with quality candidates within 2-5 business days, ensuring fast and efficient hiring.',
    },
    {
      title: 'Vast Experience in HR Staffing: ',
      text: 'Years of knowledge entirely focused on recruitment and staffing.',
    },
    {
      title: 'Dedicated Talent Matching: ',
      text: 'A job board and in-house staff focused on finding the appropriate fit for your firm.',
    },
    {
      title: 'Affordable, Scalable Solutions: ',
      text: 'Our services are cost-effective and versatile to meet organizations of any size.',
    },
    {
      title: 'Proven Track Record: ',
      text: 'Success stories of placing top-tier candidates across numerous sectors.',
    },
  ]

  // Employers logos list
  logos = [
    '../../../../assets/images/tbo-logo.webp',
    '../../../../assets/images/zedcrest-logo.webp',
    '../../../../assets/images/aim-logo.webp',
    '../../../../assets/images/pecan-logo.webp',
    '../../../../assets/images/routelink-logo.webp',
    '../../../../assets/images/qlc-logo.webp',
    '../../../../assets/images/magna-logo.webp',
    '../../../../assets/images/manna-logo.webp',
    '../../../../assets/images/fohat-logo.webp',
    '../../../../assets/images/arise-logo.webp',
    '../../../../assets/images/richway-logo.webp',
    '../../../../assets/images/platview-logo.webp',
    '../../../../assets/images/cochlear-logo.webp',
    '../../../../assets/images/gosolve-logo.webp',
  ]

  // testimonials array
  testimonials = [
    {
      imgSrc: '../../../../assets/images/ke-icon.png',
      quote: '“I am happy to be part of this training. The facilitator did a great job and I learnt so much from him. A big thank you to Worknigeria for this opportunity”',
      stars: 4,
      companyName: 'Kelechi Eke (The Tonic Technologies)',
    },
    {
      imgSrc: '../../../../assets/images/od-icon.png',
      quote: '“WorkNigeria lives up to their name in the HR industry”',
      stars: 4,
      companyName: 'Obirai Dumebi',
    },
    {
      imgSrc: '../../../../assets/images/ao-icon.png',
      quote: '“The engagement sessions with the pool of professional facilitators were exceptional. The sessions were interactive and learner-centered.”',
      stars: 4,
      companyName: ' Adedayo Ogunmowo (PWAN Group)',
    },
    {
      imgSrc: '../../../../assets/images/ke-icon.png',
      quote: '“Worknigeria’s facilitator\'\s approach to breaking down complex terminologies, coupled with the empowering atmosphere that allowed for free participant input, made the experience incredibly enriching and engaging.”',
      stars: 4,
      companyName: 'Peace Nwachukwu',
    },
    {
      imgSrc: '../../../../assets/images/od-icon.png',
      quote: '“Worknigeria Training sessions were exceptional and highly interactive”',
      stars: 4,
      companyName: 'Michael Ezemba (Landnest Homes and Properties)',
    },
    {
      imgSrc: '../../../../assets/images/ao-icon.png',
      quote: '“Training with Worknigeria was awesome! The facilitators\'\ approach to conducting the program was commendable, especially their dedication to creating a dynamic question and answer avenue that enhanced our learning experience.”',
      stars: 4,
      companyName: 'Oshilaja Olatunji',
    },
    {
      imgSrc: '../../../../assets/images/ao-icon.png',
      quote: '“Worknigeria training was very effective and enlightening.  Your expertise and engaging approach provided valuable insights, enhancing our skills and confidence.”',
      stars: 4,
      companyName: 'Lawal Idris (Yum-Yum Cafe)',
    },
  ];

  colorClasses = ['color1', 'color2', 'color3'];

  questions = [
    {
      Ques: 'How long does the recruitment process take?',
      Ans: 'The duration varies depending on the role, however, we promise our clients 3-5 business days to deliver qualified candidates.',
      open: false,
    },
    {
      Ques: 'Can you help with high-level executive recruitment?',
      Ans: 'Yes, we undertake recruitment for all levels, including senior and executive posts.',
      open: false,
    },
    {
      Ques: 'How do you ensure the quality of candidates?',
      Ans: 'Our process includes a thorough screening, skills assessment, and basic background checks to ensure candidates meet your expectations.',
      open: false,
    },
    {
      Ques: 'Can you assist with bulk recruitment?',
      Ans: 'Absolutely! We have the capacity and expertise to handle bulk recruitment projects for organizations of any size.',
      open: false,
    },
    {
      Ques: 'What is your replacement policy if a candidate doesn’t work out?',
      Ans: 'We offer a replacement guarantee within an agreed timeframe if a placed candidate does not meet expectations.',
      open: false,
    },
    {
      Ques: 'Is the job board free for employers?',
      Ans: 'Yes, employers may register and post positions for free. Additional services like sponsored postings are also accessible at affordable rates.',
      open: false,
    },
    {
      Ques: 'What industries do you specialize in?',
      Ans: 'We hire for various industries, including technology, finance, healthcare, education, and more.',
      open: false,
    },
  ]

  constructor(
    private fb: UntypedFormBuilder,
    private dataService: DataService,
    private router: Router,
    private helper: Helper,
    private io: HttpIOService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.validationForm();
  }

  cardsPerPage = window.innerWidth < 768 ? 1 : 3; // 1 card for mobile, 3 for desktop

  ngOnDestroy() {
    window.removeEventListener('resize', this.updateCardsPerPage.bind(this));
  }

  updateCardsPerPage() {
    this.cardsPerPage = window.innerWidth < 768 ? 1 : 3; // Update based on screen width
  }

  /* Displayes distinct groups of cards on each swipe
  * returns {array} -> cardGroups array, which contains non-overlapping groups of cards
  */
  groupedCards() {
    let cardGroups = [];
    for (let i = 0; i < this.testimonials.length; i += this.cardsPerPage) {
      cardGroups.push(this.testimonials.slice(i, i + this.cardsPerPage));
    }
    return cardGroups;
  }

  /* correctly reflect the number of distinct sets of cards to be displayed, including any partial set at the end
  * @returns {number} -> Dot indicators displayed 
  */
  get totalPages() {
    return Math.ceil(this.testimonials.length / this.cardsPerPage);
  }

  /* Takes the name of the company and returns the first two initials
  * @params {string} -> The string containing the company name
  * @returns {string} -> The string of initials
  */
  getInitials(name: string): string {
    const words = name.trim().split(/\s+/);
    if (words.length >= 2) {
      const initials = words.slice(0, 2).map(word => word.charAt(0).toUpperCase()).join('');
      return initials;
    }
    return words.length > 0 ? words[0].charAt(0).toUpperCase() : '';
  }

  /*Takes the index number of the testimonials array to return the color to add behind the initials
  * @params {string} -> The string containing index number
  * @returns {string} -> The string of color to be added
  */
  getColorClass(index: number): string {
    return this.colorClasses[index % this.colorClasses.length];
  }

  validationForm() {
    this.consultForm = this.fb.group({
      fullName: ['', Validators.compose([
        Validators.required,
        Validators.pattern(this.namePattern),
      ]),],
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.emailPattern),
        ]),
      ],
      phonenumber: ['', Validators.compose([
        Validators.required,
        Validators.pattern(this.phonePattern),
      ]),],
    })
  }

  // Validation Controls //
  get f() {
    return this.consultForm.controls;
  }

  // To go to the contact us UI
  navigateToContactUs(title: any) {
    this.dataService.setData({ value: title, label: title });
    this.router.navigate(['/contactUs']);
  }

  // To show the answer to the fequently asked questions on the list
  toggleQuestion(index: number) {
    this.questions[index].open = !this.questions[index].open;
  }

  goToConsultForm(id: string) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  sendEmail() {
    if (this.consultForm.status === 'INVALID') {
      this.helper.markFormGroupTouched(this.consultForm);
      return false;
    }
    let payload =
    {
      path: '/brochure-email',
      message: `I want to book a free consultation - ${this.consultForm.value.phonenumber}`,
      email: this.consultForm.value.email,
      subject: `Free Consultation Request - ${this.consultForm.value.fullName}`,
    }
    this.io._brochureEmail(payload).subscribe((res: any) => {
      if (res.serverResponse.code === 200) {
        let successData = "about us email"
        const dialogRef = this.dialog.open(PopupConfirmationComponent, {
          height: 'auto',
          width: 'auto',
          autoFocus: false,
          data: {
            data: successData,
          },
        });
        dialogRef.afterClosed().subscribe();
        this.router.navigate(['/home']);
      } else {
        this.snackBar
          .openFromComponent(DynamicSnacbarErrorComponent, {
            data: res.serverResponse.message,
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            duration: 6000,
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
      }
    })
  }
}
