import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
  NgZone,
  ɵɵqueryRefresh,
} from '@angular/core';
import { MatDialog as MatMaterialDialog, MatDialogRef} from '@angular/material/dialog';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { HttpIOService } from 'src/app/services/http-io.service';
import { SavedjobComponent } from '../savedjob/savedjob.component';
import { SharejobComponent } from '../sharejob/sharejob.component';
import { Title } from '@angular/platform-browser';
import { stringify } from 'querystring';
import { Subject } from 'rxjs';
import { Observable, Subscription, interval } from 'rxjs';
import { SaveWithoutLoginComponent } from '../save-without-login/save-without-login.component';
import { AnonymousUserComponent } from '../anonymous-user/anonymous-user.component';
import { ViaEmailComponent } from '../via-email/via-email.component';
import { ViaExternalUrlComponent } from '../via-external-url/via-external-url.component';
import { ViaWorkNigeriaComponent } from '../via-work-nigeria/via-work-nigeria.component';
import { environment } from '../../../../environments/environment';
import { ReportJobComponent } from '../report-job/report-job.component';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { DynamicSnacbarSkipComponent } from 'src/app/container/elements/dynamic-snacbar-skip/dynamic-snacbar-skip.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { EmployerService } from '../../../services/employer.service';
import { takeWhile, scan, tap } from 'rxjs/operators';
import { interval as observableInterval } from 'rxjs';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { DataService } from 'src/app/services/data.service';
import { UntypedFormGroup } from '@angular/forms';
import { CreateAlertComponent } from '../../jobseeker/create-alert/create-alert.component';
import { RestrictedJobViewModal } from 'src/app/modals/restricted-job-view-modal/restricted-job-view-modal.component';
import { PaginationService } from '../../../services/paginationService';

@Component({
  selector: 'app-job-filter-list',
  templateUrl: './job-filter-list.component.html',
  styleUrls: ['./job-filter-list.component.scss'],
})
export class JobFilterListComponent implements OnInit {
  @ViewChild('job-description') jobDescription: ElementRef;
  @ViewChild('jobsList') jobsList: ElementRef;
  status: boolean = false;
  jobLists: any = [];
  totalNoJobs = 0;
  detailsOfJob: any;
  shareJobId: any = '';
  shareJobCollection: any;
  searchDipendency: any;
  userDeatils: any;
  companyData: any;
  filterData: any;
  collectionSize: number;
  refresh: Subject<any> = new Subject();
  payLoad: any = {};
  userIdByurl: any;
  collectionIdByurl: any;
  viewJobPayLoad: any = {};
  guestUserJobId: any;
  allHotJobs: any;
  showReportSection: boolean;
  JobsByCategory: any;
  nojob: boolean = false;
  redirectForGuestSelectJob: any;
  collectionRecords: any;
  LogoutStateSaveJob: any;
  showRightSectionInMobile: boolean = false;
  isExpriedButton: boolean = false;
  countOfApplied: any;
  title: any;
  appliedStatus: any;
  dateDiff: number;
  clickSection: any = '';
  eachJobCompanyDetails: any;
  allRelatedJob: any;
  searchPhrase = '';

  // For Creating Auto Alert
  email?: string;
  createAlartForm: UntypedFormGroup;

  // MatPaginator Inputs
  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  // MatPaginator Output
  pageEvent: PageEvent;
  //

  sum = 50;
  throttle = 400;
  scrollDistance = 1;
  scrollUpDistance = 2;
  direction = '';
  startJobIndex: number = 0;
  jobAppliedButtonDisable: boolean = true;
  currentSectionagfeasgesrg: any = 'section1';

  jobDesc = '';
  qualifications = '';
  currentPage: number;

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private io: HttpIOService,
    private auth: AuthService,
    private helper: Helper,
    private dialog: MatDialog,
    private materialDialog: MatMaterialDialog,
    private titleService: Title,
    public loaderService: NgxSpinnerService,
    private EmployerService: EmployerService,
    private dataService: DataService,
    private zone: NgZone,
    private paginationService: PaginationService
  ) {
    this.currentPage = this.paginationService.getCurrentPage();
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    if (localUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
      // console.log(this.userDeatils)
    }
    if (sessionUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }
    let guestUserByselected = sessionStorage.getItem('guestUserSelectedJob');
    if (guestUserByselected) {
      this.guestUserJobId = JSON.parse(
        atob(sessionStorage.getItem('guestUserSelectedJob'))
      );
    }

    this.activeRoute.queryParams.subscribe((event) => {
      this.shareJobId = event['id'];
      this.collectionIdByurl = event['collectionId'];
      this.userIdByurl = event['userId'];
      this.allHotJobs = event['job_type_Home'];
      this.JobsByCategory = event['job_type_Category'];
      this.redirectForGuestSelectJob = event['role'];
      this.title = event['title'];
    });
    this.io.getforjobCategory().subscribe((res) => {
      // console.log(res);

      if (res != null) {
        // console.log(res);
        this.filterData = res;
        this.apiJobSearch(this.filterData);
      }
    });
    this.EmployerService.getloadMoreInSearchJob().subscribe((res) => {
      console.log(res);

      if (res === 'loadJobInSearchPage') {
        // console.log(res);
        // this.onScrollDown();
      }
    });
  }

  alert_off = true;
  alert_on = false;

  alertOn() {
    this.alert_on = true;
    this.alert_off = false;
  }
  alertOff() {
    this.alert_off = true;
    this.alert_on = false;
  }

  ngOnInit(): void {
    this.paginationService.currentPage$.subscribe(page => {
      this.currentPage = page;
    });

    if (this.userDeatils) {
      this.getCollection();
      this.payLoad = {
        path: 'search-job',
        userId: this.userDeatils.id,
        size: 10,
        start: 0,
      };
    } else {
      this.payLoad = {
        path: 'search-job',
        size: 10,
        start: 0,
      };
    }

    if (this.collectionIdByurl) {
      this.apiJobSearch({
        path: 'search-job',
        userId: this.userIdByurl,
        collectionId: this.collectionIdByurl,
      });
    }

    if (this.shareJobId) {
      this.apiJobSearch({
        path: 'search-job',
        id: this.shareJobId,
      });
     
    }
    if (this.allHotJobs) {
      this.apiJobSearch({
        path: 'search-job',
        job_type: String(this.allHotJobs),
      });
    }

    if (this.JobsByCategory) {
      this.apiJobSearch({
        path: 'search-job',
        category_id: this.JobsByCategory,
      });
    }
    if (
      !this.shareJobId &&
      !this.collectionIdByurl &&
      !this.filterData &&
      !this.allHotJobs &&
      !this.JobsByCategory
    ) {
      this.apiJobSearch(this.payLoad);
    }

    this.dataService.sharedData.subscribe((data) => (this.searchPhrase = data));
    // Timer to move the ads after 5 seconds
    setTimeout(() => {
      const adsContainer = document.querySelector('.ads-container');
      if (adsContainer) {
        const cardWidth = adsContainer.querySelector('.ads-card').clientWidth;
        const totalCards = adsContainer.querySelectorAll('.ads-card').length;
        const scrollDistance = cardWidth * totalCards;
        let currentPosition = 0;
        setInterval(() => {
          currentPosition = (currentPosition + cardWidth) % scrollDistance;
          adsContainer.scroll({
            left: currentPosition,
            behavior: 'smooth',
          });
        }, 7000);
      }
    }, 1000);
  }

  openErrorDialog(): void {
    const dialogRef = this.materialDialog.open(RestrictedJobViewModal, {
      height: '520px',
      width: '500px',
      disableClose: true,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(() => {
      console.log('The dialog was closed');
    });
  }

  splitDescription() {
    const description = this.detailsOfJob?.description || '';

    const keyword = this.detectKeyword(description);

    if (keyword) {
      const parts = description.split(keyword);
      this.jobDesc = parts[0];
      this.qualifications = parts[1];
    } else {
      // Handle the case where the keyword is not found
      this.jobDesc = description;
      this.qualifications = '';
    }
  }

  detectKeyword(description: string): string | null {
    const possibleKeywords = ["Requirements:", "Requirement:", "Requirement", "Requirements", "Qualifications", "Qualifications:"];
    for (const keyword of possibleKeywords) {
      if (description.includes(keyword)) {
        return keyword;
      }
    }
    return ''; // Keyword not found
  }

  scroll() {
    document.getElementById('qualifications').scroll(0, 0);
  }
  // CLICK ON JOB LIST //
  jobDetails(i, job) {
    this.titleService.setTitle(job.title);
    // console.log('title',this.titleService.getTitle)
    this.showRightSectionInMobile = true;
    this.LogoutStateSaveJob = job;
    this.status = job.id;
    this.detailsOfJob = this.jobLists[i];
    this.splitDescription();
    this.jobExpried(this.detailsOfJob);
    this.getCompanyDetailsPerticularJob(this.detailsOfJob);
    this.relatedJob(this.detailsOfJob);
    this.reportJob();
    this.viewJob(job);
    // this is to move thee scroll bar up on top of the section when new job is selected and remove the highlight
    // this.scrollToElement('topOfDesc');
  }

  // CLICK ON JOB LIST //
  jobDetailsRelated(i, job) {
    // console.log(job)

    this.showRightSectionInMobile = true;
    this.LogoutStateSaveJob = job;
    this.status = job.id;
    this.detailsOfJob = this.allRelatedJob[i];
    this.jobExpried(this.detailsOfJob);
    this.getCompanyDetailsPerticularJob(this.detailsOfJob);
    this.relatedJob(this.detailsOfJob);
    this.reportJob();
    this.viewJob(job);
  }

  viewJob(job) {
    if (this.userDeatils?.id == undefined || '') {
      this.viewJobPayLoad = {
        path: 'view-job',
        id: job.id,
      };
    } else {
      this.viewJobPayLoad = {
        path: 'view-job',
        id: job.id,
        userId: this.userDeatils?.id,
      };
    }
    this.io._viewJob(this.viewJobPayLoad).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        this.countOfApplied = res['result'].applied;
      }
    });

    // if (res.serverResponse.code == 200) {
    //   this.countOfApplied = res.result.applied;
    // if (res.serverResponse.code == 200) {
    //   this.countOfApplied = res.result.applied;

    // }
    // }
    // ();
    // }
    // }
    // ();
  }

  jobExpried(job) {
    this.appliedStatus = '';
    if (job.expired_at) {
      this.dateDiff = this.helper.calculateDiff(
        new Date(job.expired_at),
        new Date()
      );
      if (this.dateDiff >= 0) {
        this.isExpriedButton = false;
      } else {
        this.isExpriedButton = true;
      }
    }
    if (this.userDeatils) {
      if (this.userDeatils.userType == 'employer') {
        if (this.companyData.id == job.company_id) {
          this.jobAppliedButtonDisable = false;
        }
      }
    }
    // var hours = Math.abs(new Date(job.expired_at).getTime() - new Date().getTime()) / 36e5;
    // console.log(this.companyData.id , job.company_id)
  }

  mobileViewClassClose() {
    this.showRightSectionInMobile = false;
    this.detailsOfJob = null;
  }
  // EVENT EXPAIRE TIME //
  eventExpireIn(eventDt: string | number | Date) {
    // const dateDiff = this.helper.calculateDiff(new Date(), new Date(eventDt));
    const currentDate = new Date();
    const eventDate = new Date(eventDt);

    const timeDiff = Math.abs(currentDate.getTime() - eventDate.getTime());
    const hoursDiff = Math.floor(timeDiff / (1000 * 60 * 60));

    if (hoursDiff < 24) {
      if (hoursDiff === 0) {
        const minutesDiff = Math.floor(timeDiff / (1000 * 60));
        return `Posted ${minutesDiff}m`;
      } else {
        return `Posted ${hoursDiff}h`;
      }
    } else {
      const daysDiff = Math.floor(hoursDiff / 24);
      if (daysDiff === 1) {
        return `Posted ${daysDiff} day`;
      } else {
        return `Posted ${daysDiff} days`;
      }
    }
  }
  //****************************************************************** */
  onSectionChange(sectionId: any) {
    this.clickSection = sectionId;
  }

  scrollToElement(id: any) {
    this.clickSection = id;
    // console.log("element id : ", id);
    setTimeout(() => {
      // document.body.scrollTop = 0;
      // window.scrollTo(0,0);
      const element = <HTMLElement>document.querySelector(`#${id}`);
      element.scrollIntoView({ behavior: 'smooth' });
    });
  }
  //****************************************************************** */
  async scrollToHead() {
    await window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    //  window.scrollTo(0,0);
  }

  swipeRelatedJobs() {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    const relatedSection = document.querySelector('#related');
    if (relatedSection) {
      relatedSection.scrollIntoView({ behavior: 'smooth' });

      const firstCard = relatedSection.querySelector('.card-grid');
      if (firstCard) {
        firstCard.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  async onScrollJobChange(id: any) {
    this.clickSection = id;
    // console.log("element id : ", id);
    if (window.innerWidth > 570) {
      setTimeout(() => {
        this.scrollToHead();
      }, 500);
      this.swipeRelatedJobs();
    }
    const element = <HTMLElement>document.querySelector(`#${id}`);
    element.scrollIntoView({ behavior: 'smooth' });
  }
  //infinite scroll
  onScrollDown() {
    // console.log("scrolled down!!", this.payLoad);
    this.startJobIndex = this.startJobIndex + 10;
    if (
      !this.shareJobId &&
      !this.collectionIdByurl &&
      !this.filterData &&
      !this.allHotJobs &&
      !this.JobsByCategory
    ) {
      this.payLoad.start = this.startJobIndex;
      // console.log(this.payLoad)
      this.io
        .apicall(this.payLoad, 'jobsearch/search-job')
        .subscribe((res: any) => {
          if (res.serverResponse.code == 200) {
            let data = res['result'].jobList;
            this.totalNoJobs = res['pagination']?.total;
            this.jobLists.push(...data);
          }
        });
    } else {
      this.filterData.start = this.startJobIndex;
      // console.log(this.filterData)
      this.io
        .apicall(this.filterData, 'jobsearch/search-job')
        .subscribe((res: any) => {
          if (res.serverResponse.code == 200) {
            let data = res['result'].jobList;
            this.totalNoJobs = res['pagination']?.total;
            this.jobLists.push(...data);
          }
        });
    }
    this.EmployerService.setloadMoreInSearchJob(null);
  }

  onPageChanged(pageNumber) {
    this.paginationService.setCurrentPage(pageNumber);
    this.startJobIndex = (pageNumber - 1) * this.pageSize;
    if (
      !this.shareJobId &&
      !this.collectionIdByurl &&
      !this.filterData &&
      !this.allHotJobs &&
      !this.JobsByCategory
    ) {
      this.payLoad.start = this.startJobIndex;
      // console.log(this.payLoad)
      this.io
        .apicall(this.payLoad, 'jobsearch/search-job')
        .subscribe((res: any) => {
          if (res.serverResponse.code == 200) {
            let data = res['result'].jobList;
            this.totalNoJobs = res['pagination']?.total;
            this.jobLists = data;
          }
        });
    } else {
      this.filterData.start = this.startJobIndex;
      // console.log(this.filterData)
      this.io
        .apicall(this.filterData, 'jobsearch/search-job')
        .subscribe((res: any) => {
          if (res.serverResponse.code == 200) {
            let data = res['result'].jobList;
            this.totalNoJobs = res['pagination']?.total;
            this.jobLists = data;
          }
        });
    }
    this.EmployerService.setloadMoreInSearchJob(null);
    this.scrollToTop(this.jobsList.nativeElement);
  }

  scrollToTop(el) {
    const duration = 400;
    const interval = 5;
    const move = (el.scrollTop * interval) / duration;
    observableInterval(interval)
      .pipe(
        scan((acc, curr) => acc - move, el.scrollTop),
        tap((position) => (el.scrollTop = position)),
        takeWhile((val) => val > 0)
      )
      .subscribe();
  }

  // onUp(ev) {
  //   console.log("scrolled up!", ev);
  //   const start = this.sum;
  //   this.sum += 20;
  //   this.prependItems(start, this.sum);

  //   this.direction = "up";
  // }

  // addItems(startIndex, endIndex, _method) {
  //   for (let i = 0; i < this.sum; ++i) {
  //     this.array[_method]([i, " ", this.generateWord()].join(""));
  //   }
  // }

  // appendItems(startIndex, endIndex) {
  //   this.addItems(startIndex, endIndex, "push");
  // }

  // prependItems(startIndex, endIndex) {
  //   this.addItems(startIndex, endIndex, "unshift");
  // }

  // Saved job //
  savedJob(job) {
    if (this.userDeatils?.id == undefined || '') {
      const dialogRef = this.dialog.open(SaveWithoutLoginComponent, {
        height: 'auto',
        width: '450px',
        data: { Perticularjob: job, hint: 'logout-save-job' },
        autoFocus: false,
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res == 'save') {
          // console.log("++++++++++++")
        }
      });
    } else {
      if (job.isSaved == true) {
        let jsonData = {
          path: 'unsaved-job',
          jobId: job.id,
          id: this.userDeatils.id,
        };
        //  console.log(jsonData);
        this.io._unSaveJob(jsonData).subscribe((res: any) => {
          //  console.log(res);
          if (res.serverResponse.code == 200) {
            this.snackBar
              .openFromComponent(DynamicSnacbarComponent, {
                data: res.serverResponse.message,
                verticalPosition: 'top', // 'top' | 'bottom'
                horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
              })
              .afterOpened()
              .subscribe(this.helper.setOnTop);
            if (this.filterData) {
              this.apiJobSearch(this.filterData);
            } else {
              this.apiJobSearch({
                path: 'search-job',
                userId: this.userDeatils.id,
              });
            }
          } else {
            this.snackBar
              .openFromComponent(DynamicSnacbarComponent, {
                data: res.serverResponse.message,
                verticalPosition: 'top', // 'top' | 'bottom'
                horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
              })
              .afterOpened()
              .subscribe(this.helper.setOnTop);
            if (this.filterData) {
              this.apiJobSearch(this.filterData);
            } else {
              this.apiJobSearch({
                path: 'search-job',
                userId: this.userDeatils.id,
              });
            }
            // this.apiJobSearch({ path: "search-job", userId: this.userDeatils.id });
          }
        });
      } else {
        const dialogRef = this.dialog.open(SavedjobComponent, {
          height: '90%',
          // width: '450px',
          autoFocus: false,
          data: { data: job },
        });
        dialogRef.afterClosed().subscribe((res) => {
          if (res == 'save') {
            if (this.filterData) {
              // console.log(this.filterData)
              this.apiJobSearch(this.filterData);
            } else {
              this.apiJobSearch({
                path: 'search-job',
                userId: this.userDeatils.id,
              });
            }
          }
        });
      }
    }
  }
  // without login if any one click save button

  // Share job //
  shareJob(job) {
    const dialogRef = this.dialog.open(SharejobComponent, {
      height: 'auto',
      width: '450px',
      data: { data: job },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res === true) {
        return;
      }
    });
  }

  getCollection() {
    let jsonData = {
      id: this.userDeatils?.id,
      path: 'collection-list',
    };
    this.io._getCollection(jsonData).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        // console.log(res);
        this.collectionRecords = res['result'].collectionList;
        // console.log( this.collectionRecords)
      }
    });
  }

  relatedJob(job) {
    // console.log(job)
    let jsonData = {
      path: 'related-jobs',
      jobIds: [job.id],
      searchText: job.title,
      category_id: job.category_id,
    };
    // console.log(jsonData);
    this.io._relatedJob(jsonData).subscribe((res: any) => {
      // console.log(res);
      if (res.serverResponse.code == 200) {
        if (res['result'].jobList.length > 0) {
          this.allRelatedJob = res['result'].jobList;
        } else {
          // this.EmployerService._AllHotNewFeatureJob({
          //   path: 'hot-new-featured-jobs',
          //   userId: '',
          //   limit: 5,
          // }).subscribe((res: any) => {
          //   if (res.serverResponse.code == 200) {
          //     this.allRelatedJob = res['result'].hotJobs;
          //   }
          // });
        }
      }
    });
  }

  apiJobSearch(data: any) {
    this.loaderService.show();
    this.io.apicall(data, 'jobsearch/search-job').subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        this.jobLists = res['result'].jobList;
        this.pageSize = this.pageSize - res['pagination']?.promotedJobs
        this.collectionSize = res['pagination']?.total
          ? res['pagination']?.total
          : 0; // TODO: 100 is default value for now
        // this.collectionSize = this.collectionSize * (this.pageSize/res['pagination']?.promotedJobs)
        this.totalNoJobs = res['pagination']?.total;
        this.startJobIndex = res['pagination']?.start;
        if (this.jobLists.length == 0) {
          this.nojob = true;
          this.detailsOfJob = null;
        }
        if (this.jobLists.length > 0) {
          this.viewJob(this.jobLists[0]);
          this.jobDetails(0, this.jobLists[0]);
          this.nojob = false;
          if (this.guestUserJobId) {
            let selectedJObByGuestUser = this.jobLists.filter(
              (event) => event.id == this.guestUserJobId
            );
            this.detailsOfJob = selectedJObByGuestUser[0];
            this.jobExpried(this.detailsOfJob);
            this.relatedJob(this.detailsOfJob);
            this.getCompanyDetailsPerticularJob(this.detailsOfJob);
            this.status = selectedJObByGuestUser[0].id;
            //save job in default collection
            this.loaderService.hide();
            let jsonData = {
              path: 'saved-job',
              jobId: this.detailsOfJob.id,
              id: this.userDeatils.id,
              collectionId: this.collectionRecords[0].id,
              jobProviderId: this.detailsOfJob.job_provider_id,
              title: this.detailsOfJob.title,
              jobSeekerName: `${this.userDeatils.firstName} ${this.userDeatils.lastName}`,
              companyId: this.detailsOfJob.company_id,
            };
            // console.log(jsonData);
            this.io._saveJob(jsonData).subscribe((res: any) => {
              if (res.serverResponse.code == 200) {
                sessionStorage.removeItem('guestUserSelectedJob');
                this.guestUserJobId = undefined;
                this.detailsOfJob.isSaved = true;
              }
            });
          } else if (this.redirectForGuestSelectJob) {
            let selectedJObByGuestUser = this.jobLists.filter(
              (event) => event.id == this.guestUserJobId
            );
            this.detailsOfJob = selectedJObByGuestUser[0];
            this.jobExpried(this.detailsOfJob);
            this.getCompanyDetailsPerticularJob(this.detailsOfJob);
            this.relatedJob(this.detailsOfJob);
            this.status = selectedJObByGuestUser[0].id;
            this.loaderService.hide();
          } else {
            // this.detailsOfJob = null;
            // console.log(this.detailsOfJob)
            // this.jobExpried(this.detailsOfJob);
            // this.getCompanyDetailsPerticularJob(this.detailsOfJob);
            // this.relatedJob(this.detailsOfJob);
            this.status = this.jobLists[0].id;
            this.loaderService.hide();
          }
          sessionStorage.removeItem('guestUserSelectedJob');
          this.reportJob();
        }
      } else {
        if(res.serverResponse.message === '3MTT Jobs can only be viewed by registered 3MTT fellows'){
          this.openErrorDialog()
        }
        this.snackBar
          .openFromComponent(DynamicSnacbarErrorComponent, {
            data: res.serverResponse.message,
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            duration: 6000,
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
      }
    });
    // .catch(err => {
    //   if (err) {
    //     this.snackBar.open("No record found ", "ok")
    //   }
    //   else {
    //     this.snackBar.open(err.error.serverResponse.message, "OK", {
    //     })
    //   }
    // })
  }

  getCompanyDetailsPerticularJob(job) {
    let jsonData = {
      path: 'get-company',
      company_id: job.company_id,
    };
    this.io._getCompanyDetails(jsonData).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        // console.log(res);
        this.eachJobCompanyDetails = res['result'].company;
      }
    });
  }

  draftJob(Perticularjob: any) {
    // console.log(Perticularjob);
    let jsonData = {
      path: 'draft-job',
      userId: this.userDeatils.id,
      jobId: Perticularjob.id,
      activityType: '',
    };
    if (Perticularjob.apply_via == '1') {
      jsonData.activityType = environment.activityType.via_workNigeria;
    } else if (Perticularjob.apply_via == '2') {
      jsonData.activityType = environment.activityType.via_url;
    } else if (Perticularjob.apply_via == '3') {
      jsonData.activityType = environment.activityType.via_email;
    }
    // console.log(jsonData)
    this.io._draftJob(jsonData).subscribe((res: any) => {
      // console.log(res);
      if (res.serverResponse.code == 200) {
        // this.snackBar.open(res.serverResponse.message, "OK", {
        // })
      }
    });
  }

  async applyJob(Perticularjob: any) {
    if (Perticularjob.apply_via == '3') {
        const email_subject = `Application for the position: ${Perticularjob.title}`
        const mailtoLink = `mailto:${Perticularjob.via_email}?subject=${encodeURIComponent(email_subject)}`;
        const anchor = document.createElement('a');
        anchor.href = mailtoLink;
        anchor.click();
        let jsonData = {
          path: 'apply-job-via-email',
          jobId: Perticularjob.id,
        };
        this.io._applyViaEmail(jsonData).subscribe((res: any) => {
          this.detailsOfJob.isApplied = true;
          this.detailsOfJob.applied_at = new Date();
        });
      }
      else {
        if (this.userDeatils?.id != undefined || '') {
          await this.draftJob(Perticularjob);
        }
    
        if (this.userDeatils?.id == undefined || '') {
          // console.log("+++++");
          const dialogRef = this.dialog.open(AnonymousUserComponent, {
            height: '650px',
            width: '550px',
            autoFocus: false,
            data: { Perticularjob },
          });
          dialogRef.afterClosed().subscribe((res) => {
            if (res == 'save') {
            }
          });
        } else {
           if (Perticularjob.apply_via == '2') {
            const dialogRef = this.dialog.open(ViaExternalUrlComponent, {
              height: '650px',
              width: '450px',
              data: { Perticularjob },
              autoFocus: false,
            });
            dialogRef.afterClosed().subscribe((res) => {
              if (res == 'save') {
                console.log('++++++++++++');
                // this.ngOnInit();
                this.detailsOfJob.isApplied = true;
                this.detailsOfJob.applied_at = new Date();
              }
            });
          } else if (Perticularjob.apply_via == '1') {
            const dialogRef = this.dialog.open(ViaWorkNigeriaComponent, {
              height: '650px',
              width: '550px',
              data: { Perticularjob },
              autoFocus: false,
            });
            dialogRef.afterClosed().subscribe((res) => {
              if (res == 'save') {
                console.log('++++++++++++');
                // this.ngOnInit();
                this.detailsOfJob.isApplied = true;
                this.detailsOfJob.applied_at = new Date();
              }
            });
          }
        }
      }
  }

  alreadyApply() {
    // this.snackBar.open("You have Already Applied", "OK")
    this.snackBar
      .openFromComponent(DynamicSnacbarSkipComponent, {
        data: 'You have Already Applied',
        verticalPosition: 'top', // 'top' | 'bottom'
        horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
      })
      .afterOpened()
      .subscribe(this.helper.setOnTop);
  }

  reportJob() {
    this.showReportSection = false;
    if (this.userDeatils?.id != undefined) {
      this.showReportSection = true;
    }
  }

  reportModel(Perticularjob: any) {
    const dialogRef = this.dialog.open(ReportJobComponent, {
      height: 'auto',
      width: '450px',
      data: { Perticularjob },
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res == 'save') {
        // console.log("++++++++++++")
        // this.ngOnInit();
      }
    });
  }

  navigateToContactUs(title: any) {
    this.dataService.setData({ value: title, label: title });
    this.router.navigate(['/contactUs']);
  }

  redirectToInspireAfricaSignup() {
    window.open('https://inspireafricaconference.com', '_blank');
  }

  navigateToCvReview() {
    this.router.navigate(['/cv-review']);
  }

  clearJobDetails() {
    this.detailsOfJob = null;
    this.showRightSectionInMobile = false;
  }

  createAlert() {
    // to check if the user is logged in or not
    if (this.userDeatils) {
      // Create a JSON object with the alert data.
      let jsonData = {
        path: this.email != null ? 'set-alert-visitor' : 'set-alert',
        alertName: this.searchPhrase,
        userId: this.email != null ? '' : this.userDeatils.id,
        keywords: this.searchPhrase,
        preferredLocation: '',
        categoryId: '',
        employmentTypeId: '',
        employmentType: '',
        jobCategory: '',
        minimumSalary: '',
        maximumSalary: '',
        emailFrequency: 'weekly',
        email: this.email,
      };

      // Call the io._setAlert() or io._setAlertVisitor() method.
      if (this.email != null) {
        this.io._setAlertVisitor(jsonData).subscribe((res: any) => {
          if (res.serverResponse.code == 200) {
            // Display a snackbar message.
            this.snackBar.openFromComponent(DynamicSnacbarComponent, {
              data: res.serverResponse.message,

              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            });
          }
        });
      } else {
        this.io._setAlert(jsonData).subscribe((res: any) => {
          if (res.serverResponse.code == 200) {
            // Display a snackbar message.
            this.snackBar.openFromComponent(DynamicSnacbarComponent, {
              data: res.serverResponse.message,

              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            });
          }
        });
      }
    } else {
      this.router.navigate(['/login']);
      sessionStorage.setItem('setUrlRedirection', 'job-search');
      sessionStorage.setItem('searchTerm', this.searchPhrase);
    }
  }

  ngOnDestroy() {
    this.titleService.setTitle('WorkNigeria');
  }
  // onSectionChange(sectionId: any) {
  //   console.log(sectionId)
  //   this.clickSection = sectionId;
  // }

  // scrollTo(section) {
  //   document.querySelector('#' + section)
  //   .scrollIntoView();
  // }

  // @HostListener('window:scroll', ['$event']) // for window scroll events
  // onScroll(event) {
  //   // if((window.innerHeight + window.scrollY) >= document.body.offsetHeight){
  //     // this.clickSection ='';
  //   //   console.log(event)
  //   // }(scroll)="onScroll($event)"
  //   console.log(event)
  // }
  // @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  // scrollToBottom(): void {
  //   try {
  //     this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
  //   } catch (err) { }
  // }
}
