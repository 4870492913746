import { Component } from '@angular/core';
import { EmployerService } from 'src/app/services/employer.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { Helper } from 'src/app/helper/defult';
import { PopupConfirmationComponent } from 'src/app/container/elements/popup-confirmation/popup-confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { HttpIOService } from 'src/app/services/http-io.service';

@Component({
  selector: 'app-upload-cv',
  templateUrl: './upload-cv.component.html',
  styleUrls: ['./upload-cv.component.scss']
})
export class UploadCvComponent {
  uploadProgress = 0;

  constructor(
    private empService: EmployerService,
    private snackBar: MatSnackBar,
    private helper: Helper,
    public dialog: MatDialog,
    private io: HttpIOService,
  ) { }

  // Takes the file added as input and uploads it
  files: { file: File, name: string, progress: number }[] = [];
  fileToUpload = [];

  onFilesSelected(event: any): void {
    console.log(this.files)
    const fileInput = event.target;
    if (fileInput.files.length > 0) {
      if (this.files.length === 5) {
        let successData = 'less CVs'
        const dialogRef = this.dialog.open(PopupConfirmationComponent, {
          height: 'auto',
          width: 'auto',
          autoFocus: false,
          data: {
            data: successData,
          },
        });
        dialogRef.afterClosed().subscribe();
      }
      for (let i = 0; i < fileInput.files.length && this.files.length < 5; i++) {
        const file = fileInput.files[i];
        this.files.push({ file, name: file.name, progress: 0 });
        this.uploadFile(this.files.length - 1);
      }
      fileInput.value = '';
    }
  }

  // To trigger the progress bar on file upload
  uploadFile(index: number): void {
    const interval = setInterval(() => {
      this.files[index].progress += 10;
      if (this.files[index].progress >= 100) {
        clearInterval(interval);
      }
    }, 1000);
  }

  removeFile(fileToRemove: { file: File, name: string, progress: number }): void {
    this.files = this.files.filter(file => file !== fileToRemove);
  }

  async saveCVs() {
    // Uploads all the CVs one by one
    for (const fileObj of this.files) {
      const formData = new FormData();
      formData.append('path', 'upload-file');
      formData.append('purpose', 'parseResume');
      formData.append('file', fileObj.file);

      await this.io._uploadFile(formData).subscribe(
        (res: any) => {
          this.fileToUpload.push(res['result'].id+'_'+res['result'].fileName);
          if(this.fileToUpload.length == this.files.length){
            // Takes all the CVs and parse them
            let payLoad = {
              path: "/admin/resume-parser",
              files: this.fileToUpload.map(file => ({
                Key: file,
              }))
            }
            this.empService._parseResumes(payLoad).subscribe((res: any) => {
              if (res.statusCode == 200) {
                this.files = [];
                this.fileToUpload = [];
                let successData = "saved CVs"
                const dialogRef = this.dialog.open(PopupConfirmationComponent, {
                  height: 'auto',
                  width: 'auto',
                  autoFocus: false,
                  data: {
                    data: successData,
                  },
                });
                dialogRef.afterClosed().subscribe();
              } else {
                this.snackBar
                  .openFromComponent(DynamicSnacbarErrorComponent, {
                    data: res.body,
                    verticalPosition: 'top', // 'top' | 'bottom'
                    horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
                    duration: 6000,
                  })
                  .afterOpened()
                  .subscribe(this.helper.setOnTop);
              }
            });
          }
        },
        (err) => console.log(err)
      );
    }

  }
}
