import { Component, OnInit } from '@angular/core';
import { BackgroundCheckFormComponent } from 'src/app/container/elements/background-check-form/background-check-form.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-manpower-outsourcing',
  templateUrl: './manpower-outsourcing.component.html',
  styleUrls: ['./manpower-outsourcing.component.scss']
})
export class ManpowerOutsourcingComponent implements OnInit {
  // Employers logos list
  logos = [
    '../../../../assets/images/tbo-logo.webp',
    '../../../../assets/images/zedcrest-logo.webp',
    '../../../../assets/images/aim-logo.webp',
    '../../../../assets/images/pecan-logo.webp',
    '../../../../assets/images/routelink-logo.webp',
    '../../../../assets/images/qlc-logo.webp',
    '../../../../assets/images/magna-logo.webp',
    '../../../../assets/images/manna-logo.webp',
    '../../../../assets/images/fohat-logo.webp',
    '../../../../assets/images/arise-logo.webp',
    '../../../../assets/images/richway-logo.webp',
    '../../../../assets/images/platview-logo.webp',
    '../../../../assets/images/cochlear-logo.webp',
    '../../../../assets/images/gosolve-logo.webp',
  ];

  // List of benefits 
  items = [
    {
      title: 'Cost Savings',
      text: 'Reduce overheads on recruitment, training, and employee benefits.',
    },
    {
      title: 'Focus on core businesess',
      text: 'Free up your internal resources to concentrate on strategic business goals.',
    },
    {
      title: 'Risk Migration',
      text: 'We handle the complexities of employment, from compliance to payroll, reducing your risk.',
    },
    {
      title: 'Access to wild Talent Pool',
      text: 'Whether you need temporary staff or long-term placements, we provide access to a broad network of pre-screened professionals.',
    },
  ];

  // outsource list 
  confidence = [
    {
      title: 'Industry Expertise',
      text: 'With vast experience in manpower deployment, we understand your industry’s specific staffing needs.',
    },
    {
      title: 'Speed and Flexibility',
      text: 'Quickly scale your workforce up or down without the lengthy recruitment process.',
    },
    {
      title: 'Advance Search Filter',
      text: 'Easily narrow down candidates by skills, experience, location, and more.',
    },
  ]
  constructor(public dialog: MatDialog,) {}

  ngOnInit(): void {}

  openForm() {
    const dialogRef = this.dialog.open(BackgroundCheckFormComponent, {
      height: 'auto',
      width: '761px',
      autoFocus: false,
      data: 'manpower page'
    });
    dialogRef.afterClosed().subscribe();
  }
}
