<div class="background-check-form-container">
    <div class="close-icon" (click)="onNoClick()">
        <span class="close"><a>&#10006;</a></span>
    </div>
    <p class="consult-text">
        Book a free consultation
    </p>
    <form [formGroup]="userDetailsForm" class="user-form">
        <!-- Input Field For Full Name -->
        <label class="input-label">Full Name</label>
        <div class="input-container">
            <input type="text" placeholder="enter first name and last name" class="form-control" formControlName="fullName">
        </div>
        <div *ngIf="f['fullName'].touched && f['fullName'].invalid" class="invaild-div">
            <span class="text-left text-danger pb-1" *ngIf="f['fullName'].errors?.['required']">
                Name is required
            </span>
            <span class="text-left text-danger pb-1" *ngIf="f['fullName'].errors?.['pattern']">
                Please enter a valid name.
            </span>
        </div>
        <!-- Input Field For Email -->
        <label class="input-label">Email</label>
        <div class="input-container">
            <input type="email" placeholder="example@company.com" class="form-control" formControlName="email">
        </div>
        <div *ngIf="f['email'].touched && f['email'].invalid" class="invaild-div">
            <span class="text-left text-danger pb-1" *ngIf="f['email'].errors?.['required']">Email is required.
            </span>
            <span class="text-left text-danger pb-1" *ngIf="f['email'].errors?.['pattern']">
                Please enter a valid email.
            </span>
        </div>
        <!-- Input Field For Phone Number -->
        <label class="input-label">Phone Number</label>
        <div class="input-container">
            <input type="text" placeholder="+234 xxx xxxx xxxx" class="form-control" formControlName="phoneNumber">
        </div>
        <div
        *ngIf="f['phoneNumber'].touched && f['phoneNumber'].invalid"
        class="invaild-div">
            <span
                class="text-left text-danger pb-1"
            *ngIf="f['phoneNumber'].errors?.['required']"
            >Phone Number is required.
            </span>
            <span
                class="text-left text-danger pb-1"
            *ngIf="f['phoneNumber'].errors?.['pattern']"
            >
                Please enter a valid Phone Number.
            </span>
        </div>
        <!-- Company Name -->
        <label class="input-label">Company Name</label>
        <div class="input-container">
            <input type="text" placeholder="enter the name of your organization" class="form-control" formControlName="company">
        </div>
        <div
        *ngIf="f['company'].touched && f['company'].invalid"
        class="invaild-div">
            <span
                class="text-left text-danger pb-1"
            *ngIf="f['company'].errors?.['required']"
            >Company Name is required.
            </span>
            <span
                class="text-left text-danger pb-1"
            *ngIf="f['company'].errors?.['pattern']"
            >
                Please enter a valid Company Name.
            </span>
        </div>
        <!-- Buttons Div -->
        <div class="btns-div">
            <button class="btn-cancel" [ngClass]="this.data === 'manpower page' ? 'btn-cancel-green' : 'btn-cancel'" (click)="onNoClick()">Cancel</button>
            <button class="btn-next" [ngClass]="this.data === 'manpower page' ? 'btn-next-green' : 'btn-next'" (click)="onNext()">Next</button>
        </div>
    </form>
</div>