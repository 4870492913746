import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
  NgZone,
  ɵɵqueryRefresh,
} from '@angular/core';
import { MatDialog as MatMaterialDialog, MatDialogRef } from '@angular/material/dialog';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { HttpIOService } from 'src/app/services/http-io.service';
import { SavedjobComponent } from '../savedjob/savedjob.component';
import { SharejobComponent } from '../sharejob/sharejob.component';
import { Title } from '@angular/platform-browser';
import { stringify } from 'querystring';
import { Subject } from 'rxjs';
import { Observable, Subscription, interval } from 'rxjs';
import { SaveWithoutLoginComponent } from '../save-without-login/save-without-login.component';
import { AnonymousUserComponent } from '../anonymous-user/anonymous-user.component';
import { ViaEmailComponent } from '../via-email/via-email.component';
import { ViaExternalUrlComponent } from '../via-external-url/via-external-url.component';
import { ViaWorkNigeriaComponent } from '../via-work-nigeria/via-work-nigeria.component';
import { environment } from '../../../../environments/environment';
import { ReportJobComponent } from '../report-job/report-job.component';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { DynamicSnacbarSkipComponent } from 'src/app/container/elements/dynamic-snacbar-skip/dynamic-snacbar-skip.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { EmployerService } from '../../../services/employer.service';
import { takeWhile, scan, tap } from 'rxjs/operators';
import { interval as observableInterval } from 'rxjs';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { DataService } from 'src/app/services/data.service';
import { CreateAlertComponent } from '../../jobseeker/create-alert/create-alert.component';
import { RestrictedJobViewModal } from 'src/app/modals/restricted-job-view-modal/restricted-job-view-modal.component';
import { PaginationService } from '../../../services/paginationService';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { NgTinyUrlService } from 'ng-tiny-url';
import { RecruiterWantToJobSeekerSideComponent } from '../../general/recruiter-want-to-job-seeker-side/recruiter-want-to-job-seeker-side.component';
@Component({
  selector: 'app-search-ui',
  templateUrl: './search-ui.component.html',
  styleUrls: ['./search-ui.component.scss']
})
export class SearchUiComponent implements OnInit {
  status: boolean = false;
  jobLists: any = [];
  totalNoJobs = 0;
  detailsOfJob: any;
  shareJobId: any = '';
  shareJobCollection: any;
  searchDipendency: any;
  xxxxx: boolean = false;
  userDeatils: any;
  companyData: any;
  filterData: any;
  collectionSize: number;
  refresh: Subject<any> = new Subject();
  payLoad: any = {};
  userIdByurl: any;
  collectionIdByurl: any;
  viewJobPayLoad: any = {};
  guestUserJobId: any;
  allHotJobs: any;
  showReportSection: boolean;
  JobsByCategory: any;
  nojob: boolean = false;
  redirectForGuestSelectJob: any;
  collectionRecords: any;
  LogoutStateSaveJob: any;
  showRightSectionInMobile: boolean = false;
  isExpriedButton: boolean = false;
  countOfApplied: any;
  title: any;
  appliedStatus: any;
  dateDiff: number;
  clickSection: any = '';
  eachJobCompanyDetails: any;
  allRelatedJob: any;
  searchPhrase = '';

  // For Creating Auto Alert
  email?: string;
  createAlartForm: UntypedFormGroup;

  // MatPaginator Inputs
  length = 100;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  // MatPaginator Output
  pageEvent: PageEvent;
  //

  sum = 50;
  throttle = 400;
  scrollDistance = 1;
  scrollUpDistance = 2;
  direction = '';
  startJobIndex: number = 0;
  jobAppliedButtonDisable: boolean = true;
  currentSectionagfeasgesrg: any = 'section1';

  jobDesc = '';
  qualifications = '';
  currentPage: number;

  // The cards ui and pagination
  pageSize = 5;

  showDesc: boolean = false;
  url: any;

  // The Checklists 
  employemntTypeList: any;
  jobCatagoryTypeList: any;

  expLvl = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
  ];

  datePosted = [
    { name: 'Last Hour', value: 'lastHour' },
    { name: 'Last 24 Hours', value: 'pastweek' },
    { name: 'Last 7 Days', value: 'past24hours' },
    { name: 'Last 30 Days', value: 'pastMonth' },
    { name: 'All', value: '' }];

  Salary = [100000, 200000, 300000, 400000, 500000, 600000, 700000, 800000, 900000, 1000000];

  // SEARCH FILTERS VARIABLES
  jobFilter: UntypedFormGroup;
  filtered: any = {};
  count: number = 0;
  searchText2Way: any = '';
  showingTextResult: any = '';
  location2Way: any = '';
  searchTextPattern = /^[A-Za-z0-9][A-Za-z0-9\s]*$/;


  // Share the job Variables
  email_title: any;
  jobLocation: any;
  record: any;
  pic: any;
  cityRecords: any;

  // Display the filters on mobile view boolean
  displayFilters = false;

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private io: HttpIOService,
    private auth: AuthService,
    private helper: Helper,
    private dialog: MatDialog,
    private materialDialog: MatMaterialDialog,
    private titleService: Title,
    public loaderService: NgxSpinnerService,
    private EmployerService: EmployerService,
    private dataService: DataService,
    private zone: NgZone,
    private paginationService: PaginationService,
    private fb: UntypedFormBuilder,
    private tinyUrl: NgTinyUrlService,
  ) {
    this.currentPage = this.paginationService.getCurrentPage();
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    if (localUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
      // console.log(this.userDeatils)
    }
    if (sessionUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }
    let guestUserByselected = sessionStorage.getItem('guestUserSelectedJob');
    if (guestUserByselected) {
      this.guestUserJobId = JSON.parse(
        atob(sessionStorage.getItem('guestUserSelectedJob'))
      );
    }

    this.activeRoute.queryParams.subscribe((event) => {
      this.shareJobId = event['id'];
      this.collectionIdByurl = event['collectionId'];
      this.userIdByurl = event['userId'];
      this.allHotJobs = event['job_type_Home'];
      this.JobsByCategory = event['job_type_Category'];
      this.redirectForGuestSelectJob = event['role'];
      this.title = event['title'];
    });
    this.io.getforjobCategory().subscribe((res) => {
      // console.log(res);

      if (res != null) {
        // console.log(res);
        this.filterData = res;
        this.apiJobSearch(this.filterData);
      }
    });
    this.EmployerService.getloadMoreInSearchJob().subscribe((res) => {
      console.log(res);

      if (res === 'loadJobInSearchPage') {
        // console.log(res);
        // this.onScrollDown();
      }
    });
  }

  ngOnInit(): void {
    this.paginationService.currentPage$.subscribe(page => {
      this.currentPage = page;
    });

    if (this.userDeatils) {
      this.getCollection();
      this.payLoad = {
        path: 'search-job',
        userId: this.userDeatils.id,
        size: 10,
        start: 0,
      };
    } else {
      this.payLoad = {
        path: 'search-job',
        size: 10,
        start: 0,
      };
    }

    if (this.collectionIdByurl) {
      this.apiJobSearch({
        path: 'search-job',
        userId: this.userIdByurl,
        collectionId: this.collectionIdByurl,
      });
    }

    if (this.shareJobId) {
      this.apiJobSearch({
        path: 'search-job',
        id: this.shareJobId,
      });

    }
    if (this.allHotJobs) {
      this.apiJobSearch({
        path: 'search-job',
        job_type: String(this.allHotJobs),
      });
    }

    if (this.JobsByCategory) {
      this.apiJobSearch({
        path: 'search-job',
        category_id: this.JobsByCategory,
      });
    }
    if (
      !this.shareJobId &&
      !this.collectionIdByurl &&
      !this.filterData &&
      !this.allHotJobs &&
      !this.JobsByCategory
    ) {
      this.apiJobSearch(this.payLoad);
    }

    this.dataService.sharedData.subscribe((data) => (this.searchPhrase = data));

    // SEARCH FILTERS
    this.validationForm();
    this.employmentType();
    this.cityList()
    this.jobCatagory()
    this.io.getforjobCategory().subscribe((res) => {
      if (res != null) {
        (this.searchText2Way =
          res?.searchText != ''
            ? res?.searchText
            : this.jobFilter.get('searchText').value),

          (this.location2Way =
            res?.job_location != ''
              ? res?.job_location
              : this.jobFilter.get('job_location').value);
        // this.ShowCross = true;
        // this.showCancelButton = true;
      }
    });
  }

  alert_off = true;
  alert_on = false;

  alertOn() {
    this.alert_on = true;
    this.alert_off = false;
  }
  alertOff() {
    this.alert_off = true;
    this.alert_on = false;
  }

  toggleFilter() {
    this.displayFilters = !this.displayFilters;
  }

  openErrorDialog(): void {
    const dialogRef = this.materialDialog.open(RestrictedJobViewModal, {
      height: '520px',
      width: '500px',
      disableClose: true,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(() => {
      console.log('The dialog was closed');
    });
  }

  splitDescription() {
    const description = this.detailsOfJob?.description || '';

    const keyword = this.detectKeyword(description);

    if (keyword) {
      const parts = description.split(keyword);
      this.jobDesc = parts[0];
      this.qualifications = parts[1];
    } else {
      // Handle the case where the keyword is not found
      this.jobDesc = description;
      this.qualifications = '';
    }
  }

  detectKeyword(description: string): string | null {
    const possibleKeywords = ["Requirements:", "Requirement:", "Requirement", "Requirements", "Qualifications", "Qualifications:"];
    for (const keyword of possibleKeywords) {
      if (description.includes(keyword)) {
        return keyword;
      }
    }
    return ''; // Keyword not found
  }

  scroll() {
    document.getElementById('qualifications').scroll(0, 0);
  }
  // CLICK ON JOB LIST //
  jobDetails(i, job) {
    this.showDesc = true;
    this.titleService.setTitle(job.title);
    this.showRightSectionInMobile = true;
    this.LogoutStateSaveJob = job;
    this.status = job.id;
    this.detailsOfJob = this.jobLists[i];
    this.splitDescription();
    this.jobExpried(this.detailsOfJob);
    this.getCompanyDetailsPerticularJob(this.detailsOfJob);
    this.relatedJob(this.detailsOfJob);
    this.reportJob();
    this.viewJob(job);
    this.copyLinkShare(job);
    // this is to move thee scroll bar up on top of the section when new job is selected and remove the highlight
    // this.scrollToElement('topOfDesc');
  }

  // CLICK ON JOB LIST //
  jobDetailsRelated(i, job) {
    // console.log(job)

    this.showRightSectionInMobile = true;
    this.LogoutStateSaveJob = job;
    this.status = job.id;
    this.detailsOfJob = this.allRelatedJob[i];
    this.jobExpried(this.detailsOfJob);
    this.getCompanyDetailsPerticularJob(this.detailsOfJob);
    this.relatedJob(this.detailsOfJob);
    this.reportJob();
    this.viewJob(job);
    this.copyLinkShare(job)
  }

  viewJob(job) {
    if (this.userDeatils?.id == undefined || '') {
      this.viewJobPayLoad = {
        path: 'view-job',
        id: job.id,
      };
    } else {
      this.viewJobPayLoad = {
        path: 'view-job',
        id: job.id,
        userId: this.userDeatils?.id,
      };
    }
    this.io._viewJob(this.viewJobPayLoad).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        this.countOfApplied = res['result'].applied;
      }
    });
  }

  jobExpried(job) {
    this.appliedStatus = '';
    if (job.expired_at) {
      this.dateDiff = this.helper.calculateDiff(
        new Date(job.expired_at),
        new Date()
      );
      if (this.dateDiff >= 0) {
        this.isExpriedButton = false;
      } else {
        this.isExpriedButton = true;
      }
    }
    if (this.userDeatils) {
      if (this.userDeatils.userType == 'employer') {
        if (this.companyData.id == job.company_id) {
          this.jobAppliedButtonDisable = false;
        }
      }
    }
    // var hours = Math.abs(new Date(job.expired_at).getTime() - new Date().getTime()) / 36e5;
    // console.log(this.companyData.id , job.company_id)
  }

  mobileViewClassClose() {
    this.showRightSectionInMobile = false;
    this.detailsOfJob = null;
  }
  // EVENT EXPAIRE TIME //
  eventExpireIn(eventDt: string | number | Date) {
    // const dateDiff = this.helper.calculateDiff(new Date(), new Date(eventDt));
    const currentDate = new Date();
    const eventDate = new Date(eventDt);

    const timeDiff = Math.abs(currentDate.getTime() - eventDate.getTime());
    const hoursDiff = Math.floor(timeDiff / (1000 * 60 * 60));

    if (hoursDiff < 24) {
      if (hoursDiff === 0) {
        const minutesDiff = Math.floor(timeDiff / (1000 * 60));
        return `Posted ${minutesDiff}m`;
      } else {
        return `Posted ${hoursDiff}h`;
      }
    } else {
      const daysDiff = Math.floor(hoursDiff / 24);
      if (daysDiff === 1) {
        return `Posted ${daysDiff} day`;
      } else {
        return `Posted ${daysDiff} days`;
      }
    }
  }
  //****************************************************************** */
  onSectionChange(sectionId: any) {
    this.clickSection = sectionId;
  }

  scrollToElement(id: any) {
    this.clickSection = id;
    // console.log("element id : ", id);
    setTimeout(() => {
      // document.body.scrollTop = 0;
      // window.scrollTo(0,0);
      const element = <HTMLElement>document.querySelector(`#${id}`);
      element.scrollIntoView({ behavior: 'smooth' });
    });
  }
  //****************************************************************** */
  async scrollToHead() {
    await window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    //  window.scrollTo(0,0);
  }

  swipeRelatedJobs() {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    const relatedSection = document.querySelector('#related');
    if (relatedSection) {
      relatedSection.scrollIntoView({ behavior: 'smooth' });

      const firstCard = relatedSection.querySelector('.card-grid');
      if (firstCard) {
        firstCard.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  async onScrollJobChange(id: any) {
    this.clickSection = id;
    // console.log("element id : ", id);
    if (window.innerWidth > 570) {
      setTimeout(() => {
        this.scrollToHead();
      }, 500);
      this.swipeRelatedJobs();
    }
    const element = <HTMLElement>document.querySelector(`#${id}`);
    element.scrollIntoView({ behavior: 'smooth' });
  }
  //infinite scroll
  onScrollDown() {
    this.startJobIndex = this.startJobIndex + 10;
    if (
      !this.shareJobId &&
      !this.collectionIdByurl &&
      !this.filterData &&
      !this.allHotJobs &&
      !this.JobsByCategory
    ) {
      this.payLoad.start = this.startJobIndex;
      // console.log(this.payLoad)
      this.io
        .apicall(this.payLoad, 'jobsearch/search-job')
        .subscribe((res: any) => {
          if (res.serverResponse.code == 200) {
            let data = res['result'].jobList;
            this.totalNoJobs = res['pagination']?.total;
            this.jobLists.push(...data);
          }
        });
    } else {
      this.filterData.start = this.startJobIndex;
      // console.log(this.filterData)
      this.io
        .apicall(this.filterData, 'jobsearch/search-job')
        .subscribe((res: any) => {
          if (res.serverResponse.code == 200) {
            let data = res['result'].jobList;
            this.totalNoJobs = res['pagination']?.total;
            this.jobLists.push(...data);
          }
        });
    }
    this.EmployerService.setloadMoreInSearchJob(null);
  }


  onPageChanged(pageNumber: number) {
    this.paginationService.setCurrentPage(pageNumber);
    this.startJobIndex = (pageNumber - 1) * this.pageSize;
    if (
      !this.shareJobId &&
      !this.collectionIdByurl &&
      !this.filterData &&
      !this.allHotJobs &&
      !this.JobsByCategory
    ) {
      this.payLoad.start = this.startJobIndex;
      // console.log(this.payLoad)
      this.io
        .apicall(this.payLoad, 'jobsearch/search-job')
        .subscribe((res: any) => {
          if (res.serverResponse.code == 200) {
            let data = res['result'].jobList;
            this.totalNoJobs = res['pagination']?.total;
            this.jobLists = data;
          }
        });
    } else {
      this.filterData.start = this.startJobIndex;
      // console.log(this.filterData)
      this.io
        .apicall(this.filterData, 'jobsearch/search-job')
        .subscribe((res: any) => {
          if (res.serverResponse.code == 200) {
            let data = res['result'].jobList;
            this.totalNoJobs = res['pagination']?.total;
            this.jobLists = data;
          }
        });
    }
    this.EmployerService.setloadMoreInSearchJob(null);
    // this.scrollToTop(this.jobsList.nativeElement);
  }

  scrollToTop(el) {
    const duration = 400;
    const interval = 5;
    const move = (el.scrollTop * interval) / duration;
    observableInterval(interval)
      .pipe(
        scan((acc, curr) => acc - move, el.scrollTop),
        tap((position) => (el.scrollTop = position)),
        takeWhile((val) => val > 0)
      )
      .subscribe();
  }

  //---------------- Saved job --------------------//
  savedJob(job) {
    if (this.userDeatils?.id == undefined || '') {
      const dialogRef = this.dialog.open(SaveWithoutLoginComponent, {
        height: 'auto',
        width: '450px',
        data: { Perticularjob: job, hint: 'logout-save-job' },
        autoFocus: false,
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res == 'save') {
          // console.log("++++++++++++")
        }
      });
    } else {
      if (job.isSaved == true) {
        let jsonData = {
          path: 'unsaved-job',
          jobId: job.id,
          id: this.userDeatils.id,
        };
        //  console.log(jsonData);
        this.io._unSaveJob(jsonData).subscribe((res: any) => {
          //  console.log(res);
          if (res.serverResponse.code == 200) {
            this.snackBar
              .openFromComponent(DynamicSnacbarComponent, {
                data: res.serverResponse.message,
                verticalPosition: 'top', // 'top' | 'bottom'
                horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
              })
              .afterOpened()
              .subscribe(this.helper.setOnTop);
            if (this.filterData) {
              this.apiJobSearch(this.filterData);
            } else {
              this.apiJobSearch({
                path: 'search-job',
                userId: this.userDeatils.id,
              });
            }
          } else {
            this.snackBar
              .openFromComponent(DynamicSnacbarComponent, {
                data: res.serverResponse.message,
                verticalPosition: 'top', // 'top' | 'bottom'
                horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
              })
              .afterOpened()
              .subscribe(this.helper.setOnTop);
            if (this.filterData) {
              this.apiJobSearch(this.filterData);
            } else {
              this.apiJobSearch({
                path: 'search-job',
                userId: this.userDeatils.id,
              });
            }
            // this.apiJobSearch({ path: "search-job", userId: this.userDeatils.id });
          }
        });
      } else {
        const dialogRef = this.dialog.open(SavedjobComponent, {
          height: '90%',
          // width: '450px',
          autoFocus: false,
          data: { data: job },
        });
        dialogRef.afterClosed().subscribe((res) => {
          if (res == 'save') {
            if (this.filterData) {
              // console.log(this.filterData)
              this.apiJobSearch(this.filterData);
            } else {
              this.apiJobSearch({
                path: 'search-job',
                userId: this.userDeatils.id,
              });
            }
          }
        });
      }
    }
  }
  // without login if any one click save button

  getCollection() {
    let jsonData = {
      id: this.userDeatils?.id,
      path: 'collection-list',
    };
    this.io._getCollection(jsonData).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        this.collectionRecords = res['result'].collectionList;
        // console.log( this.collectionRecords)
      }
    });
  }

  relatedJob(job) {
    let jsonData = {
      path: 'related-jobs',
      jobIds: [job.id],
      searchText: job.title,
      category_id: job.category_id,
    };
    this.io._relatedJob(jsonData).subscribe((res: any) => {
      // console.log(res);
      if (res.serverResponse.code == 200) {
        if (res['result'].jobList.length > 0) {
          this.allRelatedJob = res['result'].jobList;
        } else {

        }
      }
    });
  }

  apiJobSearch(data: any) {
    this.loaderService.show();
    this.io.apicall(data, 'jobsearch/search-job').subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        this.jobLists = res['result'].jobList;
        this.pageSize = this.pageSize - res['pagination']?.promotedJobs
        this.collectionSize = res['pagination']?.total
          ? res['pagination']?.total
          : 0; // TODO: 100 is default value for now
        // this.collectionSize = this.collectionSize * (this.pageSize/res['pagination']?.promotedJobs)
        this.totalNoJobs = res['pagination']?.total;
        this.startJobIndex = res['pagination']?.start;
        if (this.jobLists.length == 0) {
          this.nojob = true;
          this.detailsOfJob = null;
        }
        if (this.jobLists.length > 0) {
          this.viewJob(this.jobLists[0]);
          // this.jobDetails(0, this.jobLists[0]);
          this.nojob = false;
          if (this.guestUserJobId) {
            let selectedJObByGuestUser = this.jobLists.filter(
              (event) => event.id == this.guestUserJobId
            );
            this.detailsOfJob = selectedJObByGuestUser[0];
            this.jobExpried(this.detailsOfJob);
            this.relatedJob(this.detailsOfJob);
            this.getCompanyDetailsPerticularJob(this.detailsOfJob);
            this.status = selectedJObByGuestUser[0].id;
            //save job in default collection
            this.loaderService.hide();
            let jsonData = {
              path: 'saved-job',
              jobId: this.detailsOfJob.id,
              id: this.userDeatils.id,
              collectionId: this.collectionRecords[0].id,
              jobProviderId: this.detailsOfJob.job_provider_id,
              title: this.detailsOfJob.title,
              jobSeekerName: `${this.userDeatils.firstName} ${this.userDeatils.lastName}`,
              companyId: this.detailsOfJob.company_id,
            };
            this.io._saveJob(jsonData).subscribe((res: any) => {
              if (res.serverResponse.code == 200) {
                sessionStorage.removeItem('guestUserSelectedJob');
                this.guestUserJobId = undefined;
                this.detailsOfJob.isSaved = true;
              }
            });
          } else if (this.redirectForGuestSelectJob) {
            let selectedJObByGuestUser = this.jobLists.filter(
              (event) => event.id == this.guestUserJobId
            );
            this.detailsOfJob = selectedJObByGuestUser[0];
            this.jobExpried(this.detailsOfJob);
            this.getCompanyDetailsPerticularJob(this.detailsOfJob);
            this.relatedJob(this.detailsOfJob);
            this.status = selectedJObByGuestUser[0].id;
            this.loaderService.hide();
          } else {
            this.status = this.jobLists[0].id;
            this.loaderService.hide();
          }
          sessionStorage.removeItem('guestUserSelectedJob');
          this.reportJob();
        }
      } else {
        if (res.serverResponse.message === '3MTT Jobs can only be viewed by registered 3MTT fellows') {
          this.openErrorDialog()
        }
        this.snackBar
          .openFromComponent(DynamicSnacbarErrorComponent, {
            data: res.serverResponse.message,
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            duration: 6000,
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
      }
    });
  }

  getCompanyDetailsPerticularJob(job) {
    let jsonData = {
      path: 'get-company',
      company_id: job.company_id,
    };
    this.io._getCompanyDetails(jsonData).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        // console.log(res);
        this.eachJobCompanyDetails = res['result'].company;
      }
    });
  }

  draftJob(Perticularjob: any) {
    let jsonData = {
      path: 'draft-job',
      userId: this.userDeatils.id,
      jobId: Perticularjob.id,
      activityType: '',
    };
    if (Perticularjob.apply_via == '1') {
      jsonData.activityType = environment.activityType.via_workNigeria;
    } else if (Perticularjob.apply_via == '2') {
      jsonData.activityType = environment.activityType.via_url;
    } else if (Perticularjob.apply_via == '3') {
      jsonData.activityType = environment.activityType.via_email;
    }
    // console.log(jsonData)
    this.io._draftJob(jsonData).subscribe((res: any) => {
      // console.log(res);
      if (res.serverResponse.code == 200) {
        // this.snackBar.open(res.serverResponse.message, "OK", {
        // })
      }
    });
  }

  async applyJob(Perticularjob: any) {
    if (Perticularjob.apply_via == '3') {
      const email_subject = `Application for the position: ${Perticularjob.title}`
      const mailtoLink = `mailto:${Perticularjob.via_email}?subject=${encodeURIComponent(email_subject)}`;
      const anchor = document.createElement('a');
      anchor.href = mailtoLink;
      anchor.click();
      let jsonData = {
        path: 'apply-job-via-email',
        jobId: Perticularjob.id,
      };
      this.io._applyViaEmail(jsonData).subscribe((res: any) => {
        this.detailsOfJob.isApplied = true;
        this.detailsOfJob.applied_at = new Date();
      });
    }
    else {
      if (this.userDeatils?.id != undefined || '') {
        await this.draftJob(Perticularjob);
      }

      if (this.userDeatils?.id == undefined || '') {
        // console.log("+++++");
        const dialogRef = this.dialog.open(AnonymousUserComponent, {
          height: '450px',
          width: '550px',
          autoFocus: false,
          data: { Perticularjob },
        });
        dialogRef.afterClosed().subscribe((res) => {
          if (res == 'save') {
          }
        });
      } else {
        if (Perticularjob.apply_via == '2') {
          const dialogRef = this.dialog.open(ViaExternalUrlComponent, {
            height: '650px',
            width: '450px',
            data: { Perticularjob },
            autoFocus: false,
          });
          dialogRef.afterClosed().subscribe((res) => {
            if (res == 'save') {
              console.log('++++++++++++');
              // this.ngOnInit();
              this.detailsOfJob.isApplied = true;
              this.detailsOfJob.applied_at = new Date();
            }
          });
        } else if (Perticularjob.apply_via == '1') {
          const dialogRef = this.dialog.open(ViaWorkNigeriaComponent, {
            height: '650px',
            width: '550px',
            data: { Perticularjob },
            autoFocus: false,
          });
          dialogRef.afterClosed().subscribe((res) => {
            if (res == 'save') {
              console.log('++++++++++++');
              // this.ngOnInit();
              this.detailsOfJob.isApplied = true;
              this.detailsOfJob.applied_at = new Date();
            }
          });
        }
      }
    }
  }

  alreadyApply() {
    // this.snackBar.open("You have Already Applied", "OK")
    this.snackBar
      .openFromComponent(DynamicSnacbarSkipComponent, {
        data: 'You have Already Applied',
        verticalPosition: 'top', // 'top' | 'bottom'
        horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
      })
      .afterOpened()
      .subscribe(this.helper.setOnTop);
  }

  reportJob() {
    this.showReportSection = false;
    if (this.userDeatils?.id != undefined) {
      this.showReportSection = true;
    }
  }

  reportModel(Perticularjob: any) {
    const dialogRef = this.dialog.open(ReportJobComponent, {
      height: 'auto',
      width: '450px',
      data: { Perticularjob },
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res == 'save') {
        // console.log("++++++++++++")
        // this.ngOnInit();
      }
    });
  }

  navigateToContactUs(title: any) {
    this.dataService.setData({ value: title, label: title });
    this.router.navigate(['/contactUs']);
  }

  redirectToInspireAfricaSignup() {
    window.open('https://inspireafricaconference.com', '_blank');
  }

  navigateToCvReview() {
    this.router.navigate(['/cv-review']);
  }

  clearJobDetails() {
    this.detailsOfJob = null;
    this.showRightSectionInMobile = false;
    this.showDesc = false;
  }

  createAlert() {
    // to check if the user is logged in or not
    if (this.userDeatils) {
      // Create a JSON object with the alert data.
      let jsonData = {
        path: this.email != null ? 'set-alert-visitor' : 'set-alert',
        alertName: this.searchPhrase,
        userId: this.email != null ? '' : this.userDeatils.id,
        keywords: this.searchPhrase,
        preferredLocation: '',
        categoryId: '',
        employmentTypeId: '',
        employmentType: '',
        jobCategory: '',
        minimumSalary: '',
        maximumSalary: '',
        emailFrequency: 'weekly',
        email: this.email,
      };

      // Call the io._setAlert() or io._setAlertVisitor() method.
      if (this.email != null) {
        this.io._setAlertVisitor(jsonData).subscribe((res: any) => {
          if (res.serverResponse.code == 200) {
            // Display a snackbar message.
            this.snackBar.openFromComponent(DynamicSnacbarComponent, {
              data: res.serverResponse.message,

              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            });
          }
        });
      } else {
        this.io._setAlert(jsonData).subscribe((res: any) => {
          if (res.serverResponse.code == 200) {
            // Display a snackbar message.
            this.snackBar.openFromComponent(DynamicSnacbarComponent, {
              data: res.serverResponse.message,

              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            });
          }
        });
      }
    } else {
      this.router.navigate(['/login']);
      sessionStorage.setItem('setUrlRedirection', 'job-search');
      sessionStorage.setItem('searchTerm', this.searchPhrase);
    }
  }

  // Go to Job Alert UI
  job_alert() {
    if (this.userDeatils) {
      if (this.userDeatils.userType == 'employer') {
        const dialogRef = this.dialog.open(
          RecruiterWantToJobSeekerSideComponent,
          {
            height: 'auto',
            width: '450px',
            data: {},
            autoFocus: false,
          }
        );
        dialogRef.afterClosed().subscribe((res) => {
          if (res == 'save') {
          }
        });
      }
      if (this.userDeatils.userType == 'jobseeker') {
        this.router.navigate(['/job-alert']);
      }
    } else {
      this.router.navigate(['/login']);
      sessionStorage.setItem('setUrlRedirection', 'job-alert');
    }
  }


  ngOnDestroy() {
    this.titleService.setTitle('WorkNigeria');
    this.io.setforjobCategory(null);
  }

  postAjob() {
    if (this.userDeatils.userType == 'employer') {
      this.router.navigate(['/post-job']);
    } else {
      this.router.navigate(['/login']);
      sessionStorage.setItem('setUrlRedirection', 'post-job');
    }
  }

  // ----------------- SEARCH FILTER FUNCTIONS --------------------------
  // Validation Controls //
  get f() {
    return this.jobFilter.controls;
  }

  // Insilize Form //
  validationForm() {
    this.jobFilter = this.fb.group({
      created_at: [''],
      startDate: [''],
      endDate: [''],
      job_location: [''],
      employment_type_id: [''],
      company_name: [''],
      minimum_experience: [''],
      // job_type: [''],
      currency: [''],
      minimum_salary: [''],
      category_id: [''],
      jobs: [''],
      only_3mtt: [''],
      searchText: ['', Validators.compose([
        Validators.pattern(this.searchTextPattern),
      ]),],
    });
  }

  start() {
    if (this.jobFilter.get('created_at').value == 'pastMonth') {
      let today = new Date(),
        format = 'yyyy-MM-dd';
      let priorDate: any = new Date().setDate(today.getDate() - 30);
      let value = priorDate;
      var datePipe = new DatePipe('en-US');
      value = datePipe.transform(priorDate, format);
      return value;
    } else if (this.jobFilter.get('created_at').value == 'pastweek') {
      let today = new Date(),
        format = 'yyyy-MM-dd';
      let priorDate: any = new Date().setDate(today.getDate() - 7);

      let value = priorDate;
      var datePipe = new DatePipe('en-US');
      value = datePipe.transform(priorDate, format);
      return value;
    } else if (this.jobFilter.get('created_at').value == 'past24hours') {
      let value = new Date().toDateString(),
        format = 'yyyy-MM-dd';
      var datePipe = new DatePipe('en-US');
      value = datePipe.transform(value, format);
      return value;
    } else {
      return '';
    }
  }

  end() {
    if (this.jobFilter.get('created_at').value == 'anyTime') {
      return '';
    } else {
      let value = new Date().toDateString(),
        format = 'yyyy-MM-dd';
      var datePipe = new DatePipe('en-US');
      value = datePipe.transform(value, format);
      return value;
    }
  }

  onFilterChange(event: any) {
    this.paginationService.setCurrentPage(1);
    // console.log("value: ",value)
    this.searchByDipendency();
  }

  searchByDipendency() {
    const searchTextControl = this.jobFilter.get('searchText');
    searchTextControl?.setValidators([Validators.pattern(this.searchTextPattern)]);
    searchTextControl?.updateValueAndValidity();

    this.displayFilters = false;

    if (this.userDeatils) {
      this.filtered = {
        path: 'search-job',
        userId: this.userDeatils.id,
        size: 10,
        start: 0,
      };
    } else {
      this.filtered = {
        path: 'search-job',
        size: 10,
        start: 0,
      };
    }

    if (this.location2Way != '') {
      this.filtered['job_location'] = this.location2Way;
    }

    // const filtered = { path: "search-job"};
    this.count = 0;
    if (this.jobFilter.valid) {
      for (let key in this.jobFilter.value) {
        if (this.jobFilter.value[key]) {
          if (key != 'searchText' && key != 'job_location') {
            this.count = this.count + 1;
          }
          if (key == 'created_at' && this.jobFilter.value[key] != '') {
            this.filtered['endDate'] = this.end();
            this.filtered['startDate'] = this.start();
          } else {
            this.filtered[key] = this.jobFilter.value[key];
          }
        }
      }
    }

    this.io.setforjobCategory(this.filtered);
  }

  // Get the list of employment
  employmentType() {
    this.io
      .apicall({ path: 'employment-type-list' }, 'job/employment-type-list')
      .subscribe((res: any) => {
        // console.log(res)
        if (res.serverResponse.code == 200) {
          this.employemntTypeList = res['result'].employmentList;
        } else {
          this.snackBar
            .openFromComponent(DynamicSnacbarErrorComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
              duration: 6000,
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
        }
      });
  }

  // To get the job category list
  jobCatagory() {
    this.io
      .apicall({ path: 'category-list' }, 'job/category-list')
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          this.jobCatagoryTypeList = res['result'].categoryList;
        } else {
          this.snackBar
            .openFromComponent(DynamicSnacbarErrorComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
              duration: 6000,
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
        }
      });
  }

  //------------ Sharing the job functions -----------//
  detectMob() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }

  copyLinkShare(job) {
    if (job.collectionname != undefined) {
      this.record = job.collectionname;
      this.jobLocation = job.job_location;
      this.title = 'Share this List with friends and family';
      this.detectMob();
      // this.linkUrl = `https://d2c7gcaexouhqs.cloudfront.net/job-search?userId=${this.data['data'].userId}&collectionId=${this.data['data'].collectionId}`;
      this.tinyUrl
        .shorten(
          `https://worknigeria.com/job-search?userId=${job.userId}&collectionId=${job.collectionId}`
        )
        .subscribe((res: any) => {
          this.url = res;
        });
    } else {
      this.pic = job.is_3mtt ? '../../../../assets/images/3mtt.svg' : job.company_behalf_logo ?? job.logo;
      this.record = job.title;
      this.jobLocation = job.job_location;
      this.title = 'Share this job with friends and family';
      this.detectMob();
      // this.linkUrl =`https://d2c7gcaexouhqs.cloudfront.net/job-search?id=${this.data['data'].id}`
      this.tinyUrl
        .shorten(
          `https://worknigeria.com/job-search?id=${job.id}&title=${job.title}`
        )
        .subscribe((res) => {
          this.url = encodeURI(res);
        });
    }
    if (this.userDeatils) {
      this.email_title = `${this.userDeatils.firstName} thinks you may want to hear about this job!`;
    }
  }

  openSocialMedia(check) {
    if (check === 'linkedin') {
      window.open(
        `https://www.linkedin.com/sharing/share-offsite/?url=${this.url}`
      );
    } else if (check === 'facebook') {
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${this.url}`
      );
    } else if (check === 'whatsapp') {
      window.open(
        `https://api.whatsapp.com/send?text=${this.url}`
      );
    }
  }

  copyUrl() {
    navigator.clipboard.writeText(this.url).then(() => {
      this.snackBar
        .openFromComponent(DynamicSnacbarComponent, {
          data: "URL Copied to clipboard",
          verticalPosition: 'top', // 'top' | 'bottom'
          horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
        })
        .afterOpened()
        .subscribe(this.helper.setOnTop);
    }, (error) => {
      this.snackBar
        .openFromComponent(DynamicSnacbarErrorComponent, {
          data: 'Something went wrong ',
          verticalPosition: 'top', // 'top' | 'bottom'
          horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          duration: 6000,
        })
        .afterOpened()
        .subscribe(this.helper.setOnTop);
    });
  }

  // SEARCH JOBS INPUT FUNCTIONALITIES:
  onKey(event) {
    const searchTextControl = this.jobFilter.get('searchText');
    searchTextControl?.setValidators([
      Validators.required,
      Validators.pattern(this.searchTextPattern),
    ]);
    searchTextControl?.updateValueAndValidity();
      const inputValue = event.target.value;
      this.paginationService.setCurrentPage(1);
      // if (inputValue != '') {
      //   this.ShowCross = true;
      // }
    }

    joblocationName(e) {
      this.location2Way = e;
    }

    cityList() {
      let data = { path: 'city-list' };
      this.io._cityList(data).subscribe((res: any) => {
        // console.log(res)
        if (res.serverResponse.code == 200) {
          this.cityRecords = res['result'].cityList.map((item) => item.state);
        }
      });
    }

    resetSearch(title: any) {
      this.showingTextResult = this.searchText2Way;
      this.searchText2Way = '';
      this.dataService.nextData(this.showingTextResult);
      const searchTextControl = this.jobFilter.get('searchText');
      searchTextControl?.setValidators([Validators.pattern(this.searchTextPattern)]);
      searchTextControl?.updateValueAndValidity();
      this.jobFilter.controls['searchText'].reset();
      this.jobFilter.controls['job_location'].reset();
    }
}
