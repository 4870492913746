import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { DynamicSnacbarErrorComponent } from '../container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { Helper } from '../helper/defult';
// import { LoginServiceService } from '../login-service.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  userDeatilsToken: any = '';
  excludeURL = [];
  constructor(
    private _authService: AuthService,
    private helper: Helper,
    private dialogRef: MatDialog,
    private snackBar: MatSnackBar
  ) {
    let localUsertoken = localStorage.getItem('workNigeriaToken');
    let sessionUsertoken = sessionStorage.getItem('workNigeriaToken');
    if (localUsertoken) {
      this.userDeatilsToken = this._authService.getToken();
      // console.log(this.userDeatilsToken)
    }
    if (sessionUsertoken) {
      this.userDeatilsToken = this._authService.getTokenFormseesion();
      //   console.log( this.userDeatilsToken)
    }
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          let error =
            event['body'].serverResponse != undefined
              ? event['body'].serverResponse.code
              : 'error';
          // console.log(error);
          if (error == 403) {
            this.snackBar
              .openFromComponent(DynamicSnacbarErrorComponent, {
                data: 'Session has expired',
                verticalPosition: 'top', // 'top' | 'bottom'
                horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
              })
              .afterOpened()
              .subscribe(this.helper.setOnTop);
            this._authService.allLogout();
            return;
          } else {
            return event;
          }
        }
      }),
      catchError((error: any) => {
        if (!error.ok) {
          // this.snackBar.open("Something went wrong", "OK", {
          // });
          this.snackBar
            .openFromComponent(DynamicSnacbarErrorComponent, {
              data: 'Something went wrong',
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
              duration: 6000,
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
        }
        // let data = {};
        // data = {
        //     reason: error && error.error.response ? error.error.response.message : '',
        //     status: error.status
        // };
        // // console.log("Error in interceptor ", data);
        return throwError(error);
      })
    );
    // }
    // else{
    //     return ;
    // }
    // }
  }
}
