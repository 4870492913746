<div class="call-to-action">
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="13" viewBox="0 0 17 13" fill="none">
    <path d="M10.0129 11.7758C9.81289 11.5758 9.71689 11.3341 9.72489 11.0508C9.73289 10.7674 9.83723 10.5258 10.0379 10.3258L12.8629 7.50078H1.71289C1.42956 7.50078 1.19189 7.40478 0.999894 7.21278C0.807894 7.02078 0.712227 6.78345 0.712894 6.50078C0.712894 6.21745 0.808894 5.97978 1.00089 5.78778C1.19289 5.59578 1.43023 5.50011 1.71289 5.50078H12.8629L10.0129 2.65078C9.81289 2.45078 9.71289 2.21311 9.71289 1.93778C9.71289 1.66245 9.81289 1.42511 10.0129 1.22578C10.2129 1.02578 10.4506 0.925781 10.7259 0.925781C11.0012 0.925781 11.2386 1.02578 11.4379 1.22578L16.0129 5.80078C16.1129 5.90078 16.1839 6.00911 16.2259 6.12578C16.2679 6.24245 16.2886 6.36745 16.2879 6.50078C16.2879 6.63411 16.2669 6.75911 16.2249 6.87578C16.1829 6.99245 16.1122 7.10078 16.0129 7.20078L11.4129 11.8008C11.2296 11.9841 11.0006 12.0758 10.7259 12.0758C10.4512 12.0758 10.2136 11.9758 10.0129 11.7758Z" fill="black"/>
  </svg>
  <p><span>Are you a Recruiter?</span> Are you Tired of sifting through unqualified applications?</p>
  <button class="call-to-action-button" (click)="goToSurvey()">
    Click here
  </button>
</div>
<section class="homebanner">
  <div class="container-fluid pb-0 bg-banner pr-0">
    <div class="row align-items-center">
      <div class="col-lg-1"></div>
      <div class="col-lg-5">
        <div class="homebannerinfo white">
          <h1 class="banner-heading">
            Let's Connect you to your
            <span class="dream-job-color"> Dream Job </span>
          </h1>
          <form class="search-form" [formGroup]="homeNew">
            <div class="search-tag-home">
              <input type="search" id="homesearch" class="form-control input-sm" formControlName="searchText"
                placeholder="Search jobs"/>
              <!-- location auto complete -->
              <mat-form-field class="form-control input-sm" appearance="fill">
                <input id="selectcat" type="text" aria-label="Number" placeholder="States" matInput formControlName="cityName" [matAutocomplete]="auto" />
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="changeLocation($event.option.value)">
                  <mat-option>Any</mat-option>
                  <mat-option *ngFor="let option of filteredOptions | async "[value]="option.state">
                    {{ option.state }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <button value="Find Job" class="nbtn input-sm" (click)="search()" [disabled]="homeNew.status === 'INVALID'">
                Search Jobs
              </button>
            </div>
            <div class="warning-div">
              <span *ngIf="f.searchText.touched && f.searchText.invalid">
                <span class="text-left text-danger pb-1" *ngIf="f.searchText.errors.required">Search Title is required
                </span>
                <span class="text-left text-danger pb-1" *ngIf="f.searchText.errors?.pattern">
                  Search Title is not valid.
                </span>
              </span>
            </div>
          </form>
          <a href="javascript:void(0);" routerLink="/job-search">Browse all jobs <i
              class="fas fa-chevron-right"></i></a>
        </div>
      </div>
      <div class="col-lg-1"></div>
      <div class="col-lg-5 pb-0">
        <div class="img">
          <img src="../assets/images/home-search-banner.webp" />
        </div>
      </div>
    </div>
  </div>
</section>
<!-- SECTION FOR THE CARDS -->
<section>
  <div class="card-container" id="jobAlertCard">
    <div class="row">
      <div class="col-lg-6 col-12">
        <div class="card">
          <div class="row mt-3">
            <div class="col-lg-6 col-6 d-flex flex-column align-items-start">
              <h2 class="card-para-1">Build CV</h2>
              <p class="card-para-2">
                Take advantage of our CV builder to make your CV stand out.
              </p>
              <div *ngIf="!userDeatils">
                <button href="javascript:void(0);" class="btn btn-get-started" (click)="buildCVsection()">Get
                  Started</button>
              </div>
              <div *ngIf="userDeatils">
                <a href="javascript:void(0);" class="btn btn-get-started" (click)="BuildCV()">Get Started</a>
              </div>
            </div>
            <div class="col-lg-6 col-6">
              <img class="build-cv-lady" src="../assets/images/build-cv-lady.webp" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-12">
        <div class="card job-alert-card">
          <div class="row">
            <div class="col-lg-6 col-6 order-lg-2 job-alert-desc">
              <h2 class="card-para-4">Set Job Alert</h2>
              <p class="card-para-5">
                Receive daily, weekly or monthly alert about your preferred jobs
              </p>
              <form [formGroup]="jobAlertForm">
                <input placeholder="enter email" class="form-control input-email" formControlName="email" />
                <div *ngIf="errorCheck.email.touched && errorCheck.email.invalid" class="invaild-div">
                  <span class="text-left text-danger pb-1" *ngIf="errorCheck.email.errors.required">Email is required.
                  </span>
                  <span class="text-left text-danger pb-1" *ngIf="errorCheck.email.errors?.pattern">
                    Enter valid email.
                  </span>
                </div>
                <button type="submit" class="btn-subscribe" (click)="createAlert()">
                  Subscribe
                </button>
              </form>
            </div>
            <div class="col-lg-6 col-6 order-lg-1">
              <img *ngIf="!isMobileScreen" class="job-alert-img" src="../assets/images/job-alert.webp" alt="Job Alert Card" />
              <img *ngIf="isMobileScreen" class="job-alert-img" src="../assets/images/job-alert-mobile.png"
                alt="Job Alert Card Mobile" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section>
  <div class="row hire-row">
    <div class="col-lg-1"></div>
    <div class="col-lg-4 hire-col-2">
      <h2 class="hire-heading">Increase Your Chance Of Getting Hired</h2>
      <p class="hire-text">Request A CV or Cover Letter Review</p>
      <button class="btn btn-rqst-review" (click)="navigateToCvReview()">
        Request a Review
      </button>
    </div>
    <div class="col-lg-3">

    </div>
    <div class="col-lg-3 hire-2-img">
      <img class="" src="../../../../assets/images/cv-review.webp" />
    </div>
    <div class="col-lg-1"></div>
  </div>
</section>
<section class="newjobs-boxes sky2 common-padd">
  <div class="container">
    <div class="row" style="justify-content: center;">
      <div class="col-lg-4">
        <div class="newjobs-boxes-wrap">
          <h4>Hot Jobs</h4>
          <span *ngFor="let row of hotJob; let i = index">
            <div class="newjobs-boxes-inner">
              <div class="newjobs-boxes-inner-left" (click)="jobDetails(row)" style="cursor: pointer">
                <h5>
                  <a href="javascript:void(0);">{{ row.title }}</a>
                </h5>
                <p>
                  <span *ngIf="row?.minimum_salary != '0'">
                    {{ row?.currency }} {{ row?.minimum_salary | number }}
                  </span>
                  <span *ngIf="row?.maximum_salary != '0'">
                    - {{ row?.currency }} {{ row?.maximum_salary | number }}
                  </span>
                  <span *ngIf="row?.minimum_salary == '0'">
                    Salary - Not specified or see the job description
                  </span>
                </p>
                <ul>
                  <li>{{ row.company_name }}</li>
                  <li>{{ row.location_type }}, {{ row.job_location }}</li>
                </ul>
              </div>
              <div class="newjobs-boxes-inner-right">
                <ul>
                  <li *ngIf="!row?.isSaved">
                    <a href="javascript:void(0);" (click)="savedJob(row)"><i class="far fa-heart"></i></a>
                  </li>
                  <li *ngIf="row?.isSaved">
                    <a href="javascript:void(0);" (click)="savedJob(row)" class="active"><i
                        class="far fa-heart"></i></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" (click)="shareJob(row)"><i class="fas fa-share-alt"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </span>
          <span *ngIf="hotJob?.length > 5">
            <a href="javascript:void(0);">See all Hot Jobs <i class="fas fa-chevron-right"></i></a>
          </span>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="newjobs-boxes-wrap">
          <h4>Recently Posted</h4>
          <span *ngFor="let row of recentJob; let i = index">
            <div class="newjobs-boxes-inner">
              <div class="newjobs-boxes-inner-left" (click)="jobDetails(row)" style="cursor: pointer">
                <h5>
                  <a href="javascript:void(0);">{{ row.title }}</a>
                </h5>
                <p>
                  <span *ngIf="row?.minimum_salary != '0'">{{ row?.currency }}
                    {{ row?.minimum_salary | number }}</span>
                  <span *ngIf="row?.maximum_salary != '0'">
                    - {{ row?.currency }}
                    {{ row?.maximum_salary | number }}</span>
                  <span *ngIf="row?.minimum_salary == '0'">
                    Salary - Not specified or see the job description</span>
                </p>
                <ul>
                  <li>{{ row.company_name }}</li>
                  <li>{{ row.location_type }}, {{ row.job_location }}</li>
                </ul>
              </div>
              <div class="newjobs-boxes-inner-right">
                <ul>
                  <li *ngIf="!row?.isSaved">
                    <a href="javascript:void(0);" (click)="savedJob(row)"><i class="far fa-heart"></i></a>
                  </li>
                  <li *ngIf="row?.isSaved">
                    <a href="javascript:void(0);" (click)="savedJob(row)" class="active"><i
                        class="far fa-heart"></i></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" (click)="shareJob(row)"><i class="fas fa-share-alt"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </span>
          <span *ngIf="recentJob?.length > 5">
            <a href="javascript:void(0);">See all Recently Posted Jobs <i class="fas fa-chevron-right"></i></a>
          </span>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="job-categories common-padd pb-0">
  <div class="container">
    <div class="row text-center">
      <div class="col-lg-12">
        <div class="hdr gray">
          <h2>Job Categories</h2>
          <h5>Select from below to discover jobs based on a category</h5>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-4" *ngFor="let column of getColumns(jobCatagoryTypeList)">
        <div class="newcatlist">
          <ul>
            <li *ngFor="let row of column">
              <a href="javascript:void(0);" (click)="categotyWiseJobSearch(row)">{{ row.categoryName }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row text-center align-items-center">
      <div class="col-lg-12">
        <div class="hdr mb-5">
          <h5>Did not find the right category?</h5>
          <a href="javascript:void(0);" routerLink="/job-search">Browse all jobs</a>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="employer pb-0 bg-dark-green">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="image-container">
          <img src="../assets/images/new-job-element.webp" class="new-job-element" />
          <div class="text-container">
            <h2 class="para-6">Are you an employer?</h2>
            <h5 class="para-7">
              Discover the right professional from Nigeria’s largest work
              resource!
            </h5>
            <a href="javascript:void(0);" (click)="postAjob()" class="nbtn-white m20">Post Job Opportunities</a>
            <h5 class="para-7">
              We verify our employers. <br />
              Keeping job seekers safe is our priority.
            </h5>
            <a href="javascript:void(0);" (click)="Dashboard()" class="nbtn-white">Get Verified</a>
          </div>
        </div>
      </div>
      <div class="col-lg-6 align-items-center justify-content-center">
        <div class="img">
          <img class="new-job-posting" src="../assets/images/employers-img.webp" />
        </div>
      </div>
    </div>
  </div>
</section>