import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { EmployerService } from 'src/app/services/employer.service';
import { MatDialog } from '@angular/material/dialog';
import { PopupConfirmationComponent } from 'src/app/container/elements/popup-confirmation/popup-confirmation.component';
import { HttpIOService } from 'src/app/services/http-io.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-emp-dash-saved-cv',
  templateUrl: './emp-dash-saved-cv.component.html',
  styleUrls: ['./emp-dash-saved-cv.component.scss']
})
export class EmpDashSavedCvComponent implements OnInit {
  cards = [
    {
      image: 'assets/images/help-hire.webp',
      title: 'Let’s help you hire',
      subtitle: 'Fast, Easy and Seamless',
      buttonText: 'Get Started',
      buttonAction: 'hiringTeam',
      headingClass: 'help-hire-h1',
      buttonClass: 'hire-btn',
    },
    {
      image: 'assets/images/higher-plan.webp',
      title: 'Move to higher plan to enjoy more amazing features',
      subtitle: '',
      buttonText: 'Upgrade Plan',
      buttonAction: 'upgradePlan',
      headingClass: 'higher-plan-h1',
      buttonClass: 'higher-btn',
    },
    {
      image: 'assets/images/training-staff.webp',
      title: 'Do you have a training need for your staffs?',
      subtitle: 'Let our professionals help you.',
      buttonText: 'Get Started',
      buttonAction: 'trainingStaff',
      headingClass: 'training-staff-h1',
      buttonClass: 'training-btn',
    },
    {
      image: 'assets/images/know-employee.webp',
      title: 'How well do you know your employee?',
      subtitle: 'Let’s help you verify their background',
      buttonText: 'Get Started',
      buttonAction: 'knowEmployee',
      headingClass: 'know-employee-h1',
      buttonClass: 'know-btn',
    }
  ]
  currentCardIndex: number = 0;

  constructor(
    private datePipe: DatePipe,
    private router: Router,
    private employerService: EmployerService,
    public dialog: MatDialog,
    private io: HttpIOService,
    private dataService: DataService,
  ) { }

  ngOnInit() {
    this.getFolders();

    const initialCard = document.querySelector('.dashboard-ad') as HTMLElement;
    if (initialCard) {
      setTimeout(() => {
        initialCard.style.opacity = '1';
        initialCard.style.transition = 'opacity 1s ease-in-out';
      }, 5000);
    }
    // Start the card switching cycle:
    this.cycleCards();
  }
  searchCorner: UntypedFormGroup;
  clearButton = false;

  statusOfEmployer = 'bronze';

  showRightSectionInMobile: boolean = false;
  currentIndex: number;
  status: boolean = false;
  detailsOfJob: any = '';

  loadMoreButtonVisible: boolean = true;

  folders: any;
  isHovered = false;

  candidatesList : any = []

  // GET JOB DETAILS //
  jobDetails(i, list) {
    // console.log(i);
    this.showRightSectionInMobile = true;
    this.status = list.id;
    this.currentIndex = i;
    this.detailsOfJob = this.candidatesList[i];
  }

  navigateToContactUs(title: any) {
    this.dataService.setData({ value: title, label: title });
    this.router.navigate(['/contactUs']);
  }

  onScrollDown() { }

  mobileViewClassClose() {
    this.showRightSectionInMobile = false;
  }

  navigateToSearchCV(){
    this.router.navigate(['Dashboard'], {
      queryParams: ['search-cv']
    });
  }

  createNewFolder() {
    const dialogRef = this.dialog.open(PopupConfirmationComponent, {
      height: 'auto',
      width: 'auto',
      autoFocus: false,
      data: {
        data: 'create new folder'
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      let params = {
        folderName: result,
      };
      this.employerService._createEmptyFolder(params).subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          this.folders = res['result'];
        }
      })
    });
  }

  formatDate(date: string) {
    let formattedDate = this.datePipe.transform(date, 'dd MMM yyyy');
    return formattedDate;
  }

  inviteToApply(job) {
    const dialogRef = this.dialog.open(PopupConfirmationComponent, {
      height: 'auto',
      width: 'auto',
      autoFocus: false,
      data: {
        data: 'invite to apply',
        jobDetails: job,
      },
    });
    dialogRef.afterClosed().subscribe();
  }

  messageOpen(row) {
    this.router.navigate(['/message']);
    this.employerService.setforSeletedIn_cvSearch(row);
  }

  unlockCV(candidateId) {
    this.io._unlockCV({candidateId}).subscribe((res: any) => {
      if (res['serverResponse']['code'] == 200) {
        this.loadUpdatedAssets()
        this.detailsOfJob = res.result
        this.replaceUnlockedCandidate(candidateId, res.result)
      }
    })
  }

  openSavedCV() {
    this.router.navigate(['saved-cv']);
  }

  getFolders() {
    this.employerService._getFolderforCVs().subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        this.folders = res['result'];
      }
    });
  }

  convertMonthsToYearsAndMonths(months: number) {
    const years = Math.floor(months / 12);
    const remainingMonths = months % 12;
    let result = `${years} ${years === 1 ? 'Year' : 'Years'}`;
    if (remainingMonths > 0) {
        result += ` ${remainingMonths} ${remainingMonths === 1 ? 'Month' : 'Months'}`;
    }
    return result;
}

  getCandidates(candidateIds: any) {
    let body = {
      candidateIDs: candidateIds
    }
    this.employerService._getFolderCandidates(body).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        this.candidatesList = res['result'];
      }
    });
  }
  selectedFolderId: string | null = null;

  onFolderHover(folderId: string) {
    // Change the image source when hovering
    if (this.selectedFolderId !== folderId) {
      // Change the image source only if the folder is not selected
      // You can update the logic based on your image sources
      // e.g., folder.iconHovered = 'path_to_hovered_image';
    }
  }

  onFolderUnhover(folderId: string) {
    // Change the image source back to the default when unhovering
    if (this.selectedFolderId !== folderId) {
      // Change the image source only if the folder is not selected
      // e.g., folder.iconHovered = 'path_to_default_image';
    }
  }

  onFolderClick(folderId: string, i) {
    this.selectedFolderId = this.selectedFolderId === folderId ? null : folderId;
    this.getCandidates(this.folders[i].candidates)

  }

  returnToJobs() {
    this.router.navigate(['Dashboard']);
  }

  loadUpdatedAssets() {
    this.dataService.loadUpdatedAssets().subscribe(() => {});
  }

  replaceUnlockedCandidate(candidateId, unlockedCV){
    this.candidatesList = this.candidatesList.map(candidate => {
          if(candidate.id === candidateId){
            return unlockedCV
          }else{
            return candidate
          }
        })
  }

  dateFormatter(date: string){
    const inputDate = moment.utc(date);
    return inputDate.format('MMMM YYYY').toUpperCase();
  }
  dateFormatterYear(date: string){
    const inputDate = moment.utc(date);
    return inputDate.format('YYYY');
  }

  cycleCards() {
    setTimeout(() => {
      this.currentCardIndex = (this.currentCardIndex + 1) % this.cards.length;
      // current card fade-out:
      const previousCard = document.querySelector('.dashboard-ad:not([style*="opacity: 0"])') as HTMLElement;
      if (previousCard) {
        previousCard.style.opacity = '0';
      }
      // new card fade-in
      const newCard = document.querySelector(`.dashboard-ad[style*="opacity: 0"]`) as HTMLElement;
      if (newCard) {
        setTimeout(() => {
          newCard.style.opacity = '1';
        }, 1000);
      }
      // Continuing the cycle:
      this.cycleCards();
    }, 5000);
  }

  ngAfterViewInit() {
    this.currentCardIndex = 0;
  }

  handleButtonClick(action: string) {
    if (action === 'hiringTeam') {
      this.dataService.setData({ value: 'Use our hiring team', label: 'Use our hiring team' });
      this.router.navigate(['/contactUs']);
    } else if (action === 'upgradePlan') {
      this.router.navigate(['/choose-employer-subscription']);
    }else if (action === 'trainingStaff') {
      this.dataService.setData({ value: 'Staff Trainings', label: 'Staff Trainings' });
      this.router.navigate(['/contactUs']);
    }else if (action === 'knowEmployee') {
      this.dataService.setData({ value: 'Know Your Employee', label: 'Know Your Employee' });
      this.router.navigate(['/contactUs']);
    }
  }

  trackByFn(index: number, item: any): number {
    return item.id || index;
  }
}
