<div class="search-ui" *ngIf="!showDesc">
    <div class="search-heading">
        <h1>The Right Place For Everything Job</h1>
        <!-- SEARCH JOBS INPUT ELEMENT -->
        <form [formGroup]="jobFilter">
            <div [ngClass]="userDeatils !== undefined && userDeatils.is_3mtt === true ? 'notdarkgreen' : 'darkgreen'"
                class="darkgreen">
                <div class="form-wrap search-filter-div">
                    <!-- <div class="form-row bdr-right"> -->
                    <mat-form-field class="example-full-width crossButtonCustom" appearance="fill">
                        <mat-label>Search Jobs</mat-label>
                        <input type="text" matInput placeholder="What: Title, skill or company"
                            [(ngModel)]="searchText2Way" formControlName="searchText" (keyup)="onKey($event)" />
                    </mat-form-field>
                    <div [class]="f.searchText.touched && f.searchText.invalid ? 'd-block' : 'd-none'">
                        <span *ngIf="f.searchText.touched && f.searchText.invalid">
                            <span class="text-left text-danger pb-1" *ngIf="f.searchText.errors.required">Search Title
                                is required
                            </span>
                            <span class="text-left text-danger pb-1" *ngIf="f.searchText.errors?.pattern">
                                Search Title is not valid.
                            </span>
                        </span>
                    </div>
                    <!-- <div class="form-row"> -->
                    <mat-form-field class="states-mat-form-field" appearance="fill">
                        <mat-label>States</mat-label>
                        <mat-select formControlName="job_location" placeholder=""
                            (selectionChange)="joblocationName($event.value)">
                            <mat-option>Any</mat-option>
                            <mat-option *ngFor="let row of cityRecords; let i = index" [value]="row">
                                {{ row }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <!-- </div> -->

                    <div class="form-row search-btn-div">
                        <button class="btn-filters" (click)="toggleFilter()"> <img src="../../../../assets/images/filter-icon.webp" /> Filters</button>
                        <button class="btn-search" [ngClass]="userDeatils !== undefined "
                            (click)="searchByDipendency(); resetSearch(showingTextResult)"
                            [disabled]="jobFilter.status === 'INVALID'">
                            Find Job
                        </button>
                    </div>
                </div>
            </div>
        </form>
        <div class="btns-div">
            <!-- <button (click)="postAjob()">Post A Job</button> -->
            <button class="create-alert" (click)="job_alert()">Create Job Alert</button>
            <button class="in-demand">In-demand Jobs</button>
        </div>
    </div>
    <div class="result-alert">
        <p *ngIf="totalNoJobs > 1" class="jobs-found-txt">{{ totalNoJobs }} jobs found</p>
        <p *ngIf="totalNoJobs === 0" class="jobs-found-txt">Loading Jobs...</p>
        <div class="alert-gtn-section" *ngIf="searchPhrase !== undefined">
            <p class="alert-txt" *ngIf="alert_off && searchText2Way !== ''">create alert for this search</p>
            <p class="alert-txt" *ngIf="alert_on">alert on for {{ searchPhrase }}</p>
            <div class="btn-toggle d-flex align-items-center" *ngIf="searchText2Way !== ''">
                <div class="alert-off" (click)="alertOff()" [ngClass]="{ active: alert_off }">
                    Off
                </div>
                <div class="alert-on" (click)="alertOn(); createAlert()" [ngClass]="{ active: alert_on }">
                    On
                </div>
            </div>
        </div>
    </div>
    <div class="row show-results">
        <!-- THE FILTERS -->
        <div class="col-lg-3 search-filters" [class] = "displayFilters ? 'search-filters-mobile' : 'search-filters'">
            <form [formGroup]="jobFilter">
                <h5>Filter</h5>
                <!-- JOB TYPE -->
                <h5>Job Type</h5>
                <ul class="checklist">
                    <li *ngFor="let jobType of employemntTypeList; let i = index">
                        <label>
                            <input type="radio" [value]="jobType.id" formControlName="employment_type_id"
                                (change)="onFilterChange($event)" />
                            {{ jobType.employmentType }}
                        </label>
                    </li>
                </ul>
                <!-- EXPERIENCE LEVEL -->
                <h5>Experience Level</h5>
                <div class="custom-dropdown">
                    <select (change)="onFilterChange($event)" formControlName="minimum_experience">
                        <option value="" selected>Experience</option>
                        <option value="{{ exp }}" *ngFor="let exp of expLvl">{{ exp }} year<span
                                *ngIf="exp > 1">s</span> </option>
                    </select>
                    <img src="../../../../assets/images/arrow-down.webp" alt="dropdown icon" class="dropdown-icon" />
                </div>
                <!-- <li *ngFor="let exp of expLvl" formControlName="minimum_experience">
                        <label>
                            <input type="checkbox" />
                            {{ exp.name }}
                        </label>
                    </li> -->
                <!-- DATE POSTED -->
                <h5>Date Posted</h5>
                <ul class="checklist">
                    <li *ngFor="let date of datePosted; let i = index">
                        <label>
                            <input type="radio" [value]="date.value" formControlName="created_at"
                                (change)="onFilterChange($event)" />
                            {{ date.name }}
                        </label>
                    </li>
                </ul>
                <!-- SALARY RANGE -->
                <h5>Salary Range</h5>
                <div class="custom-dropdown">
                    <select (change)="onFilterChange($event)" formControlName="minimum_salary">
                        <option value="" selected>Minimum Salary</option>
                        <option value="{{ min }}" *ngFor="let min of Salary">{{ min | currency : " ₦ " }} +</option>
                    </select>
                    <img src="../../../../assets/images/arrow-down.webp" alt="dropdown icon" class="dropdown-icon" />
                </div>
                <!-- CATEGORY -->
                <h5>Category</h5>
                <div class="custom-dropdown">
                    <select (change)="onFilterChange($event)" formControlName="category_id">
                        <option value="" selected>Category</option>
                        <option value="{{ jobCategory?.id }}" *ngFor="let jobCategory of jobCatagoryTypeList">{{
                            jobCategory?.categoryName }} </option>
                    </select>
                    <img src="../../../../assets/images/arrow-down.webp" alt="dropdown icon" class="dropdown-icon" />
                </div>
            </form>
        </div>

        <!-- THE JOB CARDS -->
        <div class="col-lg-9 show-jobs">
            <div class="row">
                <div class="card-container">
                    <div *ngFor="let job of jobLists; let i = index" [ngClass]="
                            status == job?.id && (job.isPromoted)
                                ? 'card upcoming-cards success promoted'
                                : job.isPromoted
                                ? 'card upcoming-cards promoted'
                                : status == job?.id
                                ? 'card upcoming-cards success'
                                : 'card upcoming-cards'" (click)="jobDetails(i, job)">

                        <div class="bodyjobsearch-img" *ngIf="job.is_3mtt">
                            <img [src]="'../../../assets/images/3mtt.svg'"
                                onerror="this.onerror=null;this.src='../../../assets/images/sm-Logo.png'" />
                        </div>
                        <!-- JOB TITLE AND SALARY  -->
                        <div class="jobsearch-desc">
                            <h5>{{ job?.title }}</h5>
                            <div class="tag-hot-list">
                                <div class="emp-type"
                                    [style.display]="(job?.employment_type === 'None' || job?.employment_type === '') ? 'none' : 'relative'">
                                    <a href="javascript:void(0);">{{
                                        job?.employment_type }}</a>
                                </div>
                                <div class="posted-at">
                                    <a href="javascript:void(0);"> {{ eventExpireIn(job?.created_at) }} ago</a>
                                </div>
                                <div>
                                    <h6 class="salary-type">
                                        Salary:
                                        <span *ngIf="
                                          job?.minimum_salary != '0' &&
                                          job?.minimum_salary != ''
                                        ">{{ job?.currency }}
                                            {{ job?.minimum_salary | number }}</span>
                                        <span *ngIf="
                                          job?.maximum_salary != '0' &&
                                          job?.maximum_salary != ''
                                        ">
                                            - {{ job?.currency }}
                                            {{ job?.maximum_salary | number }}</span>
                                        <span *ngIf="
                                          job?.minimum_salary == '0' ||
                                          job?.minimum_salary == ''
                                        ">
                                            {{ job?.salary_type }}</span>
                                    </h6>
                                </div>
                            </div>
                            <!-- IMAGE AND LOCATION -->
                            <div class="job-desc-city-img">
                                <div class="d-flex align-items-center gap-3">
                                    <!-- Image -->
                                    <div class="comp-img" *ngIf="!job.is_3mtt">
                                        <img [src]="job.company_behalf_logo
                                          ? job.company_behalf_logo
                                          : job.logo
                                          ? job.logo
                                          : '../../../assets/images/sm-Logo.png'"
                                            onerror="this.onerror=null;this.src='../../../assets/images/sm-Logo.png'" />
                                    </div>
                                    <div class="location">
                                        <p>{{ job?.company_name }}</p>
                                        <span>
                                            <img src="../../../../assets/images/pin-location.webp" />
                                        </span>
                                        <span class="job-desc-location">
                                            {{ job?.job_location }}, {{ job?.location_type }}
                                        </span>
                                    </div>
                                </div>
                                <!-- BOOKMARK -->
                                <div class="save-icon">
                                    <img src="../../../../assets/images/grey-bookmark.webp"
                                        *ngIf="!detailsOfJob?.isSaved" (click)="savedJob(job)" />
                                    <img src="../../../../assets/images/grey-filled-bookmark.webp"
                                        *ngIf="detailsOfJob?.isSaved" (click)="savedJob(job)" />
                                </div>
                            </div>
                            <div *ngIf="job?.isPromoted" class="sponsored">
                                <a class="sponsored-img"><img src="../../../../assets/images/promoted.svg" /></a>
                                <h6 class="sponsored-tag">Sponsored</h6>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <ngb-pagination *ngIf="jobLists?.length > 0" [page]="currentPage" [pageSize]="pageSize" [maxSize]="5"
                (pageChange)="onPageChanged($event)" [ellipses]="false" [rotate]="true"
                [collectionSize]="collectionSize">
            </ngb-pagination>
        </div>
    </div>
</div>
<!--------------------DISPLAY THE JOB---------------------------->
<div class="job-description" [ngClass]="showRightSectionInMobile ? 'mobile-view-parent' : ''"
    *ngIf="detailsOfJob && showDesc" id="topOfDesc">
    <!-- Return to Job Cards -->
    <div class="return-to-jobs">
        <button (click)="clearJobDetails()">
            <img src="../../../../assets/images/left-arrow.webp" />
            Return to Job Search
        </button>
        <!-- THE HOME TO JOB DETAILS ROUTE -->
        <div class="back-to-home">
            <p><span (click)="clearJobDetails()">Home /</span><span>{{ detailsOfJob.job_category }} /</span><span
                    class="job-deets">Job Details</span></p>
        </div>
    </div>

    <!---------- THE JOB DETAILS ---------->
    <div class="job-desc-name">
        <div class="name-img">
            <!-- COMPANY IMAGE -->
            <div class="company-img" *ngIf="detailsOfJob.is_3mtt">
                <img [src]="'../../../assets/images/3mtt.svg'"
                    onerror="this.onerror=null;this.src='../../../assets/images/sm-Logo.png'" />
            </div>
            <div class="company-img" *ngIf="!detailsOfJob.is_3mtt">
                <img [src]="detailsOfJob.company_behalf_logo
                          ? detailsOfJob.company_behalf_logo
                          : detailsOfJob.logo
                          ? detailsOfJob.logo
                          : '../../../assets/images/sm-Logo.png'"
                    onerror="this.onerror=null;this.src='../../../assets/images/sm-Logo.png'" />
            </div>
            <!-- COMPANY JOB NAME  -->
            <div class="company-name">
                <h1>{{ detailsOfJob.title }}</h1>
                <p><span>at {{ detailsOfJob.company_name }}</span><span class="emp-t">{{ detailsOfJob.employment_type
                        }}</span> <span *ngIf="detailsOfJob.isPromoted" class="is-promoted">Promoted</span></p>
            </div>
        </div>
        <div class="apply-now">
            <div class="bookmark">
                <img src="../../../../assets/images/green-bookmark.webp" (click)="savedJob(detailsOfJob)" />
            </div>
            <button (click)="applyJob(detailsOfJob)">Apply Now</button>
        </div>
    </div>
    <div class="job-details row">
        <div class="col-lg-8 show-desc">
            <!-- Description -->
            <h2>Job Description</h2>
            <p [innerHTML]="jobDesc"></p>
            <!-- Qualification -->
            <h2>Requirements</h2>
            <p [innerHTML]="qualifications"></p>
            <!-- Company Infor -->
            <h2>Company Info</h2>
            <p class="company-name">
                {{
                detailsOfJob?.company_behalf_name
                ? detailsOfJob?.company_behalf_name
                : detailsOfJob?.company_name
                }}
            </p>
            <p [innerHTML]="eachJobCompanyDetails?.about"></p>
        </div>
        <div class="col-lg-4">
            <!-- JOB OVERVIEW -->
            <div class="job-overview">
                <h3>Job Overview</h3>
                <!----- TITLE ----->
                <div class="job-overview-details">
                    <img src="../../../../assets/images/green-user.webp" />
                    <div>
                        <h4>Job Title</h4>
                        <p>{{ detailsOfJob.title }}</p>
                    </div>
                </div>
                <!----- TYPE ----->
                <div class="job-overview-details">
                    <img src="../../../../assets/images/green-clock.webp" />
                    <div>
                        <h4>Job Type</h4>
                        <p>{{ detailsOfJob.employment_type }}</p>
                    </div>
                </div>
                <!----- CATEGORY ----->
                <div class="job-overview-details">
                    <img src="../../../../assets/images/green-briefcase.webp" />
                    <div>
                        <h4>Category</h4>
                        <p>{{ detailsOfJob.job_category }}</p>
                    </div>
                </div>
                <!----- EXPERIENCE ----->
                <div class="job-overview-details">
                    <img src="../../../../assets/images/green-expertise.webp" />
                    <div>
                        <h4>Experience</h4>
                        <p>{{ detailsOfJob.minimum_experience }} Year<span
                                *ngIf="detailsOfJob.minimum_experience > 1">s</span></p>
                    </div>
                </div>
                <!----- SALARY ----->
                <div class="job-overview-details">
                    <img src="../../../../assets/images/green-wallet.webp" />
                    <div>
                        <h4>Offered Salary</h4>
                        <p *ngIf="detailsOfJob.salary_type">{{ detailsOfJob.salary_type }}</p>
                        <p *ngIf="!detailsOfJob.salary_type">{{ detailsOfJob.currency }} {{ detailsOfJob.minimum_salary
                            }}</p>
                    </div>
                </div>
                <!----- LOCATION ----->
                <div class="job-overview-details">
                    <img src="../../../../assets/images/green-pin.webp" />
                    <div>
                        <h4>Location</h4>
                        <p>{{ detailsOfJob.job_location }}, {{ detailsOfJob.location_type }}</p>
                    </div>
                </div>
            </div>
            <!-- SHARE JOBS -->
            <div class="share-job">
                <h3>Share this Job:</h3>
                <div class="links">
                    <div class="copy-hook" (click)="copyUrl()">
                        <p><img src="../../../../assets/images/copy-hook.webp" />
                            Copy Link</p>
                    </div>
                    <a href="javascript:void(0);" (click)="openSocialMedia('facebook')">
                        <img src="../../../../assets/images/facebook-icon.png" alt="" />
                    </a>
                    <a href="javascript:void(0);" (click)="openSocialMedia('linkedin')">
                        <img src="../../../../assets/images/linkedin.png" alt="" />
                    </a>
                    <a [href]="'mailto:' +'?subject=' + email_title + '&body=' + url">
                        <img src="../../../../assets/images/envelope-icon.webp" alt="" height="24" />
                    </a>
                </div>
            </div>
        </div>
    </div>
    <!-- RELATED JOBS SECTION -->
    <div class="related-jobs-sec" *ngIf="allRelatedJob" #relatedSection id="related">
        <h3>Related Jobs</h3>
        <div class="card-container">
            <div *ngFor="let job of allRelatedJob; let i = index"
                (click)="onScrollJobChange('topOfDesc'); jobDetailsRelated(i, job)" [ngClass]="
                            status == job?.id && (job.isPromoted)
                                ? 'card upcoming-cards success promoted'
                                : job.isPromoted
                                ? 'card upcoming-cards promoted'
                                : status == job?.id
                                ? 'card upcoming-cards success'
                                : 'card upcoming-cards'">
                <!-- JOB TITLE AND SALARY  -->
                <div class="jobsearch-desc">
                    <h5>{{ job?.title }}</h5>
                    <div class="tag-hot-list">
                        <div class="emp-type"
                            [style.display]="(job?.employment_type === 'None' || job?.employment_type === '') ? 'none' : 'relative'">
                            <a href="javascript:void(0);" class="employment-type">{{
                                job?.employment_type }}</a>
                        </div>
                        <div>
                            <h6 class="salary-type">
                                Salary:
                                <span *ngIf="
                                  job?.minimum_salary != '0' &&
                                  job?.minimum_salary != ''
                                ">{{ job?.currency }}
                                    {{ job?.minimum_salary | number }}</span>
                                <span *ngIf="
                                  job?.maximum_salary != '0' &&
                                  job?.maximum_salary != ''
                                ">
                                    - {{ job?.currency }}
                                    {{ job?.maximum_salary | number }}</span>
                                <span *ngIf="
                                  job?.minimum_salary == '0' ||
                                  job?.minimum_salary == ''
                                ">
                                    {{ job?.salary_type }}</span>
                            </h6>
                        </div>
                    </div>
                    <!-- IMAGE AND LOCATION -->
                    <div class="job-desc-city-img">
                        <div class="d-flex align-items-center gap-3">
                            <!-- Image -->
                            <div class="comp-img" *ngIf="!job.is_3mtt">
                                <img [src]="job.company_behalf_logo
                                  ? job.company_behalf_logo
                                  : job.logo
                                  ? job.logo
                                  : '../../../assets/images/sm-Logo.png'"
                                    onerror="this.onerror=null;this.src='../../../assets/images/sm-Logo.png'" />
                            </div>
                            <div class="location">
                                <p>{{ job?.company_name }}</p>
                                <span>
                                    <img src="../../../../assets/images/pin-location.webp" />
                                </span>
                                <span class="job-desc-location">
                                    {{ job?.job_location }}, {{ job?.location_type }}
                                </span>
                            </div>
                        </div>
                        <!-- BOOKMARK -->
                        <div class="save-icon">
                            <img src="../../../../assets/images/grey-bookmark.webp" *ngIf="!detailsOfJob?.isSaved"
                                (click)="savedJob(job)" />
                            <img src="../../../../assets/images/grey-filled-bookmark.webp" *ngIf="detailsOfJob?.isSaved"
                                (click)="savedJob(job)" />
                        </div>
                    </div>
                    <div *ngIf="job?.isPromoted" class="sponsored">
                        <a class="sponsored-img"><img src="../../../../assets/images/promoted.svg" /></a>
                        <h6 class="sponsored-tag">Sponsored</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>