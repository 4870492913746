import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { waitForAsync } from '@angular/core/testing';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Title } from '@angular/platform-browser';
import { Observable, ReplaySubject } from 'rxjs';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { DynamicSnacbarSkipComponent } from 'src/app/container/elements/dynamic-snacbar-skip/dynamic-snacbar-skip.component';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { HttpIOService } from 'src/app/services/http-io.service';
@Component({
  selector: 'app-via-work-nigeria',
  templateUrl: './via-work-nigeria.component.html',
  styleUrls: ['./via-work-nigeria.component.scss'],
})
export class ViaWorkNigeriaComponent implements OnInit {
  collectionRecords: any = [
    { name: 'subho' },
    { name: 'subho2' },
    { name: 'subho3' },
  ];
  show: boolean = true;
  userDeatils: any;
  companyData: any;
  guestUser: any;
  uploadFileResult: any = [];
  fileToUpload: File;
  fileName: any;
  uploadDocsRecords: any;
  selectedDocs: any = [];
  applyButton: boolean;
  applyButtonCover: boolean;
  fileNameCoverLetter: any;
  fileToUploadCoverLetter: any;
  uploadFileResultCoverLetter: any;
  uploadDocsRecordsCoverLetter: any;
  selectedDocsCoverLetter: any = [];
  checked: boolean = false;
  disabled = false;
  declrationCheckBox: boolean;
  selectedCV: any;
  selectedCVcover: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private titleService: Title,
    public dialogRef: MatDialogRef<ViaWorkNigeriaComponent>,
    private snackBar: MatSnackBar,
    private io: HttpIOService,
    private auth: AuthService,
    private helper: Helper
  ) {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');

    if (localUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
    }
    if (sessionUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }
    let guestUser = sessionStorage.getItem('guestUser');
    if (guestUser) {
      this.guestUser = JSON.parse(atob(sessionStorage.getItem('guestUser')));
    }
  }

  ngOnInit(): void {
    // console.log(this.data);
    // console.log(this.userDeatils.isResumeParsed);
    if (this.userDeatils != undefined) {
      this.userDocuments();
      this.userDocumentsCoverLetter();
    } else {
      this.show = false;
    }

    // if (this.data['user'] == 'WithoutSignIn') {
    //   this.show = true;
    // }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  userDocuments() {
    let jsonData = {
      path: 'user-documents',
      userId: this.userDeatils.id,
      documentType: 'resume',
    };

    this.io._userDocuments(jsonData).subscribe(
      (res: any) => {
        if (res.serverResponse.code == 200) {
          this.uploadDocsRecords = res['result'].userDocuments;
          // console.log(this.uploadDocsRecords)
          if (this.uploadDocsRecords.length == 0) {
            // this.snackBar.open("No records found", "OK", {
            // })
            // this.snackBar.openFromComponent(DynamicSnacbarSkipComponent, {
            //   data:"No records found",
            //   verticalPosition: 'top', // 'top' | 'bottom'
            //   horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            //    }).afterOpened().subscribe(this.helper.setOnTop);
          }
        }
      },
      (err) => console.log(err)
    );
  }

  userDocumentsCoverLetter() {
    let jsonData = {
      path: 'user-documents',
      userId: this.userDeatils.id,
      documentType: 'coverLetter',
    };

    this.io._userDocuments(jsonData).subscribe(
      (res: any) => {
        if (res.serverResponse.code == 200) {
          this.uploadDocsRecordsCoverLetter = res['result'].userDocuments;
          //  console.log(this.uploadDocsRecordsCoverLetter)
          // this.snackBar.open(res.serverResponse.message, "OK", {
          // })
          if (this.uploadDocsRecordsCoverLetter.length == 0) {
            // this.snackBar.openFromComponent(DynamicSnacbarSkipComponent, {
            //   data:"No records found",
            //   verticalPosition: 'top', // 'top' | 'bottom'
            //   horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            //    }).afterOpened().subscribe(this.helper.setOnTop);
          }
        }
      },
      (err) => console.log(err)
    );
  }

  selectByUploadDocs(event) {
    this.selectedDocs = [];
    let value = event.value;
    this.selectedDocs = this.uploadDocsRecords.filter(
      (name) => name.id == value
    );

    // console.log(this.selectedDocs)
    // if (this.selectedDocs == 'select CV') {
    //   this.applyButton = false;
    // }
    // else {
    //   this.applyButton = true;
    // }
  }

  selectByUploadDocsCoverLetter(event) {
    this.selectedDocsCoverLetter = [];
    let value = event.value;
    this.selectedDocsCoverLetter = this.uploadDocsRecordsCoverLetter.filter(
      (name) => name.id == value
    );
    // console.log(this.selectedDocsCoverLetter)
    // if (this.selectedDocsCoverLetter == 'select Cover') {
    //   this.applyButtonCover = false;
    // }
    // else {
    //   this.applyButtonCover = true;
    // }
  }

  async onSelectFile(event) {
    this.selectedDocs = [];
    // console.log(event.target.files[0])
    this.fileName = event.target.files[0].name;
    // console.log(this.fileName)
    if (event.target.files && event.target.files[0]) {
      let filesAmount = event.target.files.length;
      const formData = new FormData();
      for (let i = 0; i < filesAmount; i++) {
        this.fileToUpload = event.target.files[i];
        formData.append('file', this.fileToUpload);
      }
      formData.append('documentType', 'resume');
      formData.append(
        'userId',
        this.userDeatils != undefined ? this.userDeatils.id : this.guestUser.id
      );
      formData.append('path', 'upload-file');
      formData.append('documentName', this.fileName);
      formData.append('purpose', 'resumeUpload');
      this.io._uploadFile(formData).subscribe(
        (res: any) => {
          if (res.serverResponse.code == 200) {
            this.uploadFileResult = res['result'];
            if (this.userDeatils != undefined) {
              this.userDocuments();
            }
            this.selectedCV = this.uploadFileResult.id;
            this.selectedDocs.push(this.uploadFileResult);
            //   setTimeout(() => {
            //     this.selectedCV = this.uploadFileResult.fileName;
            //   }, 1000);
            // console.log(this.uploadFileResult);
            // this.snackBar.open(res.serverResponse.message, "OK", {
            // })
            this.snackBar
              .openFromComponent(DynamicSnacbarComponent, {
                data: res.serverResponse.message,
                verticalPosition: 'top', // 'top' | 'bottom'
                horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
              })
              .afterOpened()
              .subscribe(this.helper.setOnTop);
          }
        },
        (err) => console.log(err)
      );
    }
  }

  onSelectFileCoverLetter(event) {
    this.selectedDocsCoverLetter = [];
    // console.log(event.target.files[0])
    this.fileNameCoverLetter = event.target.files[0].name;
    // console.log(this.fileNameCoverLetter)
    if (event.target.files && event.target.files[0]) {
      let filesAmount = event.target.files.length;
      const formData = new FormData();
      for (let i = 0; i < filesAmount; i++) {
        this.fileToUploadCoverLetter = event.target.files[i];
        formData.append('file', this.fileToUploadCoverLetter);
      }
      formData.append('documentType', 'coverLetter');
      formData.append(
        'userId',
        this.userDeatils != undefined ? this.userDeatils.id : this.guestUser.id
      );
      formData.append('path', 'upload-file');
      formData.append('documentName', this.fileNameCoverLetter);
      formData.append('purpose', 'coverLetterUpload');
      this.io._uploadFile(formData).subscribe(
        (res: any) => {
          if (res.serverResponse.code == 200) {
            if (this.userDeatils != undefined) {
              this.userDocumentsCoverLetter();
            }
            this.uploadFileResultCoverLetter = res['result'];
            this.selectedCVcover = this.uploadFileResultCoverLetter.id;
            this.selectedDocsCoverLetter.push(this.uploadFileResultCoverLetter);
            // console.log(this.uploadFileResultCoverLetter);
            this.snackBar
              .openFromComponent(DynamicSnacbarComponent, {
                data: res.serverResponse.message,
                verticalPosition: 'top', // 'top' | 'bottom'
                horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
              })
              .afterOpened()
              .subscribe(this.helper.setOnTop);
          }
        },
        (err) => console.log(err)
      );
    }
  }

  uploadDocs() {
    // console.log(this.selectedDocsCoverLetter ,this.selectedDocs)
    let docs: any = [];

    if (this.selectedDocs.length != 0) {
      docs.push({
        id: this.selectedDocs[0].id,
        url: this.selectedDocs[0].documentUrl,
        purpose: 'resume',
      });
    }

    if (this.selectedDocsCoverLetter.length != 0) {
      docs.push({
        id: this.selectedDocsCoverLetter[0].id,
        url: this.selectedDocsCoverLetter[0].documentUrl,
        purpose: 'coverLetter',
      });
    }

    // console.log(docs);
    return docs;
  }

  checkDeclaration(event) {
    // console.log(event)
    this.declrationCheckBox = event;
    // console.log(this.declrationCheckBox)
  }

  applyJob() {
    // console.log(this.selectedDocs)
    if (this.selectedDocs.length == 0) {
      this.snackBar
        .openFromComponent(DynamicSnacbarSkipComponent, {
          data: 'Please select a CV ',
          verticalPosition: 'top', // 'top' | 'bottom'
          horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
        })
        .afterOpened()
        .subscribe(this.helper.setOnTop);
      return false;
    }
    // if ( this.selectedDocsCoverLetter.length == 0) {
    //   // this.snackBar.open("Please select a Cover letter ", "OK", {
    //   // });
    //   this.snackBar.openFromComponent(DynamicSnacbarSkipComponent, {
    //     data:"Please select a Cover letter "
    //   });
    //   return false;
    // }

    let jsonData = {
      path: 'apply-job',
      jobId: this.data['Perticularjob'].id,
      userId:
        this.userDeatils != undefined ? this.userDeatils.id : this.guestUser.id,
      documents: this.uploadDocs(),
      title: this.data['Perticularjob'].title,
      from:
        this.userDeatils != undefined
          ? this.userDeatils.email
          : this.guestUser.email,
      name:
        this.userDeatils != undefined
          ? `${this.userDeatils.firstName} ${this.userDeatils.lastName}`
          : `${this.guestUser.firstName} ${this.guestUser.lastName}`,
      job_provider_id: this.data['Perticularjob'].job_provider_id,
      company_id: this.data['Perticularjob'].company_id,
      workNigeriaPublicProfileId:
        this.declrationCheckBox == true ? this.userDeatils.publicId : '',
    };
    // console.log(jsonData);
    this.io._applyViaWorkNigeria(jsonData).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        // console.log(res);
        this.dialogRef.close('save');

        this.snackBar
          .openFromComponent(DynamicSnacbarComponent, {
            data: res.serverResponse.message,
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
        sessionStorage.removeItem('guestUser');
      } else {
        this.snackBar
          .openFromComponent(DynamicSnacbarErrorComponent, {
            data: res.serverResponse.message,
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            duration: 6000,
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
      }
    });
  }
}
