<section class="common-padd-lt-rt profile-wrap">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <!--

                <div class="profile-big-ttl"
                    style="background-image: url(../assets/images/star-bg.png); background-repeat: no-repeat; background-position: right center;  background-size: cover;">
                    <h1>User Profile</h1>
                </div>-->
        <div class="small-banner">
          <div class="img">
            <img src="../../../assets/images/User-profile.jpg" />
          </div>
        </div>

        <div class="import-cv-top" *ngIf="!this.profileRecords.isResumeParsed">
          <div class="row align-items-center">
            <div class="col-lg-7">
              <div class="import-cv-top-desc">
                <h1>Import your CV</h1>
                <p>
                  Already have a CV? Upload the file (doc, docx, or pdf) and we
                  will automatically convert it to an online profile that you
                  can edit, share as a link, and use to apply for positions.
                  Recruiters can also search for you based on your profile to
                  see if you are a match for open jobs.
                </p>
                <div class="upload-cv-btn-wrap">
                  <a
                    _ngcontent-cwt-c376=""
                    href="javascript:void(0);"
                    class="btn-green"
                  >
                    Upload CV</a
                  >
                  <input
                    _ngcontent-cwt-c376=""
                    type="file"
                    (change)="onSelectFile($event)"
                    accept="application/pdf,application/docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-5">
              <figure>
                <div class="img">
                  <img src="../assets/images/image75.png" />
                </div>
              </figure>
            </div>
          </div>
        </div>

        <div class="prfl-userbox">
          <div class="edt-hdng">
            <h3>Profile</h3>
            <a href="javascript:void(0);" (click)="profile()" class="edit-link"
              ><i class="fas fa-pen"></i> EDIT PROFILE</a
            >
          </div>
          <div class="profile-user-topinfo">
            <div class="user-img">
              <img
                src="{{
                  profileRecords?.profileImage
                    ? profileRecords?.profileImage
                    : '../assets/images/defaultuser.png'
                }}"
                alt=""
              />
            </div>
            <div class="pro-top-hd sm-padd-btm">
              <a
                href="javascript:void(0);"
                class="btn-green"
                (click)="exportAsPDF()"
                ><i class="fas fa-download"></i> Download as CV</a
              >
              &nbsp;
              <a
                href="javascript:void(0);"
                class="btn-green wh-bg"
                (click)="shareJob()"
                ><i class="fas fa-share-alt"></i> Share profile link</a
              >
            </div>
            <h5>
              {{ profileRecords?.firstName }} {{ profileRecords?.middleName }}  {{ profileRecords?.lastName }}
            </h5>
            <h5>{{ profileRecords?.contactNumber }}</h5>
            <p>About</p>
            <div class="info-list">
              <ul>
                <li>
                  <div [innerHTML]="profileRecords.about"></div>
                  <!-- <p [innerHTML]="profileRecords.about"></p> -->
                </li>
                <li *ngIf="profileRecords?.title != ''">
                  Title - {{ profileRecords?.title }}
                </li>
                <li *ngIf="profileRecords?.website != ''">
                  Website - {{ profileRecords?.website }}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="prfl-userbox">
          <div class="edt-hdng">
            <h4>Education</h4>
            <a
              href="javascript:void(0);"
              (click)="education('NEW')"
              class="btn btn-green"
              >Add Education</a
            >
          </div>
          <div class="profile-info-list">
            <ul *ngFor="let row of educationAllRecords; let i = index">
              <li>
                <div class="lt-info">
                  <p>{{ row.universityName }}</p>
                  <p>
                    <small>{{ row.courseName }}</small>
                  </p>
                </div>
                <a
                  href="javascript:void(0);"
                  (click)="education(row)"
                  class="edit-link"
                  ><i class="fas fa-pen"></i> EDIT</a
                >
              </li>
            </ul>
          </div>
        </div>

        <div class="prfl-userbox">
          <div class="edt-hdng">
            <h4>Experience</h4>
            <a
              href="javascript:void(0);"
              (click)="experiance('NEW')"
              class="btn btn-green"
              >Add Experience</a
            >
          </div>
          <div class="profile-info-list">
            <ul *ngFor="let row of experianceAllRecords; let i = index">
              <li>
                <div class="lt-info">
                  <p>{{ row.company }}</p>
                  <p>
                    <small
                      >{{ row.title }}<br />{{ row.startDate | date }} –
                      {{
                        row.endDate == null ? "Present " : (row.endDate | date)
                      }}
                      • {{calculateDuration(row.startDate, row.endDate)}}
                    </small>
                  </p>
                </div>
                <a
                  href="javascript:void(0);"
                  (click)="experiance(row)"
                  class="edit-link"
                  ><i class="fas fa-pen"></i> EDIT</a
                >
              </li>
            </ul>
          </div>
        </div>

        <div class="prfl-userbox">
          <div class="edt-hdng">
            <h4>Licenses & certifications</h4>
            <a
              href="javascript:void(0);"
              (click)="certifiate('NEW')"
              class="btn btn-green"
              >Add Licenses & Certifications</a
            >
          </div>
          <div class="profile-info-list">
            <ul *ngFor="let row of licenseAllRecords; let i = index">
              <li>
                <div class="lt-info">
                  <p>{{ row.name }}</p>
                  <p>
                    <small
                      >{{ row.issuingOrganization }}<br />Issued -
                      {{ row.issueDate | date }} •
                      {{
                        row.endDate == null ? "Present" : (row.endDate | date)
                      }}
                      <br />Credential ID {{ row.credentialId }}</small
                    >
                  </p>
                </div>
                <a
                  href="javascript:void(0);"
                  (click)="certifiate(row)"
                  class="edit-link"
                  ><i class="fas fa-pen"></i> EDIT</a
                >
              </li>
            </ul>
          </div>
        </div>

        <div class="prfl-userbox">
          <div class="edt-hdng">
            <h4>Skills</h4>
            <a
              href="javascript:void(0);"
              (click)="skill()"
              class="btn btn-green"
              >Add Skills</a
            >
          </div>
          <div class="profile-skill-list">
            <figcaption>
              <mat-form-field
                class="example-chip-list custom-chip-set"
                [ngClass]="
                  this.skillAllRecords.length > 5
                    ? 'mobile-view-skill-section'
                    : ''
                "
                style="border: none"
              >
                <mat-chip-list
                  #chipList
                  aria-label="Fruit selection"
                  style="border: none"
                >
                  <mat-chip
                    style="border: none"
                    *ngFor="let fruit of this.skillAllRecords"
                  >
                    {{ fruit }}
                  </mat-chip>
                </mat-chip-list>
              </mat-form-field>
            </figcaption>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <div class="custom-page-height">



                <div class="card">
                    <h2>
                        Skills <span><a href="javascript:void(0);" class="btn-green editicon" (click)="skill()"><i
                            class="fas fa-pen"></i> EDIT
                                SKILLS</a></span>
                    </h2>
                    <div class="card-mid-description">
                        <div class="row">
                            <div class="col-lg-12">

                                <figcaption>
                                    <mat-form-field class="example-chip-list custom-chip-set" [ngClass]="this.skillAllRecords.length > 5 ? 'mobile-view-skill-section' : ''"style="border: none;">

                                        <mat-chip-list #chipList aria-label="Fruit selection" style="border: none;">
                                            <mat-chip style="border: none;" *ngFor="let fruit of this.skillAllRecords">
                                                {{fruit}}

                                            </mat-chip>

                                        </mat-chip-list>

                                    </mat-form-field>
                                </figcaption>
                            </div>
                        </div>
                    </div>

                   
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- [ngClass]="{'subhojitDisplay' : isDisplay == true }" -->
<!-- <a [href]="profileRecords?.profileImage" class="btn cancel-button" target="_self" download>Download</a> -->

<div *ngIf="isDisplay" id="htmlData" style="width: 100%; padding: 20px; font-family: Arial, sans-serif; color: #444;">
  <!-- Centered Name Section -->
  <div style="text-align: center; margin-bottom: 5px;">
      <h2 style="margin: 0; color: #3B423E;">{{ profileRecords?.firstName }} {{ profileRecords?.lastName }}</h2>
  </div>

    <!-- Contact Information Section -->
    <div style="text-align: center; margin-bottom: 12px;">
      <span style="font-size: 10px;">email: </span>
      <a [href]="'mailto:' + profileRecords?.email" style="text-decoration: none; color: #514dbc; font-size: 10px; margin-right: 10px;">
          {{ profileRecords?.email }}
      </a>
      <span style="font-size: 10px;">website: </span>
      <a [href]="profileRecords?.website" target="_blank" style="text-decoration: none; color: #514dbc; font-size: 10px;">
          {{ profileRecords?.website }}
      </a>
  </div>

  <!-- About Section -->
  <div style="background-color: #E8FFF3; padding: 20px; margin-bottom: 13px;">
      <h3 style="margin-bottom: 8px;">About</h3>
      <p style="color: #000; margin: 5px 0 10px 0; font-size: 12px; line-height: 18px;">{{ profileRecords?.about }}</p>
      <div style="display: flex; align-items: center;" *ngIf="profileRecords?.userLocation !== null">
          <img src="../assets/images/location.png" alt="Location Icon" style="width: 20px; margin-right: 5px;">
          <span>{{ profileRecords?.userLocation }}</span>
      </div>
  </div>

  <!-- Education Section -->
  <div style="margin-top: 13px;">
      <h3 style="margin-bottom: 8px;">Education</h3>
      <div *ngFor="let row of educationAllRecords" style="margin-bottom: 6px;">
          <strong>{{ row.universityName }}</strong><br>
          <span>{{ row.courseName }}</span>
      </div>
  </div>

  <!-- Experience Section -->
  <div *ngIf="experianceAllRecords?.length" style="margin-top: 13px;">
      <h3 style="margin-bottom: 8px;">Experience</h3>
      <div *ngFor="let row of experianceAllRecords" style="margin-bottom: 6px;">
        <strong style="color: #666;">{{ row.company }}</strong><br>
        <span>{{ row.title }}</span> <small> ({{ row.startDate | date:'MMM dd, yyyy' }} - {{ row.endDate ? (row.endDate | date:'MMM dd, yyyy') : 'Present' }})</small><br>
      </div>
  </div>

  <!-- Licenses & Certifications Section -->
  <div *ngIf="licenseAllRecords?.length" style="margin-top: 13px;">
      <h3 style="margin-bottom: 8px;">Licenses & Certifications</h3>
      <div *ngFor="let row of licenseAllRecords" style="margin-bottom: 6px;">
          <strong>{{ row.name }}</strong><br>
          <span>{{ row.issuingOrganization }}</span><br>
          <small>Issued: {{ row.issueDate | date }} – {{ row.endDate ? (row.endDate | date) : "Present" }}</small><br>
          <small>Credential ID: {{ row.credentialId }}</small>
      </div>
  </div>

  <!-- Skills Section -->
  <div *ngIf="skillAllRecords?.length" style="margin-top: 13px;">
      <h3 style="margin-bottom: 8px;">Skills</h3>
      <div style="display: flex; flex-wrap: wrap;">
          <span *ngFor="let skill of skillAllRecords" style="background-color: #E8FFF3; padding: 5px 10px; margin: 4px; border-radius: 4px; font-size: 10px;">{{ skill }}</span>
      </div>
  </div>
</div>

