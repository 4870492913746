<section class="filter-area">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 p-0">
        <form [formGroup]="jobFilter">
          <div [ngClass]="userDeatils !== undefined && userDeatils.is_3mtt === true ? 'notdarkgreen' : 'darkgreen'"
            class="darkgreen">
            <div class="form-wrap top-fltr" style="align-items: normal">
              <div class="filter-btn-clear">
                <!-- <button
                  class="btn-clear white-bdr"
                  *ngIf="showCancelButton"
                  (click)="resetSearchText()"
                >
                  <img src="../../../assets/images/close_24px.png" /> Clear
                  Search
                </button> -->
              </div>
              <div class="form-row bdr-right">
                <mat-form-field class="example-full-width crossButtonCustom" appearance="fill">
                  <mat-label>Search Jobs</mat-label>
                  <input type="text" matInput placeholder="What: Title, skill or company" [(ngModel)]="searchText2Way"
                    formControlName="searchText" (keyup)="onKey($event)" />
                  <!-- <i class="fa fa-times" *ngIf="ShowCross"></i> -->
                  <!-- <button mat-button matSuffix mat-stroked-button aria-label="close" style="border: none;"
                                    *ngIf="ShowCross" (click)="resetSearchText()">
                                    <mat-icon>close</mat-icon>
                                </button> -->
                </mat-form-field>
                <span *ngIf="f.searchText.touched && f.searchText.invalid">
                  <span class="text-left text-danger pb-1" *ngIf="f.searchText.errors.required">Search Title is required
                  </span>
                  <span class="text-left text-danger pb-1" *ngIf="f.searchText.errors?.pattern">
                    Search Title is not valid.
                  </span>
                </span>
              </div>
              <div class="form-row">
                <mat-form-field class="states-mat-form-field" appearance="fill">
                  <mat-label>States</mat-label>
                  <mat-select formControlName="job_location" placeholder=""
                    (selectionChange)="joblocationName($event.value)">
                    <mat-option>Any</mat-option>
                    <mat-option *ngFor="let row of cityRecords; let i = index" [value]="row">
                      {{ row }}</mat-option>
                  </mat-select>
                  <!-- <input
                        type="text"
                        aria-label="City"
                        matInput
                        formControlName="location"
                        [matAutocomplete]="auto"
                      />
                      <mat-autocomplete
                        autoActiveFirstOption
                        #auto="matAutocomplete"
                      >
                        <mat-option
                          *ngFor="let option of filteredLocation | async"
                          [value]="option.state"
                        >
                          {{ option.state }}
                        </mat-option>
                      </mat-autocomplete> -->
                </mat-form-field>
              </div>
              <div class="form-row">
                <button class="btn-red"
                  [ngClass]="userDeatils !== undefined && userDeatils.is_3mtt === true ? 'btn-green'  : 'btn-red' "
                  (click)="searchByDipendency(); resetSearch(showingTextResult)"
                  [disabled]="jobFilter.status === 'INVALID'">
                  Search Jobs
                </button>
              </div>
            </div>
          </div>
          <ng-container>
            <div class="select-filter cutom-wrap-for-job-search" [ngClass]="
                toggleInMobile
                  ? 'mobile-filter'
                  : 'mobile-filter mobile-filter-showOrHide'
              ">
              <div class="row filter-row">
                <div class="col-lg-6 d-flex align-items-center" style="margin: 0">
                  <div class="result-here">
                    <h5 *ngIf="this.showingTextResult" class="search-result-txt">
                      Showing Result
                      <span *ngIf="this.showingTextResult">for "{{ this.showingTextResult }}"</span>
                      <span *ngIf="this.location2Way">in "{{ this.location2Way }}"</span>
                    </h5>
                  </div>
                </div>
                <div class="col-lg-6 d-flex justify-content-center align-items-center"
                  style="overflow: hidden; margin: 0"
                  [ngClass]="userDeatils !== undefined && userDeatils.is_3mtt === true ? 'd-none' :  ''">
                  <div class="result-here">
                    <h5 class="filters-txt">Filters</h5>
                    <a class="sresults" href="javascript:void(0);">
                      {{ this.count }} filter applied</a>
                    <h5 class="clear-all-txt">
                      <a href="javascript:void(0);" *ngIf="this.count > 0" (click)="resetWithOutSearchText()">Clear
                        all</a>
                    </h5>
                  </div>
                </div>
              </div>
              <div class="row align-items-center" style="overflow: hidden; margin: 0; padding: 0 20px"
                [ngClass]="filterVisible ? 'justify-content-center' : (userDeatils !== undefined && userDeatils.is_3mtt) ? 'justify-content-center mt-1' :'justify-content-right'">
                <div class="col-lg-2 filter-container" [ngClass]="{ show: filterVisible}"
                  *ngIf="userDeatils !== undefined && userDeatils.is_3mtt"
                  [ngClass]="userDeatils!==undefined && userDeatils.is_3mtt ? 'visible' : 'hidden'">
                  <div class="mtt-row">
                    <mat-form-field class="example-full-width" appearance="fill">
                      <mat-label>ALL JOBS</mat-label>
                      <mat-select formControlName="only_3mtt" (selectionChange)="Change($event.value)">
                        <mat-option>ALL JOBS</mat-option>
                        <mat-option value=true>3MTT JOBS</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-lg-2 filter-container" [ngClass]="{ show: filterVisible }">
                  <div class="select-row">
                    <mat-form-field class="example-full-width" appearance="fill">
                      <mat-label>Date posted</mat-label>
                      <!-- <input matInput [matDatepicker]="picker" formControlName="created_at"
                                                [max]="todayDate" (dateChange)="Change()" readonly>
                                            <mat-datepicker-toggle matSuffix [for]="picker">
                                                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker> -->
                      <mat-select formControlName="created_at" (selectionChange)="Change($event.value)">
                        <mat-option>Any</mat-option>
                        <mat-option value="pastMonth">Past Month</mat-option>
                        <mat-option value="pastweek">Past Week</mat-option>
                        <mat-option value="past24hours">Past 24 Hours</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-lg-2 filter-container" [ngClass]="{ show: filterVisible }">
                  <div class="select-row">
                    <mat-form-field class="example-full-width" appearance="fill">
                      <mat-label>Type</mat-label>
                      <mat-select formControlName="employment_type_id" (selectionChange)="Change($event.value)">
                        <mat-option>Any</mat-option>
                        <mat-option value="{{ emplomentType?.id }}" *ngFor="
                            let emplomentType of employemntTypeList;
                            let i = index
                          ">
                          {{ emplomentType?.employmentType }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-lg-2 filter-container" [ngClass]="{ show: filterVisible }">
                  <div class="select-row">
                    <mat-form-field class="example-full-width" appearance="fill">
                      <mat-label>Currency </mat-label>
                      <mat-select formControlName="currency" (selectionChange)="Change($event.value)">
                        <mat-option>Any</mat-option>
                        <mat-option value="{{ currency.code }}" *ngFor="let currency of currencies">
                          {{ currency.code }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-lg-2 filter-container" [ngClass]="{ show: filterVisible }">
                  <div class="select-row">
                    <mat-form-field class="example-full-width" appearance="fill">
                      <mat-label>Salary Range</mat-label>
                      <mat-select formControlName="minimum_salary" (selectionChange)="Change($event.value)">
                        <mat-option>Any</mat-option>
                        <mat-option value="{{ min }}" *ngFor="let min of Salary">{{ min | currency : " ₦ " }} +
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <div class="col-lg-2 filter-container" [ngClass]="{ show: filterVisible }">
                  <div class="select-row">
                    <mat-form-field class="example-full-width" appearance="fill">
                      <mat-label>Experience </mat-label>
                      <mat-select formControlName="minimum_experience" (selectionChange)="Change($event.value)">
                        <mat-option>Any</mat-option>
                        <mat-option value="{{ row }}" *ngFor="let row of ExpLevel">{{ row }}
                          {{ row == 1 ? "year" : "years" }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <div class="col-lg-2 filter-container" [ngClass]="{ show: filterVisible }">
                  <div class="select-row">
                    <mat-form-field class="example-full-width" appearance="fill">
                      <mat-label>Company <i class="fas fa-search"></i></mat-label>
                      <input type="text" matInput placeholder="Company" formControlName="company_name"
                        (keyup.enter)="Change($event.target.value)" />
                    </mat-form-field>
                  </div>
                </div>

                <div class="col-lg-2 filter-container" [ngClass]="{ show: filterVisible }">
                  <div class="select-row">
                    <mat-form-field class="example-full-width" appearance="fill">
                      <mat-label>Category</mat-label>
                      <mat-select formControlName="category_id" (selectionChange)="Change($event.value)">
                        <mat-option>Any</mat-option>
                        <mat-option value="{{ jobCategoryType?.id }}" *ngFor="
                            let jobCategoryType of jobCatagoryTypeList;
                            let i = index
                          ">
                          {{ jobCategoryType?.categoryName }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <div class="col-lg-2 filter-container justify-content-center"
                  [ngClass]="userDeatils!==undefined && userDeatils.is_3mtt ? 'visible' : 'hidden'"
                  [ngClass]="{ show: !filterVisible}" *ngIf="userDeatils !== undefined && userDeatils.is_3mtt">
                  <div class="mtt-row">
                    <mat-form-field class="example-full-width" appearance="fill">
                      <mat-label>ALL JOBS</mat-label>
                      <mat-select formControlName="only_3mtt" (selectionChange)="Change($event.value)">
                        <mat-option>ALL JOBS</mat-option>
                        <mat-option value=true>3MTT JOBS</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="select-row example-full-width btn-filter-div"
                    [ngClass]="filterVisible ? '': 'justify-right'">
                    <button class="btn btn-primary btn-filter" [class.btn-filter-up]="filterVisible"
                      (click)="toggleFilter()">
                      {{ filterVisible ? "Hide Filter" : "Filter" }}
                      <img *ngIf="!filterVisible" src="../../../assets/images/mi-filter.png" />
                      <img *ngIf="filterVisible" src="../../../assets/images/arrow-up.png" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </form>
      </div>
    </div>
  </div>
</section>
<!-- <div style="display: none;"><app-job-filter-list ></app-job-filter-list></div> -->