import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { NgTinyUrlService } from 'ng-tiny-url';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { DynamicSnacbarSkipComponent } from 'src/app/container/elements/dynamic-snacbar-skip/dynamic-snacbar-skip.component';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { HttpIOService } from 'src/app/services/http-io.service';
import { JobFilterListComponent } from '../job-filter-list/job-filter-list.component';
import * as currency from '../../../../assets/jsons/currency _limited.json';
import { DataService } from 'src/app/services/data.service';
import { Observable } from 'rxjs';
import { PaginationService } from '../../../services/paginationService';

@Component({
  selector: 'app-job-filter',
  templateUrl: './job-filter.component.html',
  styleUrls: ['./job-filter.component.scss'],
})
export class JobFilterComponent implements OnInit {
  // @ViewChild(JobFilterListComponent) private myChild: JobFilterListComponent;
  jobFilter: UntypedFormGroup;
  jobLists: any;
  employemntTypeList: any;
  cityRecords: any;
  companyListData: any;
  currencies: any = (currency as any).default;
  jobFilterShow: boolean = false;
  companyData: any;
  userDeatils: any;
  public ExpLevel: any[] = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
  ];
  public jobPopularityData: any[] = ['hot', 'cold', 'new'];
  public Salary: any[] = [
    100000, 200000, 300000, 400000, 500000, 600000, 700000, 800000, 900000,
    1000000,
  ];
  public maxSalary: any = [];
  minsalaryvalue: any;
  payLoad: any = {};
  filtered: any = {};
  jobCatagoryTypeList: any;
  job_type_Home: any;
  JobsByCategory: any;
  date: Date;
  ShowCross: boolean = false;
  showCancelButton: boolean = false;
  toggleInMobile: boolean = true;
  todayDate: Date = new Date();
  count: number = 0;
  searchText2Way: any = '';
  showingTextResult: any = '';
  location2Way: any = '';
  searchTextPattern = /^[A-Za-z0-9][A-Za-z0-9\s]*$/;

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private io: HttpIOService,
    private auth: AuthService,
    private helper: Helper,
    private fb: UntypedFormBuilder,
    public datepipe: DatePipe,
    private tinyUrl: NgTinyUrlService,
    private dataService: DataService,
    private paginationService: PaginationService
  ) {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    if (localUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
    }
    if (sessionUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }
    console.log("user details: ", this.userDeatils);
  }

  ngOnInit(): void {
    this.validationForm();
    this.employementType();
    this.cityList();
    this.companyLIst();
    this.jobCatagory();
    this.io.getforjobCategory().subscribe((res) => {
      if (res != null) {
        (this.searchText2Way =
          res?.searchText != ''
            ? res?.searchText
            : this.jobFilter.get('searchText').value),
          // this.jobFilter.patchValue({
          //   searchText: (res?.searchText != '') ? res?.searchText : this.jobFilter.get('searchText').value,
          //   job_location: (res?.job_location != '') ? res?.job_location : this.jobFilter.get('job_location').value,
          //   job_type: res?.job_type
          // });
          (this.location2Way =
            res?.job_location != ''
              ? res?.job_location
              : this.jobFilter.get('job_location').value);
        this.ShowCross = true;
        this.showCancelButton = true;
      }
    });
    this.showingTextResult = sessionStorage.getItem('searchTerm');
    sessionStorage.removeItem('searchTerm')
  }

  joblocationName(e) {
    this.location2Way = e;
  }

  // Validation Controls //
  get f() {
    return this.jobFilter.controls;
  }

  // Insilize Form //
  validationForm() {
    this.jobFilter = this.fb.group({
      created_at: [''],
      startDate: [''],
      endDate: [''],
      job_location: [''],
      employment_type_id: [''],
      company_name: [''],
      minimum_experience: [''],
      // job_type: [''],
      currency: [''],
      minimum_salary: [''],
      category_id: [''],
      jobs: [''],
      only_3mtt: [''],
      searchText: ['', Validators.compose([
        Validators.pattern(this.searchTextPattern),
      ]),],
    });
    this.getValueByUrl();
  }

  getValueByUrl() {
    this.activeRoute.queryParams.subscribe((event) => {
      this.JobsByCategory = event['job_type_Category'];
      this.job_type_Home = event['job_type_Home'];
      let searchText = event['searchText'];
      let category_id = event['category_id'];
      let minimum_salary = event['minimum_salary'];
      let currency = event['currency'];
      // let maximum_salary = event['maximum_salary'];
      let job_location = event['job_location'];
      let company_name = event['company_name'];
      let employment_type_id = event['employment_type_id'];
      let job_type = event['job_type'];
      let created_at = event['created_at'];
      let minimum_experience = event['minimum_experience'];
      let only_3mtt = event['only_3mtt'];

      let patchBYurl = {
        minimum_experience: minimum_experience,
        searchText: searchText,
        currency: currency,
        minimum_salary: minimum_salary,
        // maximum_salary: maximum_salary,
        job_location: job_location,
        company_name: company_name,
        employment_type_id: employment_type_id,
        job_type: job_type,
        created_at: created_at,
        category_id: category_id,
        only_3mtt: only_3mtt,
      };
      if (this.job_type_Home) {
        this.jobFilter.patchValue({ job_type: this.job_type_Home });
        this.showCancelButton = true;
      }
      if (this.JobsByCategory) {
        this.jobFilter.patchValue({ category_id: this.JobsByCategory });
        this.showCancelButton = true;
      }
      if (searchText != undefined && searchText != '') {
        this.jobFilter.patchValue(patchBYurl);
        this.getvalue();
        this.ShowCross = true;
      }
    });
  }

  getvalue() {
    this.date = new Date();
    let latest_date = this.datepipe.transform(this.date, 'yyyy-MM-dd');
    this.filtered = {
      path: 'search-job',
      current_date: latest_date,
      size: 10,
      start: 0,
    };
    if (this.jobFilter.valid) {
      for (let key in this.jobFilter.value) {
        if (this.jobFilter.value[key]) {
          this.filtered[key] = this.jobFilter.value[key];
        }
      }
    }
    this.io.setforjobCategory(this.filtered);
  }
  // rangeValidation() {
  //   this.maxSalary = [];
  //   // let minSalary = this.jobFilter.get('minimum_salary').value;
  //   let minSalary = this.minsalaryvalue;
  //   for (let i = parseInt(minSalary); i < 12; i++) {
  //     this.maxSalary.push(i + 1);
  //   }
  // }

  onKey(event) {
  const searchTextControl = this.jobFilter.get('searchText');
  searchTextControl?.setValidators([
    Validators.required,
    Validators.pattern(this.searchTextPattern),
  ]);
  searchTextControl?.updateValueAndValidity();
    const inputValue = event.target.value;
    this.paginationService.setCurrentPage(1);
    if (inputValue != '') {
      this.ShowCross = true;
    }
  }

  toggleFilterInMobile() {
    this.toggleInMobile = !this.toggleInMobile;
  }

  resetSearch(title: any) {
    this.showingTextResult = this.searchText2Way;
    this.searchText2Way = '';
    this.dataService.nextData(this.showingTextResult);
    const searchTextControl = this.jobFilter.get('searchText');
    searchTextControl?.setValidators([Validators.pattern(this.searchTextPattern)]);
    searchTextControl?.updateValueAndValidity();
    this.jobFilter.controls['searchText'].reset();
    this.jobFilter.controls['job_location'].reset();
  }

  resetSearchText() {
    this.searchText2Way = '';
    this.location2Way = '';
    this.count = 0;
    if (this.userDeatils) {
      this.filtered = {
        path: 'search-job',
        userId: this.userDeatils.id,
        size: 10,
        start: 0,
      };
    } else {
      this.filtered = {
        path: 'search-job',
        size: 10,
        start: 0,
      };
    }
    this.io.setforjobCategory(this.filtered);
    this.ShowCross = false;
    this.showCancelButton = false;
    this.jobFilter.reset();
  }

  resetWithOutSearchText() {
    this.jobFilter.controls['created_at'].reset();
    this.jobFilter.controls['employment_type_id'].reset();
    this.jobFilter.controls['company_name'].reset();
    this.jobFilter.controls['currency'].reset();
    this.jobFilter.controls['minimum_experience'].reset();
    this.jobFilter.controls['job_type'].reset();
    this.jobFilter.controls['minimum_salary'].reset();
    this.jobFilter.controls['category_id'].reset();
    this.jobFilter.controls['only_3mtt'].reset();

    if (this.userDeatils) {
      this.filtered = {
        path: 'search-job',
        userId: this.userDeatils.id,
        size: 10,
        start: 0,
      };
    } else {
      this.filtered = {
        path: 'search-job',
        size: 10,
        start: 0,
      };
    }

    this.count = 0;
    if (this.jobFilter.valid) {
      for (let key in this.jobFilter.value) {
        if (this.jobFilter.value[key]) {
          this.filtered[key] = this.jobFilter.value[key];
        }
      }
    }
    this.io.setforjobCategory(this.filtered);
  }

  jobListForSearch() {
    if (this.userDeatils) {
      this.payLoad = {
        path: 'search-job',
        userId: this.userDeatils.id,
      };
    } else {
      this.payLoad = {
        path: 'search-job',
      };
    }

    this.io
      .apicall(this.payLoad, 'jobsearch/search-job')
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          // this.snackBar.open(res.serverResponse.message, "OK", {
          // })
          this.jobLists = res['result'].jobList;
          // this.detailsOfJob = this.jobLists[0];
        } else {
          this.snackBar
            .openFromComponent(DynamicSnacbarErrorComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
              duration: 6000,
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
        }
      });
    // .catch(err => {
    //   if (!err.error.serverResponse) {
    //     this.snackBar.open('Please check connectivity', "OK", {
    //     })
    //   }
    //   else {
    //     this.snackBar.open(err.error.serverResponse.message, "OK", {
    //     })
    //   }
    // })
  }

  // CIty List //
  cityList() {
    let data = { path: 'city-list' };
    this.io._cityList(data).subscribe((res: any) => {
      // console.log(res)
      if (res.serverResponse.code == 200) {
        this.cityRecords = res['result'].cityList.map((item) => item.state);
      }
    });
  }
  // Job Catogery //
  jobCatagory() {
    this.io
      .apicall({ path: 'category-list' }, 'job/category-list')
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          this.jobCatagoryTypeList = res['result'].categoryList;
        } else {
          this.snackBar
            .openFromComponent(DynamicSnacbarErrorComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
              duration: 6000,
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
        }
      });
  }

  // Company List //
  companyLIst() {
    let data = { path: 'company-list' };
    this.io._companyList(data).subscribe((res: any) => {
      // console.log(res)
      if (res.serverResponse.code == 200) {
        this.companyListData = res['result'].companyList;
      }
    });
  }

  // Employement Type //
  employementType() {
    this.io
      .apicall({ path: 'employment-type-list' }, 'job/employment-type-list')
      .subscribe((res: any) => {
        // console.log(res)
        if (res.serverResponse.code == 200) {
          this.employemntTypeList = res['result'].employmentList;
        } else {
          this.snackBar
            .openFromComponent(DynamicSnacbarErrorComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
              duration: 6000,
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
        }
      });
  }

  transformTreeDateTime(value = '', format = 'yyyy-MM-dd') {
    var datePipe = new DatePipe('en-US');
    value = datePipe.transform(value, format);
    return value;
  }
  transformTreeDateTimeUrl(value = '', format = 'yyyy-MM-dd') {
    var datePipe = new DatePipe('en-US');
    value = datePipe.transform(value, format);
    return value;
  }

  start() {
    if (this.jobFilter.get('created_at').value == 'pastMonth') {
      let today = new Date(),
        format = 'yyyy-MM-dd';
      let priorDate: any = new Date().setDate(today.getDate() - 30);
      let value = priorDate;
      var datePipe = new DatePipe('en-US');
      value = datePipe.transform(priorDate, format);
      return value;
    } else if (this.jobFilter.get('created_at').value == 'pastweek') {
      let today = new Date(),
        format = 'yyyy-MM-dd';
      let priorDate: any = new Date().setDate(today.getDate() - 7);

      let value = priorDate;
      var datePipe = new DatePipe('en-US');
      value = datePipe.transform(priorDate, format);
      return value;
    } else if (this.jobFilter.get('created_at').value == 'past24hours') {
      let value = new Date().toDateString(),
        format = 'yyyy-MM-dd';
      var datePipe = new DatePipe('en-US');
      value = datePipe.transform(value, format);
      return value;
    } else {
      return '';
    }
  }

  end() {
    if (this.jobFilter.get('created_at').value == 'anyTime') {
      return '';
    } else {
      let value = new Date().toDateString(),
        format = 'yyyy-MM-dd';
      var datePipe = new DatePipe('en-US');
      value = datePipe.transform(value, format);
      return value;
    }
  }

  Change(value: any) {
    this.paginationService.setCurrentPage(1);
    // console.log("value: ",value)
    this.searchByDipendency();
  }

  searchByDipendency() {
    // this.resetSearch();
    // this.showCancelButton = true;
    // if (this.jobFilter.status === 'INVALID') {
    //   this.helper.markFormGroupTouched(this.jobFilter);
    //   return false;
    // }
    // if (this.jobFilter.get('minimum_salary').value > this.jobFilter.get('maximum_salary').value) {
    //   // this.snackBar.open("Maximam should be greater ", "OK");
    //   this.snackBar.openFromComponent(DynamicSnacbarSkipComponent, {
    //     data:"Maximam should be greater "
    //   });
    //   return false;
    // }
    const searchTextControl = this.jobFilter.get('searchText');
    searchTextControl?.setValidators([Validators.pattern(this.searchTextPattern)]);
    searchTextControl?.updateValueAndValidity();  

    if (this.userDeatils) {
      this.filtered = {
        path: 'search-job',
        userId: this.userDeatils.id,
        size: 10,
        start: 0,
      };
    } else {
      this.filtered = {
        path: 'search-job',
        size: 10,
        start: 0,
      };
    }

    if (this.location2Way != '') {
      this.filtered['job_location'] = this.location2Way;
    }

    // const filtered = { path: "search-job"};
    this.count = 0;
    console.log("job filter: ", this.jobFilter)
    if (this.jobFilter.valid) {
      for (let key in this.jobFilter.value) {
        if (this.jobFilter.value[key]) {
          if (key != 'searchText' && key != 'job_location') {
            this.count = this.count + 1;
          }
          if (key == 'created_at' && this.jobFilter.value[key] != '') {
            this.filtered['endDate'] = this.end();
            this.filtered['startDate'] = this.start();
          } else {
            this.filtered[key] = this.jobFilter.value[key];
          }
        }
      }
      // searchText=nodejs&minimum_salary=30000&maximum_salary=50000&employment_type_id=a789gb61-8o9b-465d-gh9-ddbytopdr46rtesweol&job_location=lagos&category_id=a789gb61-8o9b-4hyd-9c79-ddb16jb1eol&created_at=2021-09-28T07:09:17Z&current_date=2021-10-06T10:33:29Z
      // let url = `http://localhost:4200/job-search?category_id=${this.jobFilter.get('category_id').value}&searchText=${this.jobFilter.get('searchText').value}&minimum_salary=${this.jobFilter.get('minimum_salary').value}&maximum_salary=${this.jobFilter.get('maximum_salary').value}&job_location=${this.jobFilter.get('job_location').value}&company_name=${this.jobFilter.get('company_name').value}&employment_type_id=${this.jobFilter.get('employment_type_id').value}&job_type=${this.jobFilter.get('job_type').value}&minimum_experience=${this.jobFilter.get('minimum_experience').value}&created_at=${this.jobFilter.get('created_at').value}`;
      // this.tinyUrl.shorten(`http://localhost:4200/job-search?category_id=${this.jobFilter.get('category_id').value}&searchText=${this.jobFilter.get('searchText').value}&minimum_salary=${this.jobFilter.get('minimum_salary').value}&maximum_salary=${this.jobFilter.get('maximum_salary').value}&job_location=${this.jobFilter.get('job_location').value}&company_name=${this.jobFilter.get('company_name').value}&employment_type_id=${this.jobFilter.get('employment_type_id').value}&job_type=${this.jobFilter.get('job_type').value}&minimum_experience=${this.jobFilter.get('minimum_experience').value}&created_at=${this.transformTreeDateTimeUrl(this.jobFilter.get('created_at').value)}`).subscribe(res => {
      //   // this.url = res;
      //   console.log(res); // https://tinyurl.com/8wa5w2o;
      // });
    }
    this.io.setforjobCategory(this.filtered);
  }

  // gotoProfile(){
  // //  let url = `http://localhost:4200/profile?category_id=${this.jobFilter.get('category_id').value}`/
  //  this.router.navigate(['/profile'], { queryParams: { id:  this.userDeatils.id } })
  // }
  ngOnDestroy(): void {
    this.io.setforjobCategory(null);
  }

  // For the filter button
  filterVisible = false;

  toggleFilter() {
    this.filterVisible = !this.filterVisible;
  }
}
