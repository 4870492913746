import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-employer-landing',
  templateUrl: './employer-landing.component.html',
  styleUrls: ['./employer-landing.component.scss']
})
export class EmployerLandingComponent implements OnInit {
  // Employers logos list
  logos = [
    '../../../../assets/images/tbo-logo.webp',
    '../../../../assets/images/zedcrest-logo.webp',
    '../../../../assets/images/aim-logo.webp',
    '../../../../assets/images/pecan-logo.webp',
    '../../../../assets/images/routelink-logo.webp',
    '../../../../assets/images/qlc-logo.webp',
    '../../../../assets/images/magna-logo.webp',
    '../../../../assets/images/manna-logo.webp',
    '../../../../assets/images/fohat-logo.webp',
    '../../../../assets/images/arise-logo.webp',
    '../../../../assets/images/richway-logo.webp',
    '../../../../assets/images/platview-logo.webp',
    '../../../../assets/images/cochlear-logo.webp',
    '../../../../assets/images/gosolve-logo.webp',
  ];

  items = [
    {
      title: 'Free Job Posting: ',
      text: 'List your job openings without any fees – attract top talent at no cost.',
    },
    {
      title: 'Quality CV Access: ',
      text: 'Browse a vast database of pre-screened candidates to find the perfect fit for your roles.',
    },
    {
      title: 'Advanced Search Filters: ',
      text: 'Easily narrow down candidates by skills, experience, location, and more.',
    },
    {
      title: 'Unlimited Candidate Applications: ',
      text: 'Receive applications from a wide range of qualified candidates, ensuring you never miss out on the best talent.',
    },
  ];

  constructor(private router: Router,) {}

  ngOnInit(): void {}

  goToEmployerSignup() {
    this.router.navigate(['/signupemployer'])
  }
}
