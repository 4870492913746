import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { SuccecErrorModalComponent } from 'src/app/alert-message/succec-error-modal/succec-error-modal.component';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';
import { Helper } from '../../../../helper/defult';
import { HttpIOService } from 'src/app/services/http-io.service';
import { SignUpComponent } from '../../sign-up/sign-up.component';

@Component({
  selector: 'app-complete-registration-jobseeker',
  templateUrl: './complete-registration-jobseeker.component.html',
  styleUrls: ['./complete-registration-jobseeker.component.scss'],
})
export class CompleteRegistrationJobseekerComponent implements OnInit {
  completeRegiSeeker: UntypedFormGroup;
  passwordPattern =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  namePattern = /^[A-Za-z]+(?: [A-Za-z]+)*$/;
  email: any;
  role: any;
  GuestUserjobId: any;
  public showPassword: boolean = false;
  constructor(
    // @Inject(MAT_DIALOG_DATA) public data: any,
    // public dialogRef: MatDialogRef<CompleteRegistrationJobseekerComponent>,
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private io: HttpIOService,
    private helper: Helper,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar
  ) {
    let guestUserByselected = sessionStorage.getItem('guestUserSelectedJob');
    if (guestUserByselected) {
      this.GuestUserjobId = JSON.parse(
        atob(sessionStorage.getItem('guestUserSelectedJob'))
      );
    }
    this.route.queryParams.subscribe((params) => {
      this.email = params['email'];
      this.role = params['role'];
    });
  }

  ngOnInit(): void {
    this.validationForm();
  }

  // Form Insilize //
  validationForm() {
    this.completeRegiSeeker = this.fb.group({
      email: [this.email, Validators.required],
      firstName: ['', Validators.compose([
        Validators.required,
        Validators.pattern(this.namePattern),
      ]),],
      lastName: ['', Validators.compose([
        Validators.required,
        Validators.pattern(this.namePattern),
      ]),],
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.passwordPattern),
        ]),
      ],
      userType: ['jobseeker', Validators.required],
      path: ['register-job-seeker', Validators.required],
    });
  }

  // Validation Controls //
  get f() {
    return this.completeRegiSeeker.controls;
  }

  // Back To Location //
  signUP() {
    this.router.navigate(['/signup'], {
      queryParams: { _role: 'jobseeker', email: this.email },
    });
  }

  // Submit Registration //
  completeRegistrasion() {
    if (this.completeRegiSeeker.status === 'INVALID') {
      this.helper.markFormGroupTouched(this.completeRegiSeeker);
      return false;
    }
    this.io
      .apicall(this.completeRegiSeeker.value, 'auth/register-job-seeker')
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          // this.dialogRef.close();
          // const dialogRef = this.dialog.open(SuccecErrorModalComponent, {
          //   height: 'auto',
          //   width: '450px',
          //   data: { message: res.serverResponse.message }
          // });
          // dialogRef.afterClosed().subscribe(res => {
          //   if (res === true) {

          //   }
          // });
          // this.snackBar.openFromComponent( DynamicSnacbarComponent, {
          //   data: res.serverResponse.message
          // });
          this.snackBar
            .openFromComponent(DynamicSnacbarComponent, {
              data: 'Your account has been successfully created. Please check your email to verify your account',

              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
          // if (this.GuestUserjobId) {
          //   this.router.navigate(['/job-search'])
          // }
          // else{
          this.router.navigate(['/login']);
          // }
        } else {
          // const dialogRef = this.dialog.open(SuccecErrorModalComponent, {
          //   height: 'auto',
          //   width: '450px',
          //   data: { message: res.serverResponse.message }
          // });
          // dialogRef.afterClosed().subscribe(res => {
          //   if (res === true) {
          //   }
          // });
          this.snackBar
            .openFromComponent(DynamicSnacbarErrorComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
              duration: 6000,
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
        }
      });
    // .catch(err => {
    //   if (!err.error.serverResponse) {
    //     // const dialogRef = this.dialog.open(SuccecErrorModalComponent, {
    //     //   height: 'auto',
    //     //   width: '450px',
    //     //   data: { message: 'Please check connectivity' }
    //     // });
    //     // dialogRef.afterClosed().subscribe(res => {
    //     //   if (res === true) {
    //     //   }
    //     // });
    //     this.snackBar.open('Please check connectivity', "OK", {
    //     })
    //   }
    //   else {
    //     // const dialogRef = this.dialog.open(SuccecErrorModalComponent, {
    //     //   height: 'auto',
    //     //   width: '450px',
    //     //   data: { message: err.error.serverResponse.message }
    //     // });
    //     // dialogRef.afterClosed().subscribe(res => {
    //     //   if (res === true) {
    //     //   }
    //     // });
    //     this.snackBar.open(err.error.serverResponse.message, "OK", {
    //     })
    //   }

    // })
  }
  // Show Pasword //
  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }
}
